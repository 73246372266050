import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";

@Injectable({
  providedIn: 'root'
})
export class CalendarioService{
  private api = ConvivaApi;

  constructor(
    private http: HttpClient)
  { }

  public getConsultarCalendario(escolaId:number,anoLetivo:number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Diario.ConsultarCalendario}?AnoLetivo=${anoLetivo}&EscolaId=${escolaId}`)
                    .pipe(map((response: DefaultResponse) => response));
  }
}
