import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector:'step-bar',
  templateUrl: './step-bar.component.html',
  styleUrls: ['./step-bar.component.scss']
})
export class StepBarComponent{

  @Input() passoAtual:number = 1;
  @Input() temDeslocamento: boolean = false;
  @Output() outPasso = new  EventEmitter<number>();

  public setPasso(novoPasso:number){
    this.passoAtual = novoPasso;
    this.outPasso.emit(this.passoAtual);
  }
}
