//Principais
import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { DataTableDirective } from "angular-datatables";

//Métodos
import { Polo } from "src/app/shared/models/polo/Polo";
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { TurmaAluno } from "src/app/shared/models/aluno/TurmaAluno";
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { Funcionario } from 'src/app/shared/models/envolvidos/funcionario';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { ListarChamados } from 'src/app/shared/models/chamados/listar-chamados';
import { AtividadePsicologo } from 'src/app/shared/models/psicologo/AtividadePsicologo';
import { TipoPessoa } from 'src/app/shared/enums/envolvidos/TipoPessoa';

//Serviços
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Turma } from 'src/app/shared/models/turma/Turma';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { FuncionarioService } from 'src/app/shared/services/funcionarios.service';
import { GenericValidator } from 'src/app/shared/validations/genericValidators';
import { EnvolvidoService } from 'src/app/shared/services/envolvido.service';
import { ActivatedRoute } from '@angular/router';
import { AtividadePsicologoDeslocamento } from 'src/app/shared/models/atividade-psicologo/atividade-psicologo-deslocamento';
import { BaseComponent } from 'components/base/base.component';
import { formatDate } from '@angular/common';
import { AtividadePsicologoOutros } from 'src/app/shared/models/psicologo/AtividadePsicologoOutros';


@Component({
  templateUrl: './psicologo-visita-outros.component.html',
  styleUrls: ['./psicologo-visita-outros.component.scss']
})

export class PageVisitaPsicologoOutrosComponent extends BaseComponent implements OnInit {

  @Input({ required: true }) temDeslocamento: boolean;

  // Grid Datatable


  constructor(
    private config: NgSelectConfig,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private arquivoService: ArquivoService,
    private envolvidoService:EnvolvidoService,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    super();
    this.config.notFoundText = 'Não encontrado';

    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }

    this.route.params.subscribe((params) => {
      if(params.temDeslocamento === '1'){
        this.temDeslocamento = true;
      }
      else {
        this.temDeslocamento = false;
      }
    });
  }

  // Passos de Proximo
  public Passo:number = 1;

  // Objetos e Filtros
  public psicologos: Psicologo[] = [];
  public lstPsicologos: Psicologo[] = [];
  public files: ArquivoAzure[] = [];
  public envolvidos: Envolvido[] = [];
  public lstEnvolvidos: Envolvido[] = [];
  public lstEnvolvidosSelecionados: Envolvido[] = [];
  public modelDeslocamento: AtividadePsicologoDeslocamento | null;

  public FormValues: any;
  public psicologoSelecionado:Psicologo ;
  public duracaoVisita = 15;
  public enableEnviarAprovacao:boolean;
  public qtdeCaracteresDescricaoAcao = 0;


  public lstAtividadeRealizada = [
    { id: 1, name: "Conversa/atividade individual com estudante" },
    { id: 2, name: "Conversa/atividade em grupo" },
    // { id: 3, name: "Conversa/atividade com profissionais" }
  ];

  public lstGrupos = [
    {id: 1, name:"Turmas participantes da atividade" },
    {id: 2, name:"Equipe escolar" },
    {id: 3, name:"Outros" }
  ];

  public tipoPessoaAdd = [
    {descricao: 'Outros', id: 1},
    {descricao: 'Profissional', id: 2}
  ];

  // Forms
  public formVisita:FormGroup  = new FormGroup({
    //Filtros
    codigoPolo: new FormControl(null, [Validators.required]),
    codigoDiretoria: new FormControl(null, [Validators.required]),
    nomeDiretoria: new FormControl(null, [Validators.required]),
    codigoEscola: new FormControl(null, [Validators.required]),
    nomeEscola: new FormControl(null, [Validators.required]),
    //PassoUm
    PassoUm: new FormGroup({
      codigoPsicologo: new FormControl(null, [Validators.required]),
      dataVisita: new FormControl(null, [Validators.required]),
    }),
    PassoDois:new FormGroup({
      descricaoAcao: new FormControl(null, [Validators.required]),
      arquivos: new FormControl(null),
    })
  });

  public get form() { return this.formVisita.controls; }
  public get formPassoUm() { return (this.formVisita.get('PassoUm') as FormGroup).controls; }
  public get formPassoDois() { return (this.formVisita.get('PassoDois') as FormGroup).controls; }
  // public get form() { return this.formVisita.; }
  public submitted: boolean = false;

  public async ngOnInit() {
    //#endregion

    if(this.userLogado?.flPsicologo){
      await this.getPoloDiretoriaPsicologo();
    }
    else{
        if( this.atribuicoesLogin.NumeroComportamento == 1) {
          await this.getPolosAdmin();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 2) {
          await this.getPolosPerfilDiretor();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
          await this.getPolosPerfilProfessor();
        }
    }

    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0) {
      await this.getDiretoriasPorPolo();
      await this.getEscolasPorDiretoria();
    }
  }

  public async getPsicologosPolo() {
    if(this.userLogado?.codigoPerfil == 1634) { //Codigo Perfil Psicologo
      this.spinnerService.show();
      const response = await lastValueFrom(this.psicologoService.getPsicologoPorCPF(this.userLogado.cpf))
                            .catch((err) => {
                              return this.ConvivaErrors.handleResultError(err);
                            });

      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.spinnerService.hide();

      this.lstPsicologos = [];

      let psicologo: Psicologo = new Psicologo;
      psicologo.nomePsicologo = response.data.nome;
      psicologo.codigoPsicologo = response.data.codigoPsicologo;

      this.lstPsicologos.push(psicologo);

      this.formPassoUm.codigoPsicologo.setValue(psicologo.codigoPsicologo);
      this.psicologoSelecionado = psicologo;

    }
    else {
      this.spinnerService.show();
      const response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(this.formVisita.get('codigoPolo')!.value))
                            .catch((err) => {
                              return this.ConvivaErrors.handleResultError(err);
                            });

      if(!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.spinnerService.hide();
      this.lstPsicologos = response.data;
    }
  }

  //#region Polos

  public async getPoloDiretoriaPsicologo() {
    this.spinnerService.show();
    const response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
    await this.getEscolasPorDiretoria();

    await this.getPsicologosPolo();
  }

  public async getPolosAdmin() {
    this.spinnerService.show();

    const response = await lastValueFrom(this.psicologoService.getPolos())
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = response.data;

    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0)
      this.ativaPolo();

    this.spinnerService.hide();

    await this.getPsicologosPolo();
  }

  public async getPolosPerfilDiretor(){
    this.spinnerService.show();

    const response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if(this.userLogado?.DadosUsuario[0].CodigoDiretoria && this.userLogado?.DadosUsuario[0].CodigoDiretoria > 0) {
      let todasDiretoriasDoUsuario:Diretoria[] = [];

      this.userLogado?.DadosUsuario.forEach(item=>{
        let diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria) ;
      });

      this.diretorias = todasDiretoriasDoUsuario;

      if(this.diretorias.length == 1)
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      else
        this.form.codigoDiretoria.enable();

      if(this.userLogado.codigoDiretoria && this.userLogado.codigoDiretoria != 0)
        this.form.codigoDiretoria.setValue(this.userLogado.codigoDiretoria);




      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor(){
    this.spinnerService.show();

    const response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

    if(!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    const dadosUsuario = this.userLogado?.DadosUsuario[0];
    if(dadosUsuario?.CodigoDiretoria && dadosUsuario?.CodigoDiretoria > 0 && dadosUsuario?.CodigoEscola && dadosUsuario?.CodigoEscola > 0) {

      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas = [escola];
      });

      this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }

    this.spinnerService.hide();
    await this.getPsicologosPolo();
  }

  public ativaPolo() {
    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0)
      this.formVisita.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);

    if(this.userLogado)
      this.authService.setUser(this.userLogado);
  }

  public async cambioPolo() {
    const codigoPO = this.formVisita.get('codigoPolo')?.value;

    if(this.userLogado) {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }

    this.form.codigoDiretoria.enable();
    this.form.codigoEscola.enable();

    this.zerarEscola();
    await this.getDiretoriasPorPolo();
  }

  //#endregion

  //#region Diretorias

  public async getDiretoriasPorPolo() {
    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0) {

      this.zerarDiretoria();
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();

      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();

      this.ativaDiretoria();
      this.spinnerService.hide();
    }

    if(this.userLogado) {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria ;
      this.authService.setUser(this.userLogado);
    }
  }

  public ativaDiretoria(){
    if(this.diretorias && this.userLogado?.codigoDiretoria != 0) {

      const diretoria = this.diretorias.find(s=>s.codigoDiretoria == this.userLogado?.codigoDiretoria);

      if(this.userLogado?.codigoDiretoria  && diretoria)
        this.formVisita.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    }

    if(this.userLogado)
        this.authService.setUser(this.userLogado);
  }

  public zerarDiretoria() {
    this.diretorias = [];
    this.formVisita.get('codigoDiretoria')?.patchValue(null);
  }

  //#endregion

  //#region Escolas

  public async getEscolasPorDiretoria() {
    if(this.userLogado) {
      this.userLogado.codigoEscola = this.userLogado.codigoEscola == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if(this.userLogado?.codigoDiretoria && this.userLogado?.codigoDiretoria > 0) {
      this.spinnerService.show();
      this.zerarEscola();

      const response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(this.userLogado!.codigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.escolas = response.data;
      this.ativaEscola();
      this.spinnerService.hide();
    }

  }

  public ativaEscola() {
    if(this.escolas && this.userLogado?.codigoEscola && this.userLogado?.codigoEscola > 0) {

      const escola = this.escolas.find(s => s.codigoEscola == this.userLogado?.codigoEscola);

      if(this.userLogado?.codigoEscola && escola)
        this.formVisita.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formVisita.get('codigoEscola')?.patchValue(null);
  }

  public async trocaDiretoria() {
    const codigoDiretoria = this.formVisita.get('codigoDiretoria')?.value;

    if(this.userLogado && codigoDiretoria) {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }

    if(codigoDiretoria)
      await this.getEscolasPorDiretoria();
    else{
      this.escolas = [];
      this.form.codigoEscola.setValue(null);
    }
  }

  //#endregion

  //#region Salvar/Limpar


  public async OnSalvar() {
    this.spinnerService.show();

    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.form.codigoDiretoria.value)[0];
    var nomeEscola = '';
    if(this.form.codigoEscola.value){
      const esc = this.escolas.filter(r => r.codigoEscola == this.form.codigoEscola.value)[0];
      nomeEscola = esc.nomeEscola;
    }

    const objAtendimentoPsicologo: AtividadePsicologoOutros = {
      codigoPolo: this.FormValues.codigoPolo,
      codigoDiretoria: this.form.codigoDiretoria.value,
      nomeDiretoria: dir.nomeDiretoria,
      codigoEscola:this.form.codigoEscola.value,
      nomeEscola: nomeEscola,
      codigoPsicologo: this.FormValues.PassoUm.codigoPsicologo,
      dataAtividadePsicologo: this.FormValues.PassoUm.dataVisita,
      timeAtividadePsicologo: this.duracaoVisita.toString(),
      descricaoAcao:this.FormValues.PassoDois.descricaoAcao,
      tipoAcao: 5, //Inserir
      codigoUsuario: this.userLogado?.flPsicologo ? this.userLogado.codigoUsuario : this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.userLogado?.flPsicologo ? this.userLogado.codigoPerfil : this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.userLogado?.flPsicologo ? this.userLogado.nomePerfil : this.atribuicoesLogin.NomePerfil,
      arquivos: this.files
    };

    if(this.temDeslocamento) {
     objAtendimentoPsicologo.codigoDeslocamentoDiretoriaOrigem = this.modelDeslocamento?.codigoDiretoriaOrigem;
     objAtendimentoPsicologo.nomeDeslocamentoDiretoriaOrigem = this.modelDeslocamento?.nomeDiretoriaOrigem;
     objAtendimentoPsicologo.codigoDeslocamentoEscolaOrigem = this.modelDeslocamento?.codigoEscolaOrigem;
     objAtendimentoPsicologo.nomeDeslocamentoEscolaOrigem = this.modelDeslocamento?.nomeEscolaOrigem;
     objAtendimentoPsicologo.codigoDeslocamentoDiretoriaDestino = this.modelDeslocamento?.codigoDiretoriaDestino;
     objAtendimentoPsicologo.nomeDeslocamentoDiretoriaDestino = this.modelDeslocamento?.nomeDiretoriaDestino;
     objAtendimentoPsicologo.codigoDeslocamentoEscolaDestino = this.modelDeslocamento?.codigoEscolaDestino;
     objAtendimentoPsicologo.nomeDeslocamentoEscolaDestino = this.modelDeslocamento?.nomeEscolaDestino;
     objAtendimentoPsicologo.horaDeslocamentoSaida = this.modelDeslocamento?.horarioSaida.substring(0,5);
     objAtendimentoPsicologo.horaDeslocamentoChegada = this.modelDeslocamento?.horarioChegada.substring(0,5);
    }


    const responseCadastro = await lastValueFrom(this.psicologoService.setCadastrarAtividadePsicologoOutros(objAtendimentoPsicologo))
                                  .catch((err) => {
                                    this.spinnerService.hide();
                                    return this.ConvivaErrors.handleResultError(err);
                                  });

    if(!responseCadastro.isSucess) {
      this.toastr.error(responseCadastro.message);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Visita do Psicologo cadastrada com sucesso.');
    this.toastr.success('Redirecionando para o ínicio em 5 segundos.');
    // this.enableEnviarAprovacao = false;

    setTimeout(() => {
      window.location.reload();
    }, 5000);

    this.spinnerService.hide();
  }

  public async OnLimpar() {
    this.spinnerService.show();

    this.formPassoUm.codigoPsicologo.setValue(null);
    this.formPassoUm.dataVisita.setValue(null);
    this.formPassoDois.descricaoAcao.setValue(null);

    this.files = [];

    await this.getPsicologosPolo();

    this.spinnerService.hide();
  }



  public proximoPassoDeslocamento(modelDeslocamento: AtividadePsicologoDeslocamento) {
    this.modelDeslocamento = modelDeslocamento;
    this.Passo = 2; // Proximo passo do acolhimento
  }

  public setNovoPasso(novoPasso:number) {
    var passoAvaliar = novoPasso - 1 - (this.temDeslocamento ? 1 : 0);
    this.submitted =true;
    this.FormValues = this.formVisita.getRawValue();


    if(passoAvaliar == 1 && (this.formVisita.get('PassoUm')!.invalid)){
      // this.toastr.warning("Todos os campos devem ser preenchidos.");
      return;
    }

    if(passoAvaliar == 2 && (this.formVisita.get('PassoDois')!.invalid || this.files.length == 0)){
      // this.toastr.warning("Todos os campos devem ser preenchidos.");
      return;
    }

    this.Passo = novoPasso;
    this.submitted = false;
  }


  public setVoltarPasso(PassoAtual:number) {
    if(PassoAtual - 1 > 0) {
      this.Passo = PassoAtual - 1;
    }
    else {
      this.toastr.info('Não pode voltar da primeira pagina ...');
    }
  }

  // public ajusteValidacaoInfoPsicologos() {
  //   if (this.formVisita) {
  //     this.ajusteValidacaoTodoForm();

  //     this.formVisita.get('nomeDiretoria')?.removeValidators([Validators.required]);
  //     this.formVisita.get('nomeDiretoria')?.updateValueAndValidity();
  //     this.formVisita.get('nomeEscola')?.removeValidators([Validators.required]);
  //     this.formVisita.get('nomeEscola')?.updateValueAndValidity();

  //     this.formVisita.get('descricaoAcao')?.removeValidators([Validators.required]);
  //     this.formVisita.get('descricaoAcao')?.updateValueAndValidity();

  //     this.formVisita.get('arquivos')?.removeValidators([Validators.required]);
  //     this.formVisita.get('arquivos')?.updateValueAndValidity();
  //   }
  // }

  // public ajusteValidacaoInfoAcoes() {
  //   if (this.formVisita) {
  //     this.ajusteValidacaoTodoForm();

  //     this.formVisita.get('codigoPolo')?.removeValidators([Validators.required]);
  //     this.formVisita.get('codigoPolo')?.updateValueAndValidity();

  //     this.formVisita.get('codigoDiretoria')?.removeValidators([Validators.required]);
  //     this.formVisita.get('codigoDiretoria')?.updateValueAndValidity();
  //     this.formVisita.get('nomeDiretoria')?.removeValidators([Validators.required]);
  //     this.formVisita.get('nomeDiretoria')?.updateValueAndValidity();

  //     this.formVisita.get('codigoEscola')?.removeValidators([Validators.required]);
  //     this.formVisita.get('codigoEscola')?.updateValueAndValidity();
  //     this.formVisita.get('nomeEscola')?.removeValidators([Validators.required]);
  //     this.formVisita.get('nomeEscola')?.updateValueAndValidity();

  //     this.formVisita.get('codigoPsicologo')?.removeValidators([Validators.required]);
  //     this.formVisita.get('codigoPsicologo')?.updateValueAndValidity();

  //     this.formVisita.get('dataVisita')?.removeValidators([Validators.required]);
  //     this.formVisita.get('dataVisita')?.updateValueAndValidity();

  //     this.formVisita.get('codigoAtividade')?.removeValidators([Validators.required]);
  //     this.formVisita.get('codigoAtividade')?.updateValueAndValidity();

  //     this.formVisita.get('codigoChamado')?.removeValidators([Validators.required]);
  //     this.formVisita.get('codigoChamado')?.updateValueAndValidity();

  //     this.formVisita.get('codigoAcaoPreventiva')?.removeValidators([Validators.required]);
  //     this.formVisita.get('codigoAcaoPreventiva')?.updateValueAndValidity();

  //     this.formVisita.get('tipoGrupo')?.removeValidators([Validators.required]);
  //     this.formVisita.get('tipoGrupo')?.updateValueAndValidity();
  //   }
  // }

  // public ajusteValidacaoTodoForm() {
  //   if (this.formVisita) {
  //     this.formVisita.get('codigoPolo')?.addValidators([Validators.required]);
  //     this.formVisita.get('codigoPolo')?.updateValueAndValidity();

  //     this.formVisita.get('codigoDiretoria')?.addValidators([Validators.required]);
  //     this.formVisita.get('codigoDiretoria')?.updateValueAndValidity();
  //     this.formVisita.get('nomeDiretoria')?.addValidators([Validators.required]);
  //     this.formVisita.get('nomeDiretoria')?.updateValueAndValidity();

  //     this.formVisita.get('codigoEscola')?.addValidators([Validators.required]);
  //     this.formVisita.get('codigoEscola')?.updateValueAndValidity();
  //     this.formVisita.get('nomeEscola')?.addValidators([Validators.required]);
  //     this.formVisita.get('nomeEscola')?.updateValueAndValidity();

  //     this.formVisita.get('codigoPsicologo')?.addValidators([Validators.required]);
  //     this.formVisita.get('codigoPsicologo')?.updateValueAndValidity();

  //     this.formVisita.get('dataVisita')?.addValidators([Validators.required]);
  //     this.formVisita.get('dataVisita')?.updateValueAndValidity();

  //     this.formVisita.get('codigoAtividade')?.addValidators([Validators.required]);
  //     this.formVisita.get('codigoAtividade')?.updateValueAndValidity();

  //     this.formVisita.get('codigoChamado')?.addValidators([Validators.required]);
  //     this.formVisita.get('codigoChamado')?.updateValueAndValidity();

  //     this.formVisita.get('codigoAcaoPreventiva')?.addValidators([Validators.required]);
  //     this.formVisita.get('codigoAcaoPreventiva')?.updateValueAndValidity();

  //     this.formVisita.get('tipoGrupo')?.addValidators([Validators.required]);
  //     this.formVisita.get('tipoGrupo')?.updateValueAndValidity();

  //     this.formVisita.get('objetivoAcao')?.addValidators([Validators.required]);
  //     this.formVisita.get('objetivoAcao')?.updateValueAndValidity();

  //     this.formVisita.get('avaliacaoResultado')?.addValidators([Validators.required]);
  //     this.formVisita.get('avaliacaoResultado')?.updateValueAndValidity();

  //     this.formVisita.get('impactoAcao')?.addValidators([Validators.required]);
  //     this.formVisita.get('impactoAcao')?.updateValueAndValidity();

  //     this.formVisita.get('arquivos')?.removeValidators([Validators.required]);
  //     this.formVisita.get('arquivos')?.updateValueAndValidity();

  //   }
  // }

  //#endregion


  public getDate(data?: Date) {
    if (data == undefined) return;
    return formatDate(data, 'dd/MM/yyyy', this.locale);
  }


  public concatenaDataHora(data:string,hora:string): string{
    return `${data +" " + hora}`
  }

  public async OnFileChange(event: any) {
    const maxFileSize =  31 * 1024 * 1024; // 30 MB
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      for (const file of files) {
        if (file.size > maxFileSize) {
          this.toastr.warning('O tamanho máximo do arquivo é 30 MB.');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
        if(file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png" ||
          file.type ==  "application/pdf" ||
          file.type ==  "application/ppt" ||
          file.type ==  "application/pptx" ||
          file.type ==  "application/potx" ||
          file.type ==  "application/ppsx" ||
          file.type ==  "application/odp" ||
          file.type == "audio/mpeg" ||
          file.type == "video/mp4"
          ){
          formData.append(file.name, file);
        }
        else{
          this.toastr.warning('Só é possivel subir arquivos .jpg, .jpeg , .png, .pdf, .mp3 , .mp4, .ppt, .pptx, .potx e ppsx');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
      }
      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }else {
        this.files.push(...result.data);
        event.target.value = '';
      }
      this.spinnerService.hide();
    }
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
    const fileInput = document.getElementById('arquivos') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  public removeUniqueId(fileName: string): string {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/;
    return fileName.replace(regex, '');
  }


  public ValidaPassoAtual(passo:number):boolean{
    return this.Passo == passo;
  }





  // public OnChangeAtividade(){
  //   var atividade = this.formVisita.get('codigoAtividade');
  //   this.atividadeRealizadaSelecionado = this.lstAtividadeRealizada.find((fun) => fun.id === atividade?.value)?.name;
  // }

  public changeInputRange(){
    const inputRange =  document.getElementById('input-range') as HTMLFormElement;
    this.duracaoVisita = inputRange.value;
  }



  OnChangeCountLetters(campo: number){
      const dsAcao = this.formPassoDois.descricaoAcao.value;
      this.qtdeCaracteresDescricaoAcao = dsAcao.length;

  }

  OnChangePsicologo(){

    const psicologo = this.lstPsicologos.find(x=>x.codigoPsicologo == this.formPassoUm.codigoPsicologo.value)
    if(psicologo)
      this.psicologoSelecionado = psicologo;

  }
}
