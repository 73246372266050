<form [formGroup]="formListarChamado" (submit)="OnPesquisar()">
  <breadcrumb
    [paginas]="[{route:'/home',name:'Home'},{route:'/chamados-list',name:'Tela de Chamados'}]"
  />
  <!-- FILTRO ESCOLA -->
    <section class="section-filtro">
      <div class="container">
        <div class="grupo-filter">
          <p><b>Nº Chamado </b></p>
          <input
            type="number"
            formControlName="codigoChamado"
            id="codigoChamado"
            onlyNumber
            placeholder="Digite o Id de um chamado."
            name="codigoChamado"
            (blur)="limpaCamposDiretoriaEscola()"
            class="form-control input-cd"
          />

        </div>
        <div class="grupo-filter">
          <p><b>Diretoria</b></p>
          <ng-select
            [items]="diretorias"
            bindLabel="nomeDiretoria"
            bindValue="codigoDiretoria"
            placeholder="Selecione uma Diretoria"
            formControlName="codigoDiretoria"
            name="codigoDiretoria"
            [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
            (change)="getEscolas()"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
            <div *ngIf="form.codigoDiretoria.errors.required">
              * Diretoria é obrigatório
            </div>
          </div>
        </div>

        <div class="grupo-filter">
          <p><b>Escola</b></p>
          <ng-select
            [items]="escolas"
            bindLabel="nomeEscola"
            bindValue="codigoEscola"
            placeholder="Selecione uma Escola"
            formControlName="codigoEscola"
            name="codigoEscola"
            (change)="getTurnos()"
            [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
          >
          </ng-select>

          <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
            <div *ngIf="form.codigoEscola.errors.required">
              * Escola é obrigatório
            </div>
          </div>
        </div>

        <div class="grupo-filter">
          <p><b>Turno</b></p>
          <ng-select
            [items]="turnos"
            bindLabel="nomeTurno"
            bindValue="codigoTurno"
            placeholder="Selecione um Turno"
            formControlName="codigoTurno"
            name="codigoTurno"
            [ngClass]="{ 'is-invalid': submitted && form.codigoTurno.errors }"
          >
          </ng-select>

          <div *ngIf="submitted && form.codigoTurno.errors" class="alert">
            <div *ngIf="form.codigoTurno.errors.required">
              * Turno é obrigatório
            </div>
          </div>
        </div>



        <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
          <img
            src="../../../../assets/img/icons/icon-search.svg"
            alt="Pesquisar Chamados"
            title="Pesquisar Chamados"
          />
        </button>
      </div>

      <div class="container">
        <div class="grupo-filter">
          <label for="select-categoria">Categoria</label>
          <ng-select
            [items]="categorias"
            bindLabel="descricaoSubCategoria"
            bindValue="codigoSubCategoria"
            groupBy="descricaoCategoria"
            placeholder="Selecione uma Sub Categoria"
            name="codigoSubCategoria"
            formControlName="codigoSubCategoria"
            [ngClass]="{
              'is-invalid': submitted && form.codigoSubCategoria.errors
            }"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.descricaoCategoria || "Unnamed group" }}
            </ng-template>
          </ng-select>

          <div *ngIf="submitted && form.codigoSubCategoria.errors" class="alert">
            <div *ngIf="form.codigoSubCategoria.errors.required">
              * Sub Categoria é obrigatório
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- SAUDACAO USUÁRIO -->
    <section class="section-saudacao">
      <div class="container">
        <p>
          <b>Olá,</b> {{this.userLogado?.flPsicologo ? "Psicólogo(a)": this.atribuicoesLogin.NomePerfil}}<br />
          <b>{{this.userLogado?.flPsicologo ? this.userLogado?.nome : this.atribuicoesLogin.Nome}}</b>
        </p>
      </div>
    </section>

    <!-- TITULO -->
    <section class="section-saudacao header-page-voltar">
        <div class="container">
            <h2 class="titulo-page" #target>Tela de Chamados</h2>

            <a *ngIf="showHistorico" class="btn-voltar-page" (click)="Voltar()">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </a>
        </div>
    </section>

    <!-- RESULTADOS -->
    <section class="conteudo-principal">
      <div class="container-conviva">

        <!-- LISTAGEM CHAMADOS -->
        <div class="time-line" *ngIf="this.lstNaoOcorrencias.length == 0">
          <div *ngIf="showMenu" class="header-chamado">
            <div class="filtro-time-line">
              <label for="filter-select">Gravidade</label>
              <ng-select
                [items]="gravidades"
                bindLabel="name"
                bindValue="id"
                class="select-gravidade"
                placeholder="Selecione uma Gravidade"
                (change)="FiltraChamadosAtuais($event)"
                formControlName="codigoGravidade"
                name="codigoGravidade"
              >
              </ng-select>
            </div>
          </div>

          <h2>Todos os Chamados</h2>

          <div
            class="listagem-card-chamado"
            *ngIf="this.submitted && this.showPesquisa"
          >
            <div *ngFor="let item of this.lstChamados">
              <!-- STATUS PENDENTE -->
              <a
                href="javascript:void(0)"
                class="card-chamado {{ item.dsGravidade }}"
                [ngClass]="{'aberto' :  item.status ==  0,
                'encaminhado' :  item.status == 1,
                'agendado' :  item.status == 2,
                'atendido' :  item.status == 3,
                'concluido' :  item.status == 4,
                'ativo':item.codigoChamado == chamadoSelecionado}"
                (click)="GetEnvolvidos(item,target)"
              >
                <div class="card-chamado__info">
                  <div class="card-footer">
                    <p class="numero-chamado"><b>Chamado {{ item.codigoChamado }}</b></p>
                    <div class="card-status">
                      <p class="descricao-chamado">
                        {{ item.nomeCategoria }} - {{ item.nomeSubCategoria }}
                      </p>
                    </div>
                  </div>
                  <div class="card-footer">
                    <p>
                      <small>{{ item.horasAtras }}</small>
                    </p>
                    <div class="card-status">
                      {{item.dsStatus}} <i class="fa fa-check"></i>
                    </div>
                  </div>
                  <div class="card-footer">
                    <p class="descricao-chamado">
                      Data da ocorrência: <small>{{ formatDate(item.dataChamado)}}</small> &nbsp;
                      <!-- Data da ocorrência: <small>{{ item.dataChamado | date : 'dd/MM/yyyy HH:mm'}}</small> &nbsp; -->
                    </p>
                    <div class="card-status">
                      <p class="descricao-chamado">
                      Data de abertura: <small>{{ formatDate(item.dataInclusao)}}</small>
                      <!-- Data de abertura: <small>{{ item.dataInclusao | date : 'dd/MM/yyyy HH:mm'}}</small> -->
                    </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- LISTAGEM NÃO OCORRÊNCIAS -->
        <div class="time-line" *ngIf="this.lstNaoOcorrencias.length > 0">


          <h2>Todos as Não Ocorrências</h2>

          <div
            class="listagem-card-chamado"
            *ngIf="this.submitted && this.showPesquisa"
          >
            <div *ngFor="let item of this.lstNaoOcorrencias">
              <!-- STATUS PENDENTE -->
              <a
                href="javascript:void(0)"
                (click)="getInfosNaoOcorrencia(item)"
              >
                <div class="card-chamado__info">
                  <div class="card-footer">
                    <p class="numero-chamado"><b>Não Ocorrência {{ item.codigoNaoOcorrencia }}</b></p>
                    <div class="card-status">
                      <p class="descricao-chamado">
                        {{ item.nomeCategoria }} - {{ item.nomeSubCategoria }}
                      </p>
                    </div>
                  </div>
                  <div class="card-footer">
                    <p>
                      <small>{{ item.horasAtras }}</small>
                    </p>
                    <!-- <div class="card-status">
                      {{item.dsStatus}} <i class="fa fa-check"></i>
                    </div> -->
                  </div>
                  <div class="card-footer">
                    <p class="descricao-chamado">
                      Data da não ocorrência: <small>{{ item.dt_NaoOcorrencia | date : 'dd/MM/yyyy HH:mm'}}</small> &nbsp;
                    </p>
                    <div class="card-status">
                      <p class="descricao-chamado">
                      Data de abertura: <small>{{ item.dt_Inclusao | date : 'dd/MM/yyyy HH:mm'}}</small>
                    </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <!-- MENU RAPIDO -->
        <aside class="sidebar" *ngIf="showMenu">
          <!-- LINKS RAPIDOS -->
          <div class="links-acesso-rapido">
            <h3>Links rápidos</h3>
            <h5>acesso</h5>
            <ul>
              <li *ngIf="this.userLogado?.funcionalidades?.includes(2)">
                <a href="javascript:void(0)" routerLink="/cadastrar-chamados" title="Adicionar Chamado">
                  Adicionar Chamado
                </a>
              </li>
              <li *ngIf=" this.userLogado?.funcionalidades?.includes(16)">
                <a href="javascript:void(0)" routerLink="/notificacoes">
                  Notificações
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" routerLink="/buscar-alunos">
                  Buscar Estudantes com Ocorrências
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" routerLink="/listar-chamados">
                  Estudantes com Ocorrências Registradas
                </a>
              </li>
            </ul>
          </div>

          <!-- ALUNOS RECORRENTES -->
          <div class="sidebar-alunos-recorrentes">
            <h3>Estudantes  Recorrentes</h3>

            <div class="alunos-recorrentes" *ngIf="this.submitted && this.showPesquisa">
              <div *ngFor="let item of this.alunosRecorrentes; let i = index">
                <a
                  href="javascript:void(0)"
                  [routerLink]="['/perfil-aluno/', item.ra]"
                  title="Perfil Estudante"
                  class="alunos"
                >
                  <div *ngIf="item.foto" class="img-aluno {{item.dsGravidade}}">
                    <img src="data:image/jpeg;base64,{{item.foto}}" alt="Imagem do Aluno">
                  </div>
                  <div *ngIf="!item.foto" class="img-aluno {{item.dsGravidade}}">
                    <span>{{ item.iniciais }}</span>
                  </div>
                  <div class="nome-aluno">
                    {{ item.nomeAluno }}
                  </div>
                </a>
              </div>
            </div>
          </div>

        </aside>

        <!-- CHAMADO -->
        <div class="historico-box" *ngIf="showHistorico && !showDetalhesNaoOcorrencia">
          <div class="conteudo-chamado conteudo-chamado-titulo" >
            <div class="header-perfil-chamado">
              <a
                href="javascript:void(0)"
                (click)="OnVisualizarChamado(this.chamadoSeleccionado.codigoChamado)"
                title="Visualizar Chamado">
                <p class="titulo-chamado">
                  CHAMADO {{ this.chamadoSeleccionado.codigoChamado }}
                </p>
              </a>
              <input type="checkbox" checked class="card-opcoes-chamado" id="opcoes-chamado-01"/>

              <app-tres-pontos
                [Chamado]="this.chamadoSeleccionado"
                [Index]="this.chamadoSeleccionado.codigoChamado"
                [TemEnvolvido]="true"
                (ItemEvent)="EventTresPontos($event)">
              </app-tres-pontos>

            </div>
            <div class="imagem-destaque-card">
              <div class="foto-perfil" style="position: relative;">
                <img [src]="this.chamadoSeleccionado.imagemChamado[0]" alt="" *ngIf="this.chamadoSeleccionado.imagemChamado.length > 0" />
                <div *ngIf="this.chamadoSeleccionado.imagemChamado.length > 1" class="mensagem-arquivos">
                  <a>Este chamado possui {{ this.chamadoSeleccionado.imagemChamado.length }} arquivos anexados</a>
                </div>
              </div>
            </div>
            <div class="descricao-card">
              <p>{{ this.chamadoSeleccionado.descricaoChamado }}</p>
            </div>
          </div>

          <div class="conteudo-chamado conteudo-chamado-titulo" *ngIf="!this.chamadoSeleccionado.semEnvolvido">
            <div class="header-perfil-chamado">
                <p class="titulo-chamado">
                  ENVOLVIDOS
                </p>
            </div>
          </div>

          <div class="conteudo-chamado" *ngFor="let envolvido of this.lstEnvolvidos" >
            <!-- HEADER HISTORICO -->
            <div class="header-perfil-chamado">
              <div class="perfil-aluno-chamado">
                <div class="perfil-aluno aberto">
                  <!-- CASO FOR INICIAIS -->
                  <span *ngIf="!envolvido.temFoto">
                    {{ envolvido.iniciais }}</span>
                  <!-- CASO TIVER FOTO -->
                  <div class="img-perfil-aluno" *ngIf="envolvido.temFoto">
                    <img src="data:image/jpeg;base64,{{ envolvido.foto }}" alt="Foto do Aluno"/>
                  </div>
                </div>
                <div class="infor-aluno-chamado">
                  <a
                    href="javascript:void(0)"
                    title="Perfil Estudante"
                    [routerLink]="['/perfil-aluno/', envolvido.ra]"
                    >
                    <p class="nome-aluno">
                      {{
                        envolvido.tipoPessoa == 1
                          ? envolvido.nome
                          : envolvido.dsOutros
                      }}
                    </p>
                  </a>
                  <p class="descricao">
                    {{
                      envolvido.dsTipoPessoa  +
                        " - " +
                        envolvido.dsEnvolvido +
                        " - " +
                        envolvido.dsEstatusEnvolvido
                    }}
                  </p>
                  <a
                  href="javascript:void(0)"
                  title="Histórico Envolvido"
                    (click)="OnStatusChamado(this.chamadoSeleccionado.codigoChamado, envolvido.codigoEnvolvido)"
                    class="card-btn-status-chamado">
                    Histórico do Envolvido
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="historico-box" *ngIf="showDetalhesNaoOcorrencia">
          <div class="conteudo-chamado conteudo-chamado-titulo" >
            <div class="header-perfil-chamado">
              <a
                href="javascript:void(0)"
                (click)="OnVisualizarChamado(this.naoOcorrenciaSelecionada!.codigoNaoOcorrencia)"
                title="Visualizar Chamado">
                <p class="titulo-chamado">
                  NÃO OCORRÊNCIA {{ this.naoOcorrenciaSelecionada!.codigoNaoOcorrencia }}
                </p>
              </a>

            </div>
            <div class="conteudo-nao-ocorrencia">

              <p class="titulo-nao-ocorrencia">
                Descricao Não Ocorrência
              </p>
              <div class="descricao-card-nao-ocorrencia">
                <p>{{ this.naoOcorrenciaSelecionada!.ds_NaoOcorrencia }}</p>
              </div>
            </div>
          </div>


        </div>

      </div>
    </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModalExcluirChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span>{{ this.descricaoExclusao }}</span>
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalExclusao()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="ExluirChamado(this.chamadoARemover)"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalConcluirChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Concluir Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span >{{ this.descricaoConcluir }}</span>

    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalConcluir()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="ConcluirChamado()"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>
