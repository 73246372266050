import { environment } from "src/environments/environment";

const Bases ={
  server:environment.baseServerUrl,
  front: environment.baseFrontUrl,
  sed: environment.baseSedUrl,
  hub:environment.hubUrl,
  diario:environment.diarioUrl
};

export const ConvivaApi ={
  //SED
  Sed: {
    LoginSed: `${Bases.sed}api/AutorizaGovBr`,
    getUserProfileSed: `${Bases.sed}api/ListarAtribuicoesPorUsuarioPerfil`,
  },
  // NEGOCIO
  Aluno:{
    getAlunosPorRa: `${Bases.server}api/Aluno/GetAlunoPorRA`,
    getTurmasPorCodigoCie: `${Bases.server}api/Aluno/GetTurmasPorCodigoCie`,
    getAlunosPorNome: `${Bases.server}api/Aluno/GetAlunoPorNome`,
    getDadosAlunoGerais: `${Bases.server}api/Aluno/GetDadosAlunoGerais`,
    getAlunosPorEscola: `${Bases.server}api/Aluno/GetAlunosPorEscola`,
    getTurmaAlunos: `${Bases.server}api/Aluno/GetTurmaAlunos`,
    getAlunosVisita: `${Bases.server}api/Aluno/GetAlunosVisita`
  },
  Funcionario:{
    getFuncionarioPorCpf: `${Bases.server}api/Funcionario/GetFuncionarioPorCPF`,

  },
  Categoria: {
    getCategoriasWithSub: `${Bases.server}api/Categoria/GetCategoria`,
    getGravidade: `${Bases.server}api/Categoria/GetGravidade`,
  },
  Diario:{
    ConsultarCalendario: `${Bases.diario}api/CalendarioEscolar/ConsultaCalendario`,

  },
  Chamados:{
    GetChamadosEnvolvidos: `${Bases.server}api/Chamados/GetChamados`,
    GetListaChamados:  `${Bases.server}api/Chamados/GetListaChamados`,
    setFileBase64: `${Bases.server}api/Chamados/SetFileBase64`,
    setCadastrarChamado: `${Bases.server}api/Chamados/CadastrarChamado`,
    setEditarChamado: `${Bases.server}api/Chamados/EditarChamado`,
    setCadastrarHistorico: `${Bases.server}api/Chamados/CadastrarHistorico`,
    setDeletarChamado: `${Bases.server}api/Chamados/DeletarChamado`,
    GetHistoricoEnvolvido: `${Bases.server}api/Chamados/GetHistorico`,
    GetChamadosPorAluno: `${Bases.server}api/Chamados/GetChamadosPorAluno`,
    GetAlunosRecorrentes: `${Bases.server}api/Chamados/GetAlunosRecorrentes`,
    GetChamadoPorId: `${Bases.server}api/Chamados/GetChamadoPorId`,
    GetArquivoChamado: `${Bases.server}api/Chamados/GetArquivoChamado`,
    GetChamadosAgendadosPorPsicologo: `${Bases.server}api/Chamados/GetChamadosAgendadosPorPsicologo`,
    GetChamadosPOC: `${Bases.server}api/Chamados/GetChamadosPOC`,
    GetChamadosCriadosPorUsuario: `${Bases.server}api/Chamados/GetChamadosCriadosPorUsuario`,
  },
  NaoOcorrencia:{
    setCadastrarNaoOcorrencia: `${Bases.server}api/NaoOcorrencia/CadastrarNaoOcorrencia`,
    getPermissaoGravarNaoOcorrencia: `${Bases.server}api/NaoOcorrencia/GetPermissaoCadastrar`,
    getNaoOcorrencias:`${Bases.server}api/NaoOcorrencia/GetNaoOcorrencias`,
    getExisteNaoOcorrenciaHoje:`${Bases.server}api/NaoOcorrencia/GetExisteNaoOcorrenciaHoje`,
    setDesativarNaoOcorrencia:`${Bases.server}api/NaoOcorrencia/SetDesativaNaoOcorrencia`,

  },
  Escola:{
    getDiretoriaPorTipoDiretoria: `${Bases.server}api/Escola/GetDiretoriaPorCodigoTipoDiretoria`,
    getEscolaPorCodigoDiretoria: `${Bases.server}api/Escola/GetEscolasPorCodigoDiretoria`,
    getTurnosPorCodigoCie: `${Bases.server}api/Escola/GetTurnosPorCodigoCie`,
    getEscolaDetalhes: `${Bases.server}api/Escola/GetEscolaPorCodigoCIE`,
  },
  Envolvido: {
    getEnvolvidosPorTurma: `${Bases.server}api/Envolvido/GetEnvolvidosPorTurma`,
    getEnvolvidos: `${Bases.server}api/Envolvido/GetEnvolvidos`,
    getEnvolvidosPorChamado: `${Bases.server}api/Envolvido/GetEnvolvidosPorChamado`,
    getEnvolvidosPorChamadoEPsicologo: `${Bases.server}api/Envolvido/GetEnvolvidosPorChamadoEPsicologo`,
    getEnvolvidosPorChamadoEPOC: `${Bases.server}api/Envolvido/GetEnvolvidosPorChamadoEPOC`,
    getEnvolvidosPorAtividadePOC: `${Bases.server}api/Envolvido/GetEnvolvidosPorAtividadePOC`,
    getEnvolvidosPorAtividadePsicologo: `${Bases.server}api/Envolvido/GetEnvolvidosPorAtividadePsicologo`,
  },
  Login: {
    getTokenSed: `${Bases.server}api/Login/GetTokenSed`
  },
  HistoricoChamado: {
    setCadastrarHistoricoChamado: `${Bases.server}api/HistoricoChamado/CadastrarHistoricoChamado`,
    setInformacaoAdicional: `${Bases.server}api/HistoricoChamado/SetInformacaoAdicional`
  },
  Encaminhamento: {
    setCadastrarEncaminhamento: `${Bases.server}api/Encaminhamento/CadastrarEncaminhamento`,
    setCadastrarEncaminhamentoSaude: `${Bases.server}api/Encaminhamento/CadastrarEncaminhamentoSaude`,
    setCadastrarEncaminhamentoPolicia: `${Bases.server}api/Encaminhamento/CadastrarEncaminhamentoPolicia`,
    setDeletarEncaminhamentoPsicologo: `${Bases.server}api/Encaminhamento/DeletarEncaminhamentoPsicologo`
  },
  Detalhe: {
    GetChamadoDetalhePorId: `${Bases.server}api/Detalhe/GetChamadoDetalhePorId`,
    GetAtividadeDetalhePorId: `${Bases.server}api/Detalhe/GetAtividadeDetalhePorId`,
    GetAtividadeDetalhePsicologoPorId: `${Bases.server}api/Detalhe/GetAtividadeDetalhePsicologoPorId`,
    GetAtividadeDetalhePOCPorId: `${Bases.server}api/Detalhe/GetAtividadeDetalhePOCPorId`,
    GetAtividadeDetalhePsicologoFiscalPorId: `${Bases.server}api/Detalhe/GetAtividadeDetalhePsicologoFiscalPorId`
  },
  Perfil: {
    GetPerfisUsuario: `${Bases.server}api/Perfil/GetPerfisUsuario`,
    GetFuncionalidadesPerfil: `${Bases.server}api/Perfil/GetFuncionalidadesPerfil`,
    GetPerfis: `${Bases.server}api/Perfil/GetPerfis`,
    GetFuncionalidades: `${Bases.server}api/Perfil/GetFuncionalidades`,
    GetFuncionalidadesEPerfil: `${Bases.server}api/Perfil/GetFuncionalidadesEPerfil`,
    SetCadastrarPerfilAcesso: `${Bases.server}api/Perfil/SetCadastrarPerfilAcesso`,
    GetPerfisAcessos: `${Bases.server}api/Perfil/GetPerfisAcessos`,
    SetCadastrarFuncionalidade: `${Bases.server}api/Perfil/SetCadastrarFuncionalidade`,
    GetVesionSistema: `${Bases.server}api/Perfil/GetVesionSistema`,
  },
  Professor:{
    getPOCs:`${Bases.server}api/Professor/GetPOCs`
  },
  Agendamento: {
    setCadastrarAgendamento:  `${Bases.server}api/Agendamento/CadastrarAgendamento`,
    getAgendamentosEmAberto:  `${Bases.server}api/Agendamento/GetAgendamentosEmAberto`,
  },
  Atendimento: {
    setCadastrarAtendimento:  `${Bases.server}api/Atendimento/CadastrarAtendimento`
  },
  Concluir: {
    setCadastrarConcluir: `${Bases.server}api/Concluir/ConcluirChamado`,
    setCadastrarConcluirChamadoEnvolvidos: `${Bases.server}api/Concluir/ConcluirChamadoEnvolvidos`,
    concluirChamadosPorAtividadePsicologo: `${Bases.server}api/Concluir/ConcluirChamadosPorAtividadePsicologo`,
    concluirChamadosPorAtividadePOC: `${Bases.server}api/Concluir/ConcluirChamadosPorAtividadepoc`,
  },
  Dashboard: {
    getDashboard: `${Bases.server}api/Dashboard/GetDashboard`,
    getDashboardReincidencia: `${Bases.server}api/Dashboard/GetDashboardReincidencia`,
    getDashboardNaoOcorrencia:`${Bases.server}api/Dashboard/GetDashboardNaoOcorrencias`
  },
  Psicologo: {
    getPolos: `${Bases.server}api/Psicologo/GetPolos`,
    getPoloPsicologo: `${Bases.server}api/Psicologo/GetPoloPsicologo`,
    getDiretoriaEscolasPorPolo: `${Bases.server}api/Psicologo/GetDiretoriaEscolasPorPolo`,
    getDiretoriasPorPolo: `${Bases.server}api/Psicologo/GetDiretoriasPorPolo`,
    setCadastrarPsicologo: `${Bases.server}api/Psicologo/SetCadastrarPsicologo`,
    setEditarPsicologo: `${Bases.server}api/Psicologo/SetEditarPsicologo`,
    setDeletarPsicologo: `${Bases.server}api/Psicologo/SetDeletarPsicologo`,
    getPsicologoPorCPF: `${Bases.server}api/Psicologo/GetPsicologoPorCPF`,
    getEncaminhamentos: `${Bases.server}api/Psicologo/GetEncaminhamentos`,
    getPsicologosPorPolo: `${Bases.server}api/Psicologo/GetPsicologosPorPolo`,
    postSetAgendarConsulta: `${Bases.server}api/Psicologo/SetAgendarConsulta`,
    postSetExcluirAgendamento: `${Bases.server}api/Psicologo/SetExcluirAgendamento`,
    getPsicologoPorCodigo: `${Bases.server}api/Psicologo/GetPsicologoPorCodigo`,
    getAtendimentoPsicologo: `${Bases.server}api/Psicologo/GetAtendimentosPsicologos`,
    getPrazoAtendimentos: `${Bases.server}api/Psicologo/GetPrazoAtendimentos`,
    setCadastrarVisitaPsicologo: `${Bases.server}api/Psicologo/SetCadastrarVisitaPsicologo`,
    getVisitasPsicologo: `${Bases.server}api/Psicologo/GetVisitasPsicologo`,
    setEditarVisitaPsicologo: `${Bases.server}api/Psicologo/SetEditarVisitaPsicologo`,
    getDashboardAtividadePsicologo: `${Bases.server}api/Psicologo/GetDashboardAtividadePsicologo`,
    setExcluirAtividadePsicologo: `${Bases.server}api/Psicologo/SetExcluirAtividadePsicologo`,
    getDashboardAtividadePOC: `${Bases.server}api/Psicologo/GetDashboardAtividadePOC`,
    setExcluirAtividadePOC: `${Bases.server}api/Psicologo/SetExcluirAtividadePOC`,
    setCadastrarVisitaPOC: `${Bases.server}api/Psicologo/SetCadastrarAtividadePOC`,
    setCadastrarAtividadePsicologo: `${Bases.server}api/Psicologo/SetCadastrarAtividadePsicologo`,
    getTurmasAprovacaoPOC: `${Bases.server}api/Psicologo/GetTurmasAprovacaoPOC`,
    aprovacaoAtividadePOC: `${Bases.server}api/Psicologo/AprovacaoAtividadePOC`,
    aprovacaoAtividadePsicologo: `${Bases.server}api/Psicologo/AprovacaoAtividadePsicologo`,
    getTurmasAprovacaoPsicologo: `${Bases.server}api/Psicologo/GetTurmasAprovacaoPsicologo`,
    getDashboardAtividadePsicologoFiscal: `${Bases.server}api/Psicologo/GetDashboardAtividadePsicologoFiscal`,
    aprovacaoAtividadePsicologoFiscal: `${Bases.server}api/Psicologo/AprovacaoAtividadePsicologoFiscal`,
    aprovacaoAtividadePsicologoPEC: `${Bases.server}api/Psicologo/AprovacaoAtividadePsicologoPEC`,
    setCadastrarAtividadePsicologoOutros: `${Bases.server}api/Psicologo/SetCadastrarAtividadePsicologoOutros`,
    getAvaliacaoFiscal: `${Bases.server}api/Psicologo/GetAvaliacaoFiscal`,
    aprovacaoPECAtividadePOC: `${Bases.server}api/Psicologo/AprovacaoPECAtividadePOC`,
    aprovacaoDiretorAtividadePOC: `${Bases.server}api/Psicologo/AprovacaoDiretorAtividadePOC`,
    setUpdatePOC: `${Bases.server}api/Psicologo/SetUpdateAtividadePOC`,
    setUpdatePsicologo: `${Bases.server}api/Psicologo/SetUpdateAtividadePsicologo`,
  },
  Notificacoes: {
    getNotificacoesComDatas: `${Bases.server}api/Notificacoes/GetNotificacoesComDatas`,
    getNotificacoes: `${Bases.server}api/Notificacoes/GetNotificacoes`,
    setExcluirNotificacoes: `${Bases.server}api/Notificacoes/SetExcluirNotificacoes`,
  },
  Hub:{
    getNotifications:`${Bases.hub}notifications`
  },
  Relatorios: {
    getRelatorioConsolidado: `${Bases.server}api/Relatorios/GetRelatorioConsolidado`,
    getRelatorioPendencias: `${Bases.server}api/Relatorios/GetRelatorioPendencias`,
    getCalendarioOcorrencias: `${Bases.server}api/Relatorios/GetCalendarioOcorrencias`,
    getVisitaPsicologo: `${Bases.server}api/Relatorios/GetVisitaPsicologo`,
    getRelatorioChamadosUsuario: `${Bases.server}api/Relatorios/GetRelatorioChamadosUsuario`,
    getUsuarioChamadosUnicos: `${Bases.server}api/Relatorios/GetUsuarioChamadosUnicos`,
    getPerfisChamadosUnicos: `${Bases.server}api/Relatorios/GetPerfisChamadosUnicos`,
    getRelatorioOcorrenciasAtivas: `${Bases.server}api/Relatorios/GetRelatorioOcorrenciasAtivas`,
    getRelatorioCalculoUsabilidade: `${Bases.server}api/Relatorios/GetRelatorioCalculoUsabilidade`
  },
  Utils:{
    calcularHoras: `${Bases.server}api/Utils/CalcularHoras`,
  }
}
