import { Component } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { authConfig } from "src/app/shared/auth/models/auth.config";

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  constructor(
    private oauthService: OAuthService
  ) {
    this.configure();
  }

  private configure() {
    this.oauthService.configure(authConfig);
  }

  public loginGovBr() {
    this.login();
  }

  public async login() {
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.initCodeFlow();
  }
}
