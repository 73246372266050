import { Component } from "@angular/core";

@Component({
  templateUrl: './professor-filtro-visitas.component.html',
  styleUrls: ['./professor-filtro-visitas.component.scss']
})

export class PageFiltroVisitasProfessorComponent {

  constructor(

   ) {
  }


}
