//Principais
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { Loader } from "@googlemaps/js-api-loader"

//Métodos
import { Polo } from "src/app/shared/models/polo/Polo";
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { Psicologo } from 'src/app/shared/models/psicologo/Psicologo';
import { AtividadePsicologoDeslocamento } from 'src/app/shared/models/atividade-psicologo/atividade-psicologo-deslocamento';

//Serviços
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-deslocamento',
  templateUrl: './psicologo-deslocamento.component.html',
  styleUrls: ['./psicologo-deslocamento.component.scss']
})

export class PageDeslocamentoPsicologoComponent implements OnInit {
  
  @Output() proximoPasso = new  EventEmitter<AtividadePsicologoDeslocamento>();
  @Input({ required: false }) dadosDeslocamento: AtividadePsicologoDeslocamento | null;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private psicologoService:PsicologoService,
    private utilsService: UtilsService,
  ) {
    this.config.notFoundText = 'Não encontrado';

    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public requestRoute: google.maps.DirectionsRequest  = <google.maps.DirectionsRequest>{};
  public resultRoute: google.maps.DirectionsResult = <google.maps.DirectionsResult>{};
  public totalKm: number = 0;
  public totalSeg: number = 0;
  public totalDuration: string;
  public horaChegada: string;
  public loader = new Loader({
    apiKey: "AIzaSyBW3-1Uqc00l6-rwd-KZkdIZWmS5MnGMsk",
    version: "weekly",
    libraries: ['places', 'drawing', 'visualization', 'geometry'],
  });

  public polosOrigem: Polo[] = [];
  public diretoriasOrigem: Diretoria[] = [];
  public escolasOrigem: Escola[] = [];

  public polosDestino: Polo[] = [];
  public diretoriasDestino: Diretoria[] = [];
  public escolasDestino: Escola[] = [];
  public lstTipoTransporte = [{id: 1, name:"Bicicleta" }, {id: 2, name:"Carro" }, {id: 3, name:"Transporte público" }, {id: 4, name:"A Pé" }, {id: 5, name:"Motocicleta" }];
  //, {id: 5, name:"Motocicleta" }
  public escolaOrigem: Escola = new Escola();
  public escolaDestino: Escola = new Escola();
  
  // Forms
  public formDeslocamento!: FormGroup;
  public get form() { return this.formDeslocamento.controls; }
  public submitted: boolean;
  public gerouRota: boolean = false;
  public msgLatLongInvalida: string = '';
  public model: AtividadePsicologoDeslocamento = new AtividadePsicologoDeslocamento();

  public async ngOnInit() {

    // Form Deslocamento
    this.formDeslocamento = new FormGroup({
      codigoPoloOrigem: new FormControl(null, [Validators.required]),
      codigoDiretoriaOrigem: new FormControl(null, [Validators.required]),
      codigoEscolaOrigem: new FormControl(null, [Validators.required]),

      codigoPoloDestino: new FormControl(null, [Validators.required]),
      codigoDiretoriaDestino: new FormControl(null, [Validators.required]),
      codigoEscolaDestino: new FormControl(null, [Validators.required]),

      horarioSaida: new FormControl(null, [Validators.required]),
      codigoTipoTransporte: new FormControl(null, [Validators.required]),
      horarioChegada: new FormControl({value: null, disabled: true}, this.gerouRota ? [Validators.required] : null),
      tempoDeslocamento: new FormControl({value: null, disabled: true}, this.gerouRota ? [Validators.required] : null),
      kmPercorrido: new FormControl({value: null, disabled: true}, this.gerouRota ? [Validators.required] : null),
    });

    if(this.userLogado?.flPsicologo) {
      await this.getPoloDiretoriaPsicologo();
    }
    else {
        if( this.atribuicoesLogin.NumeroComportamento == 1) {
          await this.getPolosAdmin();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 2) {
          await this.getPolosPerfilDiretor();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4) {
          await this.getPolosPerfilProfessor();
        }
    }

    if(this.dadosDeslocamento)
      await this.setDadosDeslocamento();
  }

  public async setDadosDeslocamento(){
    if(this.dadosDeslocamento) {
      this.form.codigoPoloOrigem?.setValue(this.dadosDeslocamento.codigoPoloOrigem);
      this.form.codigoDiretoriaOrigem?.setValue(this.dadosDeslocamento.codigoDiretoriaOrigem);
      this.form.codigoEscolaOrigem?.setValue(this.dadosDeslocamento.codigoEscolaOrigem);

      this.form.codigoPoloDestino?.setValue(this.dadosDeslocamento.codigoPoloDestino);
      await this.getDiretoriasPorPoloDestino();
      this.form.codigoDiretoriaDestino?.setValue(this.dadosDeslocamento.codigoDiretoriaDestino);
      await this.trocaDiretoriaDestino();
      this.form.codigoEscolaDestino?.setValue(this.dadosDeslocamento.codigoEscolaDestino);

      this.form.codigoTipoTransporte?.setValue(this.dadosDeslocamento.codigoTipoTransporte);
      this.form.horarioSaida?.setValue(this.dadosDeslocamento.horarioSaida);
      this.form.horarioChegada?.setValue(this.dadosDeslocamento.horarioChegada);
      this.form.tempoDeslocamento?.setValue(this.dadosDeslocamento.tempoDeslocamento);
      this.form.kmPercorrido?.setValue(this.dadosDeslocamento.kmPercorrido);

      await this.gerarRota();
    }
  }

  //#region Polos
  
  public async getPoloDiretoriaPsicologo(){
    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polosOrigem = [response.data];
    this.polosDestino = [response.data];

    this.formDeslocamento.get('codigoPoloOrigem')?.setValue(this.polosOrigem[0].codigoPolo);
    this.form.codigoPoloOrigem.disable();

    this.formDeslocamento.get('codigoPoloDestino')?.setValue(this.polosDestino[0].codigoPolo);
    this.form.codigoPoloDestino.disable();

    await this.getDiretoriasPorPoloOrigem();
    await this.getEscolasPorDiretoriaOrigem();

    await this.getDiretoriasPorPoloDestino();
  }

  public async getPolosAdmin() {
    this.spinnerService.show();

    var  response = await lastValueFrom(this.psicologoService.getPolos())
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polosOrigem = response.data;
    this.polosDestino = response.data;

    this.spinnerService.hide();

    if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0)
      await this.ativaPolo();

    this.spinnerService.hide();
  }

  public async getPolosPerfilDiretor(){
    this.spinnerService.show();

    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polosOrigem = response.data;
    this.form.codigoPoloOrigem.setValue(this.polosOrigem[0].codigoPolo);
    this.form.codigoPoloOrigem.disable();

    this.polosDestino = response.data;
    this.form.codigoPoloDestino.setValue(this.polosDestino[0].codigoPolo);
    this.form.codigoPoloDestino.disable();

    if(this.userLogado?.DadosUsuario[0].CodigoDiretoria  && this.userLogado?.DadosUsuario[0].CodigoDiretoria > 0){

      this.diretoriasOrigem = [];
      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretoriasOrigem.push(diretoria);
      });

      if(this.diretoriasOrigem.length == 1)
        this.form.codigoDiretoriaOrigem.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      else
        this.form.codigoDiretoriaOrigem.enable();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor(){
    this.spinnerService.show();
    const response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polosOrigem = response.data;
    this.form.codigoPoloOrigem.setValue(this.polosOrigem[0].codigoPolo);
    this.form.codigoPoloOrigem.disable();

    if(this.atribuicoesLogin?.CodigoDiretoria && this.atribuicoesLogin?.CodigoDiretoria > 0 && this.atribuicoesLogin?.CodigoEscola && this.atribuicoesLogin?.CodigoEscola > 0) {

      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretoriasOrigem = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolasOrigem = [escola];
      });

      this.form.codigoDiretoriaOrigem.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscolaOrigem.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }

    this.spinnerService.hide();
  }

  public async ativaPolo(){
    if(this.userLogado?.codigoPolo)
      this.formDeslocamento.get('codigoPoloOrigem')?.setValue(this.userLogado?.codigoPolo);

    if(this.userLogado)
      this.authService.setUser(this.userLogado);

    await this.getDiretoriasPorPoloOrigem();
    await this.getEscolasPorDiretoriaOrigem();
  }

  public async cambioPoloOrigem()
  {
    const codigoPO = this.formDeslocamento.get('codigoPoloOrigem')?.value;
    if(this.userLogado)
    {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }

    this.form.codigoDiretoriaOrigem.enable();
    this.form.codigoEscolaOrigem.enable();
    this.zerarEscolaOrigem();

    await this.getDiretoriasPorPoloOrigem();
    await this.removerRota();
  }

  public async cambioPoloDestino()
  {
    const codigoPO = this.formDeslocamento.get('codigoPoloDestino')?.value;
    if(codigoPO && codigoPO > 0){
      this.form.codigoDiretoriaDestino.enable();
      this.form.codigoEscolaDestino.enable();
      this.zerarEscolaDestino();

      await this.getDiretoriasPorPoloDestino();
    }
  }

  //#endregion

  //#region Diretoria Origem

  public async getDiretoriasPorPoloOrigem() {
    if(this.userLogado?.codigoPolo){
      
      this.zerarDiretoriaOrigem();
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.diretoriasOrigem = response.data;
      this.form.codigoDiretoriaOrigem.enable();
      this.ativaDiretoriaOrigem();
      this.spinnerService.hide();
    }

    if(this.userLogado) {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria ;
      this.authService.setUser(this.userLogado);
    }
  }

  public ativaDiretoriaOrigem(){
    if(this.diretoriasOrigem && this.userLogado?.codigoDiretoria != 0) {

      const diretoria = this.diretoriasOrigem.find(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria);
      if(this.userLogado?.codigoDiretoria != undefined  && diretoria){
        this.formDeslocamento.get('codigoDiretoriaOrigem')?.setValue(this.userLogado?.codigoDiretoria);
      }
    }
    
    if(this.userLogado)
        this.authService.setUser(this.userLogado);
  }

  public zerarDiretoriaOrigem() {
    this.diretoriasOrigem = [];
    this.formDeslocamento.get('codigoDiretoriaOrigem')?.patchValue(null);
  }

  //#endregion

  //#region Diretoria Destino

  public async getDiretoriasPorPoloDestino() {
    const codigoPO = this.formDeslocamento.get('codigoPoloDestino')?.value;
    if(codigoPO && codigoPO > 0){
      
      this.zerarDiretoriaDestino();
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(codigoPO))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.diretoriasDestino = response.data;
      this.form.codigoDiretoriaDestino.enable();
      this.spinnerService.hide();
    }
  }

  public zerarDiretoriaDestino() {
    this.diretoriasDestino = [];
    this.formDeslocamento.get('codigoDiretoriaDestino')?.patchValue(null);
  }

  //#endregion

  //#region Diretorias/Escolas Origem

  public async trocaDiretoriaOrigem(){
    const codigoDiretoria = this.formDeslocamento.get('codigoDiretoriaOrigem')?.value;
    
    if(this.userLogado && codigoDiretoria)
    {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }

    if(codigoDiretoria && codigoDiretoria > 0)
      await this.getEscolasPorDiretoriaOrigem();
    else{
      this.escolasOrigem = [];
      this.form.codigoEscolaOrigem.setValue(null);
    }  

    await this.removerRota();
  }

  public async getEscolasPorDiretoriaOrigem(){
    
    if(this.userLogado) {
      this.userLogado.codigoEscola = this.userLogado.codigoEscola == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if(this.userLogado?.codigoDiretoria && this.userLogado?.codigoDiretoria > 0) {
      this.spinnerService.show();
      this.zerarEscolaOrigem();

      var response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(this.userLogado!.codigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }

        this.escolasOrigem = response.data;
        this.ativaEscolaOrigem();
        this.spinnerService.hide();
    }
  }
  
  public ativaEscolaOrigem(){
    if(this.escolasOrigem != undefined && this.userLogado?.codigoEscola != 0) {
      
      const escola = this.escolasOrigem.find(s=>s.codigoEscola == this.userLogado?.codigoEscola)

      if(this.userLogado?.codigoEscola != undefined  && escola != undefined)
        this.formDeslocamento.get('codigoEscolaOrigem')?.setValue(this.userLogado?.codigoEscola);
    }
  }

  public zerarEscolaOrigem() {
    this.escolasOrigem = [];
    this.formDeslocamento.get('codigoEscolaOrigem')?.patchValue(null);
  }

  //#endregion

  //#region Diretorias/Escolas Destino

  public async trocaDiretoriaDestino(){
    const codigoDiretoria = this.formDeslocamento.get('codigoDiretoriaDestino')?.value;

    if(codigoDiretoria && codigoDiretoria > 0)
      await this.getEscolasPorDiretoriaDestino();
    else{
      this.escolasDestino = [];
      this.form.codigoEscolaDestino.setValue(null);
    }

    await this.removerRota();
  }

  public async getEscolasPorDiretoriaDestino(){
    const codigoDiretoria = this.formDeslocamento.get('codigoDiretoriaDestino')?.value;

    if(codigoDiretoria && codigoDiretoria > 0) {
      this.spinnerService.show();
      this.zerarEscolaDestino();

      var response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(codigoDiretoria))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }

        this.escolasDestino = response.data;
        this.ativaEscolaDestino();
        this.spinnerService.hide();
    }
  }
  
  public ativaEscolaDestino(){
    const codigoEscola = this.formDeslocamento.get('codigoEscolaDestino')?.value;

    if(this.escolasDestino != undefined &&  codigoEscola > 0) {
      
      const escola = this.escolasOrigem.find(s=>s.codigoEscola == codigoEscola)    
      if(escola)
        this.formDeslocamento.get('codigoEscolaOrigem')?.setValue(codigoEscola);
    }
  }

  public zerarEscolaDestino() {
    this.escolasDestino = [];
    this.formDeslocamento.get('codigoEscolaDestino')?.patchValue(null);
  }

  //#endregion

  //#region Salvar/Limpar

  public async onSalvar(){
    this.submitted = true;

    if (this.formDeslocamento.invalid) {
      return;
    }

    if(!this.gerouRota){
      this.toastr.warning('Por favor Gere o seu Deslocamento com o Mapa ...');
      return;
    }

    this.spinnerService.show();
    const dirOrigem = this.diretoriasOrigem.filter(r => r.codigoDiretoria == this.formDeslocamento.get('codigoDiretoriaOrigem')?.value)[0];
    const escOrigem = this.escolasOrigem.filter(r => r.codigoEscola == this.formDeslocamento.get('codigoEscolaOrigem')?.value)[0];  
    const dirDestino = this.diretoriasDestino.filter(r => r.codigoDiretoria == this.formDeslocamento.get('codigoDiretoriaDestino')?.value)[0];
    const escDestino = this.escolasDestino.filter(r => r.codigoEscola == this.formDeslocamento.get('codigoEscolaDestino')?.value)[0]; 

    this.toastr.success('Deslocamento feito com sucesso ...');
    this.spinnerService.hide();

    // Origem
    this.model.codigoPoloOrigem = this.form.codigoPoloOrigem.value;
    this.model.codigoDiretoriaOrigem = dirOrigem.codigoDiretoria;
    this.model.nomeDiretoriaOrigem = dirOrigem.nomeDiretoria;
    this.model.codigoEscolaOrigem = escOrigem.codigoEscola;
    this.model.nomeEscolaOrigem = escOrigem.nomeEscola;

    // Destino
    this.model.codigoPoloDestino = this.form.codigoPoloDestino.value;
    this.model.codigoDiretoriaDestino = dirDestino.codigoDiretoria;
    this.model.nomeDiretoriaDestino = dirDestino.nomeDiretoria;
    this.model.codigoEscolaDestino = escDestino.codigoEscola;
    this.model.nomeEscolaDestino = escDestino.nomeEscola;

    // Deslocamento
    this.model.codigoTipoTransporte = this.formDeslocamento.get('codigoTipoTransporte')?.value;
    this.model.horarioSaida = this.formDeslocamento.get('horarioSaida')?.value;
    this.model.horarioChegada = this.formDeslocamento.get('horarioChegada')?.value;
    this.model.tempoDeslocamento = this.formDeslocamento.get('tempoDeslocamento')?.value;
    this.model.kmPercorrido = this.formDeslocamento.get('kmPercorrido')?.value;

    this.proximoPasso.emit(this.model);
  }

  public async onLimpar() {
    this.spinnerService.show();

    this.formDeslocamento.get('codigoPoloOrigem')?.patchValue(null);
    this.formDeslocamento.get('codigoDiretoriaOrigem')?.patchValue(null);
    this.formDeslocamento.get('codigoEscolaOrigem')?.patchValue(null);

    this.formDeslocamento.get('codigoPoloDestino')?.patchValue(null);
    this.formDeslocamento.get('codigoDiretoriaDestino')?.patchValue(null);
    this.formDeslocamento.get('codigoEscolaDestino')?.patchValue(null);

    this.formDeslocamento.get('codigoTipoTransporte')?.patchValue(null);
    this.formDeslocamento.get('horarioSaida')?.patchValue(null);
    this.formDeslocamento.get('horarioChegada')?.patchValue(null);
    this.formDeslocamento.get('tempoDeslocamento')?.patchValue(null);
    this.formDeslocamento.get('kmPercorrido')?.patchValue(null);

    this.spinnerService.hide();
  }

  //#endregion

  //#region Mapa

  public async gerarRota(){
    this.submitted = true;

    if (this.formDeslocamento.invalid) {
      return;
    }

    await this.loadMap();
  }

  public async loadMap() {
    await this.detalhesEscolaOrigemDestino();

    if(this.escolaOrigem.codigoEscola != this.escolaDestino.codigoEscola){
      if(await this.validarLatLong()) {

        this.spinnerService.show();
  
        const mapOptions = {
          center: { lat: -23.5489, lng: -46.6388 }, // Lat/Long Central SP
          zoom: 9, // Zoom SP
          mapId: "MapaDeslocamento",
        };
    
        const divMaps = document.getElementById("map");
  
        if(divMaps){
          divMaps.style.display = 'unset'
    
          // Bibliotecas google
          const importMaps = await this.loader.importLibrary('maps');
          const importMapsRoutes = await this.loader.importLibrary('routes');
          const importMapsMarkers = await this.loader.importLibrary('marker');
    
          // Serviços google
          const serviceDisplay = new importMapsRoutes.DirectionsRenderer({suppressMarkers: true});
          const serviceRoute = new importMapsRoutes.DirectionsService();     
          const infoWindow = new importMaps.InfoWindow();
  
          const maps = new importMaps.Map(divMaps, mapOptions)
          serviceDisplay.setMap(maps);
  
          // Marcador de Origem
          const titleOrigem = `<b>Origem</b> <br/> <b>Escola</b>: ${this.escolaOrigem.nomeAbreviado} <br/> <b>CIE</b>: ${this.escolaOrigem.codigoEscola}`;
          const pinOrigem = new importMapsMarkers.PinElement({background: '#FBBC04'});
          const markerOrigem = new importMapsMarkers.AdvancedMarkerElement({
            gmpDraggable: false,
            map: maps,
            position: this.getLatLong(+this.escolaOrigem.latitude, +this.escolaOrigem.longitude),
            title: `Origem - Escola: ${this.escolaOrigem.nomeAbreviado} - CIE: ${this.escolaOrigem.codigoEscola}`,
            content: pinOrigem.element
          });
          markerOrigem.addListener('click', () => {
              infoWindow.setContent(titleOrigem);
              infoWindow.setPosition(markerOrigem.position);
              infoWindow.open(maps, markerOrigem);
          });
  
          // Marcador de Destino
          const titleDestino = `<b>Destino</b> <br/> <b>Escola</b>: ${this.escolaDestino.nomeAbreviado} <br/> <b>CIE</b>: ${this.escolaDestino.codigoEscola}`;
          const pinDestino= new importMapsMarkers.PinElement({background: '#00FF7F'});
          const markerDestino = new importMapsMarkers.AdvancedMarkerElement({
            gmpDraggable: false,
            map: maps,
            position: this.getLatLong(+this.escolaDestino.latitude, +this.escolaDestino.longitude),
            title: `Destino - Escola: ${this.escolaDestino.nomeAbreviado} - CIE: ${this.escolaDestino.codigoEscola}`,
            content: pinDestino.element
          });
          markerDestino.addListener('click', () => {
            infoWindow.setContent(titleDestino);
            infoWindow.setPosition(markerDestino.position);
            infoWindow.open(maps, markerDestino);
          });
  
          this.requestRoute.origin = this.getLatLong(+this.escolaOrigem.latitude, +this.escolaOrigem.longitude);
          this.requestRoute.destination = this.getLatLong(+this.escolaDestino.latitude, +this.escolaDestino.longitude);
          var tipoTransporte = this.formDeslocamento.get('codigoTipoTransporte')?.value;

          switch (tipoTransporte) {
            case 1: {
              this.requestRoute.travelMode = google.maps.TravelMode.BICYCLING;
              break;
            }
            case 2: {
              this.requestRoute.travelMode = google.maps.TravelMode.DRIVING;
              break;
            }
            case 3: {      
              this.requestRoute.travelMode = google.maps.TravelMode.TRANSIT;
              break;
            }
            case 4: {      
              this.requestRoute.travelMode = google.maps.TravelMode.WALKING;
              break;
            }
            case 5: {      
              this.requestRoute.travelMode = 'TWO_WHEELER' as google.maps.TravelMode;
              break;
            }
          }
  
          // Executando Serviço de Rotas do Google.
          await serviceRoute.route(this.requestRoute, (result, status) => {
            if(status == google.maps.DirectionsStatus.ZERO_RESULTS){
              this.gerouRota = false;
              this.toastr.warning('Houve algum problema ao Gerar a Rota ...');
              this.spinnerService.hide();
            }
            else if (status === google.maps.DirectionsStatus.OK) {
                serviceDisplay.setDirections(result);
                this.gerouRota = true;
                if(result)
                  this.resultRoute = result;
            } 

          });
          if(this.gerouRota)
            await this.computeTotalDistance(this.resultRoute);
        }   

        this.spinnerService.hide(); 
      }
      else {
        this.gerouRota = false;
        this.toastr.warning('Houve algum problema ao buscar os Detalhes das Escolas ...');
        this.toastr.warning(this.msgLatLongInvalida);
      }
    }
    else {
      this.gerouRota = false;
      this.toastr.warning('A Escola de Destino tem que ser diferente da Escola de Origem ...');
    }
  }

  public async detalhesEscolaOrigemDestino(){
    const escolaOrigem = this.formDeslocamento.get('codigoEscolaOrigem')?.value;
    const escolaDestino = this.formDeslocamento.get('codigoEscolaDestino')?.value;

    this.spinnerService.show();

    const resultEscolaOrigem = await lastValueFrom(this.escolaService.getEscolaDetalhes(escolaOrigem))
                              .catch((err) => {
                                return this.ConvivaErrors.handleResultError(err);
                              });

    if(!resultEscolaOrigem.isSucess){
        this.toastr.error(resultEscolaOrigem.message);
        this.spinnerService.hide();
        return;
    }

    this.escolaOrigem = resultEscolaOrigem.data;
    const resultEscolaDestino = await lastValueFrom(this.escolaService.getEscolaDetalhes(escolaDestino))
                                .catch((err) => {
                                  return this.ConvivaErrors.handleResultError(err);
                                });

    if(!resultEscolaDestino.isSucess){
        this.toastr.error(resultEscolaDestino.message);
        this.spinnerService.hide();
        return;
    }
    this.escolaDestino = resultEscolaDestino.data;
    this.spinnerService.hide();
  }

  public async validarLatLong(): Promise<boolean> {
    this.msgLatLongInvalida = '';

    if(+this.escolaOrigem.latitude != 0 && +this.escolaOrigem.longitude != 0 && +this.escolaDestino.latitude != 0 && +this.escolaDestino.latitude != 0){
      return true;
    }

    if(this.escolaOrigem.latitude && +this.escolaOrigem.latitude == 0 && this.escolaOrigem.longitude && +this.escolaOrigem.longitude == 0){
      this.msgLatLongInvalida = `A Escola ${this.escolaOrigem.nomeEscola} está com problema na Lat/Long ... `;
    }

    if(this.escolaDestino.latitude && +this.escolaDestino.latitude == 0 && this.escolaDestino.longitude && +this.escolaDestino.longitude == 0){
      this.msgLatLongInvalida += `A Escola ${this.escolaDestino.nomeEscola} está com problema na Lat/Long ...`;
    }

    return false;
  }

  public getLatLong(lat: number, lng: number): google.maps.LatLng{
    return new google.maps.LatLng({lat, lng});
  }

  public async computeTotalDistance(result: google.maps.DirectionsResult) {
    const myroute = result.routes[0];

    if (!myroute) {
        return;
    }

    this.totalKm = 0;
    this.totalSeg = 0;
    myroute.legs.forEach((element) => {
      if(element.distance && element.duration){
        this.totalKm += element.distance.value;
        this.totalSeg += element.duration.value;
      }
    });

    const hours = Math.floor(this.totalSeg / 3600) < 10 ? (`00${  Math.floor(this.totalSeg / 3600)}`).slice(-2) : Math.floor(this.totalSeg / 3600);
    const minutes = (`00${  Math.floor((this.totalSeg % 3600) / 60)}`).slice(-2);
    this.totalDuration = `${hours  }:${  minutes  }`;
    this.totalKm = parseInt((this.totalKm / 1000).toString());
       
    this.form.tempoDeslocamento.setValue(this.totalDuration);
    this.form.kmPercorrido.setValue(this.totalKm);

    var response = await lastValueFrom(this.utilsService.calcularHoras(this.form.horarioSaida.value, this.totalDuration))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.form.horarioChegada.setValue(response.data);
  }

  public async trocaHoraSaida(){
    const horaSaida = this.form.horarioSaida.value;

    if(this.gerouRota){
      var response = await lastValueFrom(this.utilsService.calcularHoras(horaSaida, this.totalDuration))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.form.horarioChegada.setValue(response.data);
    }    
  }

  public async removerRota() {
    this.gerouRota = false;

    this.form.horarioChegada.setValue(null);
    this.form.tempoDeslocamento.setValue(null);
    this.form.kmPercorrido.setValue(null);

    const divMaps = document.getElementById("map");
    if(divMaps) {
      divMaps.innerHTML = ''; // Limpa a div do maps.
      divMaps.style.display = 'none'
    }
  }

  //#endregion
}

