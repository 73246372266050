import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { DefaultResponse } from '../../models/DefaultResponse';
import { ConvivaApi } from 'src/app/conviva-api';
import { environment } from 'src/environments/environment';
import { AtribuicoesPorUsuario, AutorizaGovBr, UserLoginSed } from '../../models/sed/UserLogin';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private api = ConvivaApi;
  private keyDecrypt = '1234-conviva';

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  public hasTokenGovBr():boolean {
    const userExist = localStorage.getItem("tokenGovBr");
    if(userExist == null)
      return false;
    else
      return true;
  }

  public getTokenGovBr(): string {
    const token = localStorage.getItem("tokenGovBr");
    if(token != null)
      return token;

    return "";
  }

  public setTokenGovBr(token: string) {
    localStorage.setItem("tokenGovBr", `Bearer ${token}`);
  }

  public cleanStorageGovBr() {
    localStorage.removeItem("tokenGovBr");
  }

  public hasTokenConviva():boolean {
    const userExist = localStorage.getItem("tokenConviva");
    if(userExist == null)
      return false;
    else
      return true;
  }

  public getToken(): string {
    const token = localStorage.getItem("tokenConviva");
    if(token != null)
      return token;

    return "";
  }

  public setTokenConviva(token: string) {
    localStorage.setItem("tokenConviva", `${token}`);
  }

  public cleanStorageConviva() {
    localStorage.removeItem("tokenConviva");
    this.cleanStorageUserLogado();
  }

  public getTokenConviva():Observable<DefaultResponse> {

    const token =  this.getTokenGovBr();
    let headers = new HttpHeaders({'Authorization': token, 'Ocp-Apim-Subscription-Key': environment.ApimConviva});

    return this.http.get<DefaultResponse>(`${this.api.Login.getTokenSed}`, {'headers': headers })
                      .pipe(map((response: DefaultResponse) => response));
  }

  public LoginSed():Observable<AutorizaGovBr> {
    return this.http.post<AutorizaGovBr>(`${this.api.Sed.LoginSed}`, {})
                      .pipe(map((response: AutorizaGovBr) => response));
  }

  public getAtribuicoesPerfil(cdUsuario: number, codigoPerfil: number):Observable<AtribuicoesPorUsuario> {
    const token =  this.getTokenGovBr();
    const headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': token, 'Ocp-Apim-Subscription-Key': environment.Apim});


    return this.http.get<AtribuicoesPorUsuario>(`${this.api.Sed.getUserProfileSed}/${cdUsuario}/${codigoPerfil}`, {headers: headers})
                      .pipe(map((response: AtribuicoesPorUsuario) => response));
  }

  public getUser(): UserLoginSed | null {
    const userEncrypted = localStorage.getItem("userLogado");
    if(userEncrypted != null){
      try {
        const userDecrypted = CryptoJS.AES.decrypt(userEncrypted, this.keyDecrypt).toString(CryptoJS.enc.Utf8);
        return JSON.parse(userDecrypted) as UserLoginSed;
      }
      catch(error) {
        this.router.navigate(['/logout']);
      }
    }

    return null;
  }

  public setUser(userLogado: UserLoginSed) {
    const userLogadoEncrypted = CryptoJS.AES.encrypt(JSON.stringify(userLogado), this.keyDecrypt).toString();
    localStorage.setItem("userLogado", userLogadoEncrypted);
  }

  public cleanStorageUserLogado() {
    localStorage.removeItem("userLogado");
  }
}
