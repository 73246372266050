//Principais
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSelectConfig } from "@ng-select/ng-select";
import { lastValueFrom } from 'rxjs';
//Services
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { PsicologoService } from 'src/app/shared/services/psicologo.service'
//Models
import { PoloDiretoriaEscola } from 'src/app/shared/models/polo/PoloDiretoriaEscola';
import { Psicologo } from 'src/app/shared/models/psicologo/Psicologo';
import { Polo } from 'src/app/shared/models/polo/Polo';

@Component({
  selector: 'app-psicologo-edit',
  templateUrl: './psicologo-edit.component.html',
  styleUrls: ['./psicologo-edit.component.scss']
})
export class EditarPsicologoComponent {
  codigoPsicologo: number;
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  constructor(
    private config: NgSelectConfig,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private psicologoService: PsicologoService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    private ConvivaErrors: ConvivaErrorHandlingService
  ){
    this.config.notFoundText = "Não encontrado";
    this.route.paramMap.subscribe(params => {
      this.codigoPsicologo = Number(params.get('id')) ?? 0;
    });
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public submitted = false;

  public polos: Polo[] = [];
  public psicologo: Psicologo;
  public nomePsicologo:string;
  public codigoCPF:number;
  public codigoRG:number;
  public codigoCRP:number;
  public telefone:number;
  public email:string;
  public uf = [
    { id: 'AC', name: 'AC' },
    { id: 'AL', name: 'AL' },
    { id: 'AM', name: 'AM' },
    { id: 'AP', name: 'AP' },
    { id: 'BA', name: 'BA' },
    { id: 'CE', name: 'CE' },
    { id: 'DF', name: 'DF' },
    { id: 'ES', name: 'ES' },
    { id: 'GO', name: 'GO' },
    { id: 'MA', name: 'MA' },
    { id: 'MG', name: 'MG' },
    { id: 'MS', name: 'MS' },
    { id: 'MT', name: 'MT' },
    { id: 'PA', name: 'PA' },
    { id: 'PB', name: 'PB' },
    { id: 'PE', name: 'PE' },
    { id: 'PI', name: 'PI' },
    { id: 'PR', name: 'PR' },
    { id: 'RJ', name: 'RJ' },
    { id: 'RN', name: 'RN' },
    { id: 'RO', name: 'RO' },
    { id: 'RR', name: 'RR' },
    { id: 'RS', name: 'RS' },
    { id: 'SC', name: 'SC' },
    { id: 'SE', name: 'SE' },
    { id: 'SP', name: 'SP' },
    { id: 'TO', name: 'TO' }
  ];
  public diretoriaEscolas: PoloDiretoriaEscola;
  public formEditar!: FormGroup;

  public get form() {
    return this.formEditar.controls;
  }

  public ngOnInit() {
    this.formEditar = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoUF: new FormControl(null, [Validators.required]),
      nomePsicologo: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      telefone: new FormControl(null, [Validators.required]),
      codigoCRP: new FormControl(null, [Validators.required]),
      codigoRG: new FormControl(null, [Validators.required]),
      codigoCPF: new FormControl(null, [Validators.required])
    });

    this.spinnerService.show();
    this.getPolos();
    this.popularDados(this.codigoPsicologo);
    this.spinnerService.hide();

  }

  public async popularDados(codigoPsicologo:number) {
    this.spinnerService.show();

    var response = await lastValueFrom(this.psicologoService.getPsicologoPorCodigo(codigoPsicologo))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.psicologo = response.data;
    this.formEditar.get('codigoPolo')?.patchValue(this.psicologo.codigoPolo);
    this.formEditar.get('nomePsicologo')?.patchValue(this.psicologo.nomePsicologo);
    this.formEditar.get('codigoCPF')?.patchValue(this.psicologo.codigoCPF);
    this.formEditar.get('codigoRG')?.patchValue(this.psicologo.codigoRG);
    this.formEditar.get('codigoUF')?.patchValue(this.psicologo.codigoUF);
    this.formEditar.get('codigoCRP')?.patchValue(this.psicologo.codigoCRP);
    this.formEditar.get('telefone')?.patchValue(this.psicologo.telefone);
    this.formEditar.get('email')?.patchValue(this.psicologo.email);
    this.spinnerService.hide();
  }

  public async getPolos() {
    this.spinnerService.show();

    var response = await lastValueFrom(this.psicologoService.getPolos())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.spinnerService.hide();
  }

  public OnLimpar() {
    this.polos = [];
    this.spinnerService.show();
    this.getPolos();
    this.formEditar.get('codigoPolo')?.patchValue(null);
    this.formEditar.get('nomePsicologo')?.patchValue(null);
    this.formEditar.get('codigoCPF')?.patchValue(null);
    this.formEditar.get('codigoRG')?.patchValue(null);
    this.formEditar.get('codigoUF')?.patchValue(null);
    this.formEditar.get('codigoCRP')?.patchValue(null);
    this.formEditar.get('telefone')?.patchValue(null);
    this.formEditar.get('email')?.patchValue(null);
    this.spinnerService.hide();
  }

  public async OnSalvar() {
    this.submitted = true;
    this.spinnerService.show();
    const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const codigoCPF = this.formEditar.get('codigoCPF')?.value;
    const codigoRG = this.formEditar.get('codigoRG')?.value;
    const codigoUF = this.formEditar.get('codigoUF')?.value;
    const codigoCRP = this.formEditar.get('codigoCRP')?.value;
    const telefone = this.formEditar.get('telefone')?.value;
    const nomePsicologo = this.formEditar.get('nomePsicologo')?.value
    const email = this.formEditar.get('email')?.value;

    if(codigoCPF == undefined || codigoCPF == "" || codigoCPF.length != 11 || !this.validarCPF(codigoCPF))
    {
      this.toastr.warning('CPF invalido.');
      this.formEditar.get('codigoCPF')?.addValidators([Validators.required]);
      this.formEditar.get('codigoCPF')?.updateValueAndValidity();
      this.spinnerService.hide();
      return;
    }
    if(codigoRG == undefined || codigoRG == "" || codigoRG.length != 9)
    {
      this.toastr.warning('RG invalido.');
      this.formEditar.get('codigoRG')?.addValidators([Validators.required]);
      this.formEditar.get('codigoRG')?.updateValueAndValidity();
    }
    if(codigoUF == undefined || codigoUF == "")
    {
      this.toastr.warning('UF invalido.');
      this.formEditar.get('codigoUF')?.addValidators([Validators.required]);
      this.formEditar.get('codigoUF')?.updateValueAndValidity();
    }
    if(codigoCRP == undefined || codigoCRP == "" || codigoCRP.length != 7)
    {
      this.toastr.warning('CRP invalido.');
      this.formEditar.get('codigoCRP')?.addValidators([Validators.required]);
      this.formEditar.get('codigoCRP')?.updateValueAndValidity();
    }
    if(telefone == undefined || telefone == "")
    {
      this.toastr.warning('Telefone invalido.');
      this.formEditar.get('telefone')?.addValidators([Validators.required]);
      this.formEditar.get('telefone')?.updateValueAndValidity();
    }
    if(nomePsicologo == undefined || nomePsicologo == "")
    {
      this.toastr.warning('Nome invalido.');
      this.formEditar.get('nomePsicologo')?.addValidators([Validators.required]);
      this.formEditar.get('nomePsicologo')?.updateValueAndValidity();
    }
    if(!regexEmail.test(email)) {
      this.toastr.warning('Email invalido.');
      this.formEditar.get('email')?.addValidators([Validators.email]);
      this.formEditar.get('email')?.updateValueAndValidity();
    }
    if (this.formEditar.invalid) {
      this.spinnerService.hide();
      return;
    }

    var response = await lastValueFrom(this.psicologoService.getPsicologoPorCPF(codigoCPF))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    if(response.data != null){
      const objetoPsicologo: Psicologo = {
        codigoPsicologo: this.codigoPsicologo,
        codigoPolo: this.formEditar.get('codigoPolo')?.value,
        nomePsicologo: nomePsicologo,
        codigoCPF: codigoCPF,
        codigoRG: codigoRG,
        codigoUF: codigoUF,
        codigoCRP: codigoCRP,
        telefone: telefone,
        email: email,
      };

      var response = await lastValueFrom(this.psicologoService.setEditarPsicologo(objetoPsicologo))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.spinnerService.hide();
      this.toastr.success('Psicologo Editado com sucesso.');
    }
    this.spinnerService.hide();
  }

  OnVoltar() {
    this.router.navigate(['/manutencao-psicologo']);
  }

  validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    return remainder === parseInt(cpf.substring(10, 11));
  }

}
