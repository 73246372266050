
<div class="star-rating" >
  <i
    *ngFor="let star of stars; let i = index"
    [class.fa-regular]="!star || rating == 0"
    [class.fa-solid]="star && rating != 0"
    [class.fa-star]="star || !star"
    [class.red-star]="rating == 2|| rating == 1 "
    [class.yellow-star]="rating == 3"
    [class.blue-star]="rating == 4 || rating == 5"
    (click)="onStarClick(i)"
    [class.disabled]="isDisabled"
    [class.read-only]="isReadOnly"
    [class.hover-on]="!isReadOnly "
    >
    <!-- (mouseover)="onStarHover(i)"
    (mouseleave)="onStarLeave()"
     -->
  </i>
</div>
