import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

const Bases ={
  URL_PROVIDER: environment.IdpSP,
  REDIRECT_URL: window.location.origin + '/gerenciar-login',
  REDIRECT_URL_LOGOUT: window.location.origin + '/logout',
  SCOPES_AUTH: "openid phone email profile",
  URL_TOKEN: environment.IdpSpToken,
};

export const authConfig: AuthConfig = {
  issuer: Bases.URL_PROVIDER,
  redirectUri: Bases.REDIRECT_URL,
  postLogoutRedirectUri: Bases.REDIRECT_URL_LOGOUT,
  clientId: environment.clientId,
  responseType: 'code',
  scope: Bases.SCOPES_AUTH,
};
