import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { DefaultResponse } from 'src/app/shared/models/DefaultResponse';
import { AlunosRecorrentes } from 'src/app/shared/models/aluno/AlunosRecorrentes';
import { Categoria } from 'src/app/shared/models/categoria/Categoria';
import { ListarChamados } from 'src/app/shared/models/chamados/listar-chamados';
import { ListarReincidentes } from 'src/app/shared/models/chamados/listar-reincidentes';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Turno } from 'src/app/shared/models/escola/Turno';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { Turma } from 'src/app/shared/models/turma/Turma';
import { AlunoService } from 'src/app/shared/services/alunos.service';
import { CategoriaService } from 'src/app/shared/services/categoria.service';
import { ChamadoService } from 'src/app/shared/services/chamado.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { EscolaService } from 'src/app/shared/services/escola.service';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';
import { GenericValidator } from 'src/app/shared/validations/genericValidators';
@Component({
  selector: 'dashboard-reincidencia',
  templateUrl: './dashboard-reincidencia.component.html',
  styleUrls: ['./dashboard-reincidencia.component.scss']
})

export class DashboardReincidenciaComponent implements OnInit, OnDestroy {

  private get DataAtual (){return new Date()}
  public minDate:string = '2021-01-01' ;
  public maxDate:string = `${this.DataAtual.getFullYear()}-12-30` ;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  // Variaveis do Grid DataTable
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;
  public isDtInitialized:boolean = false;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private chamadoService: ChamadoService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private categoriaService: CategoriaService,
    private psicologoService:PsicologoService,
    private validatorsService:GenericValidator,
    private alunoService:AlunoService
  ){
    // ng-select mudança de texto
    this.config.notFoundText = 'Não encontrado';

    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  // Listas da tela
  public gravidades = [ { id: 1, name: 'Amarelo' }, { id: 2, name: 'Vermelho' }];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public turmas: Turma[] = [];
  public lstChamados: ListarChamados[] = [];
  public categorias: Categoria[] = [];
  public alunos: AlunosRecorrentes[] = [];
  public alunosFiltrados: AlunosRecorrentes[] = [];

  //Form
  public formDashboard!: FormGroup;
  public get form() { return this.formDashboard.controls; }
  public submitted: boolean;
  public showPesquisa: boolean;

  //Totalizadores
  public totalChamados: number = 0;
  public totalChamadosAbertos: number = 0;
  public totalChamadosEncerrados: number = 0;
  public totalChamadosEncaminhados: number = 0;

  public ngOnInit(): void {

    this.dtOptions = {
      deferRender: true,
      destroy:true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [ 10, 25, 50, -1 ],
        [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
      ],
      buttons: [
        'pageLength',
        {
          extend:'print',
          exportOptions:{
            columns:[0,1,2,3,4,5]
          }
        },
        {
          extend:'excel',
          exportOptions:{
            columns:[0,1,2,3,4,5]
          }
        },{
          extend:'pdf',
          exportOptions:{
            columns:[0,1,2,3,4,5]
          }
        },
      ],
    };

    //Formulario de pesquisa.
    this.formDashboard = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null),
      codigoTurno: new FormControl(null),
      codigoTurma: new FormControl(null),
      codigoGravidade: new FormControl(null),
      codigoSubCategoria: new FormControl(null),
      dataInicio: new FormControl(null),
      dataFim: new FormControl(null),
      codigoAluno: new FormControl(null),
    });

    this.getCategorias();

    if(this.userLogado?.flPsicologo){
      this.getDiretoriasPerfilDiretoria();
    }else{
        if(this.atribuicoesLogin.NumeroComportamento == 1){
          this.getDiretorias();
        }

        if(this.atribuicoesLogin.NumeroComportamento == 2){
          this.getDiretoriasPerfilDiretoria();
        }

        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
          this.getDiretoriasPerfilEscola();
        }

    }


  }

  public validaAno(data: AbstractControl<any, any>){
    var dataDoCampo = data.value;
    if(dataDoCampo){
     const ano = new Date(dataDoCampo).getFullYear();
      if(ano > this.DataAtual.getFullYear() || ano < this.DataAtual.getFullYear() - 10){
        data.setValue(null);
        this.toastr.warning("Data invalida! Selecione uma data valida para pesquisar.");
      }
    }
  }

  async getAlunos(){
    this.submitted = true;
    this.form.codigoAluno.setValue(null);

    if (this.formDashboard.invalid) {
      this.showPesquisa = false;
      this.spinnerService.hide();
      return;
    }
    var response = await lastValueFrom(this.alunoService.getAlunosPorEscola(this.form.codigoEscola.value));
    if(!response.isSucess){
      this.toastr.error(response.message);
    }

    this.alunos = response.data;
    this.alunosFiltrados = response.data;
    if(this.alunos.length == 0){
      this.toastr.error("Nenhum aluno encontrado para esta turma.");
    }else{
      this.form.codigoAluno.enable();

    }
  }
  public setAlunos(){
    this.spinnerService.show();

    var codigoTurno = this.form.codigoTurno.value;
    if(codigoTurno != null){
      this.alunosFiltrados = this.alunos.filter(s=>s.codigoTurno == codigoTurno);
    }else{
      this.alunosFiltrados = this.alunos;
    }
    this.spinnerService.hide();
  }

  public ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public async PesquisarChamados(statusEnvolvido: number = -1) {
    this.spinnerService.show();
    this.submitted = true;

    if (this.formDashboard.invalid) {
      this.showPesquisa = false;
      this.spinnerService.hide();
      return;
    }
    this.showPesquisa = true;

    const parametros = this.SetParametrosChamados(statusEnvolvido);
    const response = await lastValueFrom(this.chamadoService.getDashboardReincidencia(parametros));
    if(!response.isSucess){
      this.toastr.error(response.message);
    }

    this.lstChamados = response.data;
    this.spinnerService.hide();

    if(this.lstChamados.length == 0){
      this.SetTotalizadores();
      this.toastr.warning("Não existem chamados para essa pesquisa");
      if(this.dtElement.dtInstance != null) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next(this.dtOptions);
        });
      }
    }
    else{
      this.SetTotalizadores();

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next(this.dtOptions);
        });
      } else {
        this.isDtInitialized = true;

        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
        }

        this.dtTrigger.next(this.dtOptions);
      }
    }
  }

  public SetParametrosChamados(statusEnvolvido: number): ListarReincidentes {
    const chamado = new ListarReincidentes();
    chamado.codigoDiretoria = this.form?.codigoDiretoria.value;
    chamado.codigoEscola = this.form?.codigoEscola.value;
    chamado.codigoTurno = this.form?.codigoTurno.value;
    chamado.codigoTurma = this.form?.codigoTurma.value;
    // chamado.codigoGravidade = this.form?.codigoGravidade.value;
    chamado.codigoSubCategoria = this.form?.codigoSubCategoria.value;
    chamado.dataInicio = this.form?.dataInicio.value;
    chamado.dataFim = this.form?.dataFim.value;
    chamado.filtroStatusEnvolvido = statusEnvolvido;
    chamado.codigoAluno = this.form.codigoAluno.value;
    // chamado.codigoEnvolvido =

    if(statusEnvolvido == -1)
      this.isDtInitialized = false;

    return chamado;
  }

  public getDiretorias() {
    this.spinnerService.show();

    this.escolaService.getDiretoriaPorTipoDiretoria().subscribe({
      next: (response: DefaultResponse) => {
        if(!response.isSucess){
          this.toastr.error(response.message);
        }
        this.diretorias = response.data;
        this.spinnerService.hide();
        if(this.userLogado?.codigoDiretoria != undefined){
          this.ativaDiretoria();
          this.getEscolas(true);
        }
      },
      error: (error) => {
        this.ConvivaErrors.handleError(error);
        this.spinnerService.hide();
      },
    });
  }

  public getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formDashboard.get('codigoDiretoria')?.value;
    if(this.userLogado?.flPsicologo){
      this.zerarEscola();
      this.spinnerService.show();
      this.psicologoService
        .getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo)
        .subscribe({
          next: (response: DefaultResponse) => {
            if(!response.isSucess){
              this.toastr.error(response.message);
            }
            this.escolas = response.data.escolas;
            this.diretorias = response.data.diretorias;
            this.spinnerService.hide();
            this.ativaEscola();
          },
          error: (error) => {
            this.ConvivaErrors.handleError(error);
            this.spinnerService.hide();
          },
        });
    }else
    if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2){
      this.zerarEscola();
      if (codigoDE != null) {
        this.spinnerService.show();

        this.escolaService
          .getEscolasPorCodigoDiretoria(codigoDE)
          .subscribe({
            next: (response: DefaultResponse) => {
              if(!response.isSucess){
                this.toastr.error(response.message);
              }
              this.escolas = response.data;
              this.spinnerService.hide();
              this.ativaEscola();
            },
            error: (error) => {
              this.ConvivaErrors.handleError(error);
              this.spinnerService.hide();
            },
          });
      }
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formDashboard.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public getTurnos() {
    const codigoEE = this.formDashboard.get('codigoEscola');
    if (codigoEE?.value != null) {
      this.spinnerService.show();
      this.escolaService
        .getTurnosPorCodigoCie(codigoEE?.value)
          .subscribe({
            next: async (response: DefaultResponse) => {
              if(!response.isSucess){
                this.toastr.error(response.message);
              }

              this.turnos = response.data;
              await this.getAlunos();

              if (this.turnos == null)
                this.toastr.warning('Não foi encontrados turnos para essa escola !');

              this.spinnerService.hide();
            },
          error: (error) => {
            this.ConvivaErrors.handleError(error);
            this.spinnerService.hide();
          },
      });
    }
    else{
      this.zerarTurnos();
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = codigoEE?.value == null ? 0 : codigoEE?.value;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formDashboard.get('codigoTurno')?.patchValue(null);
  }

  public getTurmas() {
    this.spinnerService.show();
    const codigoEs = this.formDashboard.get('codigoEscola');
    const codigoTurno = this.formDashboard.get('codigoTurno');

    if(codigoEs?.value != null) {
      this.escolaService
        .getTurmasPorCodigoCie(codigoEs?.value, codigoTurno?.value)
        .subscribe({
            next: (response: DefaultResponse) => {
              if(!response.isSucess){
                this.toastr.error(response.message);
              }

              this.turmas = response.data;
              this.form.codigoTurma.enable();
              this.spinnerService.hide();
            },
            error: (error) => {
              this.ConvivaErrors.handleError(error);
              this.spinnerService.hide();
            }
      });
    }
  }

  public zerarTurma() {
    this.turmas = [];
    this.formDashboard.get('codigoTurma')?.patchValue(null);
  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();

      this.getTurnos();
      this.PesquisarChamados();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria != undefined)
      this.formDashboard.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if(this.userLogado !== null)
        this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){

    if(this.userLogado?.codigoEscola != undefined){
      var existEscola = this.escolas.find(s=>s.codigoEscola == this.userLogado?.codigoEscola);
      if(existEscola != undefined){
        this.formDashboard.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }
    }
    if(this.userLogado?.codigoDiretoria != undefined && this.userLogado?.codigoEscola != undefined)
    {
      this.getTurnos();
      this.PesquisarChamados();
    }
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public getCategorias() {
    this.spinnerService.show();

    this.categoriaService
      .getCategoriasWithSub()
      .subscribe({
        next: (response: DefaultResponse) => {
          if(!response.isSucess){
            this.toastr.error(response.message);
          }
          this.categorias = response.data;
          this.spinnerService.hide();
        },
        error: (error) => {
          this.ConvivaErrors.handleError(error);
          this.spinnerService.hide();
        },
    });
  }

  private SetTotalizadores(){
    if (!this.isDtInitialized) {
      if(this.lstChamados.length > 0) {

        this.totalChamados = this.lstChamados.length;
        this.totalChamadosAbertos = this.lstChamados.filter(r => r.status == 0 || r.status != 4 && r.status != 1).length;
        this.totalChamadosEncerrados = this.lstChamados.filter(r => r.status == 4).length;
        this.totalChamadosEncaminhados = this.lstChamados.filter(r => r.status == 1 ).length;
      }
      else {
        this.totalChamados = 0;
        this.totalChamadosAbertos = 0;
        this.totalChamadosEncerrados = 0;
        this.totalChamadosEncaminhados = 0;
      }
    }
  }
}

