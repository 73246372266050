import { Component, HostListener } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  constructor(private service:ModalService) {}

  @HostListener('document:keydown.escape', ['$event'])
  onEscKeyPress(event: KeyboardEvent): void {
    this.service.emitOutClickModal();
  }
  onOutModalClick(event:MouseEvent): void {
    var divClicada = event.target as HTMLElement;
    if(divClicada.classList.contains('modal')){
      this.service.emitOutClickModal();
    }
  }

}
