import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {



  public ngOnInit(): void {
  }
  public CloseMenu(){
    const menu = document.getElementsByClassName("sidebar-menu").item(0) as HTMLElement;
    menu.className = "sidebar-menu";
  }

}
