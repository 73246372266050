<section class="indice-form">
  <div class="container">

    @if(this.temDeslocamento){
      <a href="javascript:void(0)" class="icon-indice" [ngClass]="{'ativo' :passoAtual >= 1 }"><span></span></a>
      <a href="javascript:void(0)" class="icon-indice" [ngClass]="{'ativo' :passoAtual >= 2 }"><span></span></a>
      <a href="javascript:void(0)" class="icon-indice" [ngClass]="{'ativo' :passoAtual >= 3 }"><span></span></a>
      <a href="javascript:void(0)" class="icon-indice" [ngClass]="{'ativo' :passoAtual >= 4 }"><span></span></a>
    }
    @else {
      <a href="javascript:void(0)" class="icon-indice" [ngClass]="{'ativo' :passoAtual >= 1 }"><span></span></a>
      <a href="javascript:void(0)" class="icon-indice" [ngClass]="{'ativo' :passoAtual >= 2 }"><span></span></a>
      <a href="javascript:void(0)" class="icon-indice" [ngClass]="{'ativo' :passoAtual >= 3 }"><span></span></a>
    }

  </div>
</section>