  <breadcrumb
    [paginas]="[{route:'/home',name:'Home'},{route:'/filtro-visitas-professor',name:'Registro da Atividade do POC'}]"
  />
  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Registro da Atividade do POC</h2>
    </div>
  </section>

<section class="section-filtro-visitas">
  <div class="container">
    <h2>
      Você deseja registrar uma atividade  <br />
      de acolhimento, prevenção?
    </h2>
    <div class="conteudo-btns-filtro">
      <a href="javascript:void(0)" routerLink="/visita-professor-acolhimento" class="btn_primeiro">Acolhimento</a>
      <a href="javascript:void(0)" routerLink="/visita-professor-prevencao" class="btn_segundario">Prevenção</a>
    </div>
  </div>
</section>
