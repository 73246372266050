import { AbstractControl, ValidationErrors } from '@angular/forms';

export class GenericValidator {
  constructor() {}

  public ValidaCpf(control: AbstractControl): { [key: string]: any } | null{
    const cpf = control.value;
    if (cpf) {
      let numbers, digits, sum, i, result, equalDigits;
      equalDigits = 1;
      if (cpf.length < 11) {
        return { message: 'CPF não esta no tamanho correto' };
      }

      for (i = 0; i < cpf.length - 1; i++) {
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
          equalDigits = 0;
          break;
        }
      }

      if (!equalDigits) {
        numbers = cpf.substring(0, 9);
        digits = cpf.substring(9);
        sum = 0;
        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(0))) {
            return { message: 'CPF inválido' };
        }
        numbers = cpf.substring(0, 10);
        sum = 0;

        for (i = 11; i > 1; i--) {
          sum += numbers.charAt(11 - i) * i;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(1))) {
            return { message: 'CPF inválido' };
        }
        return null;
      } else {
        return { message: 'CPF inválido' };
      }
    }
    return null;
  }

  public cnpjValidator = (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
        return null;
    }

    const regexp: RegExp = new RegExp('^[0-9]*$');

    const forbidden: boolean = !regexp.test(control.value);

    if (forbidden) {
        return { message: 'Formato inválido' };
    }

    if (control.value.length > 14) {
        return { message: 'Texto excede tamanho máximo de caracteres. (14).' };
    }

    if(!this.validaCamposCNPJ(control.value)){
        return { message: 'Digite um CNPJ Valido.' };
    }

    return null;
}


private validaCamposCNPJ = (cnpj: any): boolean => {
    cnpj = cnpj.replace(/[^\d]+/g,'');
    if (cnpj.length != 14) return false;
    if (cnpj == "00000000000000") return false;

    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) return false;
    return true;
}

}
