import { Time } from "@angular/common";

export class CriacaoAgendamento{
  codigoChamado:number;
  codigoEnvolvido:number;
  cpfCoordenador:string;
  polo:number;
  codigoDiretoria:number;
  dataAtendimento?:Date;
  dataAtendimentoString?:string;
  cpfPsicologo:string;
  codigoPerfil:number;
  nomePerfil:string;
}
