import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class CategoriaService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient,
    private router:Router)
  { }

  public getCategoriasWithSub():Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Categoria.getCategoriasWithSub}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getGravidade(codigoSubcategoria: number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Categoria.getGravidade}?codigoSubcategoria=${codigoSubcategoria}`)
                    .pipe(map((response: DefaultResponse) => response));
  }
}
