//Principal
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
//Services
import { PerfilService } from "src/app/shared/services/perfil.service";
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
// DataTable
import { lastValueFrom } from 'rxjs';

//Model
import { PerfilLoginSed } from "src/app/shared/models/sed/UserLogin";
import { DefaultResponse } from 'src/app/shared/models/DefaultResponse';
import { FuncionalidadePerfil } from 'src/app/shared/models/Funcionalidade/FuncionalidadePerfil';
import { PerfilAcesso } from 'src/app/shared/models/Funcionalidade/PerfilAcesso';

@Component({
  selector: 'app-perfil-manage',
  templateUrl: './perfil-manage.component.html',
  styleUrls: ['./perfil-manage.component.scss']
})
export class PerfilManageComponent implements OnInit , OnDestroy {

  public flagPerfil = {flagAtivo: 1};
  public submitted = false;
  public perfis: PerfilLoginSed[] = [];
  public funcionalidades: FuncionalidadePerfil[] = [];
  public itemPerfilAcesso: PerfilAcesso;
  public PerfisAcesso: PerfilAcesso[] = [];
  public formPerfilManage!: FormGroup;

  public get form() {
    return this.formPerfilManage.controls;
  }

  constructor(
    private perfilService: PerfilService,
    private spinnerService: NgxSpinnerService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
  ){

  }
  public ngOnDestroy(): void {
  }

  public ngOnInit(){
    this.formPerfilManage = new FormGroup({
      codigoPerfil: new FormControl(null, [Validators.required]),
      codigoFuncionalidade: new FormControl(null, [Validators.required]),
    });
    this.getPerfis();
    this.getFuncionalidades();
  }

  public async getPerfis(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.perfilService.getPerfis())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.perfis = response.data;
    this.spinnerService.hide();
  }

  public async getFuncionalidades(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.perfilService.getFuncionalidades())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.funcionalidades = response.data;
    this.spinnerService.hide();
  }

  public OnSalvar(event: any, perfilacesso: PerfilAcesso) {
    this.OnFuncionalidade(event , perfilacesso);
  }

  public async OnFuncionalidade(event: any, perfilacesso: PerfilAcesso) {
    this.spinnerService.show();
    const codigoFlagAtivo = event.target.checked;
    this.flagPerfil.flagAtivo = codigoFlagAtivo? 1 : 0;
    const objetoPerfilAcesso: PerfilAcesso = {
      codigoPerfil: perfilacesso.codigoPerfil,
      nomePerfil: perfilacesso.nomePerfil,
      codigoFuncionalidade: perfilacesso.codigoFuncionalidade,
      NumeroComportamento: perfilacesso.NumeroComportamento,
      flagAtivo: this.flagPerfil.flagAtivo
    }
    var response = await lastValueFrom(this.perfilService.setCadastrarPerfilAcesso(objetoPerfilAcesso))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.mostrarPerfisAcessos();
    this.spinnerService.hide();
  }

  public async mostrarPerfisAcessos()
  {
    var codigoPerfil = this.formPerfilManage.get('codigoPerfil')?.value;
    this.spinnerService.show();
    var response = await lastValueFrom(this.perfilService.getPerfisAcessos(codigoPerfil.codigoPerfil))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.PerfisAcesso = response.data;
    this.spinnerService.hide();
  }

  public zerarCombos()
  {
    this.perfis = [];
    this.funcionalidades = [];
    this.formPerfilManage.get('codigoPerfil')?.patchValue(null);
    this.formPerfilManage.get('codigoFuncionalidade')?.patchValue(null);
    this.getPerfis();
    this.getFuncionalidades();
  }

}

