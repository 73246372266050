import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { HistoricoChamado } from "../models/chamados/historico";

@Injectable({
  providedIn: 'root'
})

export class HistoricoChamadoService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient,
    private router:Router)
  { }

  public setCadastrarHistoricoChamado(parametros: HistoricoChamado):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.HistoricoChamado.setCadastrarHistoricoChamado}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public SetInformacaoAdicional(parametros: any):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.HistoricoChamado.setInformacaoAdicional}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

}
