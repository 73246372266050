<form [formGroup]="formEditar" (submit)="OnSalvar()">
  <!-- FILTRO ESCOLA -->
  <section class="section-filtro">
    <div class="container">
      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          [readonly]="true"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
          (change)="getEscolas()"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          [readonly]="true"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          (change)="getTurnos()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Turno</b></p>
        <ng-select
          [items]="turnos"
          [readonly]="true"
          bindLabel="nomeTurno"
          bindValue="codigoTurno"
          placeholder="Selecione um Turno"
          formControlName="codigoTurno"
          name="codigoTurno"
          [ngClass]="{ 'is-invalid': submitted && form.codigoTurno.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoTurno.errors" class="alert">
          <div *ngIf="form.codigoTurno.errors.required">
            * Turno é obrigatório
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Alterar Chamado</h2>
    </div>
  </section>

  <!-- DETALHES CHAMADO -->
  <section class="conteudo-principal">
    <div class="container-conviva form-registro">

      <!-- INFO DO CHAMADO -->
      <div class="time-line conteudo-registro">

        <div class="conteudo-select">
          <label for="select-gravidade">Data da Ocorrência</label>
          <input
          type="datetime-local"
          class="input-date"
          name="dataChamado"
          formControlName="dataChamado"
          />
        </div>

        <div class="conteudo-select">
          <label for="select-categoria">Categoria</label>
          <ng-select
            [items]="categorias"
            bindLabel="descricaoSubCategoria"
            bindValue="codigoSubCategoria"
            groupBy="descricaoCategoria"
            placeholder="Selecione uma Sub Categoria"
            formControlName="codigoSubCategoria"
            name="codigoSubCategoria"
            (change)="getGravidade()"
            [ngClass]="{
              'is-invalid': submitted && form.codigoSubCategoria.errors
            }"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.descricaoCategoria || "Unnamed group" }}
            </ng-template>
          </ng-select>

          <div *ngIf="submitted && form.codigoSubCategoria.errors" class="alert">
            <div *ngIf="form.codigoSubCategoria.errors.required">
              * Sub Categoria é obrigatório
            </div>
          </div>
        </div>

        <div class="conteudo-select">
          <label for="select-gravidade">Gravidade</label>
          <ng-select
            [items]="gravidades"
            bindLabel="name"
            bindValue="id"
            class="select2-single"
            placeholder="Selecione uma Gravidade"
            formControlName="codigoGravidade"
            name="codigoGravidade"
            [ngClass]="{
              'is-invalid': submitted && form.codigoGravidade.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoGravidade.errors" class="alert">
            <div *ngIf="form.codigoGravidade.errors.required">
              * Gravidade é obrigatório
            </div>
          </div>
        </div>

        <div class="conteudo-textarea">
          <label for="text-registro">Descreva a ocorrência </label>
          <textarea
            id="text-registro"
            name="text-registro"
            rows="6"
            cols="50"
            formControlName="dsChamado"
            name="dsChamado"
            minlength="50"
            (keyup)="OnChangeCountLetters()"
            [ngClass]="{ 'is-invalid': submitted && form.dsChamado.errors }"
          ></textarea>
          <p>Quantidade de caracteres atual: {{qtdeCaracteresDescricao}}</p>
          <div *ngIf="submitted && form.dsChamado.errors" class="alert">
            <div *ngIf="form.dsChamado.errors?.required">
              * Descrição da ocorrência é obrigatório.
            </div>
            <div *ngIf="form.dsChamado.errors?.minlength">
              * É obrigatório no mínimo digitar 50 caracteres.
            </div>
            <div *ngIf="form.dsChamado.errors?.maxlength">
              * Maximo de caracteres é 500.
            </div>
          </div>
        </div>

        <div class="conteudo-upload">
          <div class="conteudo-textarea">
            <label>Inserir documentos, fotos, áudios (MP3) ou vídeos (MP4).</label>
          </div>
          <div class="input-upload">
            <input
              type="file"
              id="arquivos"
              name="arquivos"
              accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4, .ppt, .pptx, .potx, .ppsx, .odp"
              (change)="OnFileChange($event)"
              multiple
            />
            <label for="arquivos" class="message-container" *ngIf="files.length === 0">
              Nenhum arquivo escolhido
            </label>
            <label for="arquivos" class="message-container" *ngIf="files.length > 0">
              Selecionar mais arquivos
            </label>
          </div>

          <div *ngIf="files.length > 0" class="imagem-escolhida">
            <div *ngFor="let file of files; let i = index">
              <div class="imagem-item">
                <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado" *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

                <div class="info-container">
                  <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                  <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                  <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                  <span>{{ removeUniqueId(file.nome).length > 50 ? removeUniqueId(file.nome).slice(0, 50) + '...' : removeUniqueId(file.nome) }}</span>
                  <i class="fas fa-times" (click)="removeFile(i)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-btns">
          <input type="button" class="btn_outline" id="btnCancelar" name="b2" value="Cancelar" (click)="GoBack()"/>
          <input type="submit" class="btn_primary" value="Salvar" />
        </div>
      </div>

      <!-- ENVOLVIDO -->
      <aside class="sidebar sidebar-registro">

        <div class="box-sidebar-person">
          <h3>Envolvidos</h3>
          <div class="content-person">
            <a
              href="javascript:void(0)"
              class="btn-add-person btn-gg"
              (click)="AbrirModal()"
            >
              <img src="../../../../assets/img/icons/icon-add.svg" alt="" />
            </a>

            <a
              *ngFor="let envolvido of envolvidos"
              href="javascript:void(0)"
              class="btn-add-person btn-gg"
              (click)="OnExcluirEnvolvido(envolvido.idTemporario !== undefined ? envolvido.idTemporario : 0, envolvido.status !== undefined ? envolvido.status : 0)"
            >
              <div class="img-aluno ">
                <span title="{{envolvido.tipoPessoa == 1 ? envolvido.escola == undefined ? envolvido.nome : envolvido.nome + '\n' + 'Escola: ' + envolvido.escola : envolvido.tipoPessoa == 2 ? envolvido.nome + '\n' + envolvido.cpf : envolvido.dsOutros }}">{{envolvido.imagemTemporaria}}</span>
              </div>
            </a>

          </div>
        </div>
      </aside>
    </div>
  </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModal">
  <form [formGroup]="formModal" (submit)="OnAdicionarEnvolvido()">
    <div class="container-conviva form-registro modal-envolvidos">
      <div>
        <h1 class="titulo-page">{{this.tituloModal}}</h1>
      </div>

      <div class="time-line conteudo-registro modal-conteudo-busca-aluno">

        <div class="conteudo-select">
          <label>Tipo de Envolvido</label>
          <ng-select
            [items]="TipoEnvolvido"
            bindLabel="name"
            bindValue="id"
            class="select2-single"
            placeholder="Selecione o tipo de envolvido"
            formControlName="tipoEnvolvido"
            name="tipoEnvolvido"
          >
          </ng-select>
        </div>

        <div class="conteudo-select">
          <label>Tipo Pessoa</label>
          <ng-select
            [items]="TipoPessoas"
            bindLabel="name"
            bindValue="id"
            class="select2-single"
            placeholder="Selecione o tipo da pessoa"
            formControlName="tipoPessoa"
            name="tipoPessoa"
            (change)="OnTipoPessoaChange($event)"
          >
          </ng-select>

          <div
            *ngIf="submittedModal && modalForm.tipoPessoa.errors"
            class="alert"
          >
            <div *ngIf="modalForm.tipoPessoa.errors.required">
              * Tipo pessoa do envolvido é obrigatório.
            </div>
          </div>
        </div>

        <div *ngIf="showBuscaAluno">
          <div class="modal-conteudo-busca-aluno">
            <div class="grupo-filter-modal">
              <p><b>Diretoria</b></p>
              <ng-select
                [items]="diretorias"
                bindLabel="nomeDiretoria"
                bindValue="codigoDiretoria"
                placeholder="Selecione uma Diretoria"
                formControlName="codigoDiretoriaModal"
                name="codigoDiretoriaModal"
                (change)="getEscolasModal()"
              >
              </ng-select>
              <div
                *ngIf="validaBuscaRA && modalForm.codigoDiretoriaModal.errors"
                class="alert"
              >
                <div *ngIf="modalForm.codigoDiretoriaModal.errors.required">
                  * Diretoria é obrigatório quando pesquisado por nome
                </div>
              </div>
            </div>

            <div class="grupo-filter-modal">
              <p><b>Escola</b></p>
              <ng-select
                [items]="escolasModal"
                bindLabel="nomeEscola"
                bindValue="codigoEscola"
                placeholder="Selecione uma Escola"
                formControlName="codigoEscolaModal"
                name="codigoEscolaModal"
              >
              </ng-select>
              <div *ngIf="modalForm.codigoEscolaModal.errors" class="alert">
                <div
                  *ngIf="
                    validaBuscaRA && modalForm.codigoEscolaModal.errors.required
                  "
                >
                  * Escola é obrigatório quando pesquisado por nome
                </div>
              </div>
            </div>

            <div class="conteudo-search">
              <input
                type="text"
                placeholder="Buscar por nome ou RA"
                id="js-input-search"
                formControlName="raNomeAluno"
                name="raNomeAluno"
              />
              <button
                type="button"
                id="btnPesquisaAluno"
                (click)="BuscarAlunoPorNomeRA()"
              >
                <img src="../../../assets/img/icons/icon-search.svg" alt="" />
              </button>
            </div>

            <div
              *ngIf="submittedModal && modalForm.raNomeAluno.errors"
              class="alert"
            >
              <div *ngIf="modalForm.raNomeAluno.errors.required">
                * Nome ou RA do aluno é obrigatório.
              </div>
            </div>

            <div *ngIf="showDropAuxiliar">
              <div class="grupo-filter-modal">
                <p><b>Estudantes</b></p>
                <ng-select
                  [items]="alunos"
                  bindLabel="nomeAluno"
                  bindValue="ra"
                  placeholder="Selecione o Aluno"
                  formControlName="raAlunoModalDrop"
                  name="raAlunoModalDrop"
                >
                </ng-select>
                <div
              *ngIf="submittedModal && modalForm.raAlunoModalDrop.errors"
              class="alert"
            >
              <div *ngIf="modalForm.raAlunoModalDrop.errors.required">
                * Nenhum aluno foi selecionado.
              </div>
              </div>

              </div>
            </div>

          </div>
        </div>

        <div *ngIf="showBuscaEquipe">
          <div class="modal-conteudo-busca-aluno">
            <div class="conteudo-search">
              <input
                type="text"
                placeholder="Digite o CPF"
                id="js-input-search"
                formControlName="cpfFuncionario"
                name="cpfFuncionario"
                mask="000.000.000-00"
                [validation]="true"
              />
              <button type="button" id="btnBuscarFuncionario" (click)="GetFuncionariosPorCpf()">
                <img src="../../../assets/img/icons/icon-search.svg" alt="" />
              </button>
            </div>
            <div
              *ngIf="submittedModal && modalForm.cpfFuncionario.errors"
              class="alert"
            >
              <div *ngIf="modalForm.cpfFuncionario.errors.required">
                * CPF do funcionario é obrigatório.
              </div>
              <div
                *ngIf="
                  modalForm.cpfFuncionario.errors &&
                  this.modalForm.cpfFuncionario.errors.message
                "
              >
                {{ this.modalForm.cpfFuncionario.errors.message }}
              </div>
            </div>
          </div>
          <div *ngIf="showDropAuxiliar">
            <div class="grupo-filter">
              <p><b>Funcionario</b></p>
              <ng-select
                [items]="funcionarios"
                bindLabel="nome"
                bindValue="cpf"
                placeholder="Selecione o funcionario"
                formControlName="cpfFuncionarioModalDrop"
                name="cpfFuncionarioModalDrop"
              >
              </ng-select>
              <div
            *ngIf="submittedModal && modalForm.cpfFuncionarioModalDrop.errors"
            class="alert"
          >
            <div *ngIf="modalForm.cpfFuncionarioModalDrop.errors.required">
              * Nenhum funcionario foi selecionado.
            </div>
            </div>

          </div>
          </div>
        </div>

        <div *ngIf="showOutros" class="conteudo-textarea">
          <label for="text-registro">Descreva quem são os outros</label>
          <ng-select
          [items]="TipoOutros"
          bindLabel="name"
          bindValue="id"
          placeholder="Selecione o tipo Outros"
          formControlName="dsOutros"
          name="dsOutros"
        >
        </ng-select>
          <div
            *ngIf="submittedModal && modalForm.dsOutros.errors"
            class="alert"
          >
            <div *ngIf="modalForm.dsOutros.errors.required">
              * Descricao de outros é obrigatório.
            </div>
          </div>
        </div>

        <div class="container-btns">
          <input
            type="button"
            (click)="FecharModal()"
            class="btn_outline"
            value="Fechar"
          />
          <input type="submit" class="btn_primary" value="Salvar" />
        </div>
      </div>
    </div>
  </form>
</app-modal>

<app-modal *ngIf="showModalExcluirAluno">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Envolvidos</h1>
    </div>

    <div class="descricao-modal-excluir">
      <span>{{this.descricaoExclusao}}</span>
    </div>

    <div class="container-btns">
      <input type="button" (click)="FecharModalExclusao()" class="btn_outline" value="Não"/>
      <input type="button" (click)="ExluirEnvolvido(this.envolvidoExclusao?.tipoEnvolvido, this.envolvidoExclusao?.idTemporario)" class="btn_primary" value="Sim" />
    </div>
  </div>
</app-modal>
