//Principais
import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
//Models
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { Turno } from "src/app/shared/models/escola/Turno";
import { HistoricoChamado } from "src/app/shared/models/chamados/historico"
import { ChamadoDeletar } from "src/app/shared/models/chamados/chamadoDeletar";
//Services
import { EscolaService } from "src/app/shared/services/escola.service";
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { EnvolvidoService } from "src/app/shared/services/envolvido.service";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { AlunosRecorrentes } from "src/app/shared/models/aluno/AlunosRecorrentes";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { Envolvido } from "src/app/shared/models/chamados/envolvido";
import { lastValueFrom } from "rxjs";
import { Categoria } from "src/app/shared/models/categoria/Categoria";
import { CategoriaService } from "src/app/shared/services/categoria.service";
import { NaoOcorrenciaService } from "src/app/shared/services/nao-ocorrencia.service";
import { ListarNaoOcorrencias } from "src/app/shared/models/nao-ocorrencia/ListarNaoOcorrencia";
import { formatDate } from "@angular/common";
import { ConcluirService } from "src/app/shared/services/concluir.service";
import { Perfil } from "src/app/shared/enums/Perfis/Perfis";

@Component({
  selector: 'app-chamados-list',
  templateUrl: './chamados-list.component.html',
  styleUrls: ['./chamados-list.component.scss']
})
export class ChamadosListComponent implements OnInit {

  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  constructor(
    private escolaService: EscolaService,
    private config: NgSelectConfig,
    private chamadoService: ChamadoService,
    private envolvidoService: EnvolvidoService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private psicologoService: PsicologoService,
    private router: Router,
    private categoriaService:CategoriaService,
    private naoOcorrenciasService: NaoOcorrenciaService,
    @Inject(LOCALE_ID) private locale: string,
    private concluirService:ConcluirService
  ){
    this.config.notFoundText = 'Não encontrado';
    this.userLogado = this.authService.getUser();

    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public gravidades = [ { id: 1, name: 'Amarelo' }, { id: 2, name: 'Vermelho' }];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public lstChamados: ListarChamados[] = [];
  public lstNaoOcorrencias:ListarNaoOcorrencias[] = [];
  public lstAllChamados: ListarChamados[] = [];
  public alunosRecorrentes: AlunosRecorrentes[] = [];
  public formListarChamado!: FormGroup;
  public formModalEncaminhamento!: FormGroup;
  public formCadastrarHistorico!: FormGroup;
  public submitted = false;
  public showPesquisa = false;
  public showMenu = true;
  public showHistorico = false;
  public showStatus = false;
  public showConteudoChamado = false;
  public showModalExcluirChamado = false;
  public parametroIdChamado: any;
  public parametroIdEnvolvido: any;
  public statusCriada:any;
  public statusFechada:any;
  public itemEnvolvido: Envolvido;
  public lstEnvolvidos: Envolvido[] = [];
  public chamadoHistorico: ListarChamados;
  public chamadoSeleccionado: ListarChamados;
  public naoOcorrenciaSelecionada: ListarNaoOcorrencias | undefined;
  public descricaoExclusao = '';
  public dsHistoricoChamado:string;
  public chamadoARemover = 0;
  public submittedModal = false;
  public showDetalhesNaoOcorrencia = false;
  public tituloModalEncaminhamento:string;
  public categorias:Categoria[] = [];
  public get CoordenadorPsicologo(){return this.userLogado?.DadosUsuario == undefined ? 0 :this.userLogado?.DadosUsuario.filter(s => s.CodigoPerfil == 1632).length}
  public get form() { return this.formListarChamado.controls; }
  //Modal concluir
  public showModalConcluirChamado:boolean;
  public descricaoConcluir:string;
  public chamadoAConcluir:number;
  public chamadoSelecionado:number;

  public ngOnInit(): void {
    this.formListarChamado = new FormGroup({
      codigoDiretoria: new FormControl(null),
      codigoEscola: new FormControl(null),
      codigoTurno: new FormControl(null),
      codigoGravidade: new FormControl(null),
      codigoSubCategoria:new FormControl(null),
      codigoChamado:new FormControl(null)
    });

    this.formCadastrarHistorico = new FormGroup({
      dsHistoricoChamado: new FormControl(null),
    });

    this.getCategorias();
    if(this.userLogado?.flPsicologo){
      this.getDiretoriasPerfilPsicologo();
    }else{
      if(this.atribuicoesLogin.NumeroComportamento == 1){
        this.getDiretorias();
      }
      if(this.atribuicoesLogin.NumeroComportamento == 2){
        this.getDiretoriasPerfilDiretoria();
      }
      if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
        this.getDiretoriasPerfilEscola();
      }
    }
  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];
    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();

      this.getTurnos();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria != undefined)
      this.formListarChamado.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if(this.userLogado !== null)
        this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){
    if(this.userLogado?.codigoEscola != undefined)
      this.formListarChamado.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
    if(this.userLogado?.codigoDiretoria != undefined && this.userLogado?.codigoEscola != undefined)
    {
      this.getTurnos();
      this.OnPesquisar();
    }
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async getDiretorias() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.escolaService.getDiretoriaPorTipoDiretoria())
                .catch((err) => {
                  return this.ConvivaErrors.handleResultError(err);
                });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.diretorias = result?.data;
    if(this.userLogado?.codigoDiretoria != undefined){
      this.ativaDiretoria();
      this.getEscolas(true);
    }
    this.spinnerService.hide();
  }

  public async getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formListarChamado.get('codigoDiretoria')?.value;
    if(this.userLogado?.flPsicologo){
      this.zerarEscola();
      this.spinnerService.show();
      const result = await lastValueFrom(await this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.escolas = result.data.escolas;
      this.diretorias = result.data.diretorias;
      this.spinnerService.hide();
      this.ativaEscola();
    }else
    if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2){
      this.zerarEscola();
      if (codigoDE != null) {
        this.spinnerService.show();
        const result = await lastValueFrom(await this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!result.isSucess){
          this.toastr.error(result.message, result.title);
          this.spinnerService.hide();
          return;
        }
        this.escolas = result.data;
        this.spinnerService.hide();
        this.ativaEscola();
      }
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formListarChamado.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public async getTurnos() {
    const codigoEE = this.formListarChamado.get('codigoEscola');
    this.zerarTurnos();
    if (codigoEE?.value != null) {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.escolaService.getTurnosPorCodigoCie(codigoEE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.turnos = result.data;
      if (this.turnos == null)
        this.toastr.warning('Não foi encontrados turnos para essa escola !');
      this.spinnerService.hide();
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = codigoEE?.value == null ? 0 : codigoEE?.value;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formListarChamado.get('codigoTurno')?.patchValue(null);
  }

  public async getCategorias() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.categoriaService.getCategoriasWithSub())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.categorias = result.data;
    this.spinnerService.hide();
  }

  public async OnPesquisar() {
    this.Voltar();
    this.submitted = true;
    this.lstChamados = [];
    this.lstNaoOcorrencias = [];
    this.showDetalhesNaoOcorrencia = false;
    if (this.formListarChamado.invalid  && this.userLogado?.codigoPerfil != Perfil.GestorConviva) {
      this.showPesquisa = false;
      return;
    }


    this.showPesquisa = true;
    this.spinnerService.show();
    const parametros = this.SetParametrosChamados();
    console.log(parametros)
    if(!parametros.codigoChamado && !parametros.codigoDiretoria && !parametros.codigoEscola ){
      this.toastr.warning("Não é possivel fazer uma pesquisa sem nenhum filtro.");
      this.spinnerService.hide();
      return;
    }
    if(parametros.codigoSubCategoria != 42){
      const result = await lastValueFrom(await this.chamadoService.getListaChamados(parametros))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.lstChamados = result.data;
      if(this.CoordenadorPsicologo){
        this.lstChamados = this.lstChamados.filter(s =>s.aluno?.statusEnvolvido === 1);
      }
      this.lstAllChamados = result.data;
      if(parametros.codigoDiretoria && parametros.codigoEscola){
        const resultAlunos = await lastValueFrom(await this.chamadoService.getAlunosRecorrentes(parametros))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!resultAlunos.isSucess){
        this.toastr.error(resultAlunos.message, resultAlunos.title);
        this.spinnerService.hide();
        return;
      }
      this.alunosRecorrentes = resultAlunos.data;
      }

      this.setParametrosNotificacoes();

    }else{
      const result = await lastValueFrom(await this.naoOcorrenciasService.getNaoOcorrencias(parametros))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.lstNaoOcorrencias = result.data;
    }
    this.spinnerService.hide();


    if(this.alunosRecorrentes.length == 0)
      this.toastr.warning("Não existem chamados para essa pesquisa");
  }

  public SetParametrosChamados(): ListarChamados {
    const chamado = new ListarChamados();
    chamado.codigoDiretoria = this.form?.codigoDiretoria.value;
    chamado.codigoEscola = this.form?.codigoEscola.value;
    chamado.codigoTurno = this.form?.codigoTurno.value;
    chamado.codigoGravidade = this.form?.codigoGravidade.value;
    chamado.ordenacaoCrescente = true;
    chamado.codigoSubCategoria = this.form?.codigoSubCategoria.value;
    chamado.codigoChamado = this.form?.codigoChamado.value;
    return chamado;
  }

  public setParametrosNotificacoes()
  {
    if(this.parametroIdChamado != null && this.parametroIdEnvolvido != null)
    {
      const chamadoParametro  = this.lstChamados.filter(x => x.codigoChamado == this.parametroIdChamado && x.codigoEnvolvido == this.parametroIdEnvolvido).find(y => y.codigoChamado == this.parametroIdChamado);
      if(chamadoParametro != undefined)
        this.GetEnvolvidos(chamadoParametro);
    }
  }

  public async GetEnvolvidos(chamado: ListarChamados,el?:HTMLElement) {

    this.spinnerService.show();
    this.statusCriada = null;
    this.statusFechada = null;
    const menu = document.getElementsByClassName("time-line")
    if(menu.length > 0){
      const teste = menu.item(0) as HTMLElement;
      teste.className = "sidebar-chamado";
    }
    const result = await lastValueFrom(await this.envolvidoService.GetEnvolvidosPorChamado(chamado.codigoChamado))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.lstEnvolvidos = result.data;
    var envolvidoOutro = this.lstEnvolvidos.find(s=>s.tipoPessoa == 3 || s.tipoPessoa == 2);
    if(envolvidoOutro)
      chamado.codigoEnvolvido = Number(envolvidoOutro.codigoEnvolvido);

    this.chamadoSeleccionado = chamado;
    this.showHistorico = true;
    this.showMenu = false;
    this.showStatus = false;
    this.showConteudoChamado = true;
    this.spinnerService.hide();
    this.chamadoSelecionado = chamado.codigoChamado;
    if(el)
      this.scrollTo(el);
  }

  public Voltar() {
    this.showHistorico = false;
    this.showConteudoChamado = false;
    this.showStatus = false;
    this.showMenu = true;
    const menu = document.getElementsByClassName("sidebar-chamado").item(0) as HTMLElement;
    if(menu != null)
      menu.className = "time-line";
  }

  public OnVisualizarChamado(id:number) {
    this.router.navigate(['/detalhe/', id]);
  }

  public FiltraChamadosAtuais(event:any){
    if(event != undefined){
      this.lstChamados =  this.lstAllChamados.filter(item => item.codigoGravidade === event.id);
    }else{
      this.lstChamados = this.lstAllChamados;
    }
  }

  public OnExcluirChamado(codigoChamdo: number){
    this.showModalExcluirChamado = true;
    this.descricaoExclusao = `Deseja remover o chamado ?`;
    this.chamadoARemover = codigoChamdo;
  }

  public async ExluirChamado(codigoChamado: number) {
    this.spinnerService.show();
    var chamadoDeletar: ChamadoDeletar = {
      cdChamado: codigoChamado,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    }
    const result = await lastValueFrom(await this.chamadoService.setDeletarChamado(chamadoDeletar))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.toastr.success('Chamado removido com sucesso!');
    this.OnPesquisar();
    this.FecharModalExclusao();
  }




  public FecharModalExclusao() {
    this.showModalExcluirChamado = false;
    this.descricaoExclusao = "";
    this.chamadoARemover = 0;
  }

  public StatusChamado() {
    this.showStatus = true;
    this.showConteudoChamado = false;
  }

  public OnStatusChamado(ic:number, ie:any)
  {
    this.router.navigate(['/listar-chamados/', ic, ie]);
  }

  public getInfosNaoOcorrencia(item:ListarNaoOcorrencias){

    this.spinnerService.show();
    this.statusCriada = null;
    this.statusFechada = null;
    const menu = document.getElementsByClassName("time-line")
    if(menu.length > 0){
      const teste = menu.item(0) as HTMLElement;
      teste.className = "sidebar-chamado";
    }


    this.naoOcorrenciaSelecionada = this.lstNaoOcorrencias.find(s=>s.codigoNaoOcorrencia == item.codigoNaoOcorrencia);
    this.showMenu = false;
    this.showStatus = false;
    this.showHistorico = true;
    this.showDetalhesNaoOcorrencia = true;
    this.spinnerService.hide();

  }
  public formatDate(data?:Date){
    if(data == undefined) return;
   return formatDate(data,'dd/MM/yyyy HH:mm',this.locale);
  }

  public AbrirModalConcluirChamado(codigoChamdo: number){
    this.showModalConcluirChamado = true;
    this.descricaoConcluir = `Deseja concluir o chamado deste Envolvido ?`;
    this.chamadoAConcluir = codigoChamdo;
  }
  public FecharModalConcluir() {
    this.showModalConcluirChamado = false;
  }
  public async ConcluirChamado() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.chamadoSeleccionado.codigoChamado,
      codigoEnvolvido: this.chamadoSeleccionado.codigoEnvolvido,
      descricaoHistorico: 'Chamado deste envolvido concluído.',
      statusChamado: 6,
      nomeArquivo: "",
      caminhoArquivo: "",
      conteudo: "",
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };
    this.FecharModalConcluir();
    this.spinnerService.show();
    var result = await lastValueFrom(await this.concluirService.setCadastrarConcluirChamadoEnvolvidos(objetoHistorico))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success('Chamado deste envolvido concluído com sucesso.');
    this.OnPesquisar();
    this.spinnerService.hide();
  }
  public podeConcluirChamado(chamado:ListarChamados){
    return this.userLogado?.funcionalidades?.includes(2) &&
    this.chamadoSeleccionado.status != 4 &&
    (this.chamadoSeleccionado.semEnvolvido || this.chamadoSeleccionado.codigoCategoria == 6 || this.chamadoSeleccionado.status == 3) ;
  }

  public EventTresPontos(event: string) {

    if(event === "Pesquisar"){
      this.OnPesquisar();
    }
  }

  scrollTo(el: HTMLElement){
      el.scrollIntoView();
  }

  private async getDiretoriasPerfilPsicologo(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo));
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.diretorias = response.data;
    if(this.userLogado?.codigoDiretoria){
      this.form.codigoDiretoria.setValue(this.userLogado.codigoDiretoria);
      await this.getEscolas();
    }
    this.form.codigoDiretoria.enable();
    this.spinnerService.hide();


  }


  limpaCamposDiretoriaEscola(){
    this.form.codigoDiretoria.setValue(null);
    this.form.codigoEscola.setValue(null);
  }

}
