import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, map } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";

@Injectable({
  providedIn: 'root'
})

export class DetalheService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient,
    private router:Router)
    { }

    public getChamadoDetalhePorId(idChamado: number):Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Detalhe.GetChamadoDetalhePorId}?idChamado=${idChamado}`)
                        .pipe(map((response: DefaultResponse) => response));
      }
      public getAtividadeDetalhePorId(idAtividade: number):Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Detalhe.GetAtividadeDetalhePorId}?idAtividade=${idAtividade}`)
                        .pipe(map((response: DefaultResponse) => response));
      }
      public getAtividadeDetalhePsicologoPorId(idAtividade: number):Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Detalhe.GetAtividadeDetalhePsicologoPorId}?idAtividade=${idAtividade}`)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getAtividadeDetalhePOCPorId(idAtividade: number):Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Detalhe.GetAtividadeDetalhePOCPorId}?idAtividade=${idAtividade}`)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getAtividadeDetalhePsicologoFiscalPorId(idAtividade: number):Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Detalhe.GetAtividadeDetalhePsicologoFiscalPorId}?idAtividade=${idAtividade}`)
                        .pipe(map((response: DefaultResponse) => response));
      }

}