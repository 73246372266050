import { Component, Input } from "@angular/core";

export interface PageBreadcrumb {
  route: string;
  name: string;
}

@Component({
  selector: 'breadcrumb',
  template: `
    <section class="section-breadcrumb">
      <div class="container">
        @for (currentPage of paginas; track currentPage;let index = $index) {
          <a
            href="javascript:void(0)"
            routerLink="{{currentPage.route}}"
            title="{{currentPage.name}}"
          >
            {{currentPage.name}}
          </a>
          <span *ngIf="index + 1 < paginas.length"> / </span>
        }
      </div>
    </section>
  `,
  styleUrls:['./breadcrumb.component.scss']
})

export class BreadcrumbComponent {
  @Input() paginas: PageBreadcrumb[] = [];
}
