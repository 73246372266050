import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { authConfig } from "src/app/shared/auth/models/auth.config";
import { AuthService } from "src/app/shared/auth/services/auth.service";

@Component({
  templateUrl: './logout.component.html',
})

export class LogoutComponent implements OnInit {
  private error: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private oauthService: OAuthService,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,) {
    this.error = this.route.snapshot.queryParams.error;
    this.configure();
  }

  private configure() {
    this.oauthService.configure(authConfig);
  }

  public async ngOnInit() {
    this.spinnerService.show();

    if(this.error != null && this.error != undefined && this.error != '') {
      this.toastr.error(this.error);
      this.spinnerService.hide();
      return;
    }

    this.authService.cleanStorageGovBr();
    this.authService.cleanStorageConviva();

    setTimeout(() => {
      this.router.navigate(['/login']);
      this.spinnerService.hide();
    }, 5000);
  }
}
