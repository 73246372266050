//Principais
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgSelectConfig } from "@ng-select/ng-select";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
//Models
import { PerfilAluno } from "src/app/shared/models/aluno/PerfilAluno";
import { ChamadosAluno } from "src/app/shared/models/chamados/ChamadosAluno";
import { HistoricoChamado } from "src/app/shared/models/chamados/historico";
import { ChamadoDeletar } from "src/app/shared/models/chamados/chamadoDeletar";
//Services
import { AlunoService } from "src/app/shared/services/alunos.service"
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup } from '@angular/forms';
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { EncaminhamentoService } from "src/app/shared/services/encaminhamento.service";
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";

@Component({
  selector: 'app-perfil-aluno',
  templateUrl: './perfil-aluno.component.html',
  styleUrls: ['./perfil-aluno.component.scss']
})

export class PerfilAlunoComponent implements OnInit {

  parametro: string;
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public raAluno:any; // id ingresado por url
  public radig:any;
  public rauf:any;
  public perfilAluno: PerfilAluno;
  public mostrarResultado = false;
  public formPerfilAluno!: FormGroup;
  public formModal!: FormGroup;
  public formModalChamado!: FormGroup;
  public listaChamados: ChamadosAluno[];
  public showChamados = false;
  public CdStChamado = 0;
  public qtyChamados = 0;
  public descricaoExclusao = '';
  public chamadoARemover = 0;
  public showModalExcluirChamado = false;
  public showModalAdicionarChamado = false;
  public codigoChamadoSelected: number;
  public codigoEnvolvidoSelected: number;
  public get isDiretor(){return this.userLogado?.NumeroComportamento == "3" && this.userLogado.codigoPerfil == 272}

  constructor(
    private config: NgSelectConfig,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private alunoService: AlunoService,
    private chamadoService: ChamadoService,
    private encaminhamento: EncaminhamentoService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private router: Router,
    private authService: AuthService,
    ) {
      this.config.notFoundText = "Não encontrado";
      this.route.paramMap.subscribe(params => {
        this.parametro = params.get('id') ?? '';
      });
      this.config.notFoundText = 'Não encontrado';
      this.userLogado = this.authService.getUser();

      if(this.userLogado != null && !this.userLogado.flPsicologo){
        this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
      }
  }

  @ViewChild('modalContent') modalContent!: TemplateRef<any>;
  public showModal = false;
  public submittedModal = false;
  public tituloModal = '';

   public ngOnInit() {
       this.formPerfilAluno = new FormGroup({
    });

    this.spinnerService.show();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.raAluno = params.get('id');
      this.spinnerService.hide();
    });

    this.getPerfilAluno(this.raAluno);
  }

  public async getPerfilAluno(numeroRA:string)
  {
    this.spinnerService.show();
    const cdEscola = this.isDiretor ? this.userLogado!.codigoEscola! : null;
    var response = await lastValueFrom(this.alunoService.getDadosAlunoGerais(numeroRA,cdEscola))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.perfilAluno = response.data;
    this.spinnerService.hide();
    this.mostrarResultado = true;
  }

  public AbrirModal(codigoChamado: number, codigoEnvolvido: number) {
    this.codigoChamadoSelected = codigoChamado;
    this.codigoEnvolvidoSelected = codigoEnvolvido;
    this.submittedModal = false;
    this.formModal = new FormGroup({
    });
    this.showModal = true;
    this.tituloModal = 'Encaminhamentos';
  }

  public async EncaminhamentoPsicologo() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado',
      statusChamado: 1,//Encaminhado
      nomeArquivo: "",
      caminhoArquivo: "",
      conteudo: "",
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };
    this.spinnerService.show();
    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamento(objetoHistorico))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.toastr.success('Chamado encaminhado com sucesso!');
    this.ngOnInit();
    this.Voltar();
    this.FecharModal();
  }

  public async EncaminhamentoPolicia() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado a Rede protetiva.',
      statusChamado: 2,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };
    this.spinnerService.show();
    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamentoPolicia(objetoHistorico))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }else{
      this.toastr.success('Aluno Encaminhado a Rede protetiva com Sucesso.');
    }
    this.spinnerService.hide();
    this.ngOnInit();
    this.Voltar();
    this.FecharModal();
  }

  public async EncaminhamentoSaude() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado a Saúde.',
      statusChamado: 3,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };
    this.spinnerService.show();
    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamentoSaude(objetoHistorico))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.ngOnInit();
    this.Voltar();
    this.FecharModal();
  }

  public FecharModal() {
    this.showModal = false;
  }

  public async ChamadosAberto(perfilChamado: PerfilAluno, codigoStatusChamado: number)
  {

    this.spinnerService.show();
    this.CdStChamado = codigoStatusChamado;
    //Obtem chamados do aluno com status 0 = Pendente

    const cdEscola = this.isDiretor ? this.userLogado!.codigoEscola! : null;

    var response = await lastValueFrom(this.chamadoService.getChamadosPorAluno(perfilChamado.aluno.codigoAluno, codigoStatusChamado,cdEscola))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.listaChamados = response.data;
    this.qtyChamados = this.listaChamados.length;
    this.showChamados = true;
    this.spinnerService.hide();
  }

  IncluirChamado(ra:string, te:number) {
    this.router.navigate(['/cadastrar-chamados/', ra, te]);
  }

  public async ExluirChamado(codigoChamado: number) {
    this.spinnerService.show();
    var chamadoDeletar: ChamadoDeletar = {
      cdChamado: codigoChamado,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    }
    var response = await lastValueFrom(this.chamadoService.setDeletarChamado(chamadoDeletar))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }else{
      this.toastr.success('Chamado removido com sucesso!');
    }
    this.spinnerService.hide();
    this.load();
    this.FecharModalExclusao();
  }

  load() {
    (sessionStorage.refresh == 'true' || !sessionStorage.refresh) && location.reload();
    sessionStorage.refresh = false;
  }

  public OnExcluirChamado(codigoChamdo: number){
    this.showModalExcluirChamado = true;
    this.descricaoExclusao = `Deseja remover o chamado ?`;
    this.chamadoARemover = codigoChamdo;
  }

  public OnIncluirChamado()
  {
    this.showModalAdicionarChamado = true;
  }

  public FecharModalExclusao() {
    this.showModalExcluirChamado = false;
    this.descricaoExclusao = "";
    this.chamadoARemover = 0;
  }

  public FecharModalInclusao() {
    this.showModalAdicionarChamado = false;
  }

  public Voltar() {
    this.showChamados = false;
  }

  OnVisualizarChamado(id:number) {
    this.router.navigate(['/detalhe/', id]);
  }

}
