import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { Chamado } from "../models/chamados/chamado";
import { ChamadoDeletar } from "../models/chamados/chamadoDeletar";
import { HistoricoChamado } from "../models/chamados/historico";
import { ListarChamados } from "../models/chamados/listar-chamados";
import { ListarReincidentes } from "../models/chamados/listar-reincidentes";
import { DefaultResponse } from "../models/DefaultResponse";

@Injectable({
  providedIn: 'root'
})

export class ChamadoService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient)
  { }

  public setCadastrarChamado(parametros: Chamado):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.setCadastrarChamado}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }


  public getChamadosEnvolvidos(parametros: ListarChamados):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.GetChamadosEnvolvidos}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getListaChamados(parametros: ListarChamados):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.GetListaChamados}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getHistoricoEnvolvido(parametros: ListarChamados):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.GetHistoricoEnvolvido}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getChamadosPorAluno(codigoAluno: number, statusChamado: number,codigoEscola:number | null):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Chamados.GetChamadosPorAluno}?codigoAluno=${codigoAluno}&statusChamado=${statusChamado}&codigoEscola=${codigoEscola}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public setDeletarChamado(parametros: ChamadoDeletar):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.setDeletarChamado}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public setCadastrarHistorico(parametros: HistoricoChamado):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.setCadastrarHistorico}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getAlunosRecorrentes(parametros: ListarChamados):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.GetAlunosRecorrentes}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getChamadoPorId(idChamado: number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Chamados.GetChamadoPorId}?idChamado=${idChamado}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public setEditarChamado(parametros: Chamado):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.setEditarChamado}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getDashboard(parametros: ListarChamados):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Dashboard.getDashboard}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getDashboardReincidencia(parametros: ListarReincidentes):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Dashboard.getDashboardReincidencia}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }
  public getChamadosAgendados(codigoEscola:number,codigoPsicologo:number = 0,poc:boolean = false):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Chamados.GetChamadosAgendadosPorPsicologo}?codigoEscola=${codigoEscola}&codigoPsicologo=${codigoPsicologo}&POC=${poc}`)
                    .pipe(map((response: DefaultResponse) => response));
  }
  public getChamadosPOC(codigoEscola:number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Chamados.GetChamadosPOC}?codigoEscola=${codigoEscola}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getChamadosCriadosPorUsuario(cpfUsuario: string, codigoEscola: number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Chamados.GetChamadosCriadosPorUsuario}?cpfUsuario=${cpfUsuario}&codigoEscola=${codigoEscola}`)
                    .pipe(map((response: DefaultResponse) => response));
  }
}
