import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'star-rating-conviva',
  templateUrl:'./star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingConvivaComponent implements OnChanges{
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['rating'])
      if(this.ratingLocal != this.rating)
        this.setRating(this.rating);
  }

  @Input() rating: number = 0;       // Avaliação inicial
  ratingLocal:number = this.rating;
  @Input() starCount: number = 5;    // Número de estrelas (padrão 5)
  @Input() checkboxNumber: number | undefined = 0;
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Output() ratingChange = new EventEmitter<number>(); // Emite mudanças na avaliação


  stars: boolean[] = [];

  toastr = inject(ToastrService)

  ngOnInit() {
    this.stars = Array(this.starCount).fill(false);
    this.setRating(this.rating);
  }

  setRating(rating: number) {

    if(Number.isNaN(rating) || !rating)
      return;

    this.rating = rating;
    this.ratingChange.emit(this.rating);

    // Atualiza a representação das estrelas com base na avaliação atual
    this.stars = this.stars.map((_, index) => index < rating);



  }

  onStarClick(index: number) {
    if(this.isDisabled || this.isReadOnly)
      return;

    this.validate(index+1);

    this.setRating(this.rating);
  }

  validate(rating:number){

    if (this.checkboxNumber !== 0) {

      if(rating  != 3 && this.checkboxNumber == 2  ){
        this.toastr.error("Quantidade de estrelas incompatível com a resposta selecionada.");
        this.rating = 3;
        return;
      }else  if(rating  < 4 && this.checkboxNumber == 1  ){
        this.toastr.error("Quantidade de estrelas incompatível com a resposta selecionada.");
        this.rating = 4;
        return;
      }else if(rating  > 2 && this.checkboxNumber == 3  ){
        this.toastr.error("Quantidade de estrelas incompatível com a resposta selecionada.");
        this.rating = 2;
        return;
      }

      this.rating = rating;
      return;
    }
  }

  // onStarHover(index: number) {
  //   this.stars = this.stars.map((_, i) => i <= index);
  // }

  // onStarLeave() {
  //   this.setRating(this.rating);
  // }

}
