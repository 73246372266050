//Principais
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { DataTableDirective } from "angular-datatables";
import { NgSelectModule } from '@ng-select/ng-select';
//Métodos
import { Polo } from "src/app/shared/models/polo/Polo";
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { TurmaAluno } from "src/app/shared/models/aluno/TurmaAluno";
import { VisitaPsicologo } from "src/app/shared/models/psicologo/VisitaPsicologo";
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { Atividade } from 'src/app/shared/models/psicologo/AtividadePsicologo';
//Serviços
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Turma } from 'src/app/shared/models/turma/Turma';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';

import { param } from 'jquery';
import { DashboardAtividadePsicologo } from 'src/app/shared/models/chamados/listar-atividades-psicologos';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { DashboardAtividadePsicologoFiscal } from 'src/app/shared/models/chamados/listar-atividade-psicologos-fiscal';

@Component({
  selector: 'app-dashboard-visitas',
  templateUrl: './dashboard-visitas.component.html',
  styleUrls: ['./dashboard-visitas.component.scss']
})
export class DashboardVisitasComponent implements OnInit, OnDestroy {
    //Variaveis de Login
    public userLogado: UserLoginSed | null;
    public atribuicoesLogin: AtribuicaoLoginSed;
  
    // Variaveis do Grid DataTable
    public dtOptions: any = {};
    public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
    @ViewChild(DataTableDirective, {static: false})
    public dtElement: DataTableDirective;
    public isDtInitialized:boolean = false;
    data: any[] = []; // Sua fonte de dados
    itemToDelete: any;
  
    constructor(private spinnerService: NgxSpinnerService,
      private authService: AuthService,
                private psicologoService:PsicologoService,
                private ConvivaErrors: ConvivaErrorHandlingService,
                private toastr: ToastrService,
                private escolaService: EscolaService,
                @Inject(LOCALE_ID) private locale: string,
                private router: Router
  
     ) {
      // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
    }
    //Forms
    public formDashboard!: FormGroup;
    public get form() { return this.formDashboard.controls; }
    public submitted: boolean;
    public showPesquisa: boolean;
  
    //Modal
    public showModalConfirmacao:boolean;
  
  
    //Listagens
    public polos: Polo[] = [];
    public diretorias: Diretoria[] = [];
    public escolas: Escola[] = [];
    public lstAtividade : DashboardAtividadePsicologo[] = [];
    public atividadeParaExcluir: number | null = null;
  
  
    public async ngOnInit(): Promise<void> {
  
      this.dtOptions = {
        deferRender: true,
        destroy:false,
        processing: true,
        paging: true,
        dom: 'Bfrtip',
        order:[],
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
        },
        lengthMenu: [
          [ 10, 25, 50, -1 ],
          [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
        ],
        buttons: [
          'pageLength',
          {
            extend:'print',
            exportOptions:{
              columns:[0,1,2,3,4,5]
            }
          },
          {
            extend:'excel',
            exportOptions:{
              columns:[0,1,2,3,4,5]
            }
          },
          {
            extend:'pdf',
            exportOptions:{
              columns:[0,1,2,3,4,5]
            }
          }
        ],
      };
      //Formulario de Pesquisa
      this.formDashboard = new FormGroup({
        codigoPolo: new FormControl(null, [Validators.required]),
        codigoDiretoria: new FormControl(null, [Validators.required]),
        codigoEscola: new FormControl(null),
      });
  
      await this.getPolos();
        if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0){
          await this.getDiretoriasEscolasPorPolo();
          await this.PesquisarVisitas();
        }else
            this.form.codigoDiretoria.disable();
    }
  
    public async getPolos() {
      
      this.spinnerService.show();
  
      var response = await lastValueFrom(this.psicologoService.getPolos())
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });
                   
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      
      this.polos = response.data;
      this.spinnerService.hide();
    }
  
    public OnChangePolo(){
          
      const codigoPolo = this.formDashboard.get('codigoPolo')?.value;
      if(this.userLogado !== null)
      {
        this.userLogado.codigoPolo = codigoPolo == null ? 0 : codigoPolo;
        this.authService.setUser(this.userLogado);
      }
  
      if(codigoPolo != null ){
        this.getDiretoriasPorPolo();
      }else{
        this.zerarDiretoria();
      }
      this.zerarEscola();
  
  
  
    }
  
    public async getDiretoriasPorPolo(diretoriaLogada:boolean = false) {
      if(this.userLogado?.codigoPolo){
        this.zerarDiretoria();
        this.spinnerService.show();
        var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!response.isSucess){
          // this.showPesquisa = false;
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }
        this.diretorias = response.data;
        this.form.codigoDiretoria.enable();
        this.ativaDiretoria();
        this.getEscolasPorDiretoria();
        this.spinnerService.hide();
      }
  
      if(this.userLogado !== null)
      {
        this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria ;
        this.authService.setUser(this.userLogado);
      }
    }
  
    public zerarDiretoria() {
      this.diretorias = [];
      this.formDashboard.get('codigoDiretoria')?.patchValue(null);
    }
  
    public async trocaDiretoria(){
  
      const codigoDiretoria = this.formDashboard.get('codigoDiretoria')?.value;
      if(this.userLogado !== null && codigoDiretoria != null)
      {
        this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
        this.authService.setUser(this.userLogado);
      }
      if(codigoDiretoria != null)
        await this.getEscolasPorDiretoria();
      else
        this.escolas = [];
        this.form.codigoEscola.setValue(null);
    }
      onChangeEscola(){
        const codigoEscola = this.formDashboard.get('codigoEscola')?.value;
        if(this.userLogado !== null && codigoEscola != null)
        {
          this.userLogado.codigoEscola = codigoEscola == null ? 0 : codigoEscola;
          this.authService.setUser(this.userLogado);
        }
      }
  
      public async getDiretoriasEscolasPorPolo() {
          
        this.spinnerService.show();
  
        if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0) {
  
          var response = await lastValueFrom(this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
                              .catch((err) => {
                                return this.ConvivaErrors.handleResultError(err);
                              });
         
          if(!response.isSucess){
            this.toastr.error(response.message);
            this.spinnerService.hide();
            return;
          }
          this.diretorias = response.data.diretorias;
          await this.getEscolasPorDiretoria();
          this.form.codigoDiretoria.enable();
          this.ativaDiretoria();
        }
  
        this.spinnerService.hide();
      }
      
      public async getEscolasPorDiretoria(){
        
        if(this.userLogado !== null)
        {
          //this.userLogado.codigoEscola == this.userLogado.codigoEscola;
          this.authService.setUser(this.userLogado);
        }
        if(this.userLogado?.codigoDiretoria && this.userLogado.codigoDiretoria > 0){
          this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
        }
        if(this.form.codigoDiretoria.value){
          
          this.spinnerService.show();
          this.zerarEscola();
          var response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(this.form.codigoDiretoria.value))
          .catch((err) => {
            return this.ConvivaErrors.handleResultError(err);
          });
          

            if(!response.isSucess){
              this.toastr.error(response.message);
              this.spinnerService.hide();
              return;
            }
            this.escolas = response.data;
            this.ativaEscola();
            this.spinnerService.hide();
        }
    
      }
  
      public zerarEscola() {
        this.escolas = [];
        this.formDashboard.get('codigoEscola')?.patchValue(null);
      }
  
      public ativaEscola(){
        if(this.escolas != undefined){
          var existEscola = this.escolas.find(s=>s.codigoEscola == this.userLogado?.codigoEscola);
            if(existEscola != undefined){
            this.formDashboard.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
          }
    
        }
      }
  
      public ativaDiretoria() {
        const diretoria = this.diretorias.filter(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria);
  
        if(this.userLogado?.codigoDiretoria && this.userLogado?.codigoDiretoria > 0 && diretoria.length > 0){
          this.formDashboard.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
        }
  
        const polo = this.polos.filter(s => s.codigoPolo == this.userLogado?.codigoPolo);
        if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0  && polo.length > 0){
          this.formDashboard.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
        }
      }
  
      // public async PesquisarVisitas(){
      //   this.spinnerService.show();
      //   this.submitted = true;
      //   if (this.formDashboard.invalid) {
      //     this.spinnerService.hide();
      //     return;
      //   }
  
      //   const parametros = this.SetParametrosAtividade();
      //   var response = await lastValueFrom(this.psicologoService.getDashboardAtividadePsicologos(parametros))
      //                       .catch((err) => {
      //                         return this.ConvivaErrors.handleResultError(err);
      //                       });
        
      //   if(!response.isSucess){
      //     this.toastr.error(response.message);
      //     this.spinnerService.hide();
      //     return;
      //   }
  
      //   this.lstAtividade = response.data;
        
      //   this.dtTrigger.next(this.dtOptions);
  
      //   this.spinnerService.hide();
  
      //   if(this.lstAtividade.length == 0){
      //     this.toastr.warning("Não existem resultados para essa pesquisa !");
          
      //     if(this.dtElement.dtInstance != null) {
      //       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //         dtInstance.destroy();
      //         this.dtTrigger.next(this.dtOptions);
      //       });
      //     }
      //   }
      //   else{
      //     if (this.isDtInitialized) {
      //       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //         dtInstance.destroy();
      //         this.dtTrigger.next(this.dtOptions);
      //       });
      //     } else {
            
      //       this.isDtInitialized = true;
      //       if(this.dtElement != undefined){
      //       if(this.dtElement.dtInstance != null) {
      //         this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //           dtInstance.destroy();
      //         });
      //       }
      //     }
      //       this.dtTrigger.next(this.dtOptions);
      //     }
      //   }
      // }
  
      public SetParametrosAtividade(): Atividade {
        const atividade = new Atividade();
        atividade.codigoPolo = this.form?.codigoPolo.value;
        atividade.codigoDiretoria = this.form?.codigoDiretoria.value;
        atividade.codigoEscola = this.form?.codigoEscola.value;
        // atividade.dataInicio = this.form?.dataInicio.value;
        // atividade.dataFim = this.form?.dataFim.value;
        return atividade;
      }
  
      public getDate(data?:Date){
        if(data == undefined) return;
       return formatDate(data,'dd/MM/yyyy',this.locale);
      }
      public ngOnDestroy(): void {
        // this.dtTrigger.unsubscribe();
      }
      public AbreFechaModalConfirmacao(codigoAtividade?: number){
        if (codigoAtividade !== undefined) {
          this.atividadeParaExcluir = codigoAtividade;
        }
        this.showModalConfirmacao = !this.showModalConfirmacao;
      }
      public async ExcluirAtividadePsicologo(){
        if (this.atividadeParaExcluir !== null) {
          this.spinnerService.show();
          
          var response = await lastValueFrom(this.psicologoService.setExcluirAtividadePsicologo(this.atividadeParaExcluir))
                            .catch((err) => {
                              return this.ConvivaErrors.handleResultError(err);
                            });
          if(!response.isSucess){
             this.toastr.error(response.message);
             this.spinnerService.hide();
             return;
            }
  
          this.atividadeParaExcluir = null;
          this.toastr.success("Atividade excluida com sucesso!");
          this.atividadeParaExcluir = null;
          this.PesquisarVisitas();
          this.AbreFechaModalConfirmacao();
      }
    }
    public async PesquisarVisitas() {
  
      this.spinnerService.show();
      this.submitted = true;
      if(this.formDashboard.invalid){
        this.showPesquisa = false;
        this.spinnerService.hide();
        return;
      }
  
      const parametros = this.SetParametrosAtividade();
      var response = await lastValueFrom(this.psicologoService.getDashboardAtividadePsicologosFiscal(parametros)).catch((err) => {
        this.spinnerService.hide();
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
      }
      this.spinnerService.hide();

      this.lstAtividade =  response.data;
  
      if(this.lstAtividade.length == 0){
        this.showPesquisa = false;
        this.toastr.warning("Não existem atividades para essa pesquisa");
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
  
  
          });
        }
      }
      else{
  
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
  
          });
        } else {
          this.isDtInitialized = true;
  
          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }
  
  
      this.showPesquisa = true;
  
    }
  
    public AbrirAprovacao(item: any) {
      this.router.navigate(['/aprovacao-atividade-psicologo-fiscal/', item.codigoAtividadePsicologo]);
    }
    public AbrirVisualizar(item: any) {
      this.router.navigate(['/visualizar-atividade-psicologo-fiscal/', item.codigoAtividadePsicologo]);
    }

    public getAprovacaoFiscalStatus(status: number | null): string {
      if (status === 1) {
        return 'Aprovado';
      } else if (status === 2) {
        return 'Aprovado com melhoria';
      } else if (status === 3) {
        return 'Não Aprovado';
      } else if (status === null){
        return 'Não Analisado';
      }
      return '';
    }
  }