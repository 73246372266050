export class BuscarEnvolvidos{

  codigoDiretoria?:number ;
  codigoEscola?:number;
  codigoTurma?:number ;
  codigoTurno?:number;
  nome?:string;
  ra?:string ;

}
