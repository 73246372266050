//Principais
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

//Models
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { Turno } from "src/app/shared/models/escola/Turno";
import { Turma } from "src/app/shared/models/turma/Turma";
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { HistoricoChamado } from "src/app/shared/models/chamados/historico";
import { ChamadoDeletar } from "src/app/shared/models/chamados/chamadoDeletar";

//Services
import { EscolaService } from "src/app/shared/services/escola.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { NotificacoesService } from "src/app/shared/services/notificacoes.service";
import { EncaminhamentoService } from "src/app/shared/services/encaminhamento.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { AlunosRecorrentes } from "src/app/shared/models/aluno/AlunosRecorrentes";
import { lastValueFrom } from "rxjs";
import { ListarNotificacoes } from "src/app/shared/models/notificacao/listarNotificacoes";

@Component({
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})

export class NotificacoesComponent implements OnInit {

  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  public get Psicologo() {
    return (this.atribuicoesLogin == undefined && this.userLogado?.flPsicologo);
  }

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private chamadoService: ChamadoService,
    private notificacoesService: NotificacoesService,
    private psicologoService: PsicologoService,
    private encaminhamento: EncaminhamentoService,
    private router: Router
  ) {
    this.config.notFoundText = 'Não encontrado';
    this.userLogado = this.authService.getUser();

    if (this.userLogado != null && !this.userLogado.flPsicologo) {
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public turmas: Turma[] = [];
  public submitted = false;
  public submittedModal = false;
  public showPesquisa = false;
  public codigoChamadoSelected: number;
  public codigoEnvolvidoSelected: number;
  public lstNotificacoes: ListarChamados[] = [];
  public alunosRecorrentes: AlunosRecorrentes[] = [];
  public formNotificacoes!: FormGroup;
  public formModal!: FormGroup;
  public showModalExcluirChamado = false;
  public showModal = false;
  public tituloModal = '';
  public chamadoARemover = 0;
  public descricaoExclusao = '';
  public get form() { return this.formNotificacoes.controls; }

  public async ngOnInit() {
    this.formNotificacoes = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      codigoTurno: new FormControl(null),
      codigoTurma: new FormControl(null),
      dataInicio: new FormControl(null),
      dataFim: new FormControl(null),
    });

    if (this.userLogado?.flPsicologo) {
      this.getDiretoriasPerfilDiretoria();
    } else {
      if (this.atribuicoesLogin.NumeroComportamento == 1) {
        this.getDiretorias();
      }
      if (this.atribuicoesLogin.NumeroComportamento == 2) {
        this.getDiretoriasPerfilDiretoria();
      }
      if (this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4) {
        this.getDiretoriasPerfilEscola();
      }
    }
  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];
    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });
    if (this.diretorias.length == 1) {
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    } else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public getDiretoriasPerfilEscola() {
    this.diretorias = [];
    this.escolas = [];
    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });
    if (this.diretorias.length == 1 && this.escolas.length == 1) {
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();
      this.getTurnos();
      this.Refresh();
    }
    if (this.diretorias.length == 1 && this.escolas.length > 1) {
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public ativaDiretoria() {
    if (this.userLogado?.codigoDiretoria != undefined)
      this.formNotificacoes.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public ativaEscola() {
    if (this.userLogado?.codigoEscola != undefined)
      this.formNotificacoes.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
    if (this.userLogado?.codigoDiretoria != undefined && this.userLogado?.codigoEscola != undefined) {
      this.getTurnos();
      this.Refresh();
    }
    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async getDiretorias() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.escolaService.getDiretoriaPorTipoDiretoria())
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.diretorias = result.data;
    this.spinnerService.hide();
    if (this.userLogado?.codigoDiretoria != undefined) {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public async getEscolas(diretoriaLogada: boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formNotificacoes.get('codigoDiretoria')?.value;
    if (this.userLogado?.flPsicologo) {
      this.spinnerService.show();
      this.zerarEscola();
      const result = await lastValueFrom(await this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.escolas = result.data.escolas;
      this.diretorias = result.data.diretorias;
      this.spinnerService.hide();
      this.ativaEscola();

    } else
      if (this.atribuicoesLogin.NumeroComportamento == 1 ||
         this.atribuicoesLogin.NumeroComportamento == 2) {
        this.zerarEscola();
        if (codigoDE != null) {
          this.spinnerService.show();
          const result = await lastValueFrom(await this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
            .catch((err) => {
              return this.ConvivaErrors.handleResultError(err);
            });
          if (!result.isSucess) {
            this.toastr.error(result.message, result.title);
            this.spinnerService.hide();
            return;
          }
          this.escolas = result.data;
          this.ativaEscola();
          this.spinnerService.hide();
        }
      }
    if (this.userLogado !== null) {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formNotificacoes.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public async getTurnos() {
    const codigoEE = this.formNotificacoes.get('codigoEscola');
    if (codigoEE?.value != null) {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.escolaService.getTurnosPorCodigoCie(codigoEE?.value))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.turnos = result.data;
      if (this.turnos == null)
        this.toastr.warning('Não foi encontrados turnos para essa escola !');
      if(this.turnos.filter(u => u.codigoTurno == 0).length > 0)
      {
        this.form.codigoTurno.setValue(0);
        this.getTurmas();
      }
      this.spinnerService.hide();
    }
    else {
      this.zerarTurnos();
    }
    if (this.userLogado !== null) {
      this.userLogado.codigoEscola = codigoEE?.value == null ? 0 : codigoEE?.value;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formNotificacoes.get('codigoTurno')?.patchValue(null);
    this.formNotificacoes.get('codigoTurma')?.patchValue(null);
  }

  public async getTurmas() {
    this.spinnerService.show();
    const codigoEs = this.formNotificacoes.get('codigoEscola');
    const codigoTurno = this.formNotificacoes.get('codigoTurno');
    if (codigoEs?.value != null) {
      const result = await lastValueFrom(await this.escolaService.getTurmasPorCodigoCie(codigoEs?.value, codigoTurno?.value))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.turmas = result.data;
      this.spinnerService.hide();
    }
  }

  public zerarTurma() {
    this.turmas = [];
    this.formNotificacoes.get('codigoTurma')?.patchValue(null);
  }

  public async PesquisarOcorrencias() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.formNotificacoes.invalid) {
      this.showPesquisa = false;
      this.spinnerService.hide();
      return;
    }
    this.showPesquisa = true;
    const parametros = this.SetParametrosChamados();
    if ((parametros.dataFim == null && parametros.dataInicio != null) || (parametros.dataFim != null && parametros.dataInicio == null)) {
      this.toastr.warning("Preencher as duas datas");
      this.spinnerService.hide();
      return;
    }
    const result = await lastValueFrom(await this.notificacoesService.getNotificacoes(parametros))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.lstNotificacoes = result.data;
    if (this.lstNotificacoes.length == 0)
      this.toastr.warning("Não existem notificações para essa pesquisa");
    this.getAlunosRecorrentes(parametros);
    this.spinnerService.hide();
  }

  public async Refresh() {
    const parametros = this.SetParametrosChamados();
    this.getAlunosRecorrentes(parametros);
    this.PesquisarOcorrencias();
    this.spinnerService.hide();
  }

  public SetParametrosChamados(): ListarChamados {
    const chamado = new ListarChamados();
    const date = new Date();
    chamado.codigoDiretoria = this.form?.codigoDiretoria.value;
    chamado.codigoEscola = this.form?.codigoEscola.value;
    chamado.codigoTurno = this.form?.codigoTurno.value;
    chamado.codigoTurma = this.form?.codigoTurma.value;
    chamado.dataInicio = this.form?.dataInicio.value;
    chamado.dataFim = this.form?.dataFim.value;
    chamado.ordenacaoCrescente = false;
    chamado.getFoto = true;
    chamado.codigoPerfil = this.userLogado?.DadosUsuario[0].CodigoPerfil;
    return chamado;
  }

  public async getAlunosRecorrentes(parametros: ListarChamados) {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.chamadoService.getAlunosRecorrentes(parametros))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.alunosRecorrentes = result.data;
    this.spinnerService.hide();
  }

  public OnExcluirChamado(codigoChamdo: number) {
    this.showModalExcluirChamado = true;
    this.descricaoExclusao = `Deseja remover o chamado ?`;
    this.chamadoARemover = codigoChamdo;
  }

  public AbrirModal(codigoChamado: number, codigoEnvolvido: number) {
    this.codigoChamadoSelected = codigoChamado;
    this.codigoEnvolvidoSelected = codigoEnvolvido;
    this.submittedModal = false;
    this.formModal = new FormGroup({
    });
    this.showModal = true;
    this.tituloModal = 'Encaminhamentos';
  }

  public FecharModal() {
    this.showModal = false;
  }

  public OnVisualizarChamado(id: number) {
    this.router.navigate(['/detalhe/', id]);
  }

  public FecharModalExclusao() {
    this.showModalExcluirChamado = false;
    this.descricaoExclusao = "";
    this.chamadoARemover = 0;
  }

  public async ExluirChamado(codigoChamado: number) {
    this.spinnerService.show();
    var chamadoDeletar: ChamadoDeletar = {
      cdChamado: codigoChamado,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    }
    const result = await lastValueFrom(await this.chamadoService.setDeletarChamado(chamadoDeletar))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success('Chamado removido com sucesso!');
    this.Refresh();
    this.FecharModalExclusao();
    this.spinnerService.hide();
  }

  public async EncaminhamentoPsicologo() {
    this.spinnerService.show();
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado ao Psicólogo.',
      statusChamado: 2,//Encaminhado
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil            
    };
    const result = await lastValueFrom(await this.encaminhamento.setCadastrarEncaminhamento(objetoHistorico))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success('Aluno Encaminhado ao Psicólogo com Sucesso.');
    this.FecharModal();
    this.Refresh();
    this.spinnerService.hide();
  }

  public async EncaminhamentoPolicia() {
    this.spinnerService.show();
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado a Rede protetiva.',
      statusChamado: 2,//Encaminhado
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil            
    };
    const result = await lastValueFrom(await this.encaminhamento.setCadastrarEncaminhamentoPolicia(objetoHistorico))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success('Aluno Encaminhado a Rede protetiva.');
    this.FecharModal();
    this.Refresh();
    this.spinnerService.hide();
  }

  public async EncaminhamentoSaude() {
    this.spinnerService.show();
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado a Saúde.',
      statusChamado: 3,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil            
    };
    const result = await lastValueFrom(await this.encaminhamento.setCadastrarEncaminhamentoSaude(objetoHistorico))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success('Aluno Encaminhado a Saúde com Sucesso.');
    this.FecharModal();
    this.Refresh();
    this.spinnerService.hide();
  }
  public OnStatusChamado(ic: number, ie: number) {
    this.router.navigate(['/listar-chamados/', ic, ie]);
  }

}
