import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from '../auth/services/auth.service';
import { Injectable } from "@angular/core";


@Injectable()
export class NonAuthGuardService {
    constructor(private router: Router, private service:AuthService){

    }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree>
    {
      if (!this.service.hasTokenConviva()) {
          return true;
      }
      this.router.navigate(['/']);
      return false;
    }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree>
  {
    return this.canActivate(childRoute,state);
  }

}
