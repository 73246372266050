import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { HistoricoChamado } from "../models/chamados/historico";
import { Atendimento } from "../models/psicologo/Atendimento";
import { CadAtendimento } from "../models/psicologo/CadAtendimento";

@Injectable({
    providedIn: 'root'
  })

  export class AtendimentoService {
    private api = ConvivaApi;

    constructor(
      private http: HttpClient,
      private router:Router)
    { }

    public setCadastrarAtendimento(parametros: CadAtendimento):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Atendimento.setCadastrarAtendimento}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }
}
