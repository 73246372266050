//Principais
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { Subject, lastValueFrom } from 'rxjs';
import { Router } from "@angular/router";
//Services
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { PsicologoService } from 'src/app/shared/services/psicologo.service'
//Models
import { Psicologo } from 'src/app/shared/models/psicologo/Psicologo';
import { Polo } from 'src/app/shared/models/polo/Polo';

@Component({
  selector: 'app-psicologo-manutencao',
  templateUrl: './psicologo-manutencao.component.html',
  styleUrls: ['./psicologo-manutencao.component.scss']
})
export class ManutencaoPsicologoComponent implements OnInit, OnDestroy{
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  // Variaveis do Grid DataTable
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;
  public isDtInitialized:boolean = false;

  constructor(
    private authService: AuthService,
    private psicologoService: PsicologoService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private ConvivaErrors: ConvivaErrorHandlingService
  ){
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public submitted = false;
  public polos: Polo[] = [];
  public codigoPolo = 0;
  public psicologos: Psicologo[] = [];
  public psicologo: Psicologo;
  public showModalExcluirPsicologo = false;
  public showModalVisualizarPsicologo = false;
  public psicologoARemover = 0;
  public formManutencao!: FormGroup;
  public get form() {
    return this.formManutencao.controls;
  }

  public ngOnInit() {

    this.dtOptions = {
      deferRender: true,
      destroy:true,
      processing: true,
      paging: true,
      scrollX: true,
      dom: 'Bfrtip',
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [ 10, 25, 50, -1 ],
        [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
      ],
      buttons: [
        'pageLength',
        {
          extend:'print',
          exportOptions:{
            columns:[0,1,2,3,4]
          }
        },
        {
          extend:'excel',
          exportOptions:{
            columns:[0,1,2,3,4]
          }
        },
        {
          extend:'pdf',
          exportOptions:{
            columns:[0,1,2,3,4]
          }
        }
      ],
    };

    this.formManutencao = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required])
    });

    this.getPolos();
  }

  public redirecionarParaCadastrarPsicologo() {
    this.router.navigate(['/cadastrar-psicologo']);
  }

  public async getPolos() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.ativaPolo();
    this.spinnerService.hide();
  }

  public async OnBuscar(){
    const codigoPolo = this.formManutencao.get('codigoPolo');
    if(codigoPolo?.value != null)
    {
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(codigoPolo?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.psicologos = response.data;
      if(this.psicologos.length == 0) {
        this.toastr.warning("Não existem chamados para essa pesquisa");
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
          });
        }
      }
      else{
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
          });
        } else {
          this.isDtInitialized = true;
          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }
      this.spinnerService.hide();
    }
  }

  public ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public OnEditarPsicologo(id:any){
    this.router.navigate(['/editar-psicologo', id]);
  }

  public FecharModalExclusao() {
    this.showModalExcluirPsicologo = false;
    this.psicologoARemover = 0;
  }

  public OnExcluirPsicologo(codigoPsicologo: any){
    this.showModalExcluirPsicologo = true;
    this.psicologoARemover = codigoPsicologo;
  }

  public FecharModalVisualizar() {
    this.showModalVisualizarPsicologo = false;
    this.psicologoARemover = 0;
  }

  public async OnVisualizarPsicologo(codigoPsicologo: any){
    this.spinnerService.show();

    var response = await lastValueFrom(this.psicologoService.getPsicologoPorCodigo(codigoPsicologo))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.psicologo = response.data;
    this.showModalVisualizarPsicologo = true;
    this.psicologoARemover = codigoPsicologo;
  }

  public async ExluirPsicologo(codigoChamado: number) {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.setDeletarPsicologo(codigoChamado))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.toastr.success('Psicologo removido com sucesso!');
    this.FecharModalExclusao();
    this.OnBuscar();
  }

  public ativaPolo(){
    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      this.codigoPolo = item.CodigoPolo;
    });

    if(this.userLogado?.codigoPolo != undefined)
      this.formManutencao.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
    if(this.userLogado?.codigoPolo != undefined)
    {
      this.OnBuscar();
    }
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public cambioPolo()
  {
    const codigoPO = this.formManutencao.get('codigoPolo')?.value;
    if(this.userLogado !== null)
    {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }
  }

}
