<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModal">
  <div class="close-modal">
    <a href="javascript:void(0)">
      <i class="fa fa-close" (click)="logout()"></i>
    </a>
  </div>

  <h1 class="titulo-page">Selecione um Perfil</h1>

  <div class="container-conviva form-registro modal-perfil">
    <div class="container-btns">

      <div *ngFor="let item of usuario?.perfis">
        <input
          type="button"
          class="btn_primary"
          value="{{item.descricaoPerfil}}"
          (click)="onClickPerfil(usuario.DadosUsuario[0].CodigoUsuario,item.codigoPerfil,item.codigoComportamento)"
        />
      </div>

    </div>
  </div>
</app-modal>


<app-modal *ngIf="showModalEscola">
  <form [formGroup]="formModalEscola" (submit)="SelecionarEscola()">
    <h1 class="titulo-page">Selecione uma escola</h1>

    <!-- FILTRO ESCOLA -->
    <section class="section-filtro busca-section-filtro">
      <div class="container">
        <!-- COMBO DIRETORIAS -->
        <div class="grupo-filter">
          <p><b>Diretoria</b></p>
          <ng-select
            [items]="diretorias"
            bindLabel="nomeDiretoria"
            bindValue="codigoDiretoria"
            placeholder="Selecione uma Diretoria"
            formControlName="codigoDiretoria"
            name="codigoDiretoria"
            (change)="getEscolas()"
            [ngClass]="{
              'is-invalid': resultPesquisa && form.codigoDiretoria.errors
            }"
          >
          </ng-select>
          <div
            *ngIf="resultPesquisa && form.codigoDiretoria.errors" class="alert">
            <div *ngIf="form.codigoDiretoria.errors.required">
              * Diretoria é obrigatório
            </div>
          </div>
        </div>
        <!-- COMBO ESCOLAS -->
        <div class="grupo-filter">
          <p><b>Escola</b></p>
          <ng-select
            [items]="escolas"
            bindLabel="nomeEscola"
            bindValue="codigoEscola"
            placeholder="Selecione uma Escola"
            formControlName="codigoEscola"
            name="codigoEscola"
            [ngClass]="{
              'is-invalid': resultPesquisa && form.codigoEscola.errors
            }"
          >
          </ng-select>
          <div
            *ngIf="resultPesquisa && form.codigoEscola.errors" class="alert">
            <div *ngIf="form.codigoEscola.errors.required">
              * Escola é obrigatório
            </div>
          </div>
        </div>
      </div>
      <div class="container-btns-escola inline">
        <input
          type="button"
          (click)="FecharModalEscola()"
          class="btn_outline"
          value="Fechar" />
          <input
            type="submit"
            class="btn_primary"
            value="Selecionar" />
    </div>
    </section>
  </form>
</app-modal>

