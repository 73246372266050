  <breadcrumb
  [paginas]="[{route:'/home',name:'Home'},{route:'/#',name:'Perfil'}]"
  />
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">
        Perfil
      </h2>
    </div>
  </section>

  <section class="conteudo-principal">
    <div class="container-conviva page-perfil">

      <div class="time-line conteudo-perfil">

        <div class="conteudo-r">
          <a class="btn-perfil-voltar" (click)="pesquisaInformacaoPerfil()">
            <img src="../../../assets/img/icons/icon-arrow.svg" alt="Voltar">
          </a>
        </div>

        <!-- QUANTIDADE DE CHAMADO DO USUARIO -->
        <div class="conteudo-casos">
          <a href="javascript:void(0)" class="box-casos abertos" (click)="PesquisarChamados(1)">
            <p class="number-casos">{{ this.totalChamadosAbertos }}</p>
            <p class="legenda-casos">Chamados em aberto</p>
          </a>
          <a href="javascript:void(0)" class="box-casos criados" (click)="PesquisarChamados(0)">
            <p class="number-casos">{{ this.totalChamados }}</p>
            <p class="legenda-casos">Chamados criados</p>
          </a>
          <a href="javascript:void(0)" class="box-casos encerrados" (click)="PesquisarChamados(2)">
            <p class="number-casos">{{ this.totalChamadosEncerrados }}</p>
            <p class="legenda-casos">Chamados encerrados</p>
          </a>
        </div>
        <!-- END QUANTIDADE DE CHAMADO DO USUARIO -->

        <!-- GRID CHAMADO -->
        @if(!this.visualizarInformacoes)  {
          <div class="grid-chamado-perfil-usuario">

            <div class="grid-titulo-com-pesquisa">
              <h3>
                Pesquisa
              </h3>
              <div class="pesquisa-chamado">
                <input type="text" placeholder="Numero da Ocorrência" />
              </div>
            </div>

            <h4>Chamados {{ this.tipoFiltro }}</h4>

            <div class="grid-listagem-cards" *ngFor="let chamado of this.lstChamadosFiltrados; let i = index">
              <a href="javascript:void(0)" (click)="OnVisualizarChamado(chamado.codigoChamado)" class="cards-chamados-perfil">
                <div class="cards-chamado-info">
                    <p><em>Chamado {{ chamado.codigoChamado }}</em></p>
                    <p><b>{{ chamado.dsEnvolvido }} -  {{ chamado.nomeSubCategoria }} - {{ chamado.dsStatus }}</b></p>
                    <p>{{ chamado.horasAtras }} <span>•</span> {{ chamado.dataChamado  | date : 'dd/MM/yyyy HH:mm' }}</p>
                </div>
                <div class="icon-card-newblank">
                  <img src="../../../assets/img/icons/icon-link-newblank.svg" alt="Ir para o chamado" />
                </div>
              </a>
            </div>


          </div>
          <!--END GRID CHAMADO -->

        } @else {
        <!-- Escola Perfil -->
        <div class="escola-perfil">
          <h3>
            {{ this.chamadosUsuario.funcionario.nome }}
          </h3>
          <div class="row-escola">
            <img src="../../../assets/img/icons/icon-nome-escola.svg" title="Diretoria de Ensino" alt="">
            <p>
              Diretoria de Ensino: {{ this.chamadosUsuario.escola.nomeDiretoria }}
            </p>
          </div>
          <div class="row-escola">
            <img src="../../../assets/img/icons/icon-cie-escola.svg" title="Código identificador da escola (CIE)" alt="">
            <p>
              Código identificador da escola (CIE): {{ this.chamadosUsuario.escola.codigoEscola }}
            </p>
          </div>
          <div class="row-escola">
            <img src="../../../assets/img/icons/icon-localizacao-escola.svg" title="localizacao" alt="">
            <p>
              {{  this.chamadosUsuario.escola.descricaoLogradouro }} {{ this.chamadosUsuario.escola.descricaoEndereco }}, {{  this.chamadosUsuario.escola.numero }}, CEP: {{  this.chamadosUsuario.escola.cep }}
              <!-- RUA TIRADENTES, SN , RUA CEP: 13579-000 -->
            </p>
          </div>
          <div class="row-escola">
            <img src="../../../assets/img/icons/icon-localizacao-escola.svg" title="Bairro" alt="">
            <p>
              Bairro: {{ this.chamadosUsuario.escola.bairro }}
            </p>
          </div>
          <div class="row-escola">
            <img src="../../../assets/img/icons/icon-localizacao-escola.svg" title="Município" alt="">
            <p>
              Município: {{ this.chamadosUsuario.escola.nomeMunicipio }}
            </p>
          </div>
          <div class="row-escola">
            <img src="../../../assets/img/icons/icon=tel-escola.svg" title="tel" alt="">
            <p>
              {{ this.chamadosUsuario.escola.numeroTelefono }}
            </p>
          </div>
          <div class="row-escola">
            <img src="../../../assets/img/icons/icon-email-escola.svg" title="email" alt="">
            <p>
              {{ this.chamadosUsuario.escola.email }}
            </p>
          </div>
        </div>
        <!-- END Escola Perfil -->

        <div class="dados-pessoais">
          <h3>
            Dados pessoais
          </h3>
          <p>
            <b>CPF</b> {{this.userLogado?.flPsicologo ? this.userLogado?.cpf: atribuicoesLogin.Cpf}}
          </p>
          <p *ngIf="!this.userLogado?.flPsicologo">
            <b>Login Sed</b> {{atribuicoesLogin.Login}}
          </p>
          <p>
            <b>RG</b> {{ this.chamadosUsuario.funcionario.rg }}
          </p>
          <p>
            <b>E-mail</b> {{this.userLogado?.flPsicologo ? this.userLogado?.email : atribuicoesLogin.Email}}
          </p>
        </div>
        }

      </div>

      <aside class="sidebar">

        <div class="identificacao-perfil">
          <div class="foto-perfil">
            <img src="../../../assets/img/elemento/avatarPerfilAluno.jpg" alt="">
          </div>
          <div class="nome-perfil">
            {{this.userLogado?.flPsicologo ? this.userLogado?.nome : atribuicoesLogin.Nome}}
          </div>
          <div class="cargo-perfil">
            {{this.userLogado?.flPsicologo ? "Psicólogo(a)" : atribuicoesLogin.NomePerfil}}
          </div>
        </div>

        <div class="links-acesso-rapido">
          <h3>
            Links rápidos
          </h3>
          <h5>
            acesso
          </h5>
          <ul>
            <li *ngIf="this.userLogado?.funcionalidades?.includes(2)">
              <a href="javascript:void(0)" routerLink="/cadastrar-chamados" title="Adicionar Chamado">
                Adicionar Chamado
              </a>
            </li>
            <li *ngIf=" this.userLogado?.funcionalidades?.includes(16)">
              <a href="javascript:void(0)" routerLink="/notificacoes"> Notificações </a>
            </li>
            <li>
              <a href="javascript:void(0)" routerLink="/buscar-alunos">
                Buscar Estudantes com Ocorrências
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" routerLink="/listar-chamados">
                Estudantes com Ocorrências Registradas
              </a>
            </li>
          </ul>
        </div>

      </aside>

    </div>
  </section>

  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
