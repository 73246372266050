import { Component } from "@angular/core";

@Component({
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss']
})

export class PageNotFoundComponent {
  public cars = [
    { id: 1, name: 'Teste' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
}
