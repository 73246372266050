import { Component, inject, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { UserLoginSed } from "src/app/shared/models/sed/UserLogin";



@Component({
  selector: 'visibility-manager',
  template:`
    @if(HavePermissionToSee){
      <ng-content></ng-content>
    }
  `,
})

export class VisibilityManagerComponent implements OnInit{
  ngOnInit(): void {
    this.UserLogado = this.authService.getUser();
  }

  authService = inject(AuthService)
  @Input() funcionalidadeId = 0 ;
  UserLogado:UserLoginSed | undefined | null;


  get HavePermissionToSee(){
    return this.UserLogado?.funcionalidades.includes(this.funcionalidadeId);
  }


}
