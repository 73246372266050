<!-- TITULO -->
<section class="section-saudacao">
  <breadcrumb
  [paginas]="[
    {route:'/home',name:'Home'},
    {route:'/dashboard-visitas-psicologo',name:'Painel de Atividade do Psicólogo'},
    {route:'/visualizar-atividade-psicologo/',name:'Visualizar Visita '+this.tpAcaoAtividade}
  ]"
/>
    <div class="container">
      <h2 class="titulo-page">Visualizar Visita {{this.tpAcaoAtividade}}</h2>
      <app-btn-back></app-btn-back>
    </div>
  </section>

  <section class="container-conviva form-registro">
    <!-- VISUALIZACAO GERAL -->
    <div class="container form-vista-psicologo visualizacao-geral">

      <div class="conteudo-row">
        <div class="grupo-filter-full">
          <label>Psicólogo:</label>
          <p>{{ this.atividade.nome }}</p>
        </div>
        <div class="grupo-filter-full">
          <label>Diretoria:</label>
          <p>{{ this.atividade.nM_DIRETORIA }}</p>
        </div>
      </div>

      <div class="conteudo-row">
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Data da Visita:</label>
            <p>{{ getDate(this.atividade.dT_ATIVIDADE_PSICOLOGO) }}</p>
          </div>
        </div>
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Escola</label>
            <p>{{this.atividade.nM_ESCOLA}}</p>
          </div>
        </div>
      </div>

      <div class="grupo-filter-full" *ngIf="this.atividade.cD_CHAMADO != null">
        <label>Ocorrências agendadas:</label>
        <p>Chamado {{this.atividade.cD_CHAMADO}} - {{this.atividade.dS_SUB_CATEGORIA}} -
          {{this.atividade.statuS_CHAMADO}}</p>
      </div>

      <div class="conteudo-row">
        <div class="grupo-filter-full">
          @if(this.atividade.tP_ACAO_ATIVIDADE == 5) {
          <label>Qual ação foi realizada?</label>
          <p style="overflow-wrap: break-word;word-break: break-word;">{{ this.atividade.dS_RESPOSTA_PERGUNTA_OBJETIVO_ACAO }}</p>
        }@else{
            <label>Qual atividade foi realizada?</label>
            <p style="overflow-wrap: break-word;word-break: break-word;">{{ this.atividade.atividadE_REALIZADA }}</p>
          }
        </div>
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Quanto tempo durou a ação?</label>
            <p>{{ this.atividade.tM_ATIVIDADE_PSICOLOGO }}MN</p>
          </div>
        </div>
      </div>

      <div class="conteudo-envolvidos" *ngIf="this.atividade.tP_ATIVIDADE_REALIZADA == 1 && this.atividade.cD_ENVOLVIDO != null">
        <h3>Envolvido</h3>
        <div class="envolvidos-aluno">
          <div class="perfil-aluno-chamado" *ngFor="let envolvido of lstEnvolvidos ">
            <div class="img-aluno">
              <span>{{envolvido.iniciais}}</span>
            </div>
            <div class="infor-aluno-chamado">
              <p class="nome-aluno">{{envolvido.nome}}</p>
              <p class="descricao">{{envolvido.dsTipoPessoa}} - {{envolvido.dsEnvolvido}}</p>
            </div>
          </div>
        </div>
      </div>


      <div class="conteudo-envolvidos" *ngIf="this.atividade.tP_ATIVIDADE_REALIZADA == 2" >
        <h3>Turmas</h3>
        <div class="grid-datatable">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border"
            aria-describedby="dashboard"
          >
            <thead>
              <tr>
                <th>Codigo Turma</th>
                <th>Numero Classe</th>
                <th>Tipo de Ensino</th>
                <th>Numero de Serie</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstTurmas">
                <td>{{item.cD_TURMA}}</td>
                <td>{{item.nR_CLASSE}}</td>
                <td>{{item.dS_TURMA}}</td>
                <td>{{item.nR_SERIE}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      @if(this.atividade.nM_DSL_DIRETORIA_DESTINO != null){
        <h3 class="sub-titulo-dash">Deslocamento:</h3>
        <div class="conteudo-row">
          <div class="box-duas-colum">
            <div class="grupo-filter-full">
              <label><b>Origem</b></label>
              <p><b>Diretoria:</b>{{this.atividade.nM_DSL_DIRETORIA_ORIGEM}}</p>
              <p><b>Escola: </b>{{this.atividade.nM_DSL_ESCOLA_ORIGEM}}</p>
              <p><b>Horário saída: </b>{{this.atividade.hR_DSL_SAIDA}}</p>
            </div>
          </div>
          <div class="box-duas-colum">
            <div class="grupo-filter-full">
              <label><b>Destino</b></label>
              <p><b>Diretoria: </b></p>
              <p><b>Escola: </b>{{this.atividade.nM_DSL_ESCOLA_DESTINO}}</p>
              <p><b>Horário chegada: </b>{{this.atividade.hR_DSL_CHEGADA}}</p>
              <p><b>Tempo de Deslocamento:</b> {{getTempoDeslocamento(atividade.hR_DSL_SAIDA, atividade.hR_DSL_CHEGADA)}}</p>
            </div>
          </div>
        </div>
      }

      @if(this.atividade.tP_ACAO_ATIVIDADE != 5){
      <div class="grupo-filter-full">
        <label>Qual o objetivo da ação?</label>
        <p>{{this.atividade.dS_RESPOSTA_PERGUNTA_OBJETIVO_ACAO}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Como você avalia o resultado da ação realizada?</label>
        <p>{{this.atividade.dS_RESPOSTA_PERGUNTA_AVALIACAO_RESULTADO_ACAO}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Como você acha que o estudante recebeu e foi impactado pela ação realizada?</label>
        <p>{{this.atividade.dS_RESPOSTA_PERGUNTA_IMPACTO_ACAO_REALIZADA}}</p>
      </div>
    }

    <div class="grupo-filter-full">
      <label>Anexos</label>
      <div *ngFor="let item of atividade.arquivos; let i = index;" class="itens-anexados">
        <a
          href="javascript:void(0)"
          class="icon-arquivo no-wrap"
          (click)="abrirEmNovaAba(item.caminhoAzure)"
          style="white-space: nowrap; margin-right: 10px;"
        >
          {{ getShortenedName(item.nome) }}
        </a>
        <span
          *ngIf="(this.userLogado?.codigoPerfil === 1634 || this.userLogado?.codigoPerfil === 1477) && (this.atividade.sT_APROVACAO_FISCAL == null || this.atividade.sT_APROVACAO_FISCAL == 0 )"
          class="remove-link"
         (click)="removeFile(item.caminhoAzure)"

          title="Remover"
        >
          &times;
        </span>
      </div>
    </div>

    <div class="conteudo-upload grupo-filter-full" *ngIf="(this.userLogado?.codigoPerfil === 1634 || this.userLogado?.codigoPerfil === 1477) && (this.atividade.sT_APROVACAO_FISCAL == null || this.atividade.sT_APROVACAO_FISCAL == 0 )">
      <label>Inserir documentos, fotos, áudios (MP3) ou vídeos (MP4).</label>
      <div class="input-upload">
        <input type="file" id="arquivos" name="arquivos" accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4, .ppt, .pptx, .potx, .ppsx, .odp"
          (change)="OnFileChange($event)" multiple />
        <label for="arquivos" class="message-container" *ngIf="atividade.arquivos?.length === 0">
          Nenhum arquivo escolhido
        </label>
        <label for="arquivos" class="message-container" *ngIf="atividade.arquivos?.length != 0">
          Selecionar mais arquivos
        </label>
      </div>
    </div>

    <div class="container-btns-visitas" *ngIf="(this.userLogado?.codigoPerfil === 1634 || this.userLogado?.codigoPerfil === 1477) && (this.atividade.sT_APROVACAO_FISCAL == null || this.atividade.sT_APROVACAO_FISCAL == 0 )">
      <input type="submit" (click)="Salvar()" class="btn_primary" value="Salvar"/>
    </div>
</div>

<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>


<app-modal *ngIf="showModal" class="modal-calendario">
  <div class="container-conviva form-registro modal-envolvidos">
    <div class="header-modal-calendario">
      <h1 class="titulo-page">Aviso !</h1>
      <p>Obrigatório anexar pelo menos um arquivo</p>
      <!-- <a class="btn-fechar-modal" (click)="closeModal()" >fechar</a> -->
    </div>
    <div class="container-btns">
      <input type="button" (click)="closeModal()" class="btn_outline" value="Fechar" />
    </div>
  </div>
</app-modal>
