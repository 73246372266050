import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { HistoricoChamado } from "../models/chamados/historico";

@Injectable({
    providedIn: 'root'
  })

  export class AgendamentoService {
      private api = ConvivaApi;

      constructor(
        private http: HttpClient,
        private router:Router)
      { }

      public setCadastrarAgendamento(parametros: HistoricoChamado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Agendamento.setCadastrarAgendamento}`, parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }


      public getAgendamentosEmAberto(parametros:any):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Agendamento.getAgendamentosEmAberto}`,parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }
  }
