<form [formGroup]="formFuncionalidadeManage" (submit)="OnSalvar()">

      <!-- TITULO -->
      <section class="section-saudacao">
        <div class="container">
          <h2 class="titulo-page">Adicionar Funcionalidade</h2>
        </div>
      </section>

      <section class="section-filtro">
        <div class="container">
            <div class="grupo-filter">
                <p><b>Funcionalidade</b></p>
                <input class="bord_text"
                  type="search"
                    id="desFuncionalidade"
                    name="desFuncionalidade"
                    formControlName="desFuncionalidade"
                    [(ngModel)]="desFuncionalidade"
                    />
            </div>
            <div class="container-btns">
                <input type="submit" class="btn_primary" value="Salvar" />
            </div>
        </div>
      </section>

      <section class="section-table">
        <div class="container">
            <table>
              <caption>Funcionalidades</caption>
              <thead>
                <tr>
                  <th>Codigo Funcionalidade</th>
                  <th>Descrição Funcionalidade</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let funcionalidad of this.funcionalidades">
                  <td>{{funcionalidad.codigoFuncionalidade}}</td>
                  <td>{{funcionalidad.descricaoFuncionalidade}}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </section>

</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>