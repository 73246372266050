//Principais
import { Component, OnInit, ViewChild, TemplateRef, ComponentFactoryResolver, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from "rxjs";
//Services
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { CategoriaService } from 'src/app/shared/services/categoria.service';
import { EscolaService } from 'src/app/shared/services/escola.service';
import { AlunoService } from 'src/app/shared/services/alunos.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { GenericValidator } from 'src/app/shared/validations/genericValidators';
import { ChamadoService } from 'src/app/shared/services/chamado.service';
import { FuncionarioService } from 'src/app/shared/services/funcionarios.service';
//Models
import { DefaultResponse } from 'src/app/shared/models/DefaultResponse';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Categoria } from 'src/app/shared/models/categoria/Categoria';
import { Gravidade } from 'src/app/shared/models/categoria/Gravidade';
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { Chamado } from 'src/app/shared/models/chamados/chamado';
import { Aluno } from 'src/app/shared/models/envolvidos/aluno';
import { Turno } from 'src/app/shared/models/escola/Turno';
import { Funcionario } from 'src/app/shared/models/envolvidos/funcionario';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { DateTimeProvider } from 'angular-oauth2-oidc';
import { NaoOcorrenciaService } from 'src/app/shared/services/nao-ocorrencia.service';


@Component({
  selector: 'app-chamado-add',
  templateUrl: './chamado-add.component.html',
  styleUrls: ['./chamado-add.component.scss'],
})
export class AdicionarChamadoComponent implements OnInit {
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  public parametroRA: any;
  public parametroTE: any;
  public parametroDT: any;
  public qtdeCaracteresDescricao:number = 0;
  public permissaoParaCadastrarNaoOcorrencia:boolean ;

  constructor(
    private escolaService: EscolaService,
    private categoriaService: CategoriaService,
    private arquivoService: ArquivoService,
    private config: NgSelectConfig,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private alunoService: AlunoService,
    private genericValidate: GenericValidator,
    private chamadoService: ChamadoService,
    private funcionarioService: FuncionarioService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private naoOcorrenciaService:NaoOcorrenciaService
  ) {
    this.config.notFoundText = "Não encontrado";

    this.route.paramMap.subscribe(params => {
      this.parametroRA = params.get('ra') ?? '';
      this.parametroTE = params.get('te') ?? '';
      this.parametroDT = params.get('dt') ?? '';
    });

    this.userLogado = this.authService.getUser();

    if(this.userLogado != null){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  @ViewChild('modalContent') modalContent!: TemplateRef<any>;

  public showModal = false;
  public showBuscaAluno = false;
  public showBuscaEquipe = false;
  public showOutros = false;
  public validaBuscaRA = false;
  public submitted = false;
  public submittedModal = false;
  public showDropAuxiliar = false;
  public showModalExcluirAluno = false;
  public NaoOcorrenciaCadastradaHoje:boolean;

  public tituloModal = '';
  public descricaoExclusao = '';
  public envolvidoExclusao?: Envolvido;

  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public escolasModal: Escola[] = [];
  public categorias: Categoria[] = [];
  public gravidadesX: Gravidade[] = [];
  public files: ArquivoAzure[] = [];
  public envolvidos: Envolvido[] = [];
  public alunos: Aluno[] = [];
  public aluno: Aluno = <Aluno>{};
  public funcionarios: Funcionario[] = [];

  public gravidades = [
    { id: 1, name: 'Amarelo' },
    { id: 2, name: 'Vermelho' },
  ];

  public TipoPessoas = [
    { id: 1, name: 'Estudante' },
    { id: 2, name: 'Equipe Escolar' },
    { id: 3, name: 'Outros' },
  ];

  public TipoEnvolvido = [
    { id: 1, name: 'Ofendido' },
    { id: 2, name: 'Ofensor' }
  ];

  public TipoOutros = [
    { id: "1", name: 'Não Identificado' },
    { id: "2", name: 'Responsável pelo Estudante' }
  ];

  public formCadastrar!: FormGroup;
  public formModal!: FormGroup;

  // FORM CADASTRAR
  public get form() {
    return this.formCadastrar.controls;
  }
  // FORM MODAL
  public get modalForm() {
    return this.formModal.controls;
  }

  // DADOS GERAIS
  public get codigoDiretoria() {
    return this.formCadastrar.get('codigoDiretoria')!;
  }
  public get codigoEscola() {
    return this.formCadastrar.get('codigoEscola')!;
  }
  public get codigoTurno() {
    return this.formCadastrar.get('codigoTurno')!;
  }
  public get codigoGravidade() {
    return this.formCadastrar.get('codigoGravidade')!;
  }
  public get dataChamado() {
    return this.formCadastrar.get('dataChamado')!;
  }
  public get codigoSubCategoria() {
    return this.formCadastrar.get('codigoSubCategoria')!;
  }
  public get dsChamado() {
    return this.formCadastrar.get('dsChamado')!;
  }
  public get arquivos() {
    return this.formCadastrar.get('arquivos')!;
  }
  public get codigoDiretoriaModal() {
    return this.formModal.get('codigoDiretoriaModal')!;
  }
  public get codigoEscolaModal() {
    return this.formModal.get('codigoEscolaModal')!;
  }

  public async ngOnInit() {
    this.formCadastrar = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      codigoTurno: new FormControl(null, [Validators.required]),
      dataChamado: new FormControl(null, [Validators.required]),
      codigoGravidade: new FormControl(null, [Validators.required]),
      codigoSubCategoria: new FormControl(null, [Validators.required]),
      dsChamado: new FormControl(null, [Validators.required, Validators.minLength(50), Validators.maxLength(500)]),
    });
    // await this.getPermissaoCadastrarOcorrencia();

    this.spinnerService.show();

    if(this.parametroRA)
      this.OnAdicionarEnvolvidoPorPerfilAluno();

    if(this.parametroDT)
      this.formCadastrar.get('dataChamado')?.setValue(this.parametroDT);

    this.getCategorias();

    if(this.atribuicoesLogin.NumeroComportamento == 1){
      this.getDiretorias();
    }

    if(this.atribuicoesLogin.NumeroComportamento == 2){
      this.getDiretoriasPerfilDiretoria();
    }

    if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
      this.getDiretoriasPerfilEscola();
    }
    await this.buscaNaoOcorrenciaDiaria();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.spinnerService.hide();
    }}

    removeUniqueId(fileName: string): string {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/;
      return fileName.replace(regex, '');
    }

  public async getGravidade() {
    this.spinnerService.show();
    const codigoSC = this.formCadastrar.get('codigoSubCategoria');
    if (codigoSC?.value != null) {
      const result = await lastValueFrom(await this.categoriaService.getGravidade(codigoSC?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.formCadastrar.get('codigoGravidade')?.setValue(result.data[0].codigoGravidade);
      this.form.codigoGravidade.disable();
      this.spinnerService.hide();
    }else{
      this.form.codigoGravidade.enable();
      this.formCadastrar.get('codigoGravidade')?.setValue(0);
      this.spinnerService.hide();
    }

  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];
    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();

      this.getTurnos();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria != undefined)
      this.formCadastrar.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){
    if(this.userLogado?.codigoEscola != undefined)
      this.formCadastrar.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
    if(this.userLogado?.codigoDiretoria != undefined && this.userLogado?.codigoEscola != undefined)
      this.getTurnos();
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async getDiretorias() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.escolaService.getDiretoriaPorTipoDiretoria())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.diretorias = result.data;
    this.spinnerService.hide();
    if(this.userLogado?.codigoDiretoria != undefined){
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public async getCategorias() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.categoriaService.getCategoriasWithSub())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.categorias = result.data.filter((x:Categoria)=>x.codigoCategoria != 13);

    this.spinnerService.hide();
  }

  public async getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formCadastrar.get('codigoDiretoria')?.value;
    if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2){
      this.zerarEscola();
      if (codigoDE != null) {
        this.spinnerService.show();
        const result = await lastValueFrom(await this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!result.isSucess){
          this.toastr.error(result.message, result.title);
          this.spinnerService.hide();
          return;
        }
        this.escolas = result.data;
        this.spinnerService.hide();
        this.ativaEscola();
      }
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public async getEscolasModal() {
    this.spinnerService.show();
    this.zerarEscolaModal();
    const codigoDE = this.formModal.get('codigoDiretoriaModal');
    if (codigoDE?.value != null) {
      const result = await lastValueFrom(await this.escolaService.getEscolasPorCodigoDiretoria(codigoDE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.escolasModal = result.data;
      this.spinnerService.hide();
    }else{
      this.escolasModal = [];
      this.spinnerService.hide();
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formCadastrar.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public zerarEscolaModal() {
    this.escolasModal = [];
    this.formModal.get('codigoEscolaModal')?.patchValue(null);
  }

  public async getTurnos() {
    const codigoEE = this.formCadastrar.get('codigoEscola');
    this.zerarTurnos();

    if (codigoEE?.value != null) {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.escolaService.getTurnosPorCodigoCie(codigoEE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.turnos = result.data;
      if (this.turnos == null)
        this.toastr.warning('Não foi encontrados turnos para essa escola !');
      if(this.turnos.filter(u => u.codigoTurno == 0).length > 0)
        this.form.codigoTurno.setValue(0);
      this.spinnerService.hide();
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = codigoEE?.value == null ? 0 : codigoEE?.value;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formCadastrar.get('codigoTurno')?.patchValue(null);
  }

  public async getAlunosPorNome() {
    const nmAluno = this.formModal.get('raNomeAluno');
    const codigoDiretoria = this.formModal.get('codigoDiretoriaModal');
    const codigoEscola = this.formModal.get('codigoEscolaModal');
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const result = await lastValueFrom(await this.alunoService.getAlunosPorNome(nmAluno?.value, codigoDiretoria?.value, codigoEscola?.value))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunos(result);
    this.spinnerService.hide();
  }

  public async getAlunosPorRa() {
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const raAluno = this.formModal.get('raNomeAluno');
    const result = await lastValueFrom(await this.alunoService.getAlunosPorRa(raAluno?.value))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunoPorRA(result);
    this.spinnerService.hide();
  }

  public async OnFileChange(event: any) {
    const maxFileSize =  31 * 1024 * 1024; // 30 MB
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      for (const file of files) {
        if (file.size > maxFileSize) {
          this.toastr.warning('O tamanho máximo do arquivo é 30 MB.');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
        if( file.type == "image/jpg" ||
            file.type == "image/jpeg" ||
            file.type == "image/png" ||
            file.type ==  "application/pdf" ||
            file.type ==  "application/ppt" ||
            file.type ==  "application/pptx" ||
            file.type ==  "application/potx" ||
            file.type ==  "application/ppsx" ||
            file.type ==  "application/odp" ||
            file.type == "audio/mpeg" ||
            file.type == "video/mp4"
          ){
          formData.append(file.name, file);
        }
        else{
          this.toastr.warning('Só é possivel subir arquivos .jpg, .jpeg , .png, .pdf, .mp3 , .mp4, .ppt, .pptx, .potx e ppsx');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
      }
      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }else {
        this.files.push(...result.data);
        event.target.value = '';
      }
      this.spinnerService.hide();
    }
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
    const fileInput = document.getElementById('arquivos') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  public async OnTipoPessoaChange(event: any) {
    this.RemoverValidacoesModal();
    this.submittedModal = false;
    this.validaBuscaRA = false;
    this.showDropAuxiliar = false;
    if (event == undefined) {
      this.showBuscaAluno = false;
      this.showBuscaEquipe = false;
      this.showOutros = false;
      return;
    }
    const tipoPessoa = event.id;
    if (tipoPessoa == 1) {
      this.formModal.get('raNomeAluno')?.addValidators([Validators.required]);
      this.formModal.get('raAlunoModalDrop')?.addValidators([Validators.required]);
      this.showBuscaAluno = true;
    } else {
      this.showBuscaAluno = false;
    }
    if (tipoPessoa == 2) {
      this.formModal.get('cpfFuncionario')?.addValidators([Validators.required]);
      this.formModal.get('cpfFuncionarioModalDrop')?.addValidators([Validators.required]);
      this.showBuscaEquipe = true;
    } else {
      this.showBuscaEquipe = false;
    }
    if (tipoPessoa == 3) {
      this.formModal.get('dsOutros')?.addValidators([Validators.required]);
      this.showOutros = true;
    } else {
      this.showOutros = false;
    }
    this.formModal.get('raNomeAluno')?.updateValueAndValidity();
    this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
    this.formModal.get('cpfFuncionario')?.updateValueAndValidity();
    this.formModal.get('cpfFuncionarioModalDrop')?.updateValueAndValidity();
    this.formModal.get('dsOutros')?.updateValueAndValidity();
  }

  public OnLimpar() {
    this.files = [];
    this.envolvidos = [];
    this.form.codigoGravidade.setValue(null);
    this.form.dataChamado.setValue(null);
    this.form.codigoTurno.setValue(null);
    this.form.codigoSubCategoria.setValue(null);
    this.form.dsChamado.setValue(null);
    this.form.arquivos.setValue(null);
  }

  public async OnSalvar() {
    this.submitted = true;

    if(this.NaoOcorrenciaCadastradaHoje){
      this.toastr.warning("Não Ocorrência já cadastrada hoje.");
      this.submitted = false;
      return
    }
    if(this.formCadastrar.get('dataChamado')?.value == null) {
      this.toastr.error('Informe uma data e hora.');
      return;
    }
    if(this.envolvidos != null) {
      if (this.envolvidos.length == 0) {
        this.toastr.error('É obrigatório incluir pelo menos um envolvido.');
        return;
      }
    }
    this.RemoverValidacoesModal();
    if (this.formCadastrar.invalid) {
      return;
    }
    // if(!this.permissaoParaCadastrarNaoOcorrencia){
    //   this.toastr.error("Não foi possivel cadastrar essa não ocorrência. Entre em contato com o Admin.");
    //   return;
    // }
    this.spinnerService.show();
    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.formCadastrar.get('codigoDiretoria')?.value)[0];
    const esc = this.escolas.filter(r => r.codigoEscola == this.formCadastrar.get('codigoEscola')?.value)[0];

    const objetoChamado: Chamado = {
      cdGravidade: this.formCadastrar.get('codigoGravidade')?.value,
      cdCategoria: this.formCadastrar.get('codigoSubCategoria')?.value,
      cdSubCategoria: this.formCadastrar.get('codigoSubCategoria')?.value,
      dtChamado: this.formCadastrar.get('dataChamado')?.value,
      cdDiretoria: dir.codigoDiretoria,
      nmDiretoria: dir.nomeDiretoria,
      cdEscola: esc.codigoEscola,
      nmEscola: esc.nomeEscola,
      dsChamado: this.formCadastrar.get('dsChamado')?.value,
      cdTurno: this.formCadastrar.get('codigoTurno')?.value,
      outros: this.formCadastrar.get('outros')?.value,
      codigoUsuario: this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      arquivos: this.files,
      envolvidos: [],
    };

    var dataAtual: Date = new Date();
    var dataSelecionada: Date = new Date(objetoChamado.dtChamado);
    if(dataSelecionada > dataAtual)
    {
      this.toastr.error('A data da ocorrência não pode ser maior que a data de hoje.');
      this.spinnerService.hide();
      return;
    }
    // let date = new Date();
    // date.setDate(date.getDate() - 7);
    // if( dataSelecionada  < date)
    // {
    //   this.toastr.error('A data da ocorrência deve constar em até 7 dias depois do acontecimento.');
    //   this.spinnerService.hide();
    //   return;
    // }

    if(this.envolvidos.length > 0)
      objetoChamado.envolvidos.push(...this.envolvidos);

    const categoria = this.categorias.filter(r => r.codigoSubCategoria == objetoChamado.cdSubCategoria);
    objetoChamado.cdCategoria = categoria.length > 0 ? categoria[0].codigoCategoria : 0;
    console.log(objetoChamado)
    const result = await lastValueFrom(await this.chamadoService.setCadastrarChamado(objetoChamado))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.toastr.success('Chamado cadastrado com sucesso.');
    setTimeout(() => {
      this.submitted = false;
      document.getElementById("btnLimpar")?.click();
    }, 2000);
  }

  public AbrirModal() {
    this.submittedModal = false;

    this.formModal = new FormGroup({
      tipoPessoa: new FormControl(null, [Validators.required]),
      tipoEnvolvido: new FormControl(null),
      raNomeAluno: new FormControl(null, [Validators.required]),
      cpfFuncionario: new FormControl(null, [
        Validators.required,
        this.genericValidate.ValidaCpf,
      ]),
      dsOutros: new FormControl(null, [Validators.required]),
      codigoDiretoriaModal: new FormControl(null, [Validators.required]),
      codigoEscolaModal: new FormControl(null, [Validators.required]),
      nomeEscola: new FormControl(null),
      raAlunoModalDrop: new FormControl(null, [Validators.required]),
      cpfFuncionarioModalDrop: new FormControl(null, [Validators.required]),
    });

    this.showModal = true;
    this.tituloModal = 'Dados do envolvido';
  }

  public FecharModal() {
    this.showModal = false;
    this.showBuscaAluno = false;
    this.showBuscaEquipe = false;
    this.showOutros = false;
  }

  public BuscarAlunoPorNomeRA() {
    const regexNumeros = /\d$/;
    const regexLetras = /^[a-zA-Z]+$/;
    const regexAmbos = /^[a-zA-Z0-9]+$/;

    this.formModal.get('raAlunoModalDrop')?.patchValue(null);
    this.formModal.get('raAlunoModalDrop')?.removeValidators([Validators.required]);
    this.validaBuscaRA = false;
    let nomeRa = this.formModal.get('raNomeAluno')?.value;
    let buscarPorRa = false;

    if (regexNumeros.test(nomeRa)) {
      this.formModal.get('codigoDiretoriaModal')?.clearAsyncValidators();
      this.formModal.get('codigoEscolaModal')?.clearAsyncValidators();
      buscarPorRa = true;
    }
    else if (regexLetras.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.formModal.get('codigoDiretoriaModal')?.addValidators([Validators.required]);
      this.formModal.get('codigoEscolaModal')?.addValidators([Validators.required]);
    }
    else if (regexAmbos.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.formModal.get('raNomeAluno')?.setValue(nomeRa.replace(/\d/g, ''));
    }
    else {
    }

    this.AtualizarValidacoesAlunos();

    if (this.formModal.invalid) {
      this.toastr.warning("Deverá ser selecionado o aluno ofendido/ofensor");
      return;
    }

    if (buscarPorRa) {
      this.getAlunosPorRa();
    } else {
      this.getAlunosPorNome();
    }

    this.formModal.get('raAlunoModalDrop')?.addValidators([Validators.required]);
    this.AtualizarValidacoesAlunos();
  }

  public OnAdicionarEnvolvido() {
    this.submittedModal = true;

    if (this.formModal.invalid) {
      return;
    }
    let tipoEnvolvido: number;
    if(this.formModal.get('tipoEnvolvido')?.value == undefined)
      tipoEnvolvido = 0;
    else
      tipoEnvolvido = this.formModal.get('tipoEnvolvido')?.value;

    const objetoEnvolvido: Envolvido = {
      tipoPessoa: this.formModal.get('tipoPessoa')?.value,
      tipoEnvolvido: tipoEnvolvido,
      cpf: this.formModal.get('cpfFuncionarioModalDrop')?.value,
      ra: this.formModal.get('raAlunoModalDrop')?.value,
      dsOutros: this.formModal.get('dsOutros')?.value == 1 ? 'Não Identificado' : 'Responsável pelo Estudante',
      escola: this.formModal.get('codigoEscolaModal')?.value
    };
    if (objetoEnvolvido.tipoPessoa == 1) {
      const envolvidos = this.envolvidos.filter(r => r.ra == objetoEnvolvido.ra && r.tipoEnvolvido == objetoEnvolvido.tipoEnvolvido);
      if(envolvidos.length > 0){
        this.toastr.warning("Está aluno já foi adicionado como Envolvido.");
        return;
      }

      const al = this.alunos.find((aluno) => aluno.ra === objetoEnvolvido.ra);
      const nomeAlunoSplit = al?.nomeAluno?.split(' ');

      if(nomeAlunoSplit != null)
        objetoEnvolvido.nome = nomeAlunoSplit?.length > 2 ? nomeAlunoSplit[0] + " " + nomeAlunoSplit[1] : nomeAlunoSplit[0];
      else{
        this.toastr.warning("Deverá ser selecionado o aluno ofendido/ofensor.");
        return;
      }

      const iniciais =  nomeAlunoSplit
                                  .filter(parte => parte.length > 3)
                                  .map(parte => parte.charAt(0))
                                  .slice(0, 2)
                                  .join('');

      objetoEnvolvido.imagemTemporaria = iniciais;
      objetoEnvolvido.codigoTurma = al?.codigoTurma;
      objetoEnvolvido.codigoAluno = al?.codigoAluno;
    }

    if (objetoEnvolvido.tipoPessoa == 2) {
      const nomeFuncionario = this.funcionarios.find((fun) => fun.cpf === objetoEnvolvido.cpf)?.nome

      const nomeFuncSplit = nomeFuncionario?.split(' ');

      if(nomeFuncSplit != null)
        objetoEnvolvido.nome = nomeFuncSplit?.length > 2 ? nomeFuncSplit[0] + " " + nomeFuncSplit[1] : nomeFuncSplit[0];

      const iniciais =  nomeFuncionario?.split(' ')
                                        .filter(parte => parte.length > 3)
                                        .map(parte => parte.charAt(0))
                                        .slice(0, 2)
                                        .join('');
      objetoEnvolvido.imagemTemporaria = iniciais;
    }

    if (objetoEnvolvido.tipoPessoa == 3) {
      objetoEnvolvido.imagemTemporaria = 'X';
    }

    objetoEnvolvido.idTemporario = this.envolvidos.reduce((maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0), 0) + 1;
    this.envolvidos.push(objetoEnvolvido);

    this.toastr.success(`${this.tituloModal} inseridos com sucesso`);
    this.FecharModal();
  }

  public async OnAdicionarEnvolvidoPorPerfilAluno(){
    this.spinnerService.show();
    const result = await lastValueFrom(await this.alunoService.getAlunosPorRa(this.parametroRA))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    let aluno = result.data;
    const objetoEnvolvido: Envolvido = {
      tipoPessoa: 1,
      tipoEnvolvido: parseInt(this.parametroTE),
      ra: this.parametroRA,
      nome: aluno.nomeAluno,
      codigoTurma: aluno.codigoTurma,
      codigoAluno: aluno.codigoAluno,
    };
    objetoEnvolvido.imagemTemporaria = aluno.iniciais;
    objetoEnvolvido.idTemporario =
    this.envolvidos.reduce(
      (maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0),
      0
    ) + 1;
    this.envolvidos.push(objetoEnvolvido);
    this.spinnerService.hide();
  }

  public RemoverValidacoesModal() {
    this.validaBuscaRA = false;
    if (this.formModal != undefined)
    {
      this.formModal.get('codigoDiretoriaModal')?.removeValidators([Validators.required]);
      this.formModal.get('codigoEscolaModal')?.removeValidators([Validators.required]);
      this.formModal.get('raNomeAluno')?.removeValidators([Validators.required]);
      this.formModal.get('cpfFuncionario')?.removeValidators([Validators.required]);
      this.formModal.get('dsOutros')?.removeValidators([Validators.required]);
      this.formModal.get('raAlunoModalDrop')?.removeValidators([Validators.required]);
      this.formModal.get('cpfFuncionarioModalDrop')?.removeValidators([Validators.required]);

      this.formModal.get('codigoDiretoriaModal')?.updateValueAndValidity();
      this.formModal.get('codigoEscolaModal')?.updateValueAndValidity();
      this.formModal.get('raNomeAluno')?.updateValueAndValidity();
      this.formModal.get('cpfFuncionario')?.updateValueAndValidity();
      this.formModal.get('dsOutros')?.updateValueAndValidity();
      this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
      this.formModal.get('cpfFuncionarioModalDrop')?.updateValueAndValidity();
    }
  }

  public AtualizarValidacoesAlunos() {
    this.formModal.get('codigoDiretoriaModal')?.updateValueAndValidity();
    this.formModal.get('codigoEscolaModal')?.updateValueAndValidity();
    this.formModal.get('raNomeAluno')?.updateValueAndValidity();
    this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
  }

  public async GetFuncionariosPorCpf() {
    const cpf = this.formModal.get('cpfFuncionario')?.value;
    if(!this.validarCPF(cpf)){
      this.toastr.warning('CPF invalido.');
      this.spinnerService.hide();
      return;
    }
    this.showDropAuxiliar = false;
    this.spinnerService.show();
    const getCPF = this.formModal.get('cpfFuncionario');
    const result = await lastValueFrom(await this.funcionarioService.getFuncionariosPorCpf(getCPF?.value.replace(".","").replace("-","")))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    if (result.data != null) {
      this.funcionarios = result.data;
    }
    if (this.funcionarios.length == 0) {
      this.toastr.warning('Nenhum funcionario encontrado!');
    } else {
      if (this.funcionarios.length == 1) {
        this.formModal
          .get('cpfFuncionarioModalDrop')
          ?.patchValue(this.funcionarios[0].cpf);
      }
    }
    this.showDropAuxiliar = true;
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunos(response: DefaultResponse) {
    if (response.data != null) {
      this.alunos = response.data;
    }
    if (this.alunos.length == 0) {
      this.toastr.warning('Nenhum aluno encontrado!');
    }
    if (this.alunos.length == 1) {
      this.formModal.get('raAlunoModalDrop')?.patchValue(this.alunos[0].ra);
    }
    if (this.alunos.length > 1) {
      this.toastr.success('Alunos carregados com sucesso. Por favor selecione um.');
    }
    this.showDropAuxiliar = true;
  }

  private RetornoBuscaAlunoPorRA(response: DefaultResponse) {
    if (response.data != null) {
      this.aluno = response.data;
      this.formModal.get('raAlunoModalDrop')?.patchValue(this.aluno.ra);
      this.alunos.push(this.aluno);
      this.showDropAuxiliar = true;
      this.toastr.success('Aluno carregado com sucesso.');
      return;
    }
    this.toastr.warning('Nenhum aluno encontrado!');
  }

  public ExluirEnvolvido(IdTemporario?: number) {
    const indiceParaRemover = this.envolvidos.findIndex(
      (envolvido) => envolvido.idTemporario === IdTemporario
    );
    this.envolvidos.splice(indiceParaRemover, 1);
    this.toastr.success(
      'Envolvido removido com sucesso!'
    );

    this.FecharModalExclusao();
  }

  public OnExcluirEnvolvido(IdTemporario: number) {
    this.showModalExcluirAluno = true;
    this.envolvidoExclusao = this.envolvidos.filter(
      (envolvido) => envolvido.idTemporario === IdTemporario
    )[0];
    this.descricaoExclusao = `Deseja remover o Envolvido ${this.envolvidoExclusao.nome == undefined  ? "Outros" : this.envolvidoExclusao.nome} ?`;
  }

  public FecharModalExclusao() {
    this.showModalExcluirAluno = false;
    this.descricaoExclusao = "";
    this.envolvidoExclusao = undefined;
  }
  validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    return remainder === parseInt(cpf.substring(10, 11));
  }
  OnChangeCountLetters(){
    const descricao = this.formCadastrar.get('dsChamado')!.value;
    if(descricao != null)
      this.qtdeCaracteresDescricao = descricao.length;
  }
  private async getPermissaoCadastrarOcorrencia(){
    const objetoEnvio = {
      cdDiretoria:this.userLogado?.codigoDiretoria,
      cdEscola:this.userLogado?.codigoEscola
    };

    var response = await lastValueFrom(this.naoOcorrenciaService.getPermissaoGravarNaoOcorrencia(objetoEnvio));
    if(!response.isSucess){
      this.toastr.error("Houve um erro na solicitação.");
    }
    this.permissaoParaCadastrarNaoOcorrencia = response.data.sucesso;

  }
  private async buscaNaoOcorrenciaDiaria(){
    var parametros = {
       codigoDiretoria: this.userLogado?.codigoDiretoria,
       codigoEscola:this.userLogado?.codigoEscola
    };
    var response = await lastValueFrom(this.naoOcorrenciaService.getExisteNaoOcorrenciaHoje(parametros)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message, response.title);
      this.spinnerService.hide();
      return;
    }
    this.NaoOcorrenciaCadastradaHoje = response.data.sucesso;

  }

}
