import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { Chamado } from "../models/chamados/chamado";
import { ListarChamados } from "../models/chamados/listar-chamados";
import { HistoricoChamado } from "../models/chamados/historico";
import { ListarReincidentes } from "../models/chamados/listar-reincidentes";
import { Router } from "@angular/router";
import { NaoOcorrencia } from "../models/nao-ocorrencia/NaoOcorrencia";
import { ListarNaoOcorrencias } from "../models/nao-ocorrencia/ListarNaoOcorrencia";

@Injectable({
  providedIn: 'root'
})
export class NaoOcorrenciaService{

  private api = ConvivaApi;

  constructor(
    private http: HttpClient,
    private router:Router)
  { }

  public setCadastrarNaoOcorrencia(parametros: NaoOcorrencia):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.NaoOcorrencia.setCadastrarNaoOcorrencia}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getPermissaoGravarNaoOcorrencia(parametros: any):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.NaoOcorrencia.getPermissaoGravarNaoOcorrencia}`, parametros)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getNaoOcorrencias(parametros:ListarChamados){
    return this.http.post<DefaultResponse>(`${this.api.NaoOcorrencia.getNaoOcorrencias}`, parametros)
    .pipe(map((response: DefaultResponse) => response));
  }
  public getDashboardNaoOcorrencias(parametros:ListarChamados){
    return this.http.post<DefaultResponse>(`${this.api.Dashboard.getDashboardNaoOcorrencia}`, parametros)
    .pipe(map((response: DefaultResponse) => response));
  }
  public getExisteNaoOcorrenciaHoje(parametros:any){
    return this.http.post<DefaultResponse>(`${this.api.NaoOcorrencia.getExisteNaoOcorrenciaHoje}`, parametros)
    .pipe(map((response: DefaultResponse) => response));
  }

  public setDesativaNaoOcorrencia(parametros:any){
    return this.http.post<DefaultResponse>(`${this.api.NaoOcorrencia.setDesativarNaoOcorrencia}`, parametros)
    .pipe(map((response: DefaultResponse) => response));
  }
}
