  <section class="section-calendario">
    <div class="animacao-calendario" *ngIf="isLoading">
      <div class="loader bolinhas">
        <div class="bolinha dot1"></div>
        <div class="bolinha dot2"></div>
        <div class="bolinha dot3"></div>
      </div>
      <p>Carregando Calendário...</p>
    </div>

    <div class="container" *ngIf="!isLoading">

      <div class="header-calendario">
        <button (click)="goToPreviousMonth()" class="icone voltar-mes ">Mês Anterior</button>
        <h2>{{ currentDate | date: 'MMMM yyyy':" ":"pt" }}</h2>
        <button (click)="goToCurrentMonth()" class="icone avancar-mes ">Mês Atual</button>
      </div>

      <div class="dias">
        <div *ngFor="let dia of listaDias"
            class="dia"
            [ngClass]="{
                        'indisponivel': dia.estatusDia == 5,
                        'dia-sem-ocorrencia': dia.estatusDia == 2,
                        'dia-com-ocorrencia': dia.estatusDia == 3,
                        'dia-sem-marcacao': dia.estatusDia == 4,
                        'dia-atual': dia.estatusDia == 1
                      }"
            (click)="OnInserirOcorrenciaNaoOcorrencia(dia.data, dia.estatusDia)"
            >

          <p class="dia-semana">{{ dia.diaDaSemana }}</p>
          <p class="numero">{{ dia.data| date: 'dd' }}</p>
        </div>
      </div>

      <div class="container legenda-calendario">
        <p><b>Legenda: </b> Calendário</p>
        <div class="conteudo-legenda">

          <div class="box-legenda dia-atual">
            <i class="icone-dot"></i> <p>Dia Atual</p>
          </div>
          <div class="box-legenda dia-sem-ocorrencia">
            <i class="icone-dot"></i> <p>Dia sem ocorrência</p>
          </div>
          <div class="box-legenda dia-com-ocorrencia">
            <i class="icone-dot"></i> <p>dia com ocorrência</p>
          </div>
          <div class="box-legenda dia-sem-marcacao">
            <i class="icone-dot"></i> <p>dia sem marcação</p>
          </div>
          <div class="box-legenda indisponivel">
            <i class="icone-dot"></i> <p>indisponível</p>
          </div>

        </div>
      </div>

    </div>



  </section>



  <app-modal *ngIf="showModalCalendarioOcorrencia" class="modal-calendario">
    <div class="container-conviva form-registro modal-envolvidos">
      <div class="header-modal-calendario">
        <h1 class="titulo-page">Deseja Inserir Ocorrencia?</h1>
        <a class="btn-fechar-modal" (click)="FecharModalCalendario()" >fechar</a>
      </div>
      <div class="container-btns">
        <input type="button" (click)="InserirOcorrencia()" class="btn_primary" value="Cadastrar Ocorrencia"/>
        <input type="button" (click)="InserirNaoOcorrencia()" class="btn_outline" value="Cadastrar Não Ocorrencia" />
      </div>
    </div>
  </app-modal>

  <app-modal *ngIf="showModalCadastraNaoOcorrencia" class="modal-ata-calendario">
    <form [formGroup]="formHomeNaoOcorrencia">
      <div class="container-conviva form-registro modal-envolvidos">
        <div class="header-modal-ata">
          <h1 class="titulo-page">Não Ocorrencia</h1>
          <a class="btn-fechar-modal" (click)="FecharModalCadastroNaoOcorrencia()" >fechar</a>
        </div>
        <div class="descricao-modal-excluir">
          <span><strong>Dia: </strong>{{this.dataOcorrenciaNaoOcorrencia | date : 'dd-MM-yyyy'}}</span>
        </div>

        <div class="conteudo-textarea">
          <label for="text-registro">Faça uma breve descrição de como foi o dia escolar.</label>
          <textarea
            id="text-registro"
            name="text-registro"
            rows="6"
            cols="50"
            formControlName="dsChamado"
            name="dsChamado"
            minlength="50"
            (keyup)="OnChangeCountLetters()"
            [ngClass]="{ 'is-invalid': submitted && formNaoOco.dsChamado.errors }"
          ></textarea>
          <p class="legenda-textarea">Quantidade de caracteres atual: {{qtdeCaracteresDescricao}}</p>
        </div>

        <div class="container-btns">
          <input type="button" (click)="InserirRegistroNaoOcorrencia()" class="btn_primary" value="Salvar" />
          <input type="button" (click)="FecharModalCadastroNaoOcorrencia()" class="btn_outline" value="Cancelar"/>
        </div>
      </div>

    </form>
  </app-modal>
