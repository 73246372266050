//Principais
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from "rxjs";
//Services
import { AlunoService } from 'src/app/shared/services/alunos.service';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { CategoriaService } from 'src/app/shared/services/categoria.service';
import { ChamadoService } from 'src/app/shared/services/chamado.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { EscolaService } from 'src/app/shared/services/escola.service';
import { FuncionarioService } from 'src/app/shared/services/funcionarios.service';
import { GenericValidator } from 'src/app/shared/validations/genericValidators';
//Models
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { Categoria } from 'src/app/shared/models/categoria/Categoria';
import { Chamado } from 'src/app/shared/models/chamados/chamado';
import { detalhesChamado } from 'src/app/shared/models/chamados/detalhesChamado';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { DefaultResponse } from 'src/app/shared/models/DefaultResponse';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Aluno } from 'src/app/shared/models/envolvidos/aluno';
import { Funcionario } from 'src/app/shared/models/envolvidos/funcionario';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Turno } from 'src/app/shared/models/escola/Turno';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';

@Component({
  templateUrl: './chamado-edit.component.html',
  styleUrls: ['./chamado-edit.component.scss'],
})
export class EditarChamadoComponent implements OnInit {
  idChamado: number;
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  public qtdeCaracteresDescricao:number = 0;

  constructor(
    private escolaService: EscolaService,
    private categoriaService: CategoriaService,
    private arquivoService: ArquivoService,
    private config: NgSelectConfig,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private alunoService: AlunoService,
    private genericValidate: GenericValidator,
    private chamadoService: ChamadoService,
    private funcionarioService: FuncionarioService,
    private location: Location,
    private authService: AuthService,
  ) {
    this.config.notFoundText = 'Não encontrado';

    this.route.paramMap.subscribe((params) => {
      this.idChamado = Number(params.get('id')) ?? 0;
    });

    this.userLogado = this.authService.getUser();

    if(this.userLogado != null){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  @ViewChild('modalContent') modalContent!: TemplateRef<any>;

  public showModal = false;
  public showBuscaAluno = false;
  public showBuscaEquipe = false;
  public showOutros = false;
  public validaBuscaRA = false;
  public submitted = false;
  public submittedModal = false;
  public showDropAuxiliar = false;
  public showModalExcluirAluno = false;

  public tituloModal = '';
  public descricaoExclusao = '';
  public envolvidoExclusao?: Envolvido;

  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public escolasModal: Escola[] = [];
  public categorias: Categoria[] = [];
  public files: ArquivoAzure[] = [];
  public envolvidos: Envolvido[] = [];
  public alunos: Aluno[] = [];
  public aluno: Aluno = <Aluno>{};
  public chamado: detalhesChamado;

  public funcionarios: Funcionario[] = [];

  public gravidades = [
    { id: 1, name: 'Amarelo' },
    { id: 2, name: 'Vermelho' },
  ];
  public TipoPessoas = [
    { id: 1, name: 'Aluno' },
    { id: 2, name: 'Equipe Escolar' },
    { id: 3, name: 'Outros' },
  ];

  public TipoEnvolvido = [
    { id: 1, name: 'Ofendido' },
    { id: 2, name: 'Ofensor' }
  ];

  public TipoOutros = [
    { id: "1", name: 'Não Identificado' },
    { id: "2", name: 'Responsável pelo Estudante' }
  ];

  public formEditar!: FormGroup;
  public formModal!: FormGroup;

  // FORM CADASTRAR
  public get form() {
    return this.formEditar.controls;
  }
  // FORM MODAL
  public get modalForm() {
    return this.formModal.controls;
  }

  // DADOS GERAIS
  public get codigoDiretoria() {
    return this.formEditar.get('codigoDiretoria')!;
  }
  public get codigoEscola() {
    return this.formEditar.get('codigoEscola')!;
  }
  public get codigoTurno() {
    return this.formEditar.get('codigoTurno')!;
  }
  public get codigoGravidade() {
    return this.formEditar.get('codigoGravidade')!;
  }
  public get dataChamado() {
    return this.formEditar.get('dataChamado')!;
  }
  public get codigoSubCategoria() {
    return this.formEditar.get('codigoSubCategoria')!;
  }
  public get dsChamado() {
    return this.formEditar.get('dsChamado')!;
  }
  public get arquivos() {
    return this.formEditar.get('arquivos')!;
  }
  public get codigoDiretoriaModal() {
    return this.formModal.get('codigoDiretoriaModal')!;
  }
  public get codigoEscolaModal() {
    return this.formModal.get('codigoEscolaModal')!;
  }

  public ngOnInit() {
    this.formEditar = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      codigoTurno: new FormControl(null, [Validators.required]),
      dataChamado: new FormControl(null, [Validators.required]),
      codigoGravidade: new FormControl(null, [Validators.required]),
      codigoSubCategoria: new FormControl(null, [Validators.required]),
      dsChamado: new FormControl(null, [
        Validators.required,
        Validators.minLength(50),
        Validators.maxLength(500),
      ]),
      arquivos: new FormControl(null),
    });

    this.submittedModal = false;

    this.formModal = new FormGroup({
      tipoPessoa: new FormControl(null, [Validators.required]),
      raNomeAluno: new FormControl(null, [Validators.required]),
      cpfFuncionario: new FormControl(null, [
        Validators.required,
        this.genericValidate.ValidaCpf,
      ]),
      dsOutros: new FormControl(null, [Validators.required]),
      codigoDiretoriaModal: new FormControl(null, [Validators.required]),
      codigoEscolaModal: new FormControl(null, [Validators.required]),
      raAlunoModalDrop: new FormControl(null, [Validators.required]),
      cpfFuncionarioModalDrop: new FormControl(null, [Validators.required]),
    });

    if(this.atribuicoesLogin.NumeroComportamento == 1){
      this.getDiretorias();
    }

    if(this.atribuicoesLogin.NumeroComportamento == 2){
      this.getDiretoriasPerfilDiretoria();
    }

    if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
      this.getDiretoriasPerfilEscola();
    }
    this.getCategorias();
    this.PopularDados();
    this.form.codigoGravidade.disable();
  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();

      this.getTurnos();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];
    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria != undefined)
      this.formEditar.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async getGravidade() {
    this.spinnerService.show();
    const codigoSC = this.formEditar.get('codigoSubCategoria');
    if (codigoSC?.value != null) {
      const result = await lastValueFrom(await this.categoriaService.getGravidade(codigoSC?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.formEditar.get('codigoGravidade')?.setValue(result.data[0].codigoGravidade);
      this.form.codigoGravidade.disable();
      this.spinnerService.hide();
    }else{
      this.form.codigoGravidade.disable();
      this.formEditar.get('codigoGravidade')?.setValue(0);
      this.spinnerService.hide();
    }
  }


  removeUniqueId(fileName: string): string {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/;
    return fileName.replace(regex, '');
  }

  public async getDiretorias() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.escolaService.getDiretoriaPorTipoDiretoria())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.diretorias = result.data;
    this.spinnerService.hide();
  }

  public async getCategorias() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.categoriaService.getCategoriasWithSub())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.categorias = result.data;
    this.spinnerService.hide();
  }

  public async getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formEditar.get('codigoDiretoria')?.value;
    if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2){
      this.zerarEscola();
      if (codigoDE != null) {
        this.spinnerService.show();
        const result = await lastValueFrom(await this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!result.isSucess){
          this.toastr.error(result.message, result.title);
          this.spinnerService.hide();
          return;
        }
        this.escolas = result.data;
        this.spinnerService.hide();
        this.ativaEscola();
      }
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public ativaEscola(){
    if(this.userLogado?.codigoEscola != undefined)
      this.formEditar.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
    if(this.userLogado?.codigoDiretoria != undefined && this.userLogado?.codigoEscola != undefined)
      this.getTurnos();
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async getEscolasModal() {
    this.spinnerService.show();
    this.zerarEscolaModal();
    const codigoDE = this.formModal.get('codigoDiretoriaModal');
    if (codigoDE?.value != null) {
      const result = await lastValueFrom(await this.escolaService.getEscolasPorCodigoDiretoria(codigoDE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.escolasModal = result.data;
      this.spinnerService.hide();
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formEditar.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public zerarEscolaModal() {
    this.escolasModal = [];
    this.formModal.get('codigoEscolaModal')?.patchValue(null);
  }

  public async getTurnos() {
    const codigoEE = this.formEditar.get('codigoEscola');
    this.zerarTurnos();

    if (codigoEE?.value != null) {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.escolaService.getTurnosPorCodigoCie(codigoEE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.turnos = result.data;
      if (this.turnos == null)
        this.toastr.warning(
          'Não foi encontrados turnos para essa escola !'
        );
      this.spinnerService.hide();
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formEditar.get('codigoTurno')?.patchValue(null);
  }

  public async getAlunosPorNome() {
    const nmAluno = this.formModal.get('raNomeAluno');
    const codigoDiretoria = this.formModal.get('codigoDiretoriaModal');
    const codigoEscola = this.formModal.get('codigoEscolaModal');
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const result = await lastValueFrom(await this.alunoService.getAlunosPorNome(nmAluno?.value, codigoDiretoria?.value, codigoEscola?.value))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunos(result);
    this.spinnerService.hide();
  }

  public async getAlunosPorRa() {
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const raAluno = this.formModal.get('raNomeAluno');
    const result = await lastValueFrom(await this.alunoService.getAlunosPorRa(raAluno?.value))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunoPorRA(result);
    this.toastr.success('Aluno carregado com sucesso.');
    this.spinnerService.hide();
  }

  public async OnFileChange(event: any) {
    const maxFileSize =  31 * 1024 * 1024; // 30 MB
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      for (const file of files) {
        if (file.size > maxFileSize) {
          this.toastr.warning('O tamanho máximo do arquivo é 30 MB.');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
        if(file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png" ||
          file.type ==  "application/pdf" ||
          file.type ==  "application/ppt" ||
          file.type ==  "application/pptx" ||
          file.type ==  "application/potx" ||
          file.type ==  "application/ppsx" ||
          file.type ==  "application/odp" ||
          file.type == "audio/mpeg" ||
          file.type == "video/mp4" ){
          formData.append(file.name, file);
        }
        else{
          this.toastr.warning('Só é possivel subir arquivos .jpg, .jpeg , .png, .pdf, .mp3 , .mp4, .ppt, .pptx, .potx e ppsx');
          this.form.arquivos.setValue(null);
          this.spinnerService.hide();
          return;
        }
      }
      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.files.push(...result.data);
      this.spinnerService.hide();
    }
  }

  public OnTipoPessoaChange(event: any) {
    this.RemoverValidacoesModal();
    this.submittedModal = false;
    this.validaBuscaRA = false;
    this.showDropAuxiliar = false;
    if (event == undefined) {
      this.showBuscaAluno = false;
      this.showBuscaEquipe = false;
      this.showOutros = false;
      return;
    }
    const tipoPessoa = event.id;
    if (tipoPessoa == 1) {
      this.formModal.get('raNomeAluno')?.addValidators([Validators.required]);
      this.formModal
        .get('raAlunoModalDrop')
        ?.addValidators([Validators.required]);
      this.showBuscaAluno = true;
    } else {
      this.showBuscaAluno = false;
    }
    if (tipoPessoa == 2) {
      this.formModal
        .get('cpfFuncionario')
        ?.addValidators([Validators.required]);
      this.formModal
        .get('cpfFuncionarioModalDrop')
        ?.addValidators([Validators.required]);
      this.showBuscaEquipe = true;
    } else {
      this.showBuscaEquipe = false;
    }
    if (tipoPessoa == 3) {
      this.formModal.get('dsOutros')?.addValidators([Validators.required]);
      this.showOutros = true;
    } else {
      this.showOutros = false;
    }

    this.formModal.get('raNomeAluno')?.updateValueAndValidity();
    this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
    this.formModal.get('cpfFuncionario')?.updateValueAndValidity();
    this.formModal.get('cpfFuncionarioModalDrop')?.updateValueAndValidity();
    this.formModal.get('dsOutros')?.updateValueAndValidity();
  }

  public OnLimpar() {
    this.files = [];
    this.envolvidos = [];
  }

  public async OnSalvar() {
    this.submitted = true;
    if(this.formEditar.get('dataChamado')?.value == '') {
      this.toastr.error('Informe uma data e hora.');
      return;
    }
    this.RemoverValidacoesModal();
    if (this.formEditar.invalid) {
      return;
    }
    if(this.envolvidos != null) {
      if (this.envolvidos.length == 0) {
        this.toastr.error('É obrigatório incluir pelo menos um envolvido.');
        return;
      }
    }
    this.spinnerService.show();
    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.formEditar.get('codigoDiretoria')?.value)[0];
    const esc = this.escolas.filter(r => r.codigoEscola == this.formEditar.get('codigoEscola')?.value)[0];
    const objetoChamado: Chamado = {
      cdChamado: this.chamado.cdChamado,
      cdGravidade: this.formEditar.get('codigoGravidade')?.value,
      cdCategoria: this.formEditar.get('codigoSubCategoria')?.value,
      cdSubCategoria: this.formEditar.get('codigoSubCategoria')?.value,
      dtChamado: this.formEditar.get('dataChamado')?.value,
      cdDiretoria: dir.codigoDiretoria,
      nmDiretoria: dir.nomeDiretoria,
      cdEscola: esc.codigoEscola,
      nmEscola: esc.nomeEscola,
      dsChamado: this.formEditar.get('dsChamado')?.value,
      cdTurno: this.formEditar.get('codigoTurno')?.value,
      outros: this.formEditar.get('outros')?.value,
      codigoUsuario: this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      arquivos: this.files,
      status:this.chamado.status,
      envolvidos: [],
    };

    var dataAtual: Date = new Date();
    var dataSelecionada: Date = new Date(objetoChamado.dtChamado);
    if(dataSelecionada > dataAtual)
    {
      this.toastr.error('A data da ocorrência não pode ser maior que a data de hoje.');
      this.spinnerService.hide();
      return;
    }
    if(this.envolvidos.length > 0)
    objetoChamado.envolvidos.push(...this.envolvidos);

    const categoria = this.categorias.filter(
      (r) => r.codigoSubCategoria == objetoChamado.cdSubCategoria
    );
    objetoChamado.cdCategoria =
      categoria.length > 0 ? categoria[0].codigoCategoria : 0;
    const result = await lastValueFrom(await this.chamadoService.setEditarChamado(objetoChamado))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success('Chamado alterado com sucesso.');
    setTimeout(() => {
      this.spinnerService.hide();
      this.submitted = false;
      this.GoBack();
    }, 2000);
  }

  public AbrirModal() {
    this.submittedModal = false;

    this.formModal = new FormGroup({
      tipoPessoa: new FormControl(null, [Validators.required]),
      tipoEnvolvido: new FormControl(null),
      raNomeAluno: new FormControl(null, [Validators.required]),
      cpfFuncionario: new FormControl(null, [
        Validators.required,
        this.genericValidate.ValidaCpf,
      ]),
      dsOutros: new FormControl(null, [Validators.required]),
      codigoDiretoriaModal: new FormControl(null, [Validators.required]),
      codigoEscolaModal: new FormControl(null, [Validators.required]),
      raAlunoModalDrop: new FormControl(null, [Validators.required]),
      cpfFuncionarioModalDrop: new FormControl(null, [Validators.required]),
    });

    this.showModal = true;
    this.tituloModal = 'Dados do envolvido';
  }

  public FecharModal() {
    this.showModal = false;
    this.showBuscaAluno = false;
    this.showBuscaEquipe = false;
    this.showOutros = false;
  }

  public BuscarAlunoPorNomeRA() {
    const regexNumeros = /\d$/;
    const regexLetras = /^[a-zA-Z]+$/;
    const regexAmbos = /^[a-zA-Z0-9]+$/;

    this.formModal.get('raAlunoModalDrop')?.patchValue(null);
    this.formModal
      .get('raAlunoModalDrop')
      ?.removeValidators([Validators.required]);
    this.validaBuscaRA = false;
    let nomeRa = this.formModal.get('raNomeAluno')?.value;
    let buscarPorRa = false;

    if (regexNumeros.test(nomeRa)) {
      this.formModal.get('codigoDiretoriaModal')?.clearAsyncValidators();
      this.formModal.get('codigoEscolaModal')?.clearAsyncValidators();
      buscarPorRa = true;
    } else if (regexLetras.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.formModal
        .get('codigoDiretoriaModal')
        ?.addValidators([Validators.required]);
      this.formModal
        .get('codigoEscolaModal')
        ?.addValidators([Validators.required]);
    } else if (regexAmbos.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.formModal.get('raNomeAluno')?.setValue(nomeRa.replace(/\d/g, ''));
    }
    this.AtualizarValidacoesAlunos();

    if (this.formModal.invalid) {
      return;
    }

    if (buscarPorRa) {
      this.getAlunosPorRa();
    } else {
      this.getAlunosPorNome();
    }

    this.formModal
      .get('raAlunoModalDrop')
      ?.addValidators([Validators.required]);
    this.AtualizarValidacoesAlunos();
  }

  public OnAdicionarEnvolvido() {
    this.submittedModal = true;

    if (this.formModal.invalid) {
      return;
    }
    let tipoEnvolvido: number;
    if(this.formModal.get('tipoEnvolvido')?.value == undefined)
      tipoEnvolvido = 0;
    else
      tipoEnvolvido = this.formModal.get('tipoEnvolvido')?.value;

    const objetoEnvolvido: Envolvido = {
      tipoPessoa: this.formModal.get('tipoPessoa')?.value,
      tipoEnvolvido: tipoEnvolvido,
      cpf: this.formModal.get('cpfFuncionarioModalDrop')?.value,
      ra: this.formModal.get('raAlunoModalDrop')?.value,
      dsOutros: this.formModal.get('dsOutros')?.value == 1 ? "Não Identificado" : "Responsável pelo Estudante",
      escola: this.formModal.get('codigoEscolaModal')?.value
    };

    if (objetoEnvolvido.tipoPessoa == 1) {
      const envolvidos = this.envolvidos.filter(r => r.ra == objetoEnvolvido.ra && r.tipoEnvolvido == objetoEnvolvido.tipoEnvolvido);
      if(envolvidos.length > 0){
        this.toastr.warning("Está aluno já foi adicionado como Envolvido.");
        return;
      }

      const al = this.alunos.find((aluno) => aluno.ra === objetoEnvolvido.ra);
      const nomeAlunoSplit = al?.nomeAluno?.split(' ');

      if(nomeAlunoSplit != null)
        objetoEnvolvido.nome = nomeAlunoSplit?.length > 2 ? nomeAlunoSplit[0] + " " + nomeAlunoSplit[1] : nomeAlunoSplit[0];
      else{
        this.toastr.warning("Deverá ser selecionado o aluno ofendido/ofensor.");
        return;
      }

      const iniciais =  nomeAlunoSplit
                                  .filter(parte => parte.length > 3)
                                  .map(parte => parte.charAt(0))
                                  .slice(0, 2)
                                  .join('');

      objetoEnvolvido.imagemTemporaria = iniciais;
      objetoEnvolvido.codigoTurma = al?.codigoTurma;
      objetoEnvolvido.codigoAluno = al?.codigoAluno;
    }

    if (objetoEnvolvido.tipoPessoa == 2) {
      const nomeFuncionario = this.funcionarios.find((fun) => fun.cpf === objetoEnvolvido.cpf)?.nome

      const nomeFuncSplit = nomeFuncionario?.split(' ');

      if(nomeFuncSplit != null)
        objetoEnvolvido.nome = nomeFuncSplit?.length > 2 ? nomeFuncSplit[0] + " " + nomeFuncSplit[1] : nomeFuncSplit[0];

      const iniciais =  nomeFuncionario?.split(' ')
                                        .filter(parte => parte.length > 3)
                                        .map(parte => parte.charAt(0))
                                        .slice(0, 2)
                                        .join('');
      objetoEnvolvido.imagemTemporaria = iniciais;
    }

    if (objetoEnvolvido.tipoPessoa == 3) {
      objetoEnvolvido.imagemTemporaria = 'X';
    }

    objetoEnvolvido.idTemporario = this.envolvidos.reduce((maxId, envolvido) => Math.max(maxId, envolvido.idTemporario || 0), 0) + 1;
    this.envolvidos.push(objetoEnvolvido);

    this.toastr.success(`${this.tituloModal} inseridos com sucesso, Salvar o chamado`);
    this.FecharModal();
  }

  public RemoverValidacoesModal() {
    this.validaBuscaRA = false;
    this.formModal
      .get('codigoDiretoriaModal')
      ?.removeValidators([Validators.required]);
    this.formModal
      .get('codigoEscolaModal')
      ?.removeValidators([Validators.required]);
    this.formModal.get('raNomeAluno')?.removeValidators([Validators.required]);
    this.formModal
      .get('cpfFuncionario')
      ?.removeValidators([Validators.required]);
    this.formModal.get('dsOutros')?.removeValidators([Validators.required]);
    this.formModal
      .get('raAlunoModalDrop')
      ?.removeValidators([Validators.required]);
    this.formModal
      .get('cpfFuncionarioModalDrop')
      ?.removeValidators([Validators.required]);

    this.formModal.get('codigoDiretoriaModal')?.updateValueAndValidity();
    this.formModal.get('codigoEscolaModal')?.updateValueAndValidity();
    this.formModal.get('raNomeAluno')?.updateValueAndValidity();
    this.formModal.get('cpfFuncionario')?.updateValueAndValidity();
    this.formModal.get('dsOutros')?.updateValueAndValidity();
    this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
    this.formModal.get('cpfFuncionarioModalDrop')?.updateValueAndValidity();
  }

  public AtualizarValidacoesAlunos() {
    this.formModal.get('codigoDiretoriaModal')?.updateValueAndValidity();
    this.formModal.get('codigoEscolaModal')?.updateValueAndValidity();
    this.formModal.get('raNomeAluno')?.updateValueAndValidity();
    this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
  }

  public async GetFuncionariosPorCpf() {
    const cpf = this.formModal.get('cpfFuncionario')?.value;
    if(!this.validarCPF(cpf)){
      this.toastr.warning('CPF invalido.');
      this.spinnerService.hide();
      return;
    }

    this.showDropAuxiliar = false;
    this.spinnerService.show();
    const getCPF = this.formModal.get('cpfFuncionario');
    const result = await lastValueFrom(await this.funcionarioService.getFuncionariosPorCpf(getCPF?.value.replace('.', '').replace('-', '')))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    if (result.data != null) {
      this.funcionarios = result.data;
    }
    if (this.funcionarios.length == 0) {
      this.toastr.warning('Nenhum funcionario encontrado!');
    } else {
      if (this.funcionarios.length == 1) {
        this.formModal
          .get('cpfFuncionarioModalDrop')
          ?.patchValue(this.funcionarios[0].cpf);
      }
    }
    this.showDropAuxiliar = true;
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunos(response: DefaultResponse) {
    if (response.data != null) {
      this.alunos = response.data;
    }
    if (this.alunos.length == 0) {
      this.toastr.warning('Nenhum aluno encontrado!');
    } else {
      if (this.alunos.length == 1) {
        this.formModal.get('raAlunoModalDrop')?.patchValue(this.alunos[0].ra);
      }
      else {
        this.toastr.success('Alunos carregados com sucesso. Por favor selecione um.');
      }
    }
    this.showDropAuxiliar = true;
  }

  private RetornoBuscaAlunoPorRA(response: DefaultResponse) {
    if (response.data != null) {
      this.aluno = response.data;
      this.formModal.get('raAlunoModalDrop')?.patchValue(this.aluno.ra);
      this.alunos.push(this.aluno);
      this.showDropAuxiliar = true;
      return;
    }
    this.toastr.warning('Nenhum aluno encontrado!');
  }

  public ExluirEnvolvido(tipoEnvolvido?: number, IdTemporario?: number) {
    const indiceParaRemover = this.envolvidos.findIndex(
      (envolvido) => envolvido.idTemporario === IdTemporario
    );
    this.envolvidos.splice(indiceParaRemover, 1);
    this.toastr.success(
      'Envolvido removido com sucesso!, Salvar o chamado'
    );
    this.FecharModalExclusao();
  }

  public OnExcluirEnvolvido(IdTemporario: number, statusEnvolvido: number) {
    if(statusEnvolvido !== 0){
      this.toastr.warning('Envolvido não pode ser excluido!');
      return;
    }
    else{
      this.showModalExcluirAluno = true;
      this.envolvidoExclusao = this.envolvidos.filter(
        (envolvido) => envolvido.idTemporario === IdTemporario
      )[0];
      this.descricaoExclusao = `Deseja remover o Envolvido ${this.envolvidoExclusao.nome == undefined  ? "Outros" : this.envolvidoExclusao.nome} ?`;
    }
  }

  public FecharModalExclusao() {
    this.showModalExcluirAluno = false;
    this.descricaoExclusao = '';
    this.envolvidoExclusao = undefined;
  }

  public async PopularDados() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.chamadoService.getChamadoPorId(this.idChamado))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.chamado = result.data;
    this.formEditar.get('codigoDiretoria')?.setValue(this.chamado.cdDiretoria);
    await this.getEscolas();
    this.formEditar.get('codigoEscola')?.setValue(this.chamado.cdEscola);
    await this.getTurnos();
    this.formEditar.get('codigoTurno')?.setValue(this.chamado.cdTurno);
    this.formEditar.get('dataChamado')?.setValue(this.chamado.dtChamado);
    this.formEditar.get('codigoGravidade')?.setValue(this.chamado.cdGravidade);
    this.formEditar.get('codigoSubCategoria')?.setValue(this.chamado.cdSubCategoria);
    this.formEditar.get('dsChamado')?.setValue(this.chamado.dsOcorrencia);

    for (let index = 0; index < this.chamado.images.length; index++) {
      let arquivodata: ArquivoAzure = {
        nome: this.chamado.images[index].nomeArquivo!,
        ehBase64: true,
        caminhoAzure:this.chamado.images[index].caminho_Arquivo!,
        conteudo: this.chamado.images[index].conteudoBase64!
      };
      this.files.push(arquivodata);
    }

    this.envolvidos = this.chamado.envolvidos
    let currentId = 1;
    this.envolvidos.forEach(pessoa => {
      pessoa.idTemporario = currentId++;
      pessoa.imagemTemporaria = pessoa.iniciais;
    });
    this.OnChangeCountLetters();
    this.spinnerService.hide();
  }

  GoBack(): void {
    this.location.back();
  }

  validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    return remainder === parseInt(cpf.substring(10, 11));
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
    const fileInput = document.getElementById('arquivos') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
  public OnChangeCountLetters(){
    const descricao = this.formEditar.get('dsChamado')!.value;
    if(descricao != null)
      this.qtdeCaracteresDescricao = descricao.length;
  }

}
