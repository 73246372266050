<form [formGroup]="formDashboard" (submit)="PesquisarAtendimentos()">
  <breadcrumb
    [paginas]="[{route:'/home',name:'Home'},{route:'/atendimento-psicologo',name:'Atendimento Psicólogos'}]"
  />
  <section class="section-filtro" >
    <div class="container">

      <div class="grupo-filter">
        <p><b>Polo</b></p>
        <ng-select
          [items]="polos"
          bindLabel="descricaoPolo"
          bindValue="codigoPolo"
          placeholder="Selecione um Polo"
          name="codigoPolo"
          formControlName="codigoPolo"
          (change)="changePolo()"
          [ngClass]="{'is-invalid': submitted && form.codigoPolo.errors}"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          (change)="changeDiretoria()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
        <img
          src="../../../../assets/img/icons/icon-search.svg"
          alt="Pesquisar Psicólogos"
          title="Pesquisar Psicólogos"
        />
      </button>
    </div>
  </section>

  <section class="section-saudacao header-page-voltar">
    <div class="container">
      <h2 class="titulo-page">Atendimento Psicólogos</h2>
    </div>
  </section>

  <section class="conteudo-principal">

    <div class="container">
      <div class="conteudo-perfil" *ngIf="this.submitted && this.showPesquisa">

        <h3 class="sub-titulo-dash">
          Listagem de Atendimentos
        </h3>

        <div class="grid-datatable" >
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border" aria-describedby="dashboard">
            <thead>
              <tr>
                <th>Polo</th>
                <th>Diretoria</th>
                <th>CPF</th>
                <th>Nome Psicólogo</th>
                <th>Ocorrências Atendidas</th>
                <th>Ocorrências Não Atendidas</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstAtendimentos">
                <td>{{item.poloPsicologo}}</td>
                <td>{{item.diretoria}}</td>
                <td>{{item.cpf}}</td>
                <td>{{item.nomePsicologo}}</td>
                <td>
                  <a href="javascript:void(0);" (click)="AbreModalAtendimentoRealizado(item.cpf, item.codigoDiretoria)">
                    {{item.realizado}}
                  </a>
                </td>
                <td>
                  <a href="javascript:void(0);" (click)="AbreModalAtendimentoNaoRealizado(item.cpf, item.codigoDiretoria)">
                    {{item.naoRealizado}}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</form>

<app-modal *ngIf="showModalAtendimentoRealizado">
    <div class="header-modal">
      <h1 class="title-ocorrencia">Ocorrências atendidas</h1>
        <a (click)="FecharModalAtendimentoRealizado()"
        >
          <i class="fa-solid fa-x"></i>
        </a>
    </div>

    <section class="conteudo-principal" *ngIf="this.submitted && this.showPesquisa">

          <div class="grid-datatable-simple" >
            <table>
              <thead>
                <tr>
                  <th>Dentro do Prazo</th>
                  <th>Fora do Prazo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{lstRealizados != undefined ? lstRealizados.dentroDoPrazo : 0}}</td>
                  <td>{{lstRealizados != undefined ? lstRealizados.foraDoPrazo : 0 }}</td>
                </tr>
              </tbody>
            </table>
          </div>

  </section>
</app-modal>

<app-modal *ngIf="showModalAtendimentoNaoRealizado">
  <div class="header-modal">
    <h1 class="title-ocorrencia">Ocorrências não atendidas</h1>
      <a (click)="FecharModalAtendimentoNaoRealizado()"
      >
        <i class="fa-solid fa-x"></i>
      </a>
  </div>

  <section class="conteudo-principal" *ngIf="this.submitted && this.showPesquisa">

        <div class="grid-datatable-simple" >
          <table>
            <thead>
              <tr>
                <th>Dentro do Prazo (Até 24 hs)</th>
                <th>Fora do Prazo (+24 hs)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{lstRealizados != undefined ? lstRealizados.dentroDoPrazo : 0}}</td>
                <td>{{lstRealizados != undefined ? lstRealizados.foraDoPrazo : 0 }}</td>
              </tr>
            </tbody>
          </table>
        </div>

</section>
</app-modal>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
