import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class ModalService{

  private modalClickedSource = new Subject<void>();

  modalClicked$ = this.modalClickedSource.asObservable();

  public emitOutClickModal(): void {
    this.modalClickedSource.next();
  }
}
