<form [formGroup]="formManutencao" (submit)="OnBuscar()">

  <section class="section-filtro" >
        <div class="container">
            <div class="grupo-filter">
                        <label for="select-polo">Polo</label>
                        <ng-select
                          [items]="polos"
                          bindLabel="descricaoPolo"
                          bindValue="codigoPolo"
                          class="select2-single"
                          placeholder="Selecione um Polo"
                          formControlName="codigoPolo"
                          name="codigoPolo"
                          (change)="cambioPolo()"
                          [ngClass]="{'is-invalid': submitted && form.codigoPolo.errors}">
                        </ng-select>
                        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
                          <div *ngIf="form.codigoPolo.errors.required">
                            * Polo é obrigatório
                          </div>
                        </div>
                      </div>
                        <div class="">
                        <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
                          <img
                            src="../../../../assets/img/icons/icon-search.svg"
                            alt="Pesquisar Psicólogos"
                            title="Pesquisar Psicólogos"
                          />
                        </button>
                        </div>
                        <div class="" style="position: relative; width: 100%;">
                          <button type="submit" class="btn-add" id="btn-add-psicologo" (click)="redirecionarParaCadastrarPsicologo()">
                            <i class="fa-solid fa-plus icon-white"></i>
                            <span class="hover-text">Adicionar Psicólogo</span>
                          </button>
                        </div>
                </div>
    </section>

    <section class="conteudo-principal">

      <div class="container">

          <h2 class="titulo-page">Manutenção de Psicólogos</h2>

          <h3 class="sub-titulo-dash">
            Listagem de Psicólogos
          </h3>

          <div class="grid-datatable">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border" aria-describedby="dashboard">
              <thead>
                <tr>
                  <th>CPF</th>
                  <th>Nome Psicólogo</th>
                  <th>E-mail</th>
                  <th>Polo</th>
                  <th>Coordenador Psicólogo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of psicologos">
                  <td>{{ item.codigoCPF }}</td>
                  <td>{{ item.nomePsicologo }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.codigoPolo }}</td>
                  <td>Coordenador do polo {{ item.codigoPolo }}</td>
                  <td>
                    <div class="icones-acoes">
                      <a
                      *ngIf="this.userLogado?.funcionalidades?.includes(15)"
                      href="javascript:void(0)"
                      (click)="OnVisualizarPsicologo(item.codigoPsicologo)">
                      <i class="fa fa-eye" aria-hidden="true"></i>
                      </a>
                      &nbsp;
                      <a
                      *ngIf="this.userLogado?.funcionalidades?.includes(13)"
                      href="javascript:void(0)"
                      (click)="OnEditarPsicologo(item.codigoPsicologo)">
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </a>
                      &nbsp;
                      <a
                      *ngIf="this.userLogado?.funcionalidades?.includes(14)"
                      href="javascript:void(0)"
                      (click)="OnExcluirPsicologo(item.codigoPsicologo)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </a>
                  </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </section>

</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModalExcluirPsicologo">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page blue">Excluir</h1>
    </div>
    <hr size="20">
    <div class="descricao-modal-excluir">
      <span>Deseja excluir o Psicólogo?</span>
    </div>
    <hr size="20">
    <div class="container-btns">
      <input type="button" (click)="FecharModalExclusao()" class="btn_outline" value="Não"/>
      <input type="button" (click)="ExluirPsicologo(this.psicologoARemover)" class="btn_primary" value="Sim" />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalVisualizarPsicologo">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page blue">Visualização Psicólogo</h1>
      <p>Dados Completos</p>
    </div>
    <hr size="20">
    <hr size="4" width="100%" align="center" color="#E0E0E0">
    <hr size="20">
    <div class="modal-envolvidos-info">
      <h2 class="blue nomePsicologo">{{this.psicologo.nomePsicologo.toUpperCase()}}</h2>
      <div class="inline"><i class="fa blue fa-envelope" aria-hidden="true"></i><p><b>E-mail: </b>{{this.psicologo.email}}</p></div>
      <div class="inline"><i class="fa blue fa-id-card" aria-hidden="true"></i><p><b>CPF: </b>{{this.psicologo.codigoCPF}}</p></div>
      <div class="inline"><i class="fa blue fa-id-card" aria-hidden="true"></i><p><b>RG: </b>{{this.psicologo.codigoRG}}</p></div>
      <div class="inline"><i class="fa blue fa-id-card" aria-hidden="true"></i><p><b>UF: </b>{{this.psicologo.codigoUF}}</p></div>
      <div class="inline"><i class="fa blue fa-id-card" aria-hidden="true"></i><p><b>CRP: </b>{{this.psicologo.codigoCRP}}</p></div>
      <div class="inline"><i class="fa blue fa-phone" aria-hidden="true"></i><p><b>Tel: </b>{{this.psicologo.telefone}}</p></div>
    </div>
    <hr size="20">
    <hr size="4" width="100%" align="center" color="#E0E0E0">
    <hr size="20">
    <div>
      <div class="inline"><i class="fa blue fa-graduation-cap" aria-hidden="true"></i><p><b>Polo: </b>{{this.psicologo.codigoPolo}}</p></div>
    </div>
    <hr size="20">
    <div class="container-btns">
      <input type="button" (click)="FecharModalVisualizar()" class="btn_outline" value="Fechar"/>
    </div>
  </div>
</app-modal>
