import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { DefaultResponse } from 'src/app/shared/models/DefaultResponse';
import { Categoria } from 'src/app/shared/models/categoria/Categoria';
import { ListarChamados } from 'src/app/shared/models/chamados/listar-chamados';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Turno } from 'src/app/shared/models/escola/Turno';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { Turma } from 'src/app/shared/models/turma/Turma';
import { CategoriaService } from 'src/app/shared/services/categoria.service';
import { ChamadoService } from 'src/app/shared/services/chamado.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { EscolaService } from 'src/app/shared/services/escola.service';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';
import { GenericValidator } from 'src/app/shared/validations/genericValidators';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {

  private get DataAtual (){return new Date()}
  public minDate:string = '2021-01-01' ;
  public maxDate:string = `${this.DataAtual.getFullYear()}-12-30` ;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public colunasGrid =  [{ title: 'Chamado', field: 'codigoChamado', width:100, hozAlign: "center",headerHozAlign:"center" },
    { title: 'Gravidade', field: 'nomeGravidade', width:100, hozAlign: "center", headerHozAlign:"center"  },
    { title: 'Categoria', field: 'nomeCategoria', width:200, hozAlign: "center" , headerHozAlign:"center" },
    { title: 'Subcategoria', field: 'nomeSubCategoria', width:250, hozAlign: "center", headerHozAlign:"center" },
    { title: 'Status', field: 'dsStatus', width:150, hozAlign: "center", headerHozAlign:"center"  },
    { title: 'Escola', field: 'nomeEscola', width:280, hozAlign: "center", headerHozAlign:"center" },    
    {
      title: 'Ações', field: 'visualizar', width:100, hozAlign: "center", formatter: function (cell: any, formatterParams: any, onRendered: any) {
        return  `<div style='display:inline-flex;gap:0.4em;'> <i class='fa fa-eye'  aria-hidden='true'></i>`

               ;
      },headerHozAlign:"center", cellClick:(e:any,cell:any)=> this.OnClickAcao(e,cell)
    }
    ];


  public exibirEscola:boolean=false;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private chamadoService: ChamadoService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private categoriaService: CategoriaService,
    private psicologoService:PsicologoService,
    private validatorsService:GenericValidator,
    private router:Router,
  ){
    // ng-select mudança de texto
    this.config.notFoundText = 'Não encontrado';

    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  // Listas da tela
  public gravidades = [ { id: 1, name: 'Amarelo' }, { id: 2, name: 'Vermelho' }];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public turmas: Turma[] = [];
  public lstChamados: ListarChamados[] = [];
  public categorias: Categoria[] = [];

  //Form
  public formDashboard!: FormGroup;
  public get form() { return this.formDashboard.controls; }
  public submitted: boolean;
  public showPesquisa: boolean;

  //Totalizadores
  public totalChamados: number = 0;
  public totalChamadosAbertos: number = 0;
  public totalChamadosEncerrados: number = 0;
  public totalChamadosEncaminhados: number = 0;

  public ngOnInit(): void {

    // this.dtOptions = {
    //   deferRender: true,
    //   destroy:true,
    //   processing: true,
    //   paging: true,
    //   columnDefs: [{
    //     target: [5], visible:this.exibirEscola
    //   }],
    //   dom: 'Bfrtip',
    //   language: {
    //     url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
    //   },
    //   lengthMenu: [
    //     [ 10, 25, 50, -1 ],
    //     [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
    //   ],
    //   buttons: [
    //     'pageLength',
    //     {
    //       extend:'print',
    //       exportOptions:{
    //         columns:[0,1,2,3,4,5]
    //       }
    //     },
    //     {
    //       extend:'excel',
    //       exportOptions:{
    //         columns:[0,1,2,3,4,5]
    //       }
    //     },{
    //       extend:'pdf',
    //       exportOptions:{
    //         columns:[0,1,2,3,4,5]
    //       }
    //     }
    //   ],
    // };

    //Formulario de pesquisa.
    this.formDashboard = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null),
      codigoTurno: new FormControl(null),
      codigoTurma: new FormControl(null),
      codigoGravidade: new FormControl(null),
      codigoSubCategoria: new FormControl(null),
      dataInicio: new FormControl(null),
      dataFim: new FormControl(null),
    });

    this.getCategorias();

    if(this.userLogado?.flPsicologo){
      this.getDiretoriasPerfilDiretoria();
    }else{
        if(this.atribuicoesLogin.NumeroComportamento == 1){
          this.getDiretorias();
        }

        if(this.atribuicoesLogin.NumeroComportamento == 2){
          this.getDiretoriasPerfilDiretoria();
        }

        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
          this.getDiretoriasPerfilEscola();
        }

    }

  }

  public validaAno(data: AbstractControl<any, any>){
    var dataDoCampo = data.value;
    if(dataDoCampo){
     const ano = new Date(dataDoCampo).getFullYear();
      if(ano > this.DataAtual.getFullYear() || ano < this.DataAtual.getFullYear() - 10){
        data.setValue(null);
        this.toastr.warning("Data invalida! Selecione uma data valida para pesquisar.");
      }
    }
  }

  public ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }

  public async PesquisarChamados(statusEnvolvido: number = -1) {
    this.spinnerService.show();
    this.submitted = true;

    if (this.formDashboard.invalid) {
      this.showPesquisa = false;
      this.spinnerService.hide();
      return;
    }
    this.showPesquisa = true;
    const parametros = this.SetParametrosChamados(statusEnvolvido);

    var response = await lastValueFrom(this.chamadoService.getDashboard(parametros))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.lstChamados = response.data;
    if(this.lstChamados.length == 0){
      this.SetTotalizadores();
      this.toastr.warning("Não existem chamados para essa pesquisa");
      this.lstChamados = [];
      // if(this.dtElement.dtInstance != null) {
      //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //     dtInstance.destroy();
      //     this.dtTrigger.next(this.dtOptions);
      //   });
      // }
    }
    else{
       if(parametros.codigoDiretoria != null && parametros.codigoEscola == null && parametros.codigoCategoria == null && parametros.codigoTurno == null){
         this.exibirEscola = true;
        //  this.isDtInitialized = true;
        //  this.dtTrigger.next(this.dtOptions);
       }
      this.SetTotalizadores();
      // if (this.isDtInitialized) {
      //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //     dtInstance.destroy();
      //     this.dtTrigger.next(this.dtOptions);
      //   });
      // } else {
      //   this.isDtInitialized = true;
      //   if(this.dtElement.dtInstance != null) {
      //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //       dtInstance.destroy();
      //     });
      //   }
      //   this.dtTrigger.next(this.dtOptions);
      // }
    }
  }

  public SetParametrosChamados(statusEnvolvido: number): ListarChamados {
    const chamado = new ListarChamados();
    chamado.codigoDiretoria = this.form?.codigoDiretoria.value;
    chamado.codigoEscola = this.form?.codigoEscola.value;
    chamado.codigoTurno = this.form?.codigoTurno.value;
    chamado.codigoTurma = this.form?.codigoTurma.value;
    chamado.codigoGravidade = this.form?.codigoGravidade.value;
    chamado.codigoSubCategoria = this.form?.codigoSubCategoria.value;
    chamado.dataInicio = this.form?.dataInicio.value;
    chamado.dataFim = this.form?.dataFim.value;
    chamado.filtroStatusEnvolvido = statusEnvolvido;

    // if(statusEnvolvido == -1)
    //   this.isDtInitialized = false;

    return chamado;
  }

  public async getDiretorias() {
    this.spinnerService.show();

    var response = await lastValueFrom(this.escolaService.getDiretoriaPorTipoDiretoria())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.diretorias = response.data;
    this.spinnerService.hide();
    if(this.userLogado?.codigoDiretoria != undefined){
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public async getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formDashboard.get('codigoDiretoria')?.value;
    if(this.userLogado?.flPsicologo){
      this.zerarEscola();
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.escolas = response.data.escolas;
      this.diretorias = response.data.diretorias;
      this.spinnerService.hide();
      this.ativaEscola();
    }else
    if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2){
      this.zerarEscola();
      if (codigoDE != null) {
        this.spinnerService.show();

        var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }
        this.escolas = response.data;
        this.spinnerService.hide();
        this.ativaEscola();
      }
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formDashboard.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public async getTurnos() {
    const codigoEE = this.formDashboard.get('codigoEscola');
    if (codigoEE?.value != null) {
      this.spinnerService.show();
      var response = await lastValueFrom(this.escolaService.getTurnosPorCodigoCie(codigoEE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.turnos = response.data;
      if (this.turnos == null)
        this.toastr.warning('Não foi encontrados turnos para essa escola !');
      this.spinnerService.hide();
    }
    else{
      this.zerarTurnos();
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = codigoEE?.value == null ? 0 : codigoEE?.value;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formDashboard.get('codigoTurno')?.patchValue(null);
  }

  public async getTurmas() {
    this.spinnerService.show();
    const codigoEs = this.formDashboard.get('codigoEscola');
    const codigoTurno = this.formDashboard.get('codigoTurno');

    if(codigoEs?.value != null) {
      var response = await lastValueFrom(this.escolaService.getTurmasPorCodigoCie(codigoEs?.value, codigoTurno?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.turmas = response.data;
      this.spinnerService.hide();
    }
  }

  public zerarTurma() {
    this.turmas = [];
    this.formDashboard.get('codigoTurma')?.patchValue(null);
  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();

      this.getTurnos();
      this.PesquisarChamados();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria != undefined)
      this.formDashboard.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if(this.userLogado !== null)
        this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){

    if(this.userLogado?.codigoEscola != undefined){
      var existEscola = this.escolas.find(s=>s.codigoEscola == this.userLogado?.codigoEscola);
      if(existEscola != undefined){
        this.formDashboard.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }
    }
    if(this.userLogado?.codigoDiretoria != undefined && this.userLogado?.codigoEscola != undefined)
    {
      this.getTurnos();
      this.PesquisarChamados();
    }
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async  getCategorias() {
    this.spinnerService.show();

    var response = await lastValueFrom(this.categoriaService.getCategoriasWithSub())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.categorias = response.data;
    this.spinnerService.hide();
  }

  private SetTotalizadores(){
    // if (!this.isDtInitialized) {
      if(this.lstChamados.length > 0) {
        this.totalChamados = this.lstChamados.length;
        this.totalChamadosAbertos = this.lstChamados.filter(r => r.status == 0).length;
        this.totalChamadosEncerrados = this.lstChamados.filter(r => r.status == 4).length;
        this.totalChamadosEncaminhados = this.lstChamados.filter(r => r.status == 1 || r.status == 2 || r.status == 3).length;
      }
      else {
        this.totalChamados = 0;
        this.totalChamadosAbertos = 0;
        this.totalChamadosEncerrados = 0;
        this.totalChamadosEncaminhados = 0;
      }
    // }
  }


  private OnClickAcao(e:any, cell: any) {
      // Evento de click na célula para capturar os botões
      var row = cell.getRow().getData(); // Dados da linha
      var target = e.target; // Elemento clicado
      let codigoChamado = row.codigoChamado

      if(target.classList.contains('fa-eye')){
        this.router.navigate(['/detalhe/' + codigoChamado]);
      } else if(target.classList.contains('fa-pen')){
        this.router.navigate(['/editar/' + codigoChamado]);
      } else if(target.classList.contains('fa-trash')){
        this.router.navigate(['/remover/' + codigoChamado]);
      }
  }
}

