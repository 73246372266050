//Principais
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
//Modelos
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { ChamadosUsuario } from "src/app/shared/models/chamados/chamadosUsuario";
//Services
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { lastValueFrom } from "rxjs";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";

@Component({
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})

export class PerfilComponent implements OnInit {
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public chamadosUsuario: ChamadosUsuario;
  public lstChamadosFiltrados: ListarChamados[] = [];
  //Totalizadores
  public totalChamados: number = 0;
  public totalChamadosAbertos: number = 0;
  public totalChamadosEncerrados: number = 0;
  public visualizarInformacoes: boolean = true;
  public tipoFiltro: string = "Criados";

  constructor(
    private authService: AuthService,
    private chamadoService: ChamadoService,
    private spinnerService: NgxSpinnerService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
    private router: Router
  )
  {
    this.userLogado = this.authService.getUser();

    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public async ngOnInit()  {
    //this.pesquisaChamados();
    await this.pesquisaInformacaoPerfil();
  }

  public OnVisualizarChamado(id: number) {
    this.router.navigate(['/detalhe/', id]);
  }

  public async pesquisaInformacaoPerfil(){
    this.visualizarInformacoes = true;
    this.spinnerService.show();
    const result = await lastValueFrom(this.chamadoService.getChamadosCriadosPorUsuario(this.userLogado!.cpf, this.userLogado?.codigoEscola == undefined ? 0 : this.userLogado?.codigoEscola)) 
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.chamadosUsuario = result.data;
    console.log(this.chamadosUsuario);
    await this.SetTotalizadores();
    this.spinnerService.hide();
  }

  private SetTotalizadores(){
    if(this.chamadosUsuario.listaChamados.length > 0) {
      this.totalChamados = this.chamadosUsuario.listaChamados.length;
      this.totalChamadosAbertos = this.chamadosUsuario.listaChamados.filter(r => r.status == 0 || r.status == 1 || r.status == 2 || r.status == 3).length;
      this.totalChamadosEncerrados = this.chamadosUsuario.listaChamados.filter(r => r.status == 4).length;
    }
    else {
      this.totalChamados = 0;
      this.totalChamadosAbertos = 0;
      this.totalChamadosEncerrados = 0;
    }
  }

  public PesquisarChamados(tipoLista: number){
    console.log("pasa por aqui ",tipoLista);
    this.visualizarInformacoes = false;
    if(tipoLista == 0){
      this.lstChamadosFiltrados = this.chamadosUsuario.listaChamados;
      this.tipoFiltro = "criados";
    }
    else if(tipoLista == 1){
      this.lstChamadosFiltrados = this.chamadosUsuario.listaChamados.filter(r => r.status == 0 || r.status == 1 || r.status == 2 || r.status == 3);
      this.tipoFiltro = "em aberto";
    }
    else if(tipoLista == 2){
      this.lstChamadosFiltrados = this.chamadosUsuario.listaChamados.filter(r => r.status == 4);
      this.tipoFiltro = "encerrados";
    }
    else{
      this.lstChamadosFiltrados = this.chamadosUsuario.listaChamados;
      this.tipoFiltro = "criados";
    }
  }


}
