<div class="icon-notificacao">
  <a href="javascript:void(0)" id="sino-notificacao" (click)="ExpandirNotificacao()" class="notificacao">
    <p>{{ notifications  }}</p>
  </a>
</div>


<aside class="sidebar-notificacao">
  <div class="header">
    <a class="notificacao-fechar" title="Fechar" (click)="FecharNotificacao()"></a>
  </div>
  <div class="content">
    <div class="title">  <h2 >Suas Notificações</h2></div>
    <div *ngIf="this.lstNotificacoesSelecionadas.length > 0" class="header-notficacoes">
      <small>Marcar como lidas: {{this.lstNotificacoesSelecionadas.length}}</small>
      <a class="btn-ler-notificacoes" (click)="MarcaNotificacoesComoVisualizadas()">Marcar como Lido</a>
    </div>
    <div class="content-array">
      <div *ngFor="let item of this.lstNotificacoes; let i = index">
        <!-- (click)="RedirecionaVisualizarChamado(item.codigoChamado)" -->
        <div class="card-notification" (click)="NotificacaoSelecionada(item.codigoNotificacao,i)" id="card-{{i}}" >
          <input type="checkbox" checked class="card-input-notification"  id="card-notification-{{ i + 1 }}">
          <div class="perfil-aluno">
          <div class="img-aluno {{item.dsGravidade}}">
            <div *ngIf="item.foto" class="foto-perfil">
              <img src="data:image/jpeg;base64,{{ item.foto }}"
                alt=""
              />
            </div>
            <div *ngIf="!item.foto" class="iniciais-perfil">
              <span>{{ item.iniciaisNome }}</span>
            </div>
          </div>
        </div>
          <div class="info-card-notification">
            <div class="card-header">
              <p>
                <small>{{ item.horasAtras }}</small>
              </p>
            </div>
            <div class="card-titulo">
              <p>

                {{item.dsTipoNotificacao + " "+ item.dsTipoAcao + " para "+ item.aluno?.nomeAluno }}
              </p>
            </div>
            <!-- <div class="card-descricao-old">
              <div class="card-descricao">
                <p>
                  {{ item.descricaoChamado}}
                </p>
              </div>
            </div> -->
            <a
            (click)="OnStatusChamado(item.codigoChamado, item.codigoEnvolvido)"
              class="card-btn-notification">
              Status do Chamado
            </a>
          </div>

          <div class="btn-acoes">
            <!-- <label for="card-notification-{{ i + 1 }}">
              <a class="btn-view-card"></a>
            </label> -->
            <input type="checkbox" checked class="card-opcoes-card"  id="card-opcoes-card-{{ i + 1 }}">
            <label for="card-opcoes-card-{{ i + 1 }}">
              <a class="btn-opcoes-card"></a>
            </label>
            <div class="opcoes-card caixa-opcoes">


              <a href="javascript:void(0)"
               (click)="RedirecionaVisualizarChamado(item.codigoChamado)"
              >Visualizar Chamado</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</aside>
