import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { HistoricoChamado } from "../models/chamados/historico";

@Injectable({
  providedIn: 'root'
})

export class EncaminhamentoService {
    private api = ConvivaApi;

    constructor(
      private http: HttpClient,
      private router:Router)
    { }
  
    public setCadastrarEncaminhamento(parametros: HistoricoChamado):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Encaminhamento.setCadastrarEncaminhamento}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setCadastrarEncaminhamentoSaude(parametros: HistoricoChamado):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Encaminhamento.setCadastrarEncaminhamentoSaude}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }
    
    public setCadastrarEncaminhamentoPolicia(parametros: HistoricoChamado):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Encaminhamento.setCadastrarEncaminhamentoPolicia}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

    public setDeletarEncaminhamentoPsicologo(parametros: HistoricoChamado):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Encaminhamento.setDeletarEncaminhamentoPsicologo}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }

}