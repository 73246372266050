import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { ListarChamados } from "../models/chamados/listar-chamados";
import { Observable, map } from "rxjs";
import { ListarEncaminhados } from "../models/chamados/listar-encaminhados";

@Injectable({
    providedIn: 'root'
  })

  export class NotificacoesService {
    private api = ConvivaApi;
    public filtrarNotificacoes = new EventEmitter<ListarEncaminhados>();

    constructor(
      private http: HttpClient,
      private router:Router)
    { }

    public getNotificacoesComDatas(parametros: ListarChamados):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Notificacoes.getNotificacoesComDatas}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }
    public getNotificacoes(parametros: ListarChamados):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Notificacoes.getNotificacoes}`, parametros)
                      .pipe(map((response: DefaultResponse) => response));
    }
    public setExcluirNotificacoes(parametros: number[]):Observable<DefaultResponse> {
      return this.http.post<DefaultResponse>(`${this.api.Notificacoes.setExcluirNotificacoes}`, {Notificacoes:parametros})
                      .pipe(map((response: DefaultResponse) => response));
    }

    public emitEventoNotificacoes(filtros:ListarEncaminhados){
      this.filtrarNotificacoes.emit(filtros);
    }
}
