import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { HistoricoChamado } from "src/app/shared/models/chamados/historico";
import { ChamadoDeletar } from "src/app/shared/models/chamados/chamadoDeletar";
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { ConcluirService } from "src/app/shared/services/concluir.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { EncaminhamentoService } from "src/app/shared/services/encaminhamento.service";

@Component({
  selector: 'app-tres-pontos',
  templateUrl: './tres-pontos.component.html',
  styleUrls: ['./tres-pontos.component.scss']
})

export class TresPontosComponent implements OnInit {
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private spinnerService: NgxSpinnerService,
              private toastr: ToastrService,
              private ConvivaErrors: ConvivaErrorHandlingService,
              private chamadoService: ChamadoService,
              private encaminhamento: EncaminhamentoService,
              private concluirService: ConcluirService){

    this.userLogado = this.authService.getUser();

    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  @Input() public Index: number = 0;
  @Input() public Chamado: ListarChamados = new ListarChamados();
  @Input() public TemEnvolvido: boolean = false;

  @Output() ItemEvent = new EventEmitter<string>();

  public IsVisualizarChamado: boolean = false;

  // Modal Excluir
  public showModalExcluirChamado = false;
  public descricaoExclusao = "";
  public chamadoARemover = 0;

  // Modal Encerrar Chamado Envolvido
  public showModalConcluirChamado = false;
  public ShowModalEncerrarChamado = false;
  public descricaoConcluir = "";
  public codigoChamadoSelected = 0;
  public codigoEnvolvidoSelected = 0;
  public chamadoAConcluir = 0;

  // Modal Encaminhamentos
  public showModal = false;
  public submittedModal = false;
  public tituloModal = "";
  public formModal!: FormGroup;
  public get modalForm() { return this.formModal.controls; }

  public async ngOnInit() {
    const url = this.router.url;
    if(url.includes("/detalhe/"))
      this.IsVisualizarChamado = true;
  }

  public VerificaStatusEnvolvido(): boolean {
    return (this.Chamado.aluno?.statusEnvolvido == 5 ||
            this.Chamado.aluno?.statusEnvolvido == 2 ||
            this.Chamado.aluno?.statusEnvolvido == 3 ||
            this.Chamado.aluno?.statusEnvolvido == 0);
  }

  public OnExcluirChamado(codigoChamdo: number){
    this.showModalExcluirChamado = true;
    this.descricaoExclusao = `Deseja remover o chamado ?`;
    this.chamadoARemover = codigoChamdo;
  }

  public FecharModalExclusao() {
    this.showModalExcluirChamado = false;
    this.descricaoExclusao = "";
    this.chamadoARemover = 0;
  }

  public async ExcluirChamado(codigoChamado: number) {
    this.spinnerService.show();
    var chamadoDeletar: ChamadoDeletar = {
      cdChamado: codigoChamado,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    }
    var response = await lastValueFrom(this.chamadoService.setDeletarChamado(chamadoDeletar))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();
    this.toastr.success('Chamado removido com sucesso!');
    this.FecharModalExclusao();
    this.ItemEvent.emit("Pesquisar");
  }

  public OnEditarChamado(codigoChamdo: number){
    this.router.navigate([`/editar-chamados/${codigoChamdo}`]);
  }

  public OnVisualizarChamado(codigoChamdo: number) {
    this.router.navigate(['/detalhe/', codigoChamdo]);
  }

  public AbrirModalEncerrarChamado(codigoChamdo: number, codigoEnvolvido:number){
    this.codigoChamadoSelected = codigoChamdo;
    this.codigoEnvolvidoSelected = codigoEnvolvido;
    this.ShowModalEncerrarChamado = true;
    this.descricaoConcluir = `Deseja encerrar o chamado deste Envolvido ?`;
    this.chamadoAConcluir = codigoChamdo;
  }

  public FecharModalEncerrarChamado() {
    this.ShowModalEncerrarChamado = false;
    this.codigoChamadoSelected = 0;
    this.codigoEnvolvidoSelected = 0;
    this.chamadoAConcluir = 0;
  }

  public async EncerrarChamado() {

    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Chamado deste envolvido foi encerrado.',
      statusChamado: 6,
      nomeArquivo: "",
      caminhoArquivo: "",
      conteudo: "",
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil           
    };

    this.FecharModalEncerrarChamado();

    this.spinnerService.show();

    const result = await lastValueFrom(await this.concluirService.setCadastrarConcluir(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Chamado deste envolvido encerrado com sucesso.');
    this.spinnerService.hide();

    this.ItemEvent.emit("Pesquisar");
  }

  public AbrirModalEncaminhamentos(codigoChamado: number, codigoEnvolvido: number) {
    this.codigoChamadoSelected = codigoChamado;
    this.codigoEnvolvidoSelected = codigoEnvolvido;
    this.submittedModal = false;
    this.formModal = new FormGroup({});
    this.showModal = true;
    this.tituloModal = 'Encaminhamentos';
  }

  public FecharModalEncaminhamentos() {
    this.showModal = false;
  }

  public async EncaminhamentoPsicologo() {
    this.spinnerService.show();

    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado ao Psicólogo.',
      statusChamado: 2,//Encaminhado
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil            
    };

    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamento(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();
    this.toastr.success('Aluno Encaminhado ao Psicologo com Sucesso.');
    this.FecharModalEncaminhamentos();
    this.ItemEvent.emit("Pesquisar");
  }

  public async EncaminhamentoPolicia() {
    this.spinnerService.show();

    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado a Rede protetiva.',
      statusChamado: 2,//Encaminhado
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil            
    };

    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamentoPolicia(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();
    this.toastr.success('Aluno Encaminhado a Rede protetiva.');
    this.FecharModalEncaminhamentos();
    this.ItemEvent.emit("Pesquisar");
  }

  public async EncaminhamentoSaude() {
    this.spinnerService.show();

    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado a Saúde.',
      statusChamado: 3,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil            
    };

    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamentoSaude(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();
    this.toastr.success('Aluno Encaminhado a Saúde com Sucesso.');
    this.FecharModalEncaminhamentos();
    this.ItemEvent.emit("Pesquisar");
  }

  public podeConcluirChamado(chamado:ListarChamados){
    return this.userLogado?.funcionalidades?.includes(2) &&
    this.Chamado.status != 4 ||
    this.Chamado.tpPessoa[0] == 3 &&
    (this.Chamado.semEnvolvido || this.Chamado.codigoCategoria == 6 || this.Chamado.status == 3);
  }

  
  public AbrirModalConcluirChamado(codigoChamdo: number){
    this.showModalConcluirChamado = true;
    this.descricaoConcluir = `Deseja concluir o chamado ?`;
    this.chamadoAConcluir = codigoChamdo;
  }

  public FecharModalConcluir() {
    this.showModalConcluirChamado = false;
  }

  public async ConcluirChamado() {

    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.Chamado.codigoChamado,
      codigoEnvolvido: this.Chamado.codigoEnvolvido,
      descricaoHistorico: 'Chamado deste envolvido concluído.',
      statusChamado: 6,
      nomeArquivo: "",
      caminhoArquivo: "",
      conteudo: "",

    };

    this.FecharModalConcluir();
    this.spinnerService.show();

    var result = await lastValueFrom(await this.concluirService.setCadastrarConcluirChamadoEnvolvidos(objetoHistorico))
                      .catch((err) => {
                        return this.ConvivaErrors.handleResultError(err);
                      });

    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();
    this.toastr.success('Chamado deste envolvido concluído com sucesso.');
    this.ItemEvent.emit("Pesquisar");
  }
}
