import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { DefaultExport } from "@angular/router";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { PerfilLoginSed } from "../models/sed/UserLogin";
import { PerfilAcesso } from "../models/Funcionalidade/PerfilAcesso";
import { FuncionalidadePerfil } from "../models/Funcionalidade/FuncionalidadePerfil";

@Injectable({
    providedIn: 'root'
})

export class PerfilService {
    private api = ConvivaApi;

    constructor(
        private http: HttpClient,
        private router:Router)
    { }

    public getPerfisUsuario(perfis: PerfilLoginSed[]):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Perfil.GetPerfisUsuario}`, perfis)
        .pipe(map((response: DefaultResponse) => response));
    }

    public getFuncionalidadesPerfil(codigoPerfil: number):Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Perfil.GetFuncionalidadesPerfil}?codigoPerfil=${codigoPerfil}`)
        .pipe(map((response: DefaultResponse) => response));
    }

    public getPerfis():Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Perfil.GetPerfis}`)
        .pipe(map((response: DefaultResponse) => response));
    }

    public getFuncionalidades():Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Perfil.GetFuncionalidades}`)
        .pipe(map((response: DefaultResponse) => response));
    }

    public getFuncionalidadesEPerfil(codigoPerfil: number, codigoFuncionalidade: number):Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Perfil.GetFuncionalidadesEPerfil}?codigoPerfil=${codigoPerfil}&codigoFuncionalidade=${codigoFuncionalidade}`)
        .pipe(map((response: DefaultResponse) => response));
    }

    public setCadastrarPerfilAcesso(parametros: PerfilAcesso):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Perfil.SetCadastrarPerfilAcesso}`, parametros)
        .pipe(map((response: DefaultResponse) => response));
      }

    public getPerfisAcessos(codigoPerfil: number):Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.api.Perfil.GetPerfisAcessos}?codigoPerfil=${codigoPerfil}`)
        .pipe(map((response: DefaultResponse) => response));
    }

    public setCadastrarFuncionalidade(parametros: FuncionalidadePerfil):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Perfil.SetCadastrarFuncionalidade}`, parametros)
        .pipe(map((response: DefaultResponse) => response));
      }

}
