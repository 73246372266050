export class Escola {
  public codigoEscola:number;
  public codigoDiretoria:number;
  public codigoDiretoriaEstadual:number;
  public nomeDiretoria:string;
  public nomeEscola:string;
  public nomeEscolaSemCodigo:string;
  public nomeAbreviado:string;
  public codigoSituacao:number;
  public codigoUnidadeAdministrativa:number;
  public codigoRedeEnsino:number;
  public nomeGestorEscola:string;
  public emailGestor:string;
  public latitude: string;
  public longitude: string;
  public descricaoLogradouro: string;
  public descricaoEndereco: string;
  public numero: string;
  public cep: string;
  public bairro: string;
  public nomeMunicipio: string;
  public numeroTelefono: string;
  public email: string;
}
