//Principais
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgSelectConfig } from '@ng-select/ng-select';
import { AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
//Models
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { AtribuicaoLoginSed, UserLoginSed, PerfilLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { ListarConsolidado } from "src/app/shared/models/relatorio/listar-consolidado";
import { ListarVisitaPsicologos } from "src/app/shared/models/relatorio/listar-visita-psicologos";
import { ListarChamadosUsuario } from 'src/app/shared/models/relatorio/listar-chamado-usuario';
import { Consolidado } from "src/app/shared/models/relatorio/Consolidado";
import { Categoria } from "src/app/shared/models/categoria/Categoria";
import { ListarOcorrenciasAtivas } from "src/app/shared/models/relatorio/listar-ocorrencias-ativas"
import { ListarCalculoUsabilidade } from "src/app/shared/models/relatorio/listar-calculo-usabilidade";
//Services
import { lastValueFrom } from "rxjs";
import { RelatorioService } from "src/app/shared/services/relatorio.service";
import { EscolaService } from 'src/app/shared/services/escola.service';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ListarPendencias } from 'src/app/shared/models/relatorio/listar-pendencias';
import { CategoriaService } from "src/app/shared/services/categoria.service";
import { formatDate } from '@angular/common';

@Component({
  selector: 'consolidado',
  templateUrl: './consolidado.component.html',
  styleUrls: ['./consolidado.component.scss']
})

export class ConsolidadoComponent implements OnInit {

  private get DataAtual (){return new Date()}
  public minDate:string = '2021-01-01' ;
  public maxDate:string = `${this.DataAtual.getFullYear()}-12-30` ;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public formRelatorios: FormGroup;
  public get form() { return this.formRelatorios.controls; }

  constructor(
    private config: NgSelectConfig,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
    private relatorioService: RelatorioService,
    private escolaService: EscolaService,
    private psicologoService:PsicologoService,
    private categoriaService:CategoriaService,
    private excelService: ExcelService,
    @Inject(LOCALE_ID) private locale: string,
  ){
    // ng-select mudança de texto
    this.config.notFoundText = 'Não encontrado';
    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public categorias: Categoria[] = [];
  public usuarios: UserLoginSed[] = [];
  public perfis: PerfilLoginSed[] = [];
  public listaConsolidado: ListarConsolidado[] = [];
  public listaPendencias: ListarPendencias[] = [];
  public listaVisitasPsicologos: ListarVisitaPsicologos[] = [];
  public listaChamadoUsuario: ListarChamadosUsuario[] = [];
  public listaOcorrenciasAtivas: ListarOcorrenciasAtivas[] = [];
  public listaCalculoUsabilidade: ListarCalculoUsabilidade[] = [];
  public ativarCamposUsuario = false;
  public ativarCamposPsicologo = false;
  public ativarCamposOcorrenciasAtivas = false;
  public ativarCamposDiretoriaEscola = true;
  public flagAtivo = [ { id: true, name: 'Sim' }, { id: false, name: 'Não' }];
  public relatorios: any[] = [];

  public ngOnInit(): void {

    this.formRelatorios = new FormGroup({
      codigoDiretoria: new FormControl(null),
      codigoEscola: new FormControl(null),
      codigoSubCategoria: new FormControl(null),
      codigoPerfil: new FormControl(null),
      cpf: new FormControl(null),
      dataInicio: new FormControl(null),
      dataFim: new FormControl(null),
      codigoAtivo: new FormControl(null),
      idRelatorio: new FormControl(null)
    });

    var objConsolidado = { id: "relatorioGeral", name: 'Relatório Consolidado Geral' };
    var objPendencias = { id: "relatorioPendencias", name: 'Relatório Pendências' };
    var objVisita = { id: "relatorioVisitaPsicologo", name: 'Relatório Visita Psicólogo' };
    var objUsuario = { id: "relatorioChamadoUsuario", name: 'Relatório Chamados por Usuario' };
    var objOcorrencias = { id: "relatorioOcorrenciasAtivas", name: 'Relatório Ocorrências Ativas/Excluídas' };
    var objCalculo = { id: "relatorioCalculoUsabilidade", name: 'Relatório Cálculo Usabilidade' };
    if(this.userLogado?.funcionalidades.includes(17) || this.userLogado?.funcionalidades.includes(20)){
      this.relatorios.push(objConsolidado);
      this.relatorios.push(objPendencias);
    }
    if(this.userLogado?.funcionalidades.includes(17)){
      this.relatorios.push(objVisita);
      this.relatorios.push(objUsuario);
      this.relatorios.push(objOcorrencias);
      this.relatorios.push(objCalculo);
    }


    this.getCategorias();
    if(this.userLogado?.flPsicologo){
      this.getDiretoriasPerfilDiretoria();
    }else{
      if(this.atribuicoesLogin.NumeroComportamento == 1){
        this.getDiretorias();
      }
      if(this.atribuicoesLogin.NumeroComportamento == 2){
        this.getDiretoriasPerfilDiretoria();
      }
      if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
        this.getDiretoriasPerfilEscola();
      }
    }

  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public async getDiretorias() {
    this.spinnerService.show();

    var response = await lastValueFrom(this.escolaService.getDiretoriaPorTipoDiretoria())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.diretorias = response.data;
    this.spinnerService.hide();
    if(this.userLogado?.codigoDiretoria != undefined){
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public async getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formRelatorios.get('codigoDiretoria')?.value;
    if(this.userLogado?.flPsicologo){
      this.zerarEscola();
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.escolas = response.data.escolas;
      this.diretorias = response.data.diretorias;
      this.spinnerService.hide();
      this.ativaEscola();
    }else
    if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2){
      this.zerarEscola();
      if (codigoDE != null) {
        this.spinnerService.show();

        var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }
        this.escolas = response.data;
        this.spinnerService.hide();
        this.ativaEscola();
      }
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria != undefined)
      this.formRelatorios.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if(this.userLogado !== null)
        this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){
    if(this.userLogado?.codigoEscola != undefined){
      var existEscola = this.escolas.find(s=>s.codigoEscola == this.userLogado?.codigoEscola);
      if(existEscola != undefined){
        this.formRelatorios.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }
    }
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public zerarEscola() {
    this.escolas = [];
    this.formRelatorios.get('codigoEscola')?.patchValue(null);
  }

  public async getCategorias() {
    if(this.ativarCamposUsuario || this.ativarCamposPsicologo || this.ativarCamposOcorrenciasAtivas){
      if(this.form?.dataInicio.value != null)
        {
          this.getPerfis();
          this.getUsuarios();
        }
        this.getUsuarios();
      this.spinnerService.show();
      const result = await lastValueFrom(await this.categoriaService.getCategoriasWithSub())
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.categorias = result.data;
      this.spinnerService.hide();
    }
  }

  public async getUsuarios() {
    if(this.ativarCamposUsuario){
      if(this.form?.dataInicio.value == null){
        this.toastr.warning("Favor informar a data de início.");
        this.spinnerService.hide();
        return;
      }
      this.spinnerService.show();
      const parametros = this.SetParametros();
      if(this.form?.dataFim.value == null)
        parametros.dataFim = new Date();
      const result = await lastValueFrom(await this.relatorioService.getUsuarioChamadosUnicos(parametros))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.usuarios = result.data;
      this.spinnerService.hide();
    }
  }

  public validaAno(data: AbstractControl<any, any>){
    var dataDoCampo = data.value;
    if(dataDoCampo){
     const ano = new Date(dataDoCampo).getFullYear();
      if(ano > this.DataAtual.getFullYear() || ano < this.DataAtual.getFullYear() - 10){
        data.setValue(null);
        this.toastr.warning("Data invalida! Selecione uma data valida para pesquisar.");
      }
    }
  }

  public async getPerfis() {
    if(this.ativarCamposUsuario){
      if(this.form?.dataInicio.value == null){
        this.toastr.warning("Favor informar a data de início.");
        this.spinnerService.hide();
        return;
      }
      this.spinnerService.show();
      const parametros = this.SetParametros();
      if(this.form?.dataFim.value == null)
        parametros.dataFim = new Date();
      const result = await lastValueFrom(await this.relatorioService.getPerfisChamadosUnicos(parametros))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.perfis = result.data;
      this.spinnerService.hide();
    }
  }

  public async RelatorioConsolidadoGeral(){
    this.spinnerService.show();
    if (this.formRelatorios.invalid) {
      this.spinnerService.hide();
      return;
    }
    if(this.form?.dataInicio.value == null){
      this.toastr.warning("Favor informar a data de início.");
      this.spinnerService.hide();
      return;
    }
    const parametros = this.SetParametros();
    const response = await lastValueFrom(this.relatorioService.getRelatorioConsolidado(parametros))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.listaConsolidado = response.data;
    this.excelService.exportToExcel(this.listaConsolidado, 'Consolidado', 'Relatorio', 1);
    if(this.listaConsolidado.length == 0){
      this.toastr.warning("Não existem dados para essa pesquisa");
      return;
    }
    this.spinnerService.hide();
  }

  public async RelatorioPendencias(){
    this.spinnerService.show();
    if (this.formRelatorios.invalid) {
      this.spinnerService.hide();
      return;
    }
    if(this.form?.dataInicio.value == null){
      this.toastr.warning("Favor informar a data de início.");
      this.spinnerService.hide();
      return;
    }

    const parametros = this.SetParametros();
    var response = await lastValueFrom(await this.relatorioService.getRelatorioPendencias(parametros))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.listaPendencias = response.data;
    this.excelService.exportToExcel(this.listaPendencias, 'Pendencias', 'Relatorio', 2);
    if(this.listaPendencias.length == 0){
      this.toastr.warning("Não existem dados para essa pesquisa");
      return;
    }
    this.spinnerService.hide();
  }

  public async RelatorioVisitaPsicologo(){
    this.spinnerService.show();
    if (this.formRelatorios.invalid) {
      this.spinnerService.hide();
      return;
    }
    if(this.form?.dataInicio.value == null){
      this.toastr.warning("Favor informar a data de início.");
      this.spinnerService.hide();
      return;
    }

    const parametros = this.SetParametros();

    if(parametros.codigoDiretoria == 0 || parametros.codigoEscola == 0){
      this.toastr.warning("Favor informar Diretoria e Escola.");
      this.spinnerService.hide();
      return;
    }

    var response = await lastValueFrom(await this.relatorioService.getRelatorioVisitaPsicologo(parametros))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.listaVisitasPsicologos = response.data;
    if(this.listaVisitasPsicologos.length != 0)
      this.excelService.exportToExcel(this.listaVisitasPsicologos, 'Visitas Psicologos', 'Relatorio', 3);

    if(this.listaVisitasPsicologos.length == 0){
      this.toastr.warning("Não existem dados para essa pesquisa");
      return;
    }
    this.spinnerService.hide();
  }

  public async RelatorioChamadosUsuario(){
    this.spinnerService.show();
    if (this.formRelatorios.invalid) {
      this.spinnerService.hide();
      return;
    }
    if(this.form?.dataInicio.value == null){
      this.toastr.warning("Favor informar a data de início.");
      this.spinnerService.hide();
      return;
    }
    const parametros = this.SetParametros();

    if(this.form?.dataFim.value == null)
      parametros.dataFim = new Date();

    var response = await lastValueFrom(await this.relatorioService.getRelatorioChamadosUsuario(parametros))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.listaChamadoUsuario = response.data;
    this.excelService.exportToExcel(this.listaChamadoUsuario, 'Chamados por Usuario', 'Relatorio', 4);
    if(this.listaChamadoUsuario.length == 0){
      this.toastr.warning("Não existem dados para essa pesquisa");
      return;
    }
    this.spinnerService.hide();
  }

  public async RelatorioOcorrenciasAtivas(){
    this.spinnerService.show();
    if (this.formRelatorios.invalid) {
      this.spinnerService.hide();
      return;
    }
    if(this.form?.dataInicio.value == null){
      this.toastr.warning("Favor informar a data de início.");
      this.spinnerService.hide();
      return;
    }
    const parametros = this.SetParametros();

    if(this.form?.dataFim.value == null)
      parametros.dataFim = new Date();
    var response = await lastValueFrom(await this.relatorioService.getRelatorioOcorrenciasAtivas(parametros))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.listaOcorrenciasAtivas = response.data;
    this.excelService.exportToExcel(this.listaOcorrenciasAtivas, 'Ocorrencias Ativas', 'Relatorio', 5);
    if(this.listaOcorrenciasAtivas.length == 0){
      this.toastr.warning("Não existem dados para essa pesquisa");
      return;
    }
    this.spinnerService.hide();
  }

  public async RelatorioCalculoUsabilidade(){
    this.spinnerService.show();
    if (this.formRelatorios.invalid) {
      this.spinnerService.hide();
      return;
    }
    if(this.form?.dataInicio.value == null){
      this.toastr.warning("Favor informar a data de início.");
      this.spinnerService.hide();
      return;
    }
    const parametros = this.SetParametros();

    if(this.form?.dataFim.value == null)
      parametros.dataFim = new Date();
    var response = await lastValueFrom(await this.relatorioService.getRelatorioCalculoUsabilidade(parametros))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.listaCalculoUsabilidade = response.data;
    this.excelService.exportToExcel(this.listaCalculoUsabilidade, 'Calculo Usabilidade', 'Relatorio', 6);
    if(this.listaCalculoUsabilidade.length == 0){
      this.toastr.warning("Não existem dados para essa pesquisa");
      return;
    }
    this.spinnerService.hide();
  }

  public SetParametros(): Consolidado {
    const chamado = new Consolidado();
    chamado.codigoDiretoria = this.form?.codigoDiretoria.value == null ? 0 : this.form?.codigoDiretoria.value;
    chamado.codigoEscola = this.form?.codigoEscola.value == null ? 0 : this.form?.codigoEscola.value;
    chamado.codigoSubCategoria = this.form?.codigoSubCategoria.value == null ? 0 : this.form?.codigoSubCategoria.value;
    chamado.codigoPerfil = this.form?.codigoPerfil.value == null ? 0 : this.form?.codigoPerfil.value;
    chamado.codigoUsuario = this.form?.cpf.value == null ? 0 : this.form?.cpf.value;
    chamado.codigoAtivo = this.form?.codigoAtivo.value;
    chamado.dataInicio = this.form?.dataInicio.value;
    chamado.dataFim = this.form?.dataFim.value == null ? formatDate(Date(),'yyyy-MM-dd',this.locale) : this.form?.dataFim.value;
    return chamado;
  }

  gerarRelatorio() {
    var opcaoSelecionada = this.formRelatorios.get('idRelatorio')?.value;
    if(opcaoSelecionada === "relatorioGeral")
      this.RelatorioConsolidadoGeral();
    else if(opcaoSelecionada === "relatorioPendencias")
      this.RelatorioPendencias();
    else if(opcaoSelecionada === "relatorioVisitaPsicologo")
      this.RelatorioVisitaPsicologo();
    else if(opcaoSelecionada === "relatorioChamadoUsuario")
      this.RelatorioChamadosUsuario();
    else if(opcaoSelecionada === 'relatorioOcorrenciasAtivas')
      this.RelatorioOcorrenciasAtivas();
    else if(opcaoSelecionada === 'relatorioCalculoUsabilidade')
      this.RelatorioCalculoUsabilidade();
  }

  onOptionsSelected() {
    var opcaoSelecionada = this.formRelatorios.get('idRelatorio')?.value;
    if(opcaoSelecionada === "relatorioChamadoUsuario"){
      this.ativarCamposDiretoriaEscola = true;
      this.ativarCamposUsuario = true;
      this.ativarCamposPsicologo = false;
      this.ativarCamposOcorrenciasAtivas = false;
      this.carregarCombos();
    }else if(opcaoSelecionada === "relatorioVisitaPsicologo"){
      this.ativarCamposDiretoriaEscola = true;
      this.ativarCamposPsicologo = false;
      this.ativarCamposUsuario = false;
      this.ativarCamposOcorrenciasAtivas = false;
      // this.getCategorias();
    }else if(opcaoSelecionada === "relatorioOcorrenciasAtivas"){
      this.ativarCamposDiretoriaEscola = true;
      this.ativarCamposOcorrenciasAtivas = true;
      this.ativarCamposPsicologo = false;
      this.ativarCamposUsuario = false;
      this.getCategorias();
    }else if(opcaoSelecionada === "relatorioCalculoUsabilidade"){
      this.ativarCamposDiretoriaEscola = false;
      this.ativarCamposOcorrenciasAtivas = false;
      this.ativarCamposPsicologo = false;
      this.ativarCamposUsuario = false;
      this.getCategorias();
    }else{
      this.ativarCamposDiretoriaEscola = true;
      this.ativarCamposPsicologo = false;
      this.ativarCamposUsuario = false;
      this.ativarCamposOcorrenciasAtivas = false;
    }
  }

  carregarCombos(){
    this.getCategorias();
    this.getPerfis();
    this.getUsuarios();
  }

}
