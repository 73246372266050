//Principais
import { Component, OnDestroy, Inject, LOCALE_ID, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { DataTableDirective } from "angular-datatables";
//Métodos
import { Polo } from "src/app/shared/models/polo/Polo";
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { TurmaAluno } from "src/app/shared/models/aluno/TurmaAluno";
import { VisitaPsicologo } from "src/app/shared/models/psicologo/VisitaPsicologo";
import { Consolidado } from "src/app/shared/models/relatorio/Consolidado";
//Serviços
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { AlunoService } from "src/app/shared/services/alunos.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { AbstractControl, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-visita-editar',
  templateUrl: './visita-editar.component.html',
  styleUrls: ['./visita-editar.component.scss']
})
export class VisitaEditarComponent implements OnInit, OnDestroy {

  private get DataAtual (){return new Date()}
  public minDate:string = '2021-01-01' ;
  public maxDate:string = `${this.DataAtual.getFullYear()}-12-30` ;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;
  public hideAfterLoadTable: boolean;
  public hideAfterLoadTableAlunos: boolean;
  public isDtInitialized:boolean = false;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private alunoService: AlunoService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    @Inject(LOCALE_ID) private locale: string,
    private psicologoService:PsicologoService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
    // ng-select mudança de texto
    this.config.notFoundText = 'Não encontrado';
    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
    this.formVisitaAlunos = this.formBuilder.group({
      presenca: false,
      atendimentoClinico: true,
      encaminhamentoRedeSaude: 'Sem Encaminhamento'
    });

  }

  public polos: Polo[] = [];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public psicologos: Psicologo[] = [];
  public lstPsicologos: Psicologo[] = [];
  public lstVisitas: VisitaPsicologo[] = [];
  public lstTurmaAluno: TurmaAluno[] = [];  
  public NomePsicologo?:string;
  public showGridVisita:boolean;
  public showGridAlunos:boolean;
  public encaminhamentos = [ { id: 0, name: 'Sem Encaminhamento' }, { id: 1, name: 'Hospital Geral' }, { id: 2, name: 'Pronto Socorro' }, { id: 3, name: 'CRAS' }, { id: 4, name: 'UBS' }, { id: 5, name: 'UPA' }, { id: 6, name: 'CAISM' }, { id: 7, name: 'CAPS' }];
  public visita: VisitaPsicologo;

  public formVisitaEditar!: FormGroup;
  public get form() { return this.formVisitaEditar.controls; }
  public formVisitaAlunos!: FormGroup;
  public get formAluno() { return this.formVisitaAlunos.controls; }
  public submitted: boolean;

  public ngOnInit() {

    this.dtOptions = {
      deferRender: true,
      destroy:true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [ 10, 25, 50, -1 ],
        [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
      ],
      buttons: [
        'pageLength',
        {
          extend:'print',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        },
        {
          extend:'excel',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        },
        {
          extend:'pdf',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        }
      ],
    };

    this.formVisitaEditar = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      codigoPsicologo: new FormControl(null, [Validators.required]),
      dataInicio: new FormControl(null, [Validators.required]),
      dataFim: new FormControl(null, [Validators.required]),
    });
    
    this.formVisitaAlunos = new FormGroup({
      presenca: new FormControl(null),
      atendimentoClinico: new FormControl(null),
      encaminhamentoRedeSaude: new FormControl(null)
    });
    this.cdRef.detectChanges();

    if(this.userLogado?.flPsicologo){
      this.getPoloDiretoriaPsicologo();
    }
    else{
        if( this.atribuicoesLogin.NumeroComportamento == 1){
          this.getPolosAdmin();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 2){
          this.getPolosPerfilDiretor();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
          this.getPolosPerfilProfessor();
        }
    }
    if(this.userLogado?.codigoPolo != 0 && this.userLogado?.codigoPolo != undefined){
        this.getDiretoriasPorPolo();
        this.getEscolasPorDiretoria();
    }else{
      this.form.codigoDiretoria.disable();
      this.form.codigoEscola.disable();
    }
    this.getPsicologosPolo();
  }

  public ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public async getPoloDiretoriaPsicologo(){

    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
  }

  public async getPolosAdmin() {
    this.spinnerService.show();
       var  response = await lastValueFrom(this.psicologoService.getPolos()).catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.polos = response.data;
      this.spinnerService.hide();
      if(this.userLogado?.codigoPolo != 0){
        this.ativaPolo();
      }
    this.spinnerService.hide();
  }

  public async getPolosPerfilDiretor(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if(this.userLogado?.DadosUsuario[0].CodigoDiretoria != 0){
      var todasDiretoriasDoUsuario:Diretoria[] = [];
      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria) ;
      });
      this.diretorias = todasDiretoriasDoUsuario;

      if(this.diretorias.length == 1){
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      }else{
        this.form.codigoDiretoria.enable();
      }
      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    var dadosUsuario = this.userLogado?.DadosUsuario[0];
    if(dadosUsuario?.CodigoDiretoria != 0 && dadosUsuario?.CodigoEscola != 0){
      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas = [escola];
      });

      this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }
    this.spinnerService.hide();
  }

  public async getDiretoriasPorPolo(diretoriaLogada:boolean = false) {
    if(this.userLogado?.codigoPolo){
      this.zerarDiretoria();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.getEscolasPorDiretoria();
      this.getPsicologosPolo();
      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
      this.spinnerService.hide();
    }

    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria ;
      this.authService.setUser(this.userLogado);
    }
  }

  public async getEscolasPorDiretoria(){
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = this.userLogado.codigoEscola == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if(this.userLogado?.codigoDiretoria){
      this.spinnerService.show();
      this.zerarEscola();
      var response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(this.userLogado!.codigoDiretoria))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }
        this.escolas = response.data;
        this.ativaEscola();
        this.spinnerService.hide();
    }

  }

  public ativaPolo(){
    if(this.userLogado?.codigoPolo != undefined)
      this.formVisitaEditar.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public ativaDiretoria(){
    if(this.diretorias != undefined && this.userLogado?.codigoDiretoria != 0){
      const diretoria = this.diretorias.find(s=>s.codigoDiretoria == this.userLogado?.codigoDiretoria )
      if(this.userLogado?.codigoDiretoria != undefined  && diretoria != undefined){
        this.formVisitaEditar.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
      }
    }
    if(this.userLogado !== null)
        this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){
    if(this.escolas != undefined && this.userLogado?.codigoEscola != 0){
      const escola = this.escolas.find(s=>s.codigoEscola == this.userLogado?.codigoEscola)
      if(this.userLogado?.codigoEscola != undefined  && escola != undefined){
        this.formVisitaEditar.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }
    }
  }

  public zerarDiretoria() {
    this.diretorias = [];
    this.formVisitaEditar.get('codigoDiretoria')?.patchValue(null);
  }
  public zerarEscola() {
    this.escolas = [];
    this.formVisitaEditar.get('codigoEscola')?.patchValue(null);
  }

  public cambioPolo()
  {
    const codigoPO = this.formVisitaEditar.get('codigoPolo')?.value;
    if(this.userLogado !== null)
    {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }
    this.form.codigoDiretoria.enable();
    this.form.codigoEscola.enable();
    this.zerarEscola();
    this.getDiretoriasPorPolo();
  }

  public async trocaDiretoria(){
    const codigoDiretoria = this.formVisitaEditar.get('codigoDiretoria')?.value;
    if(this.userLogado !== null && codigoDiretoria != null)
    {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
    if(codigoDiretoria != null)
      await this.getEscolasPorDiretoria();
    else
      this.escolas = [];
      this.form.codigoEscola.setValue(null);
  }

  public async getPsicologosPolo(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(this.formVisitaEditar.get('codigoPolo')!.value))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.lstPsicologos = response.data;
  }

  public async pesqusarVisitas(){
    //this.submitted = true;
    if(!this.ValidaCampos()){
      this.spinnerService.hide();
      return;
    }
    const objParametros: Consolidado = {
      codigoPolo: this.formVisitaEditar.get('codigoPolo')?.value,
      codigoDiretoria: this.formVisitaEditar.get('codigoDiretoria')?.value == null ? 0 : this.formVisitaEditar.get('codigoDiretoria')?.value,
      codigoEscola: this.formVisitaEditar.get('codigoEscola')?.value == null ? 0 : this.formVisitaEditar.get('codigoEscola')?.value,
      codigoPsicologo: this.formVisitaEditar.get('codigoPsicologo')?.value == null ? 0 : this.formVisitaEditar.get('codigoPsicologo')?.value,
      dataInicio: this.formVisitaEditar.get('dataInicio')?.value,
      dataFim: this.formVisitaEditar.get('dataFim')?.value == null ? new Date() : this.formVisitaEditar.get('dataFim')?.value
    }

    var responseConsulta = await lastValueFrom(this.psicologoService.getVisitasPsicologo(objParametros))
    .catch((err) => {
      this.spinnerService.hide();
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!responseConsulta.isSucess){
      this.toastr.error(responseConsulta.message);
      this.spinnerService.hide();
      return;
    }
    this.showGridVisita = true;
    this.lstVisitas = responseConsulta.data;
  }

  public async OnSalvar(){
    this.spinnerService.show();
    if(!this.ValidaCampos()){
      this.spinnerService.hide();
      return;
    }
    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.formVisitaEditar.get('codigoDiretoria')?.value)[0];
    const esc = this.escolas.filter(r => r.codigoEscola == this.formVisitaEditar.get('codigoEscola')?.value)[0];
      const objVisitaPsicologo: VisitaPsicologo = {
      codigoVisita: this.visita.codigoVisita,
      codigoPolo: this.visita.codigoPolo,
      codigoDiretoria: this.visita.codigoDiretoria,
      nomeDiretoria: this.visita.nomeDiretoria,
      codigoEscola: this.visita.codigoEscola,
      nomeEscola: this.visita.nomeEscola,
      tituloVisita: this.visita.tituloVisita,
      codigoPsicologo: this.visita.codigoPsicologo,
      dataVisitaInicio: this.visita.dataVisitaInicio,
      dataVisitaFim: this.visita.dataVisitaFim,
      descricaoVisita: this.visita.descricaoVisita,
      codigoUsuario: this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      codigoAcaoPreventiva: this.visita.codigoAcaoPreventiva,
      codigoAtividade: this.visita.codigoAtividade,
      listaAlunos: this.lstTurmaAluno
      };
    var responseCadastro = await lastValueFrom(this.psicologoService.setEditarVisitaPsicologo(objVisitaPsicologo))
    .catch((err) => {
      this.spinnerService.hide();
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!responseCadastro.isSucess){
      this.toastr.error(responseCadastro.message);
      this.spinnerService.hide();
      return;
    }
    const visita: VisitaPsicologo = responseCadastro.data;
    this.toastr.success('Visita do Psicologo cadastrada com sucesso.');
    this.OnLimpar();
    this.spinnerService.hide();

  }

  public validaAno(data: AbstractControl<any, any>){
    var dataDoCampo = data.value;
    if(dataDoCampo){
     const ano = new Date(dataDoCampo).getFullYear();
      if(ano > this.DataAtual.getFullYear() || ano < this.DataAtual.getFullYear() - 10){
        data.setValue(null);
        this.toastr.warning("Data invalida! Selecione uma data valida para pesquisar.");
      }
    }
  }

  public ValidaCampos(){
    var erro = false;
    var polo = this.formVisitaEditar.get('codigoPolo')?.value;
    var dataInicio = this.formVisitaEditar.get('dataInicio')?.value;
    if(polo == 0 || polo == undefined){
      this.toastr.error("Favor de preencher o Polo");
      erro = true;
    }      
    if(dataInicio == "" || dataInicio == null || dataInicio == undefined){
      this.toastr.error("Favor de preencher a Data da Visita");    
      erro = true;
    }      
    this.lstTurmaAluno.forEach(element => {
      if((element.atendimentoClinico || element.encaminhamentoRedeSaude > 0) && (element.presenca == false || element.presenca == undefined)){
        this.toastr.error("Não podem existir Encaminhamentos para Rede de Saude ou Atendimentos clinicos se o aluno não tive presença");    
        erro = true;
      }
    });
    if(erro) return false;
    else return true;
  }

  public async onCheckVisita(codigoVisita?: number){
      this.spinnerService.show();
      this.lstVisitas.forEach(element => {
        if(element.codigoVisita == codigoVisita){
          this.visita = element;
        }
      });
      
      const result = await lastValueFrom(await this.alunoService.getAlunosVisita(codigoVisita == undefined ? 0 : codigoVisita))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.spinnerService.hide();
      this.lstTurmaAluno = result.data;
      this.showGridAlunos = true;

  // Inicializar el formulario con los datos de los alumnos
  this.formVisitaAlunos = this.formBuilder.group({
    presenca: false, 
    atendimentoClinico: false,
    encaminhamentoRedeSaude: 0
  });

      if(this.lstTurmaAluno.length == 0){
        this.toastr.warning("Não existem Alunos para essa pesquisa");
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
            this.hideAfterLoadTableAlunos = true;
          });
        }
      }
      else{
        this.hideAfterLoadTableAlunos = false;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);

          });
        } else {
          this.isDtInitialized = true;

          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }
  }

  public OnLimpar() {
    this.spinnerService.show();
    this.lstVisitas = [];
    this.lstTurmaAluno = [];
    this.showGridAlunos = false;
    this.showGridVisita = false;
    this.getPsicologosPolo();
    this.spinnerService.hide();
  }

  public formatDate(data?:Date){
    if(data == undefined) return;
   return formatDate(data,'dd/MM/yyyy HH:mm',this.locale);
  }

  public onCheckTodosFalse(){
    this.lstTurmaAluno.forEach(element => {
      element.presenca = false;
    });
  }

  public onCheckTodosTrue(){
    this.lstTurmaAluno.forEach(element => {
      element.presenca = true;
    });
  }

  public oncheckPresenca(index: number){
    this.lstTurmaAluno[index].presenca = !this.lstTurmaAluno[index].presenca;
  }
  
  public oncheckatendimentoClinico(index: number){
    this.lstTurmaAluno[index].atendimentoClinico = !this.lstTurmaAluno[index].atendimentoClinico;
  }

  public oncheckEncaminhamento(index: number, valor: number){
  }

}
