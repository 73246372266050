import { Component, EventEmitter, Input, input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseComponent } from "components/base/base.component";
import { lastValueFrom } from "rxjs";
import { Perfil } from "src/app/shared/enums/Perfis/Perfis";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { FiltroPadrao } from "src/app/shared/models/filtro-padrao/filtroPadrao";
import { AtribuicaoLoginSed } from "src/app/shared/models/sed/UserLogin";
import { callPromiseAsync } from "src/app/shared/services/convivaPromise.service";


@Component({
  selector: 'app-filtro-padrao',
  templateUrl: './filtro-padrao.component.html',
  styleUrls: ['./filtro-padrao.component.scss']

})

export class FiltroPadraoComponent extends BaseComponent implements OnInit {

  @Output() filtroEmitter = new EventEmitter<FiltroPadrao>();
  @Input() template: number = 1;
  submitted: boolean = false;
  formFilter!: FormGroup;
  escolasDiretor:Escola[] =this.escolas;
  // filtros: FiltroPadrao;
  public get form() { return this.formFilter.controls; }




  async ngOnInit(): Promise<void> {

    this.formFilter = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      codigoTurno: new FormControl(null),
      codigoTurma: new FormControl(null)
    });


    await this.UserBehavior();

    if (this.userLogado?.codigoDiretoria && this.userLogado.codigoEscola) {
      this.onSubmit();
    }

  }



  public async UserBehavior() {

    if (this.userLogado?.flPsicologo) {
      await this.getDiretoriasPerfilPsicologo();
    }
    else {
      if (this.atribuicoesLogin.NumeroComportamento == 1) {
        await this.getDiretoriasAdmin();
      }

      if (this.atribuicoesLogin.NumeroComportamento == 2) {
        await this.getDiretoriasPerfilDiretoria();
      }

      if (this.atribuicoesLogin.NumeroComportamento == 3 ||
        this.atribuicoesLogin.NumeroComportamento == 4) {
        await this.getDiretoriasPerfilEscola();
      }
    }

    this.disableVoidInputs();
    await this.activeDiretoria();
  }

  // LISTA AS DIRETORIAS QUE O USUÁRIO TEM ACESSO DE ACORDO COM O COMPORTAMENTO DELE
  private async getDiretoriasPerfilPsicologo() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo));
    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.diretorias = response.data;
    this.spinnerService.hide();


  }
  public async getDiretoriasAdmin() {
    this.spinnerService.show();


    const response = await lastValueFrom(this.escolaService.getDiretoriaPorTipoDiretoria())
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.diretorias = response.data;
    this.spinnerService.hide();


  }
  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];
    this.spinnerService.show();

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

    });

    if(this.diretorias.length === 1){
      this.form.codigoDiretoria.disable();
    }

    this.spinnerService.hide();

  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];

    this.spinnerService.show();
    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
      this.escolasDiretor.push(escola);

    });
    this.spinnerService.hide();



  }

  //LISTA AS ESCOLAS DE ACORDO COM A DIRETORIA
  public async getEscolas(codigoDiretoria: number | null = null) {
    this.spinnerService.show();
    this.disableVoidInputs();

    if (this.userLogado?.flPsicologo) {
      this.zerarEscola();

      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo));

      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.escolas = response.data.escolas;
      // this.diretorias = response.data.diretorias;
      this.form.codigoEscola.enable();
      this.spinnerService.hide();
    }
    else if (this.atribuicoesLogin.NumeroComportamento == 1 ||
        this.atribuicoesLogin.NumeroComportamento == 2 ) {
      this.zerarEscola();

      if (codigoDiretoria != null) {

        var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDiretoria))
          .catch((err) => {
            return this.convivaErrors.handleResultError(err);
          });

        if (!response.isSucess) {
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }

        this.form.codigoEscola.enable();
        this.escolas = response.data;
        await this.activeEscola();

      }
    }else if(this.atribuicoesLogin.NumeroComportamento == 3 ||
       this.atribuicoesLogin.NumeroComportamento == 4){

      this.zerarEscola();
      this.form.codigoEscola.enable();
      if(codigoDiretoria != null){
        this.escolas = this.escolasDiretor.filter(x=>x.codigoDiretoria == codigoDiretoria);
        await this.activeEscola();

      }else{
        this.form.codigoEscola.disable();
      }




    }



    if (this.userLogado) {
      this.userLogado.codigoDiretoria = codigoDiretoria ?? 0;
      this.authService.setUser(this.userLogado);
    }
    this.spinnerService.hide();
  }

  public zerarEscola() {
    this.escolas = [];
  }

  public async getTurmas(codigoEscola: number | null = null, codigoTurno: number | null = null) {

    this.spinnerService.show();
    this.turmas = [];
    this.form.codigoTurma.setValue(null);
    this.form.codigoTurma.disable();
    if (codigoEscola && codigoTurno) {
      var response = await lastValueFrom(this.escolaService.getTurmasPorCodigoCie(codigoEscola, codigoTurno))
        .catch((err) => {
          return this.convivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.turmas = response.data;
      this.form.codigoTurma.enable();
    }
    this.spinnerService.hide();
  }

  public async getTurnos() {

    this.zerarTurnos();
    this.zerarTurmas();

    if (this.form.codigoEscola.value) {
      this.spinnerService.show();

      const result = await lastValueFrom(this.escolaService.getTurnosPorCodigoCie(this.form.codigoEscola.value))
        .catch((err) => {
          return this.convivaErrors.handleResultError(err);
        });

      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.turnos = result?.data;
      this.form.codigoTurno.enable();
      if (this.turnos == null || this.turnos == undefined)
        this.toastr.warning('Não foi encontrados turnos para essa escola !');

      if (this.turnos.filter(u => u.codigoTurno == 0).length > 0) {
        this.form.codigoTurno.setValue(0);
        await this.getTurmas();
      }

      this.spinnerService.hide();
    }

    if (this.userLogado) {
      this.userLogado.codigoEscola = this.form.codigoEscola.value ?? 0;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.form.codigoTurno.patchValue(null);
    this.form.codigoTurno.disable();


  }
  public zerarTurmas() {
    this.turmas = [];
    this.form.codigoTurma.patchValue(null);
    this.form.codigoTurma.disable();
  }

  onSubmit() {

    if (this.formInvalid()) {
      this.submitted = true;
      return;
    }

    this.setFilters();
    this.filtroEmitter.emit(this.filtros);
  }


  setFilters() {
    this.filtros = new FiltroPadrao();
    this.filtros.codigoDiretoria = this.form.codigoDiretoria.value;
    this.filtros.codigoEscola = this.form.codigoEscola.value;
    this.filtros.codigoTurno = this.form.codigoTurno.value;
    this.filtros.codigoTurma = this.form.codigoTurma.value;
  }



  async activeDiretoria() {
    if (this.diretorias != undefined && this.userLogado?.codigoDiretoria != 0) {
      const diretoria = this.diretorias.find(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria)
      if (diretoria) {
        this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
        await this.getEscolas(this.form.codigoDiretoria.value);
      }
    }
  }

  async activeEscola() {
    if (this.escolas != undefined && this.userLogado?.codigoEscola != 0) {
      const escola = this.escolas.find(s => s.codigoEscola == this.userLogado?.codigoEscola)
      if (escola) {
        this.form.codigoEscola.setValue(this.userLogado?.codigoEscola);
        await this.getTurnos();
      }
    }
  }

  disableVoidInputs() {

    this.form.codigoEscola.setValue(null);
    this.form.codigoEscola.disable();
    this.form.codigoTurno.setValue(null);
    this.form.codigoTurno.disable();
    this.form.codigoTurma.setValue(null);
    this.form.codigoTurma.disable();
  }


  formInvalid(): boolean {

    const nrComportamento = this.userLogado?.NumeroComportamento ?? this.atribuicoesLogin.NumeroComportamento.toString();
    if (nrComportamento == "1") {
      this.form.codigoDiretoria.setErrors(null);
      this.form.codigoEscola.setErrors(null);
      return this.formFilter.invalid;
    }

    if (nrComportamento == "2") {
      this.form.codigoEscola.setValidators(null);
      return this.formFilter.invalid;
    }

    if (nrComportamento == "3" || nrComportamento == "4") {
      return this.formFilter.invalid;
    }

    return true;
    // return this.formFilter.invalid && this.userLogado?.codigoPolo == Perfil.GestorConviva;
  }




}
