<breadcrumb
  [paginas]="[{route:'/home',name:'Home'},{route:'/filtro-visitas-psicologo',name:'Registro da Atividade do Psicólogo'}]"
/>

<section class="section-filtro-visitas">
  <div class="container">
    <h2>
      Você deseja registrar uma atividade <br />
      de acolhimento ou prevenção?
    </h2>
    <div class="conteudo-btns-filtro">
      <a href="javascript:void(0)" (click)="AbrirModal()" class="btn_primeiro">Acolhimento</a>
      <a href="javascript:void(0)" (click)="AbrirModalPrevencao()" class="btn_segundario">Prevenção</a>
      <a href="javascript:void(0)" [routerLink]="['/visita-psicologo-outros', 0]" class="btn_segundario">Outros</a>
      <!-- <a href="javascript:void(0)" (click)="AbrirModalOutros()" class="btn_segundario">Outros</a> -->
      <!-- <a href="javascript:void(0)" [routerLink]="['/visita-psicologo-acolhimento', 1]" class="btn_terciario">Deslocamento</a> -->
    </div>
  </div>
</section>

<app-modal *ngIf="showModal">
    <div class="container-conviva form-registro modal-envolvidos modal-visita-psicologo">
      <div class="header-modal-filtro">
        <h1 class="titulo-page">{{this.tituloModal}}</h1>
        <a class="btn-fechar-modal" (click)="FecharModal()">fechar</a>
      </div>

      <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
        <div class="container-btns">
          <input type="button" [routerLink]="['/visita-psicologo-acolhimento', 0]" class="btn_outline" value="Não" />
          <input type="submit" class="btn_primary" [routerLink]="['/visita-psicologo-acolhimento', 1]" value="Sim" />
          <input type="button" (click)="FecharModal()" class="btn_terciario" value="Fechar" />
        </div>
      </div>
    </div>
</app-modal>


<app-modal *ngIf="showModalPrevencao">
  <div class="container-conviva form-registro modal-envolvidos modal-visita-psicologo">
    <div class="header-modal-filtro">
      <h1 class="titulo-page">{{this.tituloModal}}</h1>
      <a class="btn-fechar-modal" (click)="FecharModalPrevencao()">fechar</a>
    </div>

    <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
      <div class="container-btns">
        <input type="button" [routerLink]="['/visita-psicologo-prevencao', 0]" class="btn_outline" value="Não" />
        <input type="submit" class="btn_primary" [routerLink]="['/visita-psicologo-prevencao', 1]" value="Sim" />
        <input type="button" (click)="FecharModalPrevencao()" class="btn_terciario" value="Fechar" />
      </div>
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalOutros">
  <div class="container-conviva form-registro modal-envolvidos modal-visita-psicologo">
    <div class="header-modal-filtro">
      <h1 class="titulo-page">{{this.tituloModal}}</h1>
      <a class="btn-fechar-modal" (click)="FecharModalOutros()">fechar</a>
    </div>

    <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
      <div class="container-btns">
        <input type="button" [routerLink]="['/visita-psicologo-outros', 0]" class="btn_outline" value="Não" />
        <input type="submit" class="btn_primary" [routerLink]="['/visita-psicologo-outros', 1]" value="Sim" />
        <input type="button" (click)="FecharModalOutros()" class="btn_terciario" value="Fechar" />
      </div>
    </div>
  </div>
</app-modal>
