import { Injectable, signal } from "@angular/core";
import { FiltroPadrao } from "../models/filtro-padrao/filtroPadrao";


@Injectable({
  providedIn: 'root',
})
export class FiltroService {
  constructor() {}

  filtro = signal<FiltroPadrao>({
    codigoEscola: 0,
    codigoDiretoria: 0
  });


  atualizaFiltro(newFilter:FiltroPadrao){
    this.filtro.set(newFilter);
  }


}

