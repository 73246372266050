<form [formGroup]="formAtividade">
  <breadcrumb
    [paginas]="[{route:'/home',name:'Home'},{route:'/filtro-visitas-professor',name:'Registro da Atividade do POC'},{route:'/visita-professor-acolhimento',name:'Visita de Acolhimento'}]"
  />
  <section class="section-filtro">
    <div class="container">
      <div class="grupo-filter">
        <label for="select-categoria">Polo</label>
        <ng-select [items]="polos" bindLabel="descricaoPolo" bindValue="codigoPolo" placeholder="Selecione um Polo"
          name="codigoPolo" formControlName="codigoPolo" (change)="trocaPolo()" [ngClass]="{
            'is-invalid': submitted && form.codigoPolo.errors
          }">
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.descricaoPolo || "Unnamed group" }}
          </ng-template>
        </ng-select>
        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select [items]="diretorias" bindLabel="nomeDiretoria" bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria" formControlName="codigoDiretoria" name="codigoDiretoria"
          (change)="trocaDiretoria()" [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }">
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select [items]="this.escolas.length > 0 ? this.escolas : this.escolasb" bindLabel="nomeEscola" bindValue="codigoEscola" placeholder="Selecione uma Escola"
          formControlName="codigoEscola" name="codigoEscola"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }" (change)="getChamadosAgendados()">
        </ng-select>
        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page" #target>Visita de Acolhimento</h2>
    </div>
  </section>

  <step-bar [passoAtual]="Passo" (outPasso)="setNovoPasso($event)"></step-bar>

  <section class="container-conviva form-registro">

    <!-- INFO PSICOLOGO -->
    <form [formGroup]="formPassoUm" class="container form-vista-psicologo info-psicologo" *ngIf="ValidaPassoAtual(1)">
      <div class="conteudo-select">
        <label for="select-gravidade">Professor Orientador de Convivência (POC):</label>
        <ng-select [items]="lstPocs" bindLabel="nome" bindValue="cpf" placeholder="Selecione um POC" name="cpfPOC"
          formControlName="cpfPOC" [ngClass]="{   'is-invalid': submitted && formUm.cpfPOC.errors }">
        </ng-select>
        <div *ngIf="submittedPassoUm && formUm.cpfPOC.errors" class="alert">
          <div *ngIf="formUm.cpfPOC.errors.required">
            * POC obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter-full">
        <label for="select-data-visita">Data da Visita: </label>
        <input type="date" name="dataAtividade"
          [ngClass]="{   'is-invalid': submittedPassoUm && formUm.dataAtividade.errors }"
          formControlName="dataAtividade" class="input-date" />

        <div *ngIf="submittedPassoUm && formUm.dataAtividade.errors" class="alert">
          <div *ngIf="formUm.dataAtividade.errors.required">
            * Data da Visita obrigatório
          </div>
        </div>

      </div>

      <div class="conteudo-range-slider">
        <label>Quanto tempo durou a ação?</label>
        <input-range (change)="changeInputRange()" />
      </div>

      <div class="conteudo-select">
        <label for="select-gravidade">Ocorrências agendadas:</label>

        <ng-select [items]="lstOcorrenciasAgendadas" bindLabel="descricaoChamado" bindValue="codigoChamado"
          placeholder="Selecione uma Ocorrência" name="codigoChamado" formControlName="codigoChamado"
          (change)="enableTipoAtividade()" [ngClass]="{'is-invalid': submittedPassoUm && formUm.codigoChamado.errors}">

        </ng-select>
        <div *ngIf="submittedPassoUm && formUm.codigoChamado.errors" class="alert">
          <div *ngIf="formUm.codigoChamado.errors.required">
            * Ocorrência obrigatória
          </div>
        </div>
      </div>

      <div class="conteudo-select">
        <label for="select-gravidade">Qual atividade foi realizada?</label>
        <ng-select [items]="TipoAcolhimento" bindLabel="descricao" bindValue="id"
          placeholder="Selecione o tipo de atividade" name="codigoTipoAtividade" formControlName="codigoTipoAtividade"
          (change)="setTipoAtividade()" [ngClass]="{
          'is-invalid': submittedPassoUm && formUm.codigoTipoAtividade.errors}"

          >

        </ng-select>

        <div *ngIf="submittedPassoUm && formUm.codigoTipoAtividade.errors" class="alert">
          <div *ngIf="formUm.codigoTipoAtividade.errors.required">
            * Atividade obrigatória
          </div>
        </div>
      </div>

      <div class="conteudo-envolvidos" *ngIf="showEnvolvidos">
        <h3>Envolvido</h3>
        <div class="envolvidos-aluno">
          <div class="perfil-aluno-chamado" *ngFor="let envolvido of lstEnvolvidos ">
            <input type="checkbox" class="check-envolvidos"
              (click)="addEnvolvidoOnLstEnvolvidoSelecionado(envolvido.codigoEnvolvido!,$event.target!)"
              [checked]="IsEnvolvidoSelected(envolvido)" />
            <div class="img-aluno">
              <span>{{envolvido.iniciais}}</span>
            </div>
            <div class="infor-aluno-chamado">
              <p class="nome-aluno">{{envolvido.nome}}</p>
              <p class="descricao">{{envolvido.dsTipoPessoa}} - {{envolvido.dsEnvolvido}}</p>
            </div>
          </div>

        </div>

      </div>

      <div class="conteudo-envolvidos" *ngIf="showTurmas">
        <h3>Turmas</h3>

        <div class="grid-datatable">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border"
            aria-describedby="dashboard">
            <thead>
              <tr>
                <th>N</th>
                <th>Numero Turma</th>
                <th>Descrição Turma</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let turma of lstTurmas; let i = index">
                <td>{{i}}</td>
                <td>{{turma.codigoTurma}}</td>
                <td>{{turma.descricaoTurma}}</td>
                <td>
                  <label>
                    <input (change)="addTurmaOnLstTurmaSelecionada(turma.codigoTurma,$event.target!)" type="checkbox"
                      class="checkbox" title="presença" [checked]="IsTurmaSelected(turma)" />
                    <i></i>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="conteudo-envolvidos" *ngIf="showProfissionais">
        <h3>Convidados</h3>

        @for (func of lstFuncionariosSelecionados; track $index;) {
        <div class="grupo-filter-full">
          <label for="select-gravidade">
            Equipe Escolar
            <a href="javascript:void(0)" class="btn-close-convidados"
              (click)="removeFuncionario(func.codigoFuncionario)"></a>
          </label>
          <input type="text" id="func-{{$index}}" name="funcionario" readonly [value]="func.nome" />
        </div>
        }@empty {
        Nenhum profissional adicionado.
        }

        @for (outro of lstOutros; track $index;) {
        <div class="grupo-filter-full">
          <label for="select-gravidade">
            Outro
            <a href="javascript:void(0)" class="btn-close-convidados" (click)="removeOutro(outro)"></a>
          </label>
          <input type="text" id="func-{{$index}}" name="outro" readonly [value]="outro" />
        </div>
        }


        <div class="content-person">
          <a href="javascript:void(0)" class="btn-add-person btn-gg" (click)="AbreModalProfissionais()">
            <img src="../../../../assets/img/icons/icon-add.svg" alt="">
          </a>
        </div>





      </div>

      <div class="container-btns-visitas">
        <input type="button" class="btn_outline" id="btnLimpar" name="b2" (click)="OnLimpar()" value="Limpar" />
        <input type="submit" class="btn_primary" (click)="avancaParaPassoDois(target)" value="Próximo" />
      </div>
    </form>
    <!-- END INFO PSICOLOGO -->

    <!-- Passo 2 -->
    <!-- ACOES PSICOLOGO -->
    <form [formGroup]="formPassoDois" class="container form-vista-psicologo acoes-psicologo"
      *ngIf="ValidaPassoAtual(2)">

      <div class="grupo-filter-full">
        <label for="select-gravidade">Qual o objetivo da ação?</label>
        <input type="text" id="text-titulo" name="text-titulo"
        formControlName="dsObjetivo"
        (keyup)="OnChangeCountLettersObjetivo()"
        name="dsObjetivo" [ngClass]="{
          'is-invalid': submittedPassoDois && formDois.dsObjetivo.errors
        }" />
        <small>Quantidade de caracteres atual: {{qtdeCaracteresDsObjetivo}}</small>
        <div *ngIf="submittedPassoDois && formDois.dsObjetivo.errors" class="alert">
          <div *ngIf="formDois.dsObjetivo.errors.required">
            * Objetivo da ação obrigatório
          </div>
          <div *ngIf="formDois.dsObjetivo.errors?.maxlength">
            * Maximo de caracteres é 500.
          </div>

        </div>
      </div>

      <div class="grupo-filter-full">
        <label for="dsAvaliacao">Como você avalia o resultado da ação realizada?</label>
        <textarea id="dsAvaliacao"
        name="dsAvaliacao"
        rows="6"
         cols="40"
          formControlName="dsAvaliacao"
          name="dsAvaliacao"
          (keyup)="OnChangeCountLettersAvaliacao()"
          [ngClass]="{
          'is-invalid': submittedPassoDois && formDois.dsAvaliacao.errors
        }"></textarea>
        <small>Quantidade de caracteres atual: {{qtdeCaracteresDsAvaliacao}}</small>
        <div *ngIf="submittedPassoDois && formDois.dsAvaliacao.errors" class="alert">
          <div *ngIf="formDois.dsAvaliacao.errors.required">
            * Resultado da ação obrigatório
          </div>
          <div *ngIf="formDois.dsAvaliacao.errors?.maxlength">
            * Maximo de caracteres é 500.
          </div>
          <div *ngIf="formDois.dsAvaliacao.errors?.minlength">
            * Mínimo de caracteres é 150.
          </div>
        </div>
      </div>

      <div class="grupo-filter-full">
        <label for="dsImpacto">Como você acha que o estudante recebeu e foi impactado pela ação realizada?</label>
        <textarea id="dsImpacto" name="dsImpacto" rows="6" cols="40" formControlName="dsImpacto" name="dsImpacto"
          (keyup)="OnChangeCountLettersImpacto()"
        [ngClass]="{
          'is-invalid': submittedPassoDois && formDois.dsImpacto.errors
        }"></textarea>
        <small>Quantidade de caracteres atual: {{qtdeCaracteresDsImpacto}}</small>
        <div *ngIf="submittedPassoDois && formDois.dsImpacto.errors" class="alert">
          <div *ngIf="formDois.dsImpacto.errors.required">
            * Impacto da ação obrigatória
          </div>
           <div *ngIf="formDois.dsImpacto.errors?.maxlength">
            * Maximo de caracteres é 500.
          </div>
          <div *ngIf="formDois.dsImpacto.errors?.minlength">
            * Mínimo de caracteres é 150.
          </div>
        </div>
      </div>

      <div class="conteudo-upload grupo-filter-full">
        <label>Inserir documentos, fotos, áudios (MP3) ou vídeos (MP4).</label>
        <div class="input-upload">
          <input type="file" id="arquivos" name="arquivos" accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4, .ppt, .pptx, .potx, .ppsx, .odp"
            (change)="OnFileChange($event)" multiple />
          <label for="arquivos" class="message-container" *ngIf="files.length === 0">
            Nenhum arquivo escolhido
          </label>
          <label for="arquivos" class="message-container" *ngIf="files.length > 0">
            Selecionar mais arquivos
          </label>
        </div>

        <div *ngIf="files.length > 0" class="imagem-escolhida">
          <div *ngFor="let file of files; let i = index">
            <div class="imagem-item">
              <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado"
                *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

              <div class="info-container">
                <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                <span>{{ removeUniqueId(file.nome).length > 50 ? removeUniqueId(file.nome).slice(0, 50) + '...' :
                  removeUniqueId(file.nome) }}</span>
                <i class="fas fa-times" (click)="removeFile(i)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-btns-visitas">
        <input type="button" class="btn_outline" id="btnVoltar" name="b2" (click)="setNovoPasso(1)" value="Voltar" />
        <input type="button" (click)="avancaParaPassoTres(target)" class="btn_primary" value="Próximo" />
      </div>
    </form>
    <!-- END ACOES POC  -->

    <!-- Passo 3 -->
    <!-- VISUALIZACAO GERAL -->
    <div class="container form-vista-psicologo visualizacao-geral" *ngIf="ValidaPassoAtual(3)">

      <div class="grupo-filter-full">
        <label>Professor Orientador de Convivência (POC):</label>
        <p>{{this.userLogado?.nome}}</p>
      </div>

      <div class="conteudo-row">
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Data da Visita:</label>
            <p>{{getDate(this.formUm.dataAtividade.value)}}</p>
          </div>
        </div>
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Quanto tempo durou a ação?</label>
            <p>{{this.formUm.duracaoVisita.value}}MN</p>
          </div>
        </div>
      </div>

      <div class="grupo-filter-full">
        <label>Ocorrências agendadas:</label>
        <p>Chamado {{OcorrenciaSelecionada.codigoChamado}} - {{OcorrenciaSelecionada.nomeSubCategoria}} -
          {{OcorrenciaSelecionada.dsStatus}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Qual atividade foi realizada?</label>
        <p>{{tipoAtividadeSelecionada.descricao}}</p>
      </div>

      <div class="conteudo-envolvidos" *ngIf="formUm.codigoTipoAtividade.value == 1">
        <h3>Envolvido</h3>
        <div class="envolvidos-aluno">
          <div class="perfil-aluno-chamado" *ngFor="let envolvido of lstEnvolvidosSelecionados ">
            <div class="img-aluno">
              <span>{{envolvido.iniciais}}</span>
            </div>
            <div class="infor-aluno-chamado">
              <p class="nome-aluno">{{envolvido.nome}}</p>
              <p class="descricao">{{envolvido.dsTipoPessoa}} - {{envolvido.dsEnvolvido}}</p>
            </div>
          </div>

        </div>
      </div>


      <div class="conteudo-envolvidos" *ngIf="formUm.codigoTipoAtividade.value == 2">
        <h3>Turmas</h3>

        <div class="grid-datatable">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border"
            aria-describedby="dashboard">
            <thead>
              <tr>
                <th>Codigo Turma</th>
                <th>Numero Classe</th>
                <th>Tipo de Ensino</th>
                <th>Numero da Serie</th>
                <th>Descrição Turma</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let turma of lstTurmasSelecionadas">

                <td>{{turma.codigoTurma}}</td>
                <td>{{turma.numeroClasse}}</td>
                <td>{{turma.nomeTipoEnsino}}</td>
                <td>{{turma.numeroSerie}}</td>
                <td>{{turma.descricaoTurma}}</td>

              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div class="conteudo-envolvidos" *ngIf="formUm.codigoTipoAtividade.value == 3">
        <h3>Convidados</h3>

        <div class="grid-datatable outros">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border"
            aria-describedby="dashboard">
            <thead>
              <tr>
                <th>Outros</th>
              </tr>
            </thead>
            <tbody>
              @for (item of lstFuncionariosSelecionados; track $index) {
              <tr>
                <td>{{item.nome}}</td>
              </tr>
              }
              @for (item of lstOutros; track $index) {
              <tr>
                <td>{{item}}</td>
              </tr>
              }
            </tbody>
          </table>
        </div>

      </div>



      <div class="grupo-filter-full">
        <label>Qual o objetivo da ação?</label>
        <p>{{this.formDois.dsObjetivo.value}}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Como você avalia o resultado da ação realizada?</label>
        <p>{{this.formDois.dsAvaliacao.value}} </p>
      </div>

      <div class="grupo-filter-full">
        <label>Como você acha que o estudante recebeu e foi impactado pela ação realizada?</label>
        <p> {{this.formDois.dsImpacto.value}} </p>
      </div>

      <div class="grupo-filter-full">
        <label>Arquivos Adicionados</label>
        <!-- <label>inserir fotos ou vídeos</label> -->

        <a href="javascript:void(0)" *ngFor="let arquivo of files" class="icon-arquivo">
          {{arquivo.nome.substring(37,arquivo.nome.length+1)}}
        </a>
        <!-- <a href="javascript:void(0)" class="icon-arquivo">Arquivo.PPT</a> -->
      </div>

      <div class="container-btns-visitas">
        <input type="button" class="btn_outline" id="btnVoltar" name="b2" (click)="setNovoPasso(2)" value="Voltar" />

        <input type="submit" (click)="OnSalvar()" class="btn_primary" value="Enviar para aprovação"
          [disabled]="enableEnviarAprovacao" />
      </div>
    </div>
    <!-- END VISUALIZACAO GERAL  -->


  </section>


</form>


<app-modal *ngIf="showModal">
  <form [formGroup]="formModal" (submit)="OnAdicionarProfissionaisEOutros()">
    <div class="container-conviva form-registro modal-envolvidos">
      <div>
        <h1 class="titulo-page">Adicionar Pessoas</h1>
      </div>

      <div class="time-line conteudo-registro modal-conteudo-busca-aluno">

        <div>
          <div class="modal-conteudo-busca-aluno">

            <div>
              <div class="grupo-filter">
                <p><b>Tipo Pessoa</b></p>
                <ng-select [items]="tipoPessoaAdd" bindLabel="descricao" bindValue="id" formControlName="tipoPessoa"
                  (change)="changeTipoPessoaModal()" name="tipoPessoa" placeholder="Selecione um tipo de pessoa">
                </ng-select>

              </div>
            </div>
            @if(this.formModalControls.tipoPessoa.value == 2){
            <div class="conteudo-search">
              <input type="text" placeholder="Digite o CPF" id="js-input-search" formControlName="cpfBusca"
                name="cpfBusca" mask="000.000.000-00" [validation]="true" />
              <button type="button" id="btnBuscarFuncionario" (click)="GetFuncionariosPorCpf()">
                <img src="../../../assets/img/icons/icon-search.svg" alt="" />
              </button>
            </div>

            }
          </div>
          <div *ngIf="this.formModalControls.tipoPessoa.value == 2 &&
                      this.formModalControls.cpfBusca.value &&
                      this.lstFuncionarios.length > 0">
            <div class="grupo-filter">
              <p><b>Funcionario</b></p>
              <ng-select [items]="lstFuncionarios" bindLabel="nome" bindValue="cpf"
                placeholder="Selecione o funcionario" formControlName="cpfProfissionalSelecionado"
                name="cpfProfissionalSelecionado">
              </ng-select>
              <div *ngIf="submittedModal && formModalControls.cpfProfissionalSelecionado.errors" class="alert">
                <div *ngIf="formModalControls.cpfProfissionalSelecionado.errors.required">
                  * Nenhum funcionario foi selecionado.
                </div>
              </div>

            </div>
          </div>

          @if(this.formModalControls.tipoPessoa.value == 1){
          <div class="grupo-filter-full">
            <label for="dsAvaliacao">Descreva quem são os outros</label>
            <textarea id="dsOutros" name="dsOutros" rows="6" cols="40" formControlName="dsOutros"
              name="dsOutros"></textarea>

          </div>
          }
        </div>



        <div class="container-btns">
          <input type="button" (click)="FecharModal()" class="btn_outline" value="Fechar" />
          <input type="submit" class="btn_primary" value="Adicionar" />
        </div>
      </div>
    </div>
  </form>
</app-modal>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
