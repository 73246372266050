<form [formGroup]="formDeslocamento" (submit)="onSalvar()">

  <!-- Titulo -->
  <section class="section-saudacao">
    <div class="container">
      <h6 class="titulo-page">Registro de deslocamento</h6>
    </div>
  </section>

  <section class="container-conviva form-registro">
    <div class="container form-vista-psicologo ">

      <!-- Origem/Destino -->
      <div class="deslocamento">
        
        <!-- Origem -->
        <div class="box-duas-colum">
          <h3>Origem:</h3>

          <div class="grupo-filter">
            <p><b>Polo</b></p>
            <ng-select
              [items]="polosOrigem"
              bindLabel="descricaoPolo"
              bindValue="codigoPolo"
              placeholder="Selecione um Polo"
              name="codigoPoloOrigem"
              formControlName="codigoPoloOrigem"
              (change)="cambioPoloOrigem()"
              [ngClass]="{'is-invalid': submitted && form.codigoPoloOrigem.errors}"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoPoloOrigem.errors" class="alert">
              <div *ngIf="form.codigoPoloOrigem.errors.required">
                * Polo é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Diretoria</b></p>
            <ng-select
              [items]="diretoriasOrigem"
              bindLabel="nomeDiretoria"
              bindValue="codigoDiretoria"
              placeholder="Selecione uma Diretoria"
              formControlName="codigoDiretoriaOrigem"
              name="codigoDiretoriaOrigem"
              (change)="trocaDiretoriaOrigem()"
              [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoriaOrigem.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoDiretoriaOrigem.errors" class="alert">
              <div *ngIf="form.codigoDiretoriaOrigem.errors.required">
                * Diretoria Origem é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Escola</b></p>
            <ng-select
              [items]="escolasOrigem"
              bindLabel="nomeEscola"
              bindValue="codigoEscola"
              placeholder="Selecione uma Escola"
              formControlName="codigoEscolaOrigem"
              name="codigoEscolaOrigem"
              (change)="removerRota()"
              [ngClass]="{ 'is-invalid': submitted && form.codigoEscolaOrigem.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoEscolaOrigem.errors" class="alert">
              <div *ngIf="form.codigoEscolaOrigem.errors.required">
                * Escola é obrigatório
              </div>
            </div>
          </div>
        </div>

        <!-- Destino -->
        <div class="box-duas-colum">
          <h3>Destino:</h3>

          <div class="grupo-filter">
            <p><b>Polo</b></p>
            <ng-select
              [items]="polosDestino"
              bindLabel="descricaoPolo"
              bindValue="codigoPolo"
              placeholder="Selecione um Polo"
              name="codigoPoloDestino"
              formControlName="codigoPoloDestino"
              (change)="cambioPoloDestino()"
              [ngClass]="{'is-invalid': submitted && form.codigoPoloDestino.errors}"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoPoloDestino.errors" class="alert">
              <div *ngIf="form.codigoPoloDestino.errors.required">
                * Polo é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Diretoria</b></p>
            <ng-select
              [items]="diretoriasDestino"
              bindLabel="nomeDiretoria"
              bindValue="codigoDiretoria"
              placeholder="Selecione uma Diretoria"
              formControlName="codigoDiretoriaDestino"
              name="codigoDiretoriaDestino"
              (change)="trocaDiretoriaDestino()"
              [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoriaDestino.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoDiretoriaDestino.errors" class="alert">
              <div *ngIf="form.codigoDiretoriaDestino.errors.required">
                * Diretoria é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Escola</b></p>
            <ng-select
              [items]="escolasDestino"
              bindLabel="nomeEscola"
              bindValue="codigoEscola"
              placeholder="Selecione uma Escola"
              formControlName="codigoEscolaDestino"
              name="codigoEscolaDestino"
              (change)="removerRota()"
              [ngClass]="{ 'is-invalid': submitted && form.codigoEscolaDestino.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoEscolaDestino.errors" class="alert">
              <div *ngIf="form.codigoEscolaDestino.errors.required">
                * Escola é obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- KM e Tempo Total Deslocamento -->
      <div class="deslocamento">

        <div class="box-duas-colum">         

          <div class="grupo-filter">
            <p><b>Transporte</b></p>
            <ng-select
              [items]="lstTipoTransporte"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione uma Forma de Transporte"
              formControlName="codigoTipoTransporte"
              name="codigoTipoTransporte"
              (change)="removerRota()"
              [ngClass]="{ 'is-invalid': submitted && form.codigoTipoTransporte.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoTipoTransporte.errors" class="alert">
              <div *ngIf="form.codigoTipoTransporte.errors.required">
                * Tipo de Transporte é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Horário Saída: (hh:mm)</b></p>
            <input type="time" formControlName="horarioSaida" (blur)="trocaHoraSaida()"/>

            <div *ngIf="submitted && form.horarioSaida.errors" class="alert">
              <div *ngIf="form.horarioSaida.errors.required">
                *Horário de Saída é obrigatório
              </div>
            </div>
          </div>
        </div>  
        
        <div class="box-duas-colum">
          <div class="grupo-filter">
            <p><b>Tempo de Deslocamento:</b></p>
            <input type="time" formControlName="tempoDeslocamento" />
            
            <div *ngIf="submitted && form.tempoDeslocamento.errors" class="alert">
              <div *ngIf="form.tempoDeslocamento.errors.required">
                *Tempo de Deslocamento é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Horário Chegada: (hh:mm)</b></p>
            <input type="time" formControlName="horarioChegada" />
            <div *ngIf="submitted && form.horarioChegada.errors" class="alert">
              <div *ngIf="form.horarioChegada.errors.required">
                *Horário Chegada é obrigatório
              </div>
            </div>
          </div>
  
          <div class="grupo-filter">
            <p><b>Kilômetro Percorrido: (KM)</b></p>
            <input type="number" formControlName="kmPercorrido" />
            <div *ngIf="submitted && form.kmPercorrido.errors" class="alert">
              <div *ngIf="form.kmPercorrido.errors.required">
                *Kilômetro Percorrido é obrigatório
              </div>
            </div>
          </div>
        </div>    
      </div>

      <!-- Gerar Rota -->
      <div class="container-btns-visitas">
        <input type="button" class="btn_primary" value="Gerar Deslocamento" (click)="gerarRota()"/>
      </div>

      <!-- Mapa -->
      <div id="map" style="height: 400px; display: none;"></div>

      <!-- Botoes -->
      <div class="container-btns-visitas">
        <input type="reset" class="btn_outline" id="btnLimpar" name="b2" value="Limpar" (click)="onLimpar()" />

        <input type="submit" class="btn_primary" value="Próximo" />
      </div>

    </div>
  </section>
</form>
