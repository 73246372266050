import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token =  this.authService.getToken();
        if(token != null && token != "" && token != undefined){
            const isSed = req.url.includes('/credenciais');
            const isGovbr = req.url.includes('idpsp');
            const isDiario = req.url.includes('diarioclasse');

            if(isSed){
              let headers = new HttpHeaders({
                'Authorization': this.authService.getTokenGovBr(),
                'Ocp-Apim-Subscription-Key': environment.Apim,
                'Access-Control-Allow-Origin':environment.baseFrontUrl
              });

              req = req.clone({
                  withCredentials: false,
                  headers: headers
              });

              return next.handle(req);
            }
            else {

              if(isGovbr) {
                return next.handle(req);
              }if(isDiario){
                let headers = new HttpHeaders({
                  'Authorization': token,
                  'Ocp-Apim-Subscription-Key': environment.diarioKey,
                  'Access-Control-Allow-Origin':environment.baseFrontUrl
                });

                req = req.clone({
                    withCredentials: false,
                    headers: headers
                });

                return next.handle(req);
              }
              else {
                let headers = new HttpHeaders({
                  'Authorization': token,
                  'Ocp-Apim-Subscription-Key': environment.ApimConviva,
                  'Access-Control-Allow-Origin':environment.baseFrontUrl
                });

                req = req.clone({
                    withCredentials: false,
                    headers: headers
                });

                return next.handle(req);
              }
            }
        }

        return next.handle(req);
    }
}
