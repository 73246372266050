//Principais
import { AfterViewInit, Component, Inject, input, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { DataTableDirective } from "angular-datatables";
//Métodos
import { Polo } from "src/app/shared/models/polo/Polo";
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { TurmaAluno } from "src/app/shared/models/aluno/TurmaAluno";
import { VisitaPsicologo } from "src/app/shared/models/psicologo/VisitaPsicologo";
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
//Serviços
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Turma } from 'src/app/shared/models/turma/Turma';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { ProfessorService } from 'src/app/shared/services/professor.service';
import { POC } from 'src/app/shared/models/professor/POC';
import { Perfil } from 'src/app/shared/enums/Perfis/Perfis';
import { EnvolvidoService } from 'src/app/shared/services/envolvido.service';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { ChamadoService } from 'src/app/shared/services/chamado.service';
import { AtividadePOC, TipoAcaoAtividade } from 'src/app/shared/models/professor/AtividadePOC';
import { AlunoService } from 'src/app/shared/services/alunos.service';
import { formatDate } from '@angular/common';
import { Chamado } from 'src/app/shared/models/chamados/chamado';
import { ListarChamados } from 'src/app/shared/models/chamados/listar-chamados';
import { EnvolvidoAtividadePOC, VisitaPOC } from 'src/app/shared/models/professor/VisitaPOC';
import { TipoPessoa } from 'src/app/shared/enums/envolvidos/TipoPessoa';
import { Funcionario } from 'src/app/shared/models/envolvidos/funcionario';
import { GenericValidator } from 'src/app/shared/validations/genericValidators';
import { FuncionarioService } from 'src/app/shared/services/funcionarios.service';
import { Route, Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { Aluno } from 'src/app/shared/models/envolvidos/aluno';
import { DefaultResponse } from 'src/app/shared/models/DefaultResponse';

@Component({
  templateUrl: './professor-visita-prevencao.component.html',
  styleUrls: ['./professor-visita-prevencao.component.scss']
})

export class PageVisitaProfessorPrevencaoComponent implements OnInit, OnDestroy {

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;


  //Tabela
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public hideAfterLoadTable: boolean;
  public hideAfterLoadTableAlunos: boolean;
  public isDtInitialized: boolean = false;

  //Booleans
  public enableEnviarAprovacao: boolean = false;
  public showTurmas: boolean = false;
  public showProfissionais: boolean = false;
  public showDropFuncionarios: boolean = false;
  public showModal: boolean = false;
  public showModalAluno: boolean = false;
  public submittedPassoUm: boolean;
  public submittedPassoDois: boolean;
  public submitted: boolean;
  public submittedModal: boolean;
  public validaBuscaRA: boolean;
  public showDropAuxiliar: boolean;
  //Listas
  public lstFuncionarios: Funcionario[] = [];
  public lstOutros: string[] = [];
  public lstFuncionariosSelecionados: Funcionario[] = [];
  public polos: Polo[] = [];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public escolasb: Escola[] = [];
  public escolasModalEnvolvidos: Escola[] = [];
  public lstPocs: POC[] = [];
  public lstEnvolvidos: Envolvido[] = [];
  public lstEnvolvidosSelecionados: Envolvido[] = [];
  public lstTurmas: Turma[] = [];
  public lstTurmasSelecionadas: Turma[] = [];
  public files: ArquivoAzure[] = [];
  public aluno: Aluno = <Aluno>{};
  public alunos: Aluno[] = [];
  public alunosSelecionados: Aluno[] = [];



  // Objetos
  public formAtividade!: FormGroup;
  public formPassoUm!: FormGroup;
  public formPassoDois!: FormGroup;
  public formModal!: FormGroup;
  public formModalEnvolvidos!: FormGroup;
  public get form() { return this.formAtividade.controls; }
  public get formUm() { return this.formPassoUm.controls; }
  public get formDois() { return this.formPassoDois.controls; }
  public get formModalControls() { return this.formModal.controls; }
  public get formEnvolvidos() { return this.formModalEnvolvidos.controls; }


  public TipoAcolhimento = AtividadePOC.TipoAtividadeRealizada;
  public tipoAtividadeSelecionada: AtividadePOC;
  public tipoPessoaAdd = [{
    descricao: 'Outros',
    id: 1
  }, {
    descricao: 'Profissional',
    id: 2
  }]
  public get tipoAtividade():number{
    return this.formUm.codigoTipoAtividade.value;
   }

   public qtdeCaracteresDsObjetivo:number = 0;
   public qtdeCaracteresDsAvaliacao:number = 0;
   public qtdeCaracteresDsImpacto:number = 0;


  //Step Bar
  public Passo: number = 1;
  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private psicologoService: PsicologoService,
    private arquivoService: ArquivoService,
    private genericValidator: GenericValidator,
    private funcionarioService: FuncionarioService,
    @Inject(LOCALE_ID) private locale: string,
    private router: Router,
    private alunoService: AlunoService
  ) {
    // ng-select mudança de texto
    this.config.notFoundText = 'Não encontrado';
    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if (this.userLogado != null && !this.userLogado.flPsicologo) {
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public async ngOnInit(): Promise<void> {

    this.dtOptions = {
      deferRender: true,
      destroy: true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [10, 25, 50, -1],
        ['10 linhas', '25 linhas', '50 linhas', 'Tudo']
      ],
      buttons: [
        'pageLength',
        {
          extend: 'print',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        },
        {
          extend: 'excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        },
        {
          extend: 'pdf',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        }
      ],
    };
    //Formulario antigo
    this.formAtividade = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
    })

    //Formulario passo Um
    this.formPassoUm = new FormGroup({
      codigoTipoAtividade: new FormControl(null, [Validators.required]),
      cpfPOC: new FormControl(null, [Validators.required]),
      dataAtividade: new FormControl(null, [Validators.required]),
      duracaoVisita: new FormControl("15", [Validators.required]),
    });
    // Formulario passo Dois
    this.formPassoDois = new FormGroup({
      dsObjetivo: new FormControl(null, [Validators.required]),
      dsAvaliacao: new FormControl(null, [Validators.required,Validators.minLength(150)]),
      dsImpacto: new FormControl(null, [Validators.required,Validators.minLength(150)]),
    });

    this.formModal = new FormGroup({
      tipoPessoa: new FormControl(null),
      cpfBusca: new FormControl(null, [Validators.required, this.genericValidator.ValidaCpf]),
      cpfProfissionalSelecionado: new FormControl(null),
      dsOutros: new FormControl(null)
    })
    this.formModalEnvolvidos = new FormGroup({
      codigoDiretoriaModal:new FormControl(null,[Validators.required]),
      codigoEscolaModal: new FormControl(null,[Validators.required]),
      raAlunoModalDrop:new FormControl(null),
      raNomeAluno:new FormControl(null,[Validators.required])
    })

    if (this.userLogado?.flPsicologo) {
      await this.getPoloDiretoriaPsicologo();
    }
    else {
      if (this.atribuicoesLogin.NumeroComportamento == 1) {
        await this.getPolosAdmin();
      }
      if (this.atribuicoesLogin.NumeroComportamento == 2) {
        await this.getPolosPerfilDiretor();
      }
      if (this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4) {
        await this.getPolosPerfilProfessor();
      }
    }

    if (this.userLogado?.codigoPolo != 0 && this.userLogado?.codigoPolo != undefined) {
      await this.getDiretoriasPorPolo();
      await this.getEscolasPorDiretoria();
    } else {
      this.form.codigoDiretoria.disable();
      //this.form.codigoEscola.disable();
    }

    this.setPoc();
    this.formEnvolvidos.codigoEscolaModal.disable();

  }





  public ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public async getPoloDiretoriaPsicologo() {

    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
    // await this.getPocsPorPolo();
  }

  public async getPolosAdmin() {

    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos())

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = response.data;
    this.spinnerService.hide();

    if (this.userLogado?.codigoPolo != 0) {
      this.ativaPolo();
    }
    this.spinnerService.hide();
    // await this.getPocsPorPolo();
  }

  public async getPolosPerfilDiretor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if (this.userLogado?.DadosUsuario[0].CodigoDiretoria != 0) {
      var todasDiretoriasDoUsuario: Diretoria[] = [];
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria);
      });
      this.diretorias = todasDiretoriasDoUsuario;

      if (this.diretorias.length == 1) {
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      } else {
        this.form.codigoDiretoria.enable();
      }
      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
      // await this.getPocsPorPolo();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    var dadosUsuario = this.userLogado?.DadosUsuario[0];
    if (dadosUsuario?.CodigoDiretoria != 0 && dadosUsuario?.CodigoEscola != 0) {
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas.push(escola);
      });
      this.escolasb = this.escolas;
      this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.codigoEscola);
    }
    this.spinnerService.hide();
    // await this.getPocsPorPolo();
  }

  public async getDiretoriasPorPolo(diretoriaLogada: boolean = false) {
    if (this.userLogado?.codigoPolo) {
      this.zerarDiretoria();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.getEscolasPorDiretoria();
      // this.getPocsPorPolo();
      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
      this.spinnerService.hide();
    }

    if (this.userLogado !== null) {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
  }

  public async getEscolasPorDiretoria() {
    if (this.userLogado !== null) {
      this.userLogado.codigoEscola = this.userLogado.codigoEscola == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if (this.userLogado?.codigoDiretoria) {
      this.spinnerService.show();
      this.zerarEscola();
      var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(this.userLogado!.codigoDiretoria))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.escolas = response.data;
      this.ativaEscola();
      this.spinnerService.hide();
    }

  }

  public ativaPolo() {
    if (this.userLogado?.codigoPolo != undefined)
      this.formAtividade.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);

    if (this.userLogado !== null && this.userLogado)
      this.authService.setUser(this.userLogado);
  }

  public ativaDiretoria() {
    if (this.diretorias != undefined && this.userLogado?.codigoDiretoria != 0) {
      const diretoria = this.diretorias.find(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria)
      if (this.userLogado?.codigoDiretoria != undefined && diretoria != undefined) {
        this.formAtividade.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
      }
    }
    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public ativaEscola() {
    if (this.escolas != undefined && this.userLogado?.codigoEscola != 0) {
      const escola = this.escolas.find(s => s.codigoEscola == this.userLogado?.codigoEscola)
      if (this.userLogado?.codigoEscola != undefined && escola != undefined) {
        this.formAtividade.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }
    }
  }

  public zerarDiretoria() {
    this.diretorias = [];
    this.formAtividade.get('codigoDiretoria')?.patchValue(null);
  }
  public zerarEscola() {
    this.escolas = [];
    this.formAtividade.get('codigoEscola')?.patchValue(null);
  }

  public trocaPolo() {
    const codigoPO = this.formAtividade.get('codigoPolo')?.value;
    if (this.userLogado !== null) {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }
    this.form.codigoDiretoria.enable();
    this.form.codigoEscola.enable();
    this.zerarEscola();
    this.getDiretoriasPorPolo();
  }

  public async trocaDiretoria() {
    const codigoDiretoria = this.formAtividade.get('codigoDiretoria')?.value;
    if (this.userLogado !== null && codigoDiretoria != null) {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
    if (codigoDiretoria != null)
      await this.getEscolasPorDiretoria();
    else
      this.escolas = [];
    this.form.codigoEscola.setValue(null);
  }

  public async OnSalvar() {
    this.spinnerService.show();

    if (this.formAtividade.invalid) {
      this.toastr.error("Campos invalidos.");
      this.spinnerService.hide();
      return;
    }
    const visitaPOC: VisitaPOC = this.setObjectVisitaPOC();
    if (visitaPOC.nomeEscola == null || visitaPOC.nomeEscola == "") {
      this.toastr.warning("Favor de escolher uma escola.");
      this.spinnerService.hide();
      return;
    }
    var responseCadastro = await lastValueFrom(this.psicologoService.setCadastrarVisitaPOC(visitaPOC))
      .catch((err) => {
        this.spinnerService.hide();
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!responseCadastro.isSucess) {
      this.toastr.error(responseCadastro.message);
      this.spinnerService.hide();
      return;
    }
    // const visita: VisitaPsicologo = responseCadastro.data;
    this.toastr.success('Visita do POC cadastrada com sucesso.');
    this.toastr.success('Redirecionando para o ínicio em 5 segundos.');
    this.enableEnviarAprovacao = false;
    setTimeout(() => {
      window.location.reload();
    }, 5000);

    this.spinnerService.hide();
  }


  public OnLimpar() {
    this.spinnerService.show();
    this.setAlltoFalse();

    this.formUm.dataAtividade.setValue(null);
    this.formUm.codigoChamado.setValue(null);
    this.formUm.codigoTipoAtividade.setValue(null);
    this.formUm.duracaoVisita.setValue(15);
    this.formUm.duracaoVisita.setValue(15);
    // const inputRange = document.getElementById('input-range') as HTMLFormElement;
    // inputRange.value = 15;
    this.spinnerService.hide();

  }

  public concatenaDataHora(data: string, hora: string): string {
    return `${data + " " + hora}`
  }

  public async OnFileChange(event: any) {
    const maxFileSize =  31 * 1024 * 1024; // 30 MB
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      const permittedTypes: string[] = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/ppt",
        "application/pptx",
        "application/potx",
        "application/ppsx",
        "application/odp",
        "video/mp4",
        "audio/mpeg"
      ]
      for (const file of files) {
        if (file.size > maxFileSize) {
          this.toastr.warning('O tamanho máximo do arquivo é 30 MB.');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
        if (permittedTypes.includes(file.type)) {
          formData.append(file.name, file);
        }
        else {
          this.toastr.warning('Só é possivel subir arquivos .jpg, .jpeg , .png, .pdf, .mp3 , .mp4, .ppt, .pptx, .potx e ppsx');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
            fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
      }

      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      } else {
        this.files.push(...result.data);
        event.target.value = '';
      }
      this.spinnerService.hide();
    }
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
    const fileInput = document.getElementById('arquivos') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  removeUniqueId(fileName: string): string {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/;
    return fileName.replace(regex, '');
  }


  removeAluno(codigoAluno:number){
    this.alunosSelecionados = this.alunosSelecionados.filter(x=>x.codigoAluno != codigoAluno);
  }

  setPoc() {

    const { cpf, nome } = this.userLogado!;
    const professor = new POC();
    professor.cpf = cpf;
    professor.nome = nome;
    this.lstPocs = [professor];
    this.formUm.cpfPOC.setValue(cpf);
    this.formUm.cpfPOC.disable();
  }





  addEnvolvidoOnLstEnvolvidoSelecionado(codigoEnvolvido: number, target: EventTarget) {
    const checkbox = target as HTMLInputElement;

    if (checkbox.checked)
      this.lstEnvolvidosSelecionados.push(this.lstEnvolvidos.find(x => x.codigoEnvolvido == codigoEnvolvido)!);
    else
      this.lstEnvolvidosSelecionados = this.lstEnvolvidosSelecionados.filter(x => x.codigoEnvolvido != codigoEnvolvido);

  }

  async setTipoAtividade() {
    const tipoAtivdade = this.formUm.codigoTipoAtividade.value;
    this.setAlltoFalse();
    switch (tipoAtivdade) {
      case 1: {
        break;
      }
      case 2: {
        this.showTurmas = true;
        await this.getTurmasPorEscola();
        break;
      }
      case 3: {

        this.showProfissionais = true;
        break;
      }
      // default: {
      //   this.setAlltoFalse();
      //   break;
      // }
    }

    this.tipoAtividadeSelecionada = this.TipoAcolhimento.find(x => x.id == tipoAtivdade)!




  }

  setAlltoFalse() {
    this.showTurmas = false;
    this.showProfissionais = false;
    this.lstEnvolvidosSelecionados = [];
    this.lstTurmasSelecionadas = [];
    this.lstFuncionariosSelecionados = [];
    this.alunosSelecionados = [];
  }

  addTurmaOnLstTurmaSelecionada(codigoTurma: number, target: EventTarget) {
    const checkbox = target as HTMLInputElement;
    if (checkbox.checked)
      this.lstTurmasSelecionadas.push(this.lstTurmas.find(x => x.codigoTurma == codigoTurma)!);
    else
      this.lstTurmasSelecionadas = this.lstTurmasSelecionadas.filter(x => x.codigoTurma != codigoTurma);

  }

  async getTurmasPorEscola() {
    this.spinnerService.show();
    this.lstTurmas = [];
    this.lstTurmasSelecionadas = [];
    var response = await lastValueFrom(this.escolaService.getTurmasPorCodigoCie(this.form.codigoEscola.value, 0));
    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.lstTurmas = response.data;

    this.spinnerService.hide();

  }


  setNovoPasso(novoPasso: number) {

    this.Passo = novoPasso;

  }

  avancaParaPassoDois(target: HTMLElement) {
    this.submittedPassoUm = true;
    if (this.formPassoUm.invalid) {
      this.toastr.error('Há campos não preenchidos');
      return;
    }

    if (this.alunosSelecionados.length == 0 &&
      this.lstTurmasSelecionadas.length == 0 &&
      this.lstFuncionariosSelecionados.length == 0 &&
      this.lstOutros.length == 0
    ) {
      this.toastr.error('É necessario selecionar envolvidos.');
      return;
    }



    this.Passo = 2;
    this.scrollTo(target);
  }

  avancaParaPassoTres(target: HTMLElement) {
    this.submittedPassoDois = true;
    if (this.formPassoDois.invalid) {
      this.toastr.error('Há campos não preenchidos');
      return;
    }

    if (this.files.length == 0) {
      this.toastr.error('É necessário selecionar arquivos para a atividade.');
      return;
    }



    this.Passo = 3;
    this.scrollTo(target);
  }

  ValidaPassoAtual(passo: number): boolean {
    return this.Passo == passo;
  }

  public getDate(data?: Date) {
    if (data == undefined) return;
    return formatDate(data, 'dd/MM/yyyy', this.locale);
  }


  changeInputRange() {
    const inputRange = document.getElementById('input-range') as HTMLFormElement;
    this.formUm.duracaoVisita.setValue(inputRange.value);
  }

  IsEnvolvidoSelected(envolvido: Envolvido): boolean {
    return this.lstEnvolvidosSelecionados.includes(envolvido);
  }

  IsTurmaSelected(turma: Turma): boolean {
    return this.lstTurmasSelecionadas.includes(turma);
  }
  private setObjectVisitaPOC(): VisitaPOC {
    const nomeDiretoria = this.diretorias.find(r => r.codigoDiretoria == this.form.codigoDiretoria.value)?.nomeDiretoria;
    const nomeEscola = this.escolas
      .find(r => r.codigoEscola == this.form.codigoEscola.value)?.nomeEscola
      .replace(this.form.codigoEscola.value, "")
      .replace("-", "")
      .trim();


    const obj: VisitaPOC = {
      //Informações diretoria e escola
      codigoPolo: this.form.codigoPolo.value,
      codigoDiretoria: this.form.codigoDiretoria.value,
      nomeDiretoria: nomeDiretoria!,
      codigoEscola: this.form.codigoEscola.value,
      nomeEscola: nomeEscola!,
      //Informações Passo Um
      cpf: this.formUm.cpfPOC.value,
      nome: this.userLogado!.nome,
      dataAtividadePOC: this.formUm.dataAtividade.value,
      timeAtividadePOC: this.formUm.duracaoVisita.value,
      tipoAtividadeRealizada: this.formUm.codigoTipoAtividade.value,
      tipoAcaoAtividade: TipoAcaoAtividade.Prevenção,
      //Informações Passo dois
      descricaoRespostaPerguntaObjetivoAcao: this.formDois.dsObjetivo.value,
      descricaoRespostaPerguntaAvaliacaoResultadoAcao: this.formDois.dsAvaliacao.value,
      descricaoRespostaPerguntaImpactoAcaoRealizada: this.formDois.dsImpacto.value,
      arquivos: this.files,
      listaEnvolvidos: this.mappingArrayEnvolvidos(),
      codigoPerfil: this.userLogado!.codigoPerfil,
      nomePerfil: this.userLogado!.nomePerfil,

    };

    return obj;
  }

  private mappingArrayEnvolvidos(): EnvolvidoAtividadePOC[] {
    var envolvidosNaAtividade: EnvolvidoAtividadePOC[] = [];
    var tipoAtividade: number = this.formUm.codigoTipoAtividade.value;

    if (tipoAtividade == 1) { // tipo envolvidos

      envolvidosNaAtividade = this.alunosSelecionados.map((x: Aluno) => {
        var envolvidoAtv: EnvolvidoAtividadePOC = new EnvolvidoAtividadePOC();
        envolvidoAtv.tipoPessoa = TipoPessoa.Aluno;
        envolvidoAtv.codigoAluno = x.codigoAluno;
        envolvidoAtv.numeroRA = x.ra;
        return envolvidoAtv;
      });

    } else if (tipoAtividade == 2) { //tipo grupo
      envolvidosNaAtividade = this.lstTurmasSelecionadas.map((x: Turma) => {
        var envolvidoAtv: EnvolvidoAtividadePOC = new EnvolvidoAtividadePOC();
        envolvidoAtv.codigoTurma = x.codigoTurma;
        envolvidoAtv.tipoPessoa = TipoPessoa.Turma;
        envolvidoAtv.numeroClasse = x.numeroClasse;
        envolvidoAtv.nomeTipoEnsino = x.nomeTipoEnsino;
        envolvidoAtv.numeroSerie = x.numeroSerie;
        envolvidoAtv.descricaoTurma = x.descricaoTurma;
        return envolvidoAtv;
      });
    } else { //tipo outros

      const funcionarios = this.lstFuncionariosSelecionados.map((x: Funcionario) => {
        var envolvidoAtv: EnvolvidoAtividadePOC = new EnvolvidoAtividadePOC();
        envolvidoAtv.numeroCPF = x.cpf;
        envolvidoAtv.tipoPessoa = TipoPessoa.EquipeEscolar;
        return envolvidoAtv;
      });
      const outros = this.lstOutros.map(x => {
        var envolvidoAtv: EnvolvidoAtividadePOC = new EnvolvidoAtividadePOC();
        envolvidoAtv.outros = x;
        envolvidoAtv.tipoPessoa = TipoPessoa.Outros;
        return envolvidoAtv;
      });

      envolvidosNaAtividade = funcionarios.concat(outros);

    }


    return envolvidosNaAtividade;
  }


  OnAdicionarProfissionaisEOutros() {
    const tipoPessoa = this.formModalControls.tipoPessoa.value;
    const dsOutros = this.formModalControls.dsOutros.value;
    const cpfSelecionado = this.formModalControls.cpfProfissionalSelecionado.value;
    if (tipoPessoa == 1 && dsOutros) {
      this.lstOutros.push(dsOutros);
    } else if (tipoPessoa == 2 && cpfSelecionado) {
      var funcionario = this.lstFuncionarios.find(x => x.cpf == cpfSelecionado);
      if (funcionario) {
        this.lstFuncionariosSelecionados.push(funcionario);
      }
    } else {
      this.toastr.error('Preencha todos os campos.');
    }
    this.lstFuncionarios = [];
    this.FecharModal();
    this.formModalControls.tipoPessoa.setValue(null);
    this.formModalControls.cpfBusca.setValue(null);
    this.formModalControls.dsOutros.setValue(null);


  }

  public async GetFuncionariosPorCpf() {
    if (this.formModal.invalid) {
      this.toastr.warning('CPF invalido.');
      this.spinnerService.hide();
      return;
    }
    this.showDropFuncionarios = false;
    this.spinnerService.show();
    const cpf = this.formModalControls.cpfBusca.value;
    const result = await lastValueFrom(
      this.funcionarioService.getFuncionariosPorCpf(cpf))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });


    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    if (result.data != null) {
      this.lstFuncionarios = result.data;
    }
    this.showDropFuncionarios = true;
    if (this.lstFuncionarios.length == 0) {
      this.toastr.warning('Nenhum funcionario encontrado!');
    } else {
      if (this.lstFuncionarios.length == 1) {
        this.formModalControls.cpfProfissionalSelecionado.patchValue(this.lstFuncionarios[0].cpf);
      }
    }
    this.spinnerService.hide();
  }

  AbreModalProfissionais() {
    this.showModal = true;
  }

  FecharModal() {
    this.showModal = false;
    this.showDropFuncionarios = false;
    this.formModalControls.cpfBusca.setValue(null);
    this.formModalControls.dsOutros.setValue(null);
  }

  removeFuncionario(codigoFuncionario: number) {
    this.lstFuncionariosSelecionados = this.lstFuncionariosSelecionados.filter(x => x.codigoFuncionario != codigoFuncionario);
  }

  removeOutro(outro: string) {
    this.lstOutros = this.lstOutros.filter(x => x != outro);
  }

  changeTipoPessoaModal() {
    this.showDropFuncionarios = false;
    this.lstFuncionarios = [];
  }
  scrollTo(el: HTMLElement) {
    el.scrollIntoView();
  }


  // downloadArquivo(arquivo:ArquivoAzure){

  //   if(arquivo.nome.includes('mp4')){
  //     const byteArray = new TextEncoder().encode(arquivo.conteudo);
  //     // Criar um Blob a partir do array de bytes
  //     const blob = new Blob([byteArray],{type:'video/mp4'});
  //     saveAs(blob,'video.mp4');


  //   }
  // }

  AbreModalAluno(){
    this.showModalAluno = true;
  }

  FecharModalAluno(){
    this.showModalAluno = false;
    this.resetModalEnvolvidos();
  }

  OnAdicionarEnvolvido(){

    var alunoSelecionado= this.alunos.find(x=>x.ra == this.formEnvolvidos.raAlunoModalDrop.value)!;
    this.alunosSelecionados.push(alunoSelecionado);
    this.showDropAuxiliar = false;
    this.showModalAluno = false;
    this.resetModalEnvolvidos();

  }

  public async getEscolasModalEnvolvidos() {
    this.spinnerService.show();
    this.zerarEscolaModal();

    const codigoDE = this.formEnvolvidos.codigoDiretoriaModal.value;
    if (codigoDE) {
      const result = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.escolasModalEnvolvidos = result.data;
      this.formEnvolvidos.codigoEscolaModal.enable();
      this.spinnerService.hide();
    }else{
      this.escolasModalEnvolvidos = [];
      this.spinnerService.hide();
    }
  }

  zerarEscolaModal(){
    this.escolasModalEnvolvidos = [];
  }
  public BuscarAlunoPorNomeRA() {
    const regexNumeros = /\d$/;
    const regexLetras = /^[a-zA-Z]+$/;
    const regexAmbos = /^[a-zA-Z0-9]+$/;

    this.formEnvolvidos.raAlunoModalDrop.patchValue(null);
    this.formEnvolvidos.raAlunoModalDrop.removeValidators([Validators.required]);
    this.validaBuscaRA = false;
    let nomeRa = this.formEnvolvidos.raNomeAluno.value;
    let buscarPorRa = false;

    if (regexNumeros.test(nomeRa)) {
      this.formEnvolvidos.codigoDiretoriaModal.clearAsyncValidators();
      this.formEnvolvidos.codigoEscolaModal.clearAsyncValidators();
      buscarPorRa = true;
    }
    else if (regexLetras.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.formEnvolvidos.codigoDiretoriaModal.addValidators([Validators.required]);
      this.formEnvolvidos.codigoEscolaModal.addValidators([Validators.required]);
    }
    else if (regexAmbos.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.formEnvolvidos.raNomeAluno.setValue(nomeRa.replace(/\d/g, ''));
    }
    else {
    }

    this.AtualizarValidacoesAlunos();

    if (this.formEnvolvidos.invalid) {
      this.toastr.warning("Deverá ser selecionado o aluno ofendido/ofensor");
      return;
    }

    if (buscarPorRa) {
      this.getAlunosPorRa();
    } else {
      this.getAlunosPorNome();
    }

    this.formEnvolvidos.raAlunoModalDrop.addValidators([Validators.required]);
    this.AtualizarValidacoesAlunos();

  }



  public AtualizarValidacoesAlunos() {
    this.formEnvolvidos.codigoDiretoriaModal.updateValueAndValidity();
    this.formEnvolvidos.codigoEscolaModal.updateValueAndValidity();
    this.formEnvolvidos.raNomeAluno.updateValueAndValidity();
    this.formEnvolvidos.raAlunoModalDrop.updateValueAndValidity();
  }

  public async getAlunosPorRa() {
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const raAluno = this.formModal.get('raNomeAluno');
    const result = await lastValueFrom( this.alunoService.getAlunosPorRa(raAluno?.value))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunoPorRA(result);
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunoPorRA(response: DefaultResponse) {
    if (response.data != null) {
      this.aluno = response.data;
      this.formEnvolvidos.raAlunoModalDrop.patchValue(response.data.ra);
      this.alunos.push(this.aluno);
      this.showDropAuxiliar = true;
      this.toastr.success('Aluno carregado com sucesso.');
      return;
    }
    this.toastr.warning('Nenhum aluno encontrado!');
  }


  public async getAlunosPorNome() {
    const nmAluno = this.formEnvolvidos.raNomeAluno.value;
    const codigoDiretoria = this.formEnvolvidos.codigoDiretoriaModal.value;
    const codigoEscola = this.formEnvolvidos.codigoEscolaModal.value;
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const result = await lastValueFrom(this.alunoService.getAlunosPorNome(nmAluno, codigoDiretoria, codigoEscola))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunos(result);
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunos(response: DefaultResponse) {
    if (response.data != null) {
      this.alunos = response.data;
    }
    if (this.alunos.length == 0) {
      this.toastr.warning('Nenhum aluno encontrado!');
    }
    if (this.alunos.length == 1) {
      this.formModal.get('raAlunoModalDrop')?.patchValue(this.alunos[0].ra);
    }
    if (this.alunos.length > 1) {
      this.toastr.success('Alunos carregados com sucesso. Por favor selecione um.');
    }
    this.showDropAuxiliar = true;
  }


  resetModalEnvolvidos(){
    this.formModalEnvolvidos.reset();

  }

  OnChangeCountLettersAvaliacao(){
    const dsAvaliacao = this.formDois.dsAvaliacao.value;
    if(dsAvaliacao != null)
      this.qtdeCaracteresDsAvaliacao = dsAvaliacao.length;
  }

  OnChangeCountLettersImpacto(){
    const dsImpacto = this.formDois.dsImpacto.value;
    if(dsImpacto != null)
      this.qtdeCaracteresDsImpacto= dsImpacto.length;
  }
  OnChangeCountLettersObjetivo(){
    const dsObjetivo = this.formDois.dsObjetivo.value;
    if(dsObjetivo != null)
      this.qtdeCaracteresDsObjetivo = dsObjetivo.length;
  }
}
