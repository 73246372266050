<form [formGroup]="formCadastrar" (submit)="OnPesquisar()">
  <breadcrumb
    [paginas]="[{route:'/home',name:'Home'},{route:'/listar-chamados',name:'Estudantes com Ocorrências Registradas'}]"
  />
  <!-- FILTRO ESCOLA -->
  <section class="section-filtro">
    <div class="container">

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
          (change)="getEscolas()"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          (change)="getTurnos()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Turno</b></p>
        <ng-select
          [items]="turnos"
          bindLabel="nomeTurno"
          bindValue="codigoTurno"
          placeholder="Selecione um Turno"
          formControlName="codigoTurno"
          name="codigoTurno"
          [ngClass]="{ 'is-invalid': submitted && form.codigoTurno.errors }"
          (change)="getTurmas()"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoTurno.errors" class="alert">
          <div *ngIf="form.codigoTurno.errors.required">
            * Turno é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Turma</b></p>
        <ng-select
          [items]="turmas"
          bindLabel="descricaoTurma"
          bindValue="codigoTurma"
          placeholder="Selecione uma Turma"
          formControlName="codigoTurma"
          name="codigoTurma"
          [ngClass]="{ 'is-invalid': submitted && form.codigoTurma.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoTurma.errors" class="alert">
          <div *ngIf="form.codigoTurma.errors.required"></div>
        </div>
      </div>

      <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
        <img
          src="../../../../assets/img/icons/icon-search.svg"
          alt="Pesquisar Chamados"
          title="Pesquisar Chamados"
        />
      </button>
    </div>
  </section>

  <!-- SAUDACAO USUÝRIO -->
  <section class="section-saudacao">
    <div class="container">
      <p>
        <b>Olá,</b> {{this.userLogado?.flPsicologo ? "Psicólogo(a)": this.atribuicoesLogin.NomePerfil}}<br />
        <b>{{this.userLogado?.flPsicologo ? this.userLogado?.nome : this.atribuicoesLogin.Nome}}</b>
      </p>
    </div>
  </section>



  <!-- TITULO -->
  <section class="section-saudacao header-page-voltar">
    <div class="container">
      <h2 class="titulo-page" #target>Estudantes com Ocorrências Registradas</h2>

      <a *ngIf="showHistorico" class="btn-voltar-page" (click)="Voltar()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </a>
    </div>
  </section>

  <!-- RESULTADOS -->
  <section class="conteudo-principal">
    <div class="container-conviva">

      <!-- LISTAGEM CHAMADOS -->
      <div class="time-line mx-w-900">

        @if(this.showMenu) {
          <div class="section-filtro filtro-gravidade-status">
            <div class="container">

              <div class="grupo-filter-gravidade">
                <label for="filter-select">Gravidade</label>
                <ng-select
                  [items]="gravidades"
                  bindLabel="name"
                  bindValue="id"
                  class="select-gravidade"
                  placeholder="Selecione uma Gravidade"
                  (change)="FiltraChamadosAtuais()"
                  formControlName="codigoGravidade"
                  name="codigoGravidade"
                >
                </ng-select>
              </div>

              <div class="grupo-filter-status">
                <label for="filter-select">Status</label>
                <ng-select
                  [items]="status"
                  bindLabel="name"
                  bindValue="id"
                  class="select-status"
                  placeholder="Selecione um Status"
                  (change)="FiltraChamadosAtuais()"
                  formControlName="codigoStatus"
                  name="codigoStatus"
                >
                </ng-select>
              </div>

              <div class="grupo-filter-sla">
                <label for="filter-select">SLA</label>
                <ng-select
                  [items]="slas"
                  bindLabel="name"
                  bindValue="id"
                  class="select-sla"
                  placeholder="Selecione um SLA"
                  (change)="FiltraChamadosAtuais()"
                  formControlName="codigoSLA"
                  name="codigoSLA"
                >
                </ng-select>
              </div>

            </div>
          </div>
        }
        <h2>Todos os Envolvidos</h2>

        <div class="section-filtro">
          <div class="container">
            <div>
              <label for="filter-select">Ocorrência</label>
              <input type="number" placeholder="Numero da Ocorrência" class="filtro-ocorrencia" (input)="FiltrarChamadoId($event)"/>
            </div>
          </div>
        </div>

        @if(this.submitted && this.showPesquisa) {
          <div class="listagem-card-chamado">
            @for(item of this.lstChamados | slice: (page-1) * 4 : page * 4; track item; let i = $index) {
              <div>
                <a href="javascript:void(0)"
                  class="card-chamado"
                  [ngClass]="{
                    'aberto' :  item.aluno?.statusEnvolvido ==  0,
                    'encaminhado' :  item.aluno?.statusEnvolvido == 1 || item.aluno?.statusEnvolvido == 2 || item.aluno?.statusEnvolvido == 3 ,
                    'agendado' :  item.aluno?.statusEnvolvido == 4,
                    'atendido' :  item.aluno?.statusEnvolvido == 5,
                    'encerrado' :  item.aluno?.statusEnvolvido == 6,
                    'ativo':item.codigoEnvolvido == envolvidoSelecionado
                  }"
                  (click)="HistoricoChamado(item,target)">

                  @if(!item.temFoto){
                    <div class="photo-aluno">
                      <span>{{ item.iniciaisNome }}</span>
                    </div>
                  }
                  @else {
                    <div class="photo-aluno">
                      <img src="data:image/jpeg;base64,{{ item.foto }}" alt="Foto do Aluno" />
                    </div>
                  }

                  <div class="card-chamado__info" >
                    <p class="numero-chamado">Chamado {{ item.codigoChamado }}</p>
                    <p class="nome-aluno">
                      {{
                        item.tipoPessoa == 1
                          ? item.aluno?.nomeAluno
                          : item.tipoPessoa == 2
                          ? item.equipeEscolar?.nome
                          : item.descricaoOutros
                      }}
                    </p>
                    <p class="descricao-chamado">
                      {{
                        item.dsPessoa +
                          " - " +
                          item.dsEnvolvido +
                          " - " +
                          item.nomeSubCategoria
                      }}
                    </p>
                    <div class="card-footer">
                      <p>
                        <small>{{ item.horasAtras }}</small>
                      </p>
                      <div class="card-status">
                        {{item.aluno?.dsStatusEnvolvido}} <i class="fa fa-check"></i>
                      </div>
                    </div>
                    <p class="descricao-chamado">
                      Data da ocorrência: <small>{{ item.dataChamado | date : 'dd/MM/yyyy HH:mm'}}</small>
                      Data de abertura: <small>{{ item.dataInclusao | date : 'dd/MM/yyyy HH:mm'}}</small>
                    </p>
                  </div>

                </a>
              </div>
            }

            <ngb-pagination [(page)]="page" [pageSize]="4" [maxSize]="4" [collectionSize]="this.lstChamados.length" class="d-flex justify-content-center" size="lg" />
          </div>
        }
      </div>

      <!-- HISTORICO CHAMADO -->
      @if(this.showHistorico) {
        <div class="historico-box">
          <div class="conteudo-chamado">

            <!-- HEADER HISTORICO -->
            <div class="header-perfil-chamado">
              <div class="perfil-aluno-chamado">

                <div class="perfil-aluno aberto">
                  @if(!chamadoHistorico.temFoto) {
                    <span>{{ chamadoHistorico.iniciaisNome }}</span>
                  }
                  @else {
                    <div class="img-perfil-aluno">
                      <img src="data:image/jpeg;base64,{{ chamadoHistorico.foto }}" alt="Foto do Aluno"/>
                    </div>
                  }
                </div>

                <div class="infor-aluno-chamado">
                  <a href="javascript:void(0)" (click)="OnVisualizarChamado(chamadoHistorico.codigoChamado)" title="Visualizar Chamado">
                    <p class="numero-chamado">
                      Chamado {{ chamadoHistorico.codigoChamado }}
                    </p>
                  </a>

                  <a href="javascript:void(0)" title="Perfil Estudante" [routerLink]="['/perfil-aluno/', chamadoHistorico.aluno?.ra]">
                    <p class="nome-aluno">
                      {{
                        chamadoHistorico.tipoPessoa == 1
                          ? chamadoHistorico.aluno?.nomeAluno
                          : chamadoHistorico.tipoPessoa == 2
                          ? chamadoHistorico.equipeEscolar?.nome
                          : chamadoHistorico.descricaoOutros
                      }}
                    </p>
                  </a>

                  <p class="descricao">
                    {{ chamadoHistorico.dsPessoa + " - " + chamadoHistorico.dsEnvolvido + " - " + chamadoHistorico.nomeSubCategoria }}
                  </p>
                </div>
              </div>

              <input type="checkbox" checked class="card-opcoes-chamado" id="opcoes-chamado-01"/>

              <!-- OPÇÕES DE LISTAR CHAMADO -->
              <label for="opcoes-chamado-01">
                <div class="opcoes-card caixa-opcoes">

                  <a *ngIf="this.userLogado?.funcionalidades?.includes(4) &&
                        chamadoHistorico.aluno?.statusEnvolvido === 0 &&
                        chamadoHistorico.status === 0"
                      href="javascript:void(0)"
                     (click)="OnExcluirChamado(chamadoHistorico.codigoChamado)">
                    Excluir
                  </a>

                  <a href="javascript:void(0)"
                    *ngIf="chamadoHistorico.aluno?.statusEnvolvido === 0 &&
                      this.userLogado?.funcionalidades?.includes(3) &&
                      chamadoHistorico.status === 0"
                     routerLink="/editar-chamados/{{chamadoHistorico.codigoChamado}}">
                    Alterar
                  </a>

                  <a href="javascript:void(0)"
                    *ngIf="((chamadoHistorico.aluno?.statusEnvolvido === 0)
                    && (this.userLogado?.funcionalidades?.includes(6) ||
                     this.userLogado?.funcionalidades?.includes(7) ||
                     this.userLogado?.funcionalidades?.includes(8)))"
                    (click)="AbrirModalEncaminhamento(chamadoHistorico.codigoChamado, chamadoHistorico.codigoEnvolvido)">
                      Encaminhamento
                  </a>

                  <a href="javascript:void(0)" *ngIf="
                    ((chamadoHistorico.aluno?.statusEnvolvido == 1 || chamadoHistorico.aluno?.statusEnvolvido == 2 || chamadoHistorico.aluno?.statusEnvolvido == 3)&&
                    this.userLogado?.funcionalidades?.includes(6))"
                      (click)="OnEliminarEncaminhamento(chamadoHistorico.codigoChamado, chamadoHistorico.codigoEnvolvido)">
                      Eliminar Encaminhamento
                  </a>

                  <a href="javascript:void(0)"
                    *ngIf="chamadoHistorico.aluno?.statusEnvolvido == 4 &&
                    this.userLogado?.funcionalidades?.includes(11) &&
                    (this.userLogado?.DadosUsuario![0].CodigoPerfil == 1634 ||
                    this.userLogado?.DadosUsuario![0].CodigoPerfil == 1477 || this.userLogado?.DadosUsuario![0].CodigoPerfil == 1632)"
                    (click)="AbrirModalAtenderPsicologo(chamadoHistorico.codigoChamado)">
                    Atendimento
                  </a>

                  <a href="javascript:void(0)" *ngIf="(chamadoHistorico.aluno?.statusEnvolvido == 5 || chamadoHistorico.aluno?.statusEnvolvido == 2 || chamadoHistorico.aluno?.statusEnvolvido == 3 || chamadoHistorico.aluno?.statusEnvolvido == 0) && (this.userLogado?.funcionalidades?.includes(5))"
                    (click)="AbrirModalConcluirChamado(chamadoHistorico.codigoChamado)">
                    Concluir
                  </a>

                  <a href="javascript:void(0)"
                  (click)="OnVisualizarChamado(chamadoHistorico.codigoChamado)"
                  >
                    Visualizar Chamado
                  </a>

                </div>
                <a class="btn-opcoes"></a>
              </label>
            </div>

            <!-- OPÇÕES ENCAMINHAMENTO -->
            <div class="tab-status">
              <a href="javascript:void(0)" class="status ativo" (click)="StatusChamado()">
                Status
              </a>

              @if( (chamadoHistorico.aluno?.statusEnvolvido === 5 || chamadoHistorico.aluno?.statusEnvolvido === 2 || chamadoHistorico.aluno?.statusEnvolvido === 3) &&
                   (this.userLogado?.funcionalidades?.includes(5)) ) {

                  <div>
                    <button type="button" (click)="AbrirModalConcluirChamado(chamadoHistorico.codigoChamado)" class="btn-action-flut">
                      &nbsp; Concluir
                    </button>
                  </div>
              }

              @if(chamadoHistorico.aluno?.statusEnvolvido === 0  ) {
                <div class="opcoes-encaminhamentos">
                  <p>Encaminhamentos:</p>
                  <div class="encaminhamentos">
                    @if (this.userLogado?.funcionalidades?.includes(6)) {
                      <a href="javascript:void(0)" title="Psicólogo" class="encaminhamentos__psc" (click)="EncaminhamentoPsicologo()"></a>
                    }
                    @if (this.userLogado?.funcionalidades?.includes(7)) {
                      <a href="javascript:void(0)" title="Rede Protetiva" class="encaminhamentos__police" (click)="EncaminhamentoPolicia()"></a>
                    }
                    @if (this.userLogado?.funcionalidades?.includes(8)) {
                      <a href="javascript:void(0)" title="Saúde" class="encaminhamentos__obs" (click)="EncaminhamentoSaude()"></a>
                    }
                  </div>
                </div>
              }

            </div>

            <!-- CONTEUDO HISTORICO -->
            <div *ngIf="showConteudoChamado">
              <div class="conteudo-do-chamado-chat">
                <div
                  class="container-text-historico"
                  *ngFor="let historico of this.lstHistoricos"
                >
                  <div
                    *ngIf="historico.descricaoHistorico != ''"
                    class="text-historico"
                  >
                    <p>{{ historico.descricaoHistorico }}</p>
                    <i *ngIf="historico.infoAdicional1" class="info-adiconal">- {{historico.infoAdicional1}}</i>
                    <i *ngIf="historico.infoAdicional2" class="info-adiconal">- {{historico.infoAdicional2}}</i>
                  </div>
                  <div class="imagem-destaque-card">
                    <div
                      *ngIf="historico.caminhoArquivo != ''"
                      class="imagem-destaque-card"
                    >
                      <img src="{{ historico.caminhoArquivo }}" alt="" />
                    </div>
                  </div>
                  <div class="div-informacoes-historico">
                    <p class="time-left">
                      {{ historico.data }} - {{ historico.hora }} - {{ historico.nomeUsuario }}
                    </p>
                    <i *ngIf="this.userLogado?.DadosUsuario![0].CodigoPerfil == 1477 ||
                              this.userLogado?.DadosUsuario![0].CodigoPerfil == 1634"
                    class="icon-plus-add-hist" (click)="openModalAddInfo(historico.codigoHistorico)"></i>
                  </div>
                </div>

                <div *ngIf="this.userLogado?.funcionalidades?.includes(9)" class="input-upload">
                  <form [formGroup]="formCadastrarHistorico">
                    <textarea
                      rows="4"
                      cols="50"
                      id="dsHistoricoChamado"
                      [(ngModel)]="dsHistoricoChamado"
                      formControlName="dsHistoricoChamado"
                      (keyup)="OnChangeCountLetters()"
                      name="dsHistoricoChamado"
                      placeholder="O máximo de caracteres permitidos são 500 e o mínimo são 10."
                      maxlength="500"
                    >
                    </textarea>
                    <small>Quantidade de caracteres:{{qtdCaracteresHistorico}}</small>
                    <input
                      type="file"
                      id="arquivos"
                      name="arquivos"
                      accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4"
                      (change)="OnFileChange($event)"
                      #archivoInput
                    />
                    <input
                      type="button"
                      id="btnSalvarHistorico"
                      class="btn-upload"
                      (click)="onSalvarHistorico()"
                    />
                  </form>
                </div>
              </div>
            </div>

            <!-- STATUS CHAMADO -->
            <div class="chamado-historico" *ngIf="showStatus">
              <ul>

                <li *ngIf="this.statusCriada">
                  <p>Ocorrência criada</p>
                  <p><small>{{statusCriada}}</small></p>
                </li>

                <li *ngIf="this.statusEncaminhadaPolicia">
                  <p>Ocorrência Encaminhada a Rede protetiva</p>
                  <p><small>{{this.statusEncaminhadaPolicia}}</small></p>
                </li>

                <li *ngIf="this.statusEncaminhadaSaude">
                  <p>Ocorrência Encaminhada a Saúde</p>
                  <p><small>{{this.statusEncaminhadaSaude}}</small></p>
                </li>

                <li *ngIf="this.statusEncaminhada">
                  <p>Ocorrência Encaminhada ao Psicólogo</p>
                  <p><small>{{this.statusEncaminhada}}</small></p>
                </li>

                <li *ngIf="this.statusAgendada">
                  <p>Ocorrência Agendada</p>
                  <p><small>{{this.statusAgendada}}</small></p>
                </li>

                <li *ngIf="this.statusAtendida">
                  <p>Ocorrência Atendida</p>
                  <p><small>{{this.statusAtendida}}</small></p>
                </li>

                <li *ngIf="this.statusFechada">
                  <p>Ocorrência Resolvida</p>
                  <p><small>{{this.statusFechada}}</small></p>
                </li>

              </ul>
            </div>
          </div>
        </div>
      }

    </div>
  </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModalExcluirChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span>{{ this.descricaoExclusao }}</span>
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalExclusao()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="ExluirChamado(this.chamadoARemover)"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalEliminarEncaminhamento">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Eliminar Encaminhamento</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span>{{ this.descricaoEliminarEncaminhamento }}</span>
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalEliminarEncaminhamento()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="EliminarEncaminhamento()"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalEncaminhamento">
  <form [formGroup]="formModalEncaminhamento">
    <div>
      <div>
        <h1 class="titulo-page">{{ this.tituloModalEncaminhamento }}</h1>
      </div>
      <br />
      <div>
        <div class="container-btns container-btns-centrar grupo-filter">
          <div *ngIf="this.userLogado?.funcionalidades?.includes(6)">
            <button
              type="button"
              (click)="EncaminhamentoPsicologo()"
              class="btn-action"
            >
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Psicólogo
            </button>
          </div>
          <br />
          <div *ngIf="this.userLogado?.funcionalidades?.includes(7)">
            <button
              type="button"
              (click)="EncaminhamentoPolicia()"
              class="btn-action"
            >
              <img src="../../../assets/img/icons/btn-police.svg" alt="" />
              &nbsp; Rede Protetiva
            </button>
          </div>
          <br />
          <div *ngIf="this.userLogado?.funcionalidades?.includes(8)">
            <button
              type="button"
              (click)="EncaminhamentoSaude()"
              class="btn-action"
            >
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Rede Hospitalar
            </button>
          </div>
          <br />
          <div>
            <button type="button" (click)="FecharModalEncaminhamento()" class="btn-action">
              &nbsp; Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-modal>

<app-modal *ngIf="showModalAgendamentoPsicologo">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Agendamento do Psicólogo</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span>{{ this.descricaoAgendamento }}</span>
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalAgendamento()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="AgendarChamado()"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalAtendimentoPsicologo">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Atendimento do Psicólogo</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span *ngIf="!showDataAtendimento">{{ this.descricaoAtendimento }}</span>
      <form [formGroup]="formAtendimento">
        <span *ngIf="showDataAtendimento"> Quando ocorreu esse atendimento?</span>
        <input
            type="datetime-local"
            class="input-date"
            *ngIf="showDataAtendimento"
            name="dataAtendimento"
            formControlName="dataAtendimento"
            />
      </form>
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalAtendimento()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="ShowInputDataAtendimento()"
        *ngIf="!showDataAtendimento"
        class="btn_primary"
        value="Sim"
      />
      <input
        type="button"
        *ngIf="showDataAtendimento"
        (click)="AtenderChamado()"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalConcluirChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Concluir Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span >{{ this.descricaoConcluir }}</span>

    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalConcluir()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="ConcluirChamado()"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalAddInfoHistorico">
  <div class="container-conviva form-registro modal-envolvidos">
    <form [formGroup]="formAddInfoHistorico">

      <h1 class="titulo-page">Adição</h1>
      <p>Ao comentario abaixo</p>
      <div class="descricao-historico-add-info">
        <p>{{this.historico?.descricaoHistorico}}</p>
        <i *ngIf="this.historico?.infoAdicional1" class="info-adiconal">- {{this.historico?.infoAdicional1}}</i>
        <i *ngIf="this.historico?.infoAdicional2" class="info-adiconal">- {{this.historico?.infoAdicional2}}</i>

      </div>
      <p>Escreva um comentário abaixo.</p>
      <textarea
        rows="5"
        cols="50"
        formControlName="newInfoHistoric"
        name="newInfoHistoric"
        class="text-area-new-info"
        maxlength="500"
        minlength="10"
        placeholder="O máximo de caracteres permitidos são 500 e o mínimo são 10."
        (keyup)="OnChangeCountLettersInfoAdicional()"
      ></textarea>
      <div class="qtde-caracteres">
        <p>Quantidade de caracteres :{{qtdeCaracteresInfoAdicional}}</p>
      </div>
    </form>


    <div class="container-btns">
      <input type="button" (click)="closeModalAddInfo()" class="btn_outline" value="Cancelar"/>
      <input type="submit" (click)="saveNewInfoOnHistoric()" class="btn_outline" value="Salvar"/>
    </div>
  </div>
</app-modal>

