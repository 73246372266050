// signalr.service.ts
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { ConvivaApi } from 'src/app/conviva-api';
import { ListarChamados } from '../models/chamados/listar-chamados';
import { UserLoginSed } from '../models/sed/UserLogin';
import { ListarEncaminhados } from '../models/chamados/listar-encaminhados';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private notificacaoSubject = new Subject<number>();
  private userLogado!: UserLoginSed | undefined;
  private get logginLevel (){return !environment.production ? signalR.LogLevel.Information : signalR.LogLevel.None}
  // private get urlHub (){ }
  notificacao$ = this.notificacaoSubject.asObservable();

  constructor(private authService:AuthService) {
    this.criaHub();
    this.registerServerEvents();
    this.iniciarConexaoComHub();
  }

  private criaHub() {

    this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(ConvivaApi.Hub.getNotifications,{
        withCredentials: false,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
    })
    .configureLogging(this.logginLevel)
    .build();
  }


  private iniciarConexaoComHub(){
    this.hubConnection.start().then(() => {
      if(!environment.production)
        console.log('Hub conectado.')
      // this.startStreaming();
    }).catch(()=>{
      setTimeout(() => {
        this.iniciarConexaoComHub();
      }, 5000);
    });
  }
  private registerServerEvents(){
    //Evento que atualiza o total de notificações no sino.
    this.hubConnection.on('AtualizarNotificacao', (count: number) => {
      this.notificacaoSubject.next(count);
    });
  }
  public sendNotification(filtros:ListarEncaminhados){
    this.hubConnection.invoke("SendNotification",filtros).catch((err)=>{
      console.log(err)
    })
  }
  public setParametrosNotificacoes(): ListarEncaminhados {
    const chamado = new ListarEncaminhados();
    if(this.userLogado != null && this.userLogado.codigoDiretoria != null)
      chamado.codigoDiretoria = this.userLogado.codigoDiretoria;
    if(this.userLogado != null && this.userLogado.codigoEscola != null)
      chamado.codigoEscola = this.userLogado.codigoEscola;
    return chamado;
  }
}
