import { Component } from "@angular/core";

@Component({
  templateUrl: './psicologo-filtro-visitas.component.html',
  styleUrls: ['./psicologo-filtro-visitas.component.scss']
})

export class PageFiltroVisitasPsicologoComponent {
  public showModal = false;
  public showModalPrevencao = false;
  public showModalOutros = false;
  public tituloModal = '';

  public AbrirModal() {
    this.showModal = true;
    this.tituloModal = 'Deseja fazer um deslocamento ?';
  }

  public AbrirModalPrevencao() {
    this.showModalPrevencao = true;
    this.tituloModal = 'Deseja fazer um deslocamento ?';
  }

  public AbrirModalOutros() {
    this.showModalOutros = true;
    this.tituloModal = 'Deseja fazer um deslocamento ?';
  }

  public FecharModal() {
    this.showModal = false;
  }

  public FecharModalPrevencao() {
    this.showModalPrevencao = false;
  }
  public FecharModalOutros() {
    this.showModalOutros = false;
  }
}
