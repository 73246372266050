import { ArquivoAzure } from "../arquivo/ArquivoAzure";
import { Envolvido } from "../chamados/envolvido";
import { VisitaPsicologo } from "../psicologo/VisitaPsicologo";


export interface VisitaPOC {
  codigoPolo:number;
  codigoDiretoria:number;
  codigoEscola:number;
  nomeEscola:string;
  nomeDiretoria:string;
  cpf:string;
  nome:string;
  codigoChamado?:number;
  dataAtividadePOC:Date;
  timeAtividadePOC:string;
  tipoAtividadeRealizada:number;
  tipoAcaoAtividade:number;
  descricaoRespostaPerguntaObjetivoAcao?:string;
  descricaoRespostaPerguntaAvaliacaoResultadoAcao?:string;
  descricaoRespostaPerguntaImpactoAcaoRealizada?:string;
  codigoPerfil:number;
  nomePerfil:string;
  arquivos:ArquivoAzure[];
  listaEnvolvidos:EnvolvidoAtividadePOC[];

}


export class EnvolvidoAtividadePOC{
  codigoEnvolvido?:number;
  codigoAluno?:number;
  tipoPessoa?:number;
  numeroRA?:string;
  numeroCPF?:string;
  outros?:string;

  codigoTurma?:number;
  numeroClasse?:number;
  nomeTipoEnsino?:string;
  numeroSerie?:number;
  descricaoTurma?:string;

}

