import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/services/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../auth/models/auth.config';
import { DefaultResponse } from '../models/DefaultResponse';

@Injectable({
  providedIn: 'root',
})
export class ConvivaErrorHandlingService {
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private authService:AuthService,
    private oauthService: OAuthService,) {this.configure();}

    private configure() {
      this.oauthService.configure(authConfig);
    }

  async handleError(error: any) {

    if (error instanceof HttpErrorResponse) {
      let nomeMetodo = "";
      const parts = error.url?.split('?');

      if(parts != undefined){
        let lastSlashIndex = parts[0].lastIndexOf('/');
        nomeMetodo = parts[0].substring(lastSlashIndex + 1);
      }

      if (error.error && error.error.message) {
        this.toastr.error(error.error.message, 'Erro');
        return;
      }

      if(error.status == 0) {
        this.toastr.error('Não foi possivel se conectar com o servidor.', nomeMetodo);
        return;
      }

      if(error.status == 401){
        this.toastr.error('Sua sessão expirou, por favor efetue o login novamente.', 'CONVIVA');
        this.toastr.error('Em 5 segundos iremos te redirecionar para o login.', 'CONVIVA');

        this.authService.cleanStorageGovBr();
        this.authService.cleanStorageConviva();
        this.router.navigate(['/login']);
        return;
      }

      this.toastr.error('Ocorreu um erro.', 'Erro');

    } else {
      console.error('Ocorreu um erro:', error);
      this.toastr.error('Ocorreu um erro.', 'Erro');
    }
  }

  async handleResultError(error: any): Promise<DefaultResponse> {
    const result: DefaultResponse = <DefaultResponse>{};
    result.isSucess = false;

    if (error instanceof HttpErrorResponse) {
      let nomeMetodo = "";
      const parts = error.url?.split('?');

      if(parts != undefined){
        let lastSlashIndex = parts[0].lastIndexOf('/');
        nomeMetodo = parts[0].substring(lastSlashIndex + 1);
        result.title = nomeMetodo;
      }

      if (error.error && error.error.message) {
        result.message = error.error.message + 'Erro';
        result.data = error;
      }

      if(error.status == 0) {
        result.message = 'Não foi possivel se conectar com o servidor.';
        result.data = error;
      }

      if(error.status == 401){
        this.toastr.error('Sua sessão expirou, por favor efetue o login novamente.', 'CONVIVA');
        this.toastr.error('Em 5 segundos iremos te redirecionar para o login.', 'CONVIVA');

        this.authService.cleanStorageGovBr();
        this.authService.cleanStorageConviva();
        this.router.navigate(['/login']);
      }
    }
    else {
      result.message = 'Ocorreu um erro:' + error;
      result.data = error;
    }

    return result;
  }
}
