import { Component, HostListener, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Route, Router } from "@angular/router";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { ListarEncaminhados } from "src/app/shared/models/chamados/listar-encaminhados";
import { lstNotificacoes } from "src/app/shared/models/notificacao/lstNotificacoes";
import { UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { NotificacoesService } from "src/app/shared/services/notificacoes.service";
import { SignalRService } from "src/app/shared/services/signalr.service";


@Component({
  selector: 'sino-component',
  templateUrl: './sino.component.html',
  styleUrls: ['./sino.component.scss']
})
export class SinoComponent implements OnInit {

  public notifications:number = 0;
  public userLogado: UserLoginSed | null;
  public expandir: boolean = false;
  public lstNotificacoes: lstNotificacoes[] = [];
  public codigoChamadoSelected: number;
  public codigoEnvolvidoSelected: number;
  public submittedModal = false;
  public showModal = false;
  public formModal!: FormGroup;
  public tituloModal!: string;
  //Funcionalidade de selecionar uma ou mais notificações
  public lstNotificacoesSelecionadas:number[] = [];

  constructor(
    private signalRService: SignalRService,
    private authService: AuthService,
    private notificationService:NotificacoesService,
    private offcanvasService: NgbOffcanvas,
    private spinnerService:NgxSpinnerService,
    private ConvivaErrors:ConvivaErrorHandlingService,
    private toastr:ToastrService,
    private router:Router)
  {
    this.userLogado = this.authService.getUser();

  }

  ngOnInit(): void {
    //Quando for pesquisado chamados, emitirá um evento que será capturado aqui.
    this.notificationService.filtrarNotificacoes.subscribe((objetoFiltro:ListarEncaminhados) => {
      this.signalRService.sendNotification(objetoFiltro);
    });
    //Faz o count e adiciona na variavel
    this.buscaNotificacoes();
  }

  private buscaNotificacoes(){
    this.signalRService.notificacao$.subscribe((count: number) => {
      this.notifications = count;
    });
  }




  public async ExpandirNotificacao(): Promise<void> {

    const menu = document.getElementsByClassName("sidebar-notificacao").item(0) as HTMLElement;
    const bodyoverlay = document.getElementsByTagName("body").item(0) as HTMLElement;
    bodyoverlay.className = "overlay";
    this.expandir = true;

    if(this.expandir){
      menu.className = "sidebar-notificacao active";
      await this.PesquisarOcorrencias();
      }else{
        this.FecharNotificacao();
    }
  }

  public FecharNotificacao() : void {
    const menu = document.getElementsByClassName("sidebar-notificacao").item(0) as HTMLElement;
    const bodyoverlay = document.getElementsByTagName("body").item(0) as HTMLElement;
    menu.className = "sidebar-notificacao";
    bodyoverlay.className = "";
    this.expandir = false;
    this.lstNotificacoesSelecionadas = [];
  }

  public async PesquisarOcorrencias() {
    this.spinnerService.show();
    const parametros = this.SetParametrosEventoNotificacao();

    const result = await lastValueFrom(await this.notificationService.getNotificacoes(parametros))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.lstNotificacoes = result.data;
    if (this.lstNotificacoes.length == 0)
      this.toastr.warning("Não existem notificações para essa pesquisa");
    // this.getAlunosRecorrentes(parametros);
    this.spinnerService.hide();
  }
  public RedirecionaVisualizarChamado(codigoChamado:number){
    this.FecharNotificacao();
    this.router.navigate(['/detalhe/', codigoChamado]);
  }
  public OnStatusChamado(ic: number, ie: number) {
    this.router.navigate(['/listar-chamados/', ic, ie]);
  }
  public AbrirModal(codigoChamado: number, codigoEnvolvido: number) {
    this.codigoChamadoSelected = codigoChamado;
    this.codigoEnvolvidoSelected = codigoEnvolvido;
    this.submittedModal = false;
    this.formModal = new FormGroup({
    });
    this.showModal = true;
    this.tituloModal = 'Encaminhamentos';
  }




  public SetParametrosEventoNotificacao():ListarChamados
  {
    const chamado = new ListarChamados();
    if(this.userLogado != null && this.userLogado.codigoDiretoria != null)
      chamado.codigoDiretoria = this.userLogado.codigoDiretoria;
    if(this.userLogado != null && this.userLogado.codigoEscola != null)
      chamado.codigoEscola = this.userLogado.codigoEscola;

      chamado.codigoPerfil = this.userLogado!.DadosUsuario[0].CodigoPerfil;
    return chamado;
  }

  NotificacaoSelecionada(cdNotificacao:number,idDiv:number){
    this.lstNotificacoesSelecionadas.includes(cdNotificacao) ?
    this.lstNotificacoesSelecionadas.splice(this.lstNotificacoesSelecionadas.indexOf(cdNotificacao),1) :
    this.lstNotificacoesSelecionadas.push(cdNotificacao);

    document.getElementById('card-'+idDiv)?.classList.toggle('card-ativo')
  }
  async MarcaNotificacoesComoVisualizadas(){
    this.spinnerService.show();
    if(this.lstNotificacoesSelecionadas.length == 0){
      this.toastr.warning("Selecione ao menos uma notificação para visualizar.");
      this.spinnerService.hide();
      return;
    }

    var result =  await lastValueFrom(await this.notificationService.setExcluirNotificacoes(this.lstNotificacoesSelecionadas));
    if(!result.isSucess){
      this.toastr.error(result.message);
      this.spinnerService.hide();
      return
    }
    this.lstNotificacoesSelecionadas = [];

    await this.PesquisarOcorrencias();
    this.spinnerService.hide();
  }
}
