import { Component, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgSelectConfig } from "@ng-select/ng-select";
import { DataTableDirective } from "angular-datatables";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from 'rxjs';
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { Categoria } from "src/app/shared/models/categoria/Categoria";
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { Turno } from "src/app/shared/models/escola/Turno";
import { ListarNaoOcorrencias } from "src/app/shared/models/nao-ocorrencia/ListarNaoOcorrencia";
import { NaoOcorrencia } from "src/app/shared/models/nao-ocorrencia/NaoOcorrencia";
import { UserLoginSed, AtribuicaoLoginSed } from "src/app/shared/models/sed/UserLogin";
import { Turma } from "src/app/shared/models/turma/Turma";
import { CategoriaService } from "src/app/shared/services/categoria.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { EscolaService } from "src/app/shared/services/escola.service";
import { NaoOcorrenciaService } from "src/app/shared/services/nao-ocorrencia.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { GenericValidator } from "src/app/shared/validations/genericValidators";

@Component({
    selector: 'dashboard-nao-ocorrencia',
    templateUrl: './dashboard-nao-ocorrencia.component.html',
    styleUrls: ['./dashboard-nao-ocorrencia.component.scss']
  })

export class DashboardNaoOcorrenciaComponent {


    private get DataAtual (){return new Date()}
  public minDate:string = '2021-01-01' ;
  public maxDate:string = `${this.DataAtual.getFullYear()}-12-30` ;

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  // Variaveis do Grid DataTable
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;
  public isDtInitialized:boolean = false;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private naoOcorrencia: NaoOcorrenciaService,
    private authService: AuthService,
    private categoriaService: CategoriaService,
    private psicologoService:PsicologoService,
    private validatorsService:GenericValidator
  ){
    // ng-select mudança de texto
    this.config.notFoundText = 'Não encontrado';

    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  // Listas da tela
  public gravidades = [ { id: 1, name: 'Amarelo' }, { id: 2, name: 'Vermelho' }];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public turmas: Turma[] = [];
  public lstNaoOcorrencia : ListarNaoOcorrencias[] = [];
  public categorias: Categoria[] = [];

  //Form
  public formDashboard!: FormGroup;
  public get form() { return this.formDashboard.controls; }
  public submitted: boolean;
  public showPesquisa: boolean;

  //Totalizadores
  public totalNaoOcorrencias: number = 0;

  public ngOnInit(): void {

    this.dtOptions = {
      deferRender: true,
      destroy:true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [ 10, 25, 50, -1 ],
        [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
      ],
      buttons: [
        'pageLength',
        {
          extend:'print',
          stripHtml: false,
          exportOptions:{
            columns:[0,1,2,3]
          }
        },
        {
          extend:'excel',
          exportOptions:{
            columns:[0,1,2,3]
          }
        },{
          extend:'pdf',
          orientation: 'landscape',
          exportOptions:{
            columns:[0,1,2,3]
          }
        }
      ],
    };

    //Formulario de pesquisa.
    this.formDashboard = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null),
      codigoTurno: new FormControl(null),
      codigoTurma: new FormControl(null),
      codigoGravidade: new FormControl(null),
      codigoSubCategoria: new FormControl(null),
      dataInicio: new FormControl(null),
      dataFim: new FormControl(null),
    });

    this.getCategorias();

    if(this.userLogado?.flPsicologo){
      this.getDiretoriasPerfilDiretoria();
    }else{
        if(this.atribuicoesLogin.NumeroComportamento == 1){
          this.getDiretorias();
        }

        if(this.atribuicoesLogin.NumeroComportamento == 2){
          this.getDiretoriasPerfilDiretoria();
        }

        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
          this.getDiretoriasPerfilEscola();
        }

    }

  }

  public validaAno(data: AbstractControl<any, any>){
    var dataDoCampo = data.value;
    if(dataDoCampo){
     const ano = new Date(dataDoCampo).getFullYear();
      if(ano > this.DataAtual.getFullYear() || ano < this.DataAtual.getFullYear() - 10){
        data.setValue(null);
        this.toastr.warning("Data invalida! Selecione uma data valida para pesquisar.");
      }
    }
  }

  public ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public async PesquisarNaoOcorrencias(statusEnvolvido: number = -1) {
    this.spinnerService.show();
    this.submitted = true;

    if (this.formDashboard.invalid) {
      this.showPesquisa = false;
      this.spinnerService.hide();
      return;
    }
    this.showPesquisa = true;
    const parametros = this.SetParametrosNaoOcorrencia(statusEnvolvido);

    var response = await lastValueFrom(this.naoOcorrencia.getDashboardNaoOcorrencias(parametros))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.lstNaoOcorrencia = response.data;
    this.totalNaoOcorrencias = this.lstNaoOcorrencia.length;
    if(this.lstNaoOcorrencia.length == 0){
      this.SetTotalizadores();
      this.toastr.warning("Não existem não ocorrências para essa pesquisa");
      if(this.dtElement.dtInstance != null) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next(this.dtOptions);
        });
      }
    }
    else{
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next(this.dtOptions);
        });
      } else {
        this.isDtInitialized = true;
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
        }
        this.dtTrigger.next(this.dtOptions);
      }
    }
  }

  public SetParametrosNaoOcorrencia(statusEnvolvido: number): ListarChamados {
    const naoOcorrencia = new ListarChamados();
    naoOcorrencia.codigoDiretoria = this.form?.codigoDiretoria.value;
    naoOcorrencia.codigoEscola = this.form?.codigoEscola.value;
    naoOcorrencia.codigoTurno = this.form?.codigoTurno.value;
    naoOcorrencia.codigoTurma = this.form?.codigoTurma.value;
    naoOcorrencia.codigoGravidade = this.form?.codigoGravidade.value;
    naoOcorrencia.codigoSubCategoria = this.form?.codigoSubCategoria.value;
    naoOcorrencia.dataInicio = this.form?.dataInicio.value;
    naoOcorrencia.dataFim = this.form?.dataFim.value;
    naoOcorrencia.filtroStatusEnvolvido = statusEnvolvido;

    return naoOcorrencia;
  }

  public async getDiretorias() {
    this.spinnerService.show();

    var response = await lastValueFrom(this.escolaService.getDiretoriaPorTipoDiretoria())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.diretorias = response.data;
    this.spinnerService.hide();
    if(this.userLogado?.codigoDiretoria != undefined){
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public async getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formDashboard.get('codigoDiretoria')?.value;
    if(this.userLogado?.flPsicologo){
      this.zerarEscola();
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.escolas = response.data.escolas;
      this.diretorias = response.data.diretorias;
      this.spinnerService.hide();
      this.ativaEscola();
    }else
    if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2){
      this.zerarEscola();
      if (codigoDE != null) {
        this.spinnerService.show();

        var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }
        this.escolas = response.data;
        this.spinnerService.hide();
        this.ativaEscola();
      }
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formDashboard.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public async getTurnos() {
    const codigoEE = this.formDashboard.get('codigoEscola');
    if (codigoEE?.value != null) {
      this.spinnerService.show();
      var response = await lastValueFrom(this.escolaService.getTurnosPorCodigoCie(codigoEE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.turnos = response.data;
      if (this.turnos == null)
        this.toastr.warning('Não foi encontrados turnos para essa escola !');
      this.spinnerService.hide();
    }
    else{
      this.zerarTurnos();
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = codigoEE?.value == null ? 0 : codigoEE?.value;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formDashboard.get('codigoTurno')?.patchValue(null);
  }

  public async getTurmas() {
    this.spinnerService.show();
    const codigoEs = this.formDashboard.get('codigoEscola');
    const codigoTurno = this.formDashboard.get('codigoTurno');

    if(codigoEs?.value != null) {
      var response = await lastValueFrom(this.escolaService.getTurmasPorCodigoCie(codigoEs?.value, codigoTurno?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.turmas = response.data;
      this.spinnerService.hide();
    }
  }

  public zerarTurma() {
    this.turmas = [];
    this.formDashboard.get('codigoTurma')?.patchValue(null);
  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();

      this.getTurnos();
      this.PesquisarNaoOcorrencias();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria != undefined)
      this.formDashboard.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if(this.userLogado !== null)
        this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){

    if(this.userLogado?.codigoEscola != undefined){
      var existEscola = this.escolas.find(s=>s.codigoEscola == this.userLogado?.codigoEscola);
      if(existEscola != undefined){
        this.formDashboard.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }
    }
    if(this.userLogado?.codigoDiretoria != undefined && this.userLogado?.codigoEscola != undefined)
    {
      this.getTurnos();
      this.PesquisarNaoOcorrencias();
    }
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async  getCategorias() {
    this.spinnerService.show();

    var response = await lastValueFrom(this.categoriaService.getCategoriasWithSub())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.categorias = response.data;
    this.spinnerService.hide();
  }

  private SetTotalizadores(){
    if (!this.isDtInitialized) {
      if(this.lstNaoOcorrencia.length > 0) {
        this.totalNaoOcorrencias = this.lstNaoOcorrencia.length;

      }
      else {
        this.totalNaoOcorrencias = 0;

      }
    }
  }
}
