<form [formGroup]="formVisualizar">
  <breadcrumb
    [paginas]="[{route:'/home',name:'Home'},
    {route:'/#',name:'Detalhe Chamado'},
    {route:'/detalhe/'+chamado.cdChamado,name:'Chamado: '+chamado.cdChamado}]"
  />
  <section class="section-saudacao">
    <div class="container">

      <p>
        <b>Olá,</b> {{this.userLogado?.flPsicologo ? "Psicólogo(a)": this.atribuicoesLogin.NomePerfil }}<br />
        <b>{{this.userLogado?.flPsicologo ? this.userLogado?.nome : this.atribuicoesLogin.Nome}}</b>
      </p>

      <a
        class="btn-voltar-page ng-star-inserted"
        href="javascript:void(0)"
        (click)="goBack()">
        <i aria-hidden="true" class="fa fa-arrow-left"></i>
      </a>

    </div>
  </section>

  <section class="conteudo-principal">
    <div class="container-conviva busca-aluno">
      <div class="container-conviva">

        <div class="conteudo-busca-aluno conteudo-chamado page-detalhes-chamado" *ngIf="chamado != null">

          <div class="header-detalhes-chamado-title header-perfil-chamado">
            <h4 class="numero-chamado-header">Chamado {{chamado.cdChamado}}</h4>
            <input type="checkbox" checked class="card-opcoes-chamado" id="opcoes-chamado-01"/>

            <label for="opcoes-chamado-01">
              <div class="opcoes-card">

                <a *ngIf="chamado.status === 0"
                   href="javascript:void(0)"
                   routerLink="/editar-chamados/{{chamado.cdChamado}}">
                  Alterar
                </a>

              </div>
              <a class="btn-opcoes"></a>
            </label>

          </div>

          <div class="header-detalhes-chamado">

            <div class="header-detalhes-chamado-info">
              <h3 class="nome-label-detalhes">Gravidade: </h3>
              &nbsp;
              <p class="{{chamado.dsGravidade.toLowerCase()}}">{{chamado.dsGravidade}}</p>
            </div>

            <div class="header-detalhes-chamado-info">
              <h3 class="nome-label-detalhes">Diretoria: </h3>
              &nbsp;
              <p>{{chamado.dsDiretoria}}</p>
            </div>

            <div class="header-detalhes-chamado-info">
              <h3 class="nome-label-detalhes">Escola: </h3>
              &nbsp;
              <p>{{chamado.dsEscola}}</p>
            </div>

            <div class="header-detalhes-chamado-info">
              <h3 class="nome-label-detalhes">Turno: </h3>
              &nbsp;
              <p>{{chamado.dsTurno}}</p>
            </div>

            <div class="header-detalhes-chamado-info">
              <h3 class="nome-label-detalhes">Categoria: </h3>
              &nbsp;
              <p>{{chamado.dsCategoria}}</p>
            </div>

            <div class="header-detalhes-chamado-info">
              <h3 class="nome-label-detalhes">Subcategoria: </h3>
              &nbsp;
              <p>{{chamado.dsSubCategoria}}</p>
            </div>

            <div class="header-detalhes-chamado-info">
              <h3 class="nome-label-detalhes">Status: </h3>
              &nbsp;
              <p>{{chamado.statusS}}</p>
            </div>

            <div class="header-detalhes-chamado-info">
              <h3 class="nome-label-detalhes">Descrição do Chamado: </h3>
            </div>

            <div class="ds-chamado">
              <textarea
              rows="5"
              cols="50"
              id="dsChamado"
              name="dsChamado" readonly>{{chamado.dsOcorrencia}}</textarea>
            </div>
              <!-- <ng-container *ngIf="caminho.endsWith('.pdf')">
                <a href="caminho"> <i class="icon-pdf"></i> </a>
              </ng-container> -->

          <div [ngClass]="{'div-imagens':!arquivoPDF, 'div-pdf':arquivoPDF}" *ngIf="chamado.caminhoArquivo!.length == 1 " >
            <ng-container *ngFor="let caminho of chamado.caminhoArquivo">
              <img *ngIf="!caminho.endsWith('.pdf')" [src]="caminho" alt="Foto do Chamado"/>

              <div class="icon-pdf" *ngIf="caminho.endsWith('.pdf')" (click)="downloadDocument(caminho)" >
                <i href="javascript:void(0)"  class="pdf">
                </i>
                <p>{{ extraiNomeArquivoDaURLSemGuid(caminho)}}</p>
              </div>
            </ng-container>


        </div>
        <div *ngIf="chamado.caminhoArquivo!.length > 1">
          <button type="button" (click)="AbrirModalArquivo()" class="btn_outline">
            Ver Arquivos do Chamado
          </button>
        </div>
          </div>

          <br>

          <h2 class="titulo-envolvidos-header">ENVOLVIDOS</h2>
          <div class="header-perfil-chamado perfil-aluno-envolvidos">
            <div *ngFor="let envolvido of this.envolvidos;">
              <a
              href="javascript:void(0)"
              class="card-chamado aberto"
              (click)="HistoricoChamado(chamado.cdChamado, envolvido.cdEnvolvido, envolvido.nome)"
              >
                <!-- ativo -->
                <div class="perfil-aluno-chamado ativo">
                  <div class="perfil-aluno encaminhado">
                    <div *ngIf="envolvido.foto" class="img-aluno-oval">
                      <img src="data:image/jpeg;base64,{{envolvido.foto}}" alt="Imagem do Envolvido">
                    </div>
                    <div *ngIf="!envolvido.foto" class="img-aluno">
                      <span>{{ envolvido.iniciais }}</span>
                    </div>
                  </div>
                  <div class="infor-aluno-chamado">
                    <p class="nome-aluno">{{envolvido.nome}}</p>
                    <p class="descricao">{{envolvido.dsTipoPessoa}} - {{envolvido.statusS}}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <!-- CONTEUDO HISTORICO -->
          <div *ngIf="showModalDetalheEnvolvido">
            <h2 class="numero-chamado-header">HISTÓRICO {{ this.nomeSelected }}</h2>
            <div class="conteudo-do-chamado-chat" >
              <div
                class="container-text-historico"
                *ngFor="let historico of this.lstHistoricos"
              >
                <div
                  *ngIf="historico.descricaoHistorico != ''"
                  class="text-historico"
                >
                  <p>{{ historico.descricaoHistorico }}</p>
                    <i *ngIf="historico.infoAdicional1" class="info-adiconal">- {{historico.infoAdicional1}}</i>
                    <i *ngIf="historico.infoAdicional2" class="info-adiconal">- {{historico.infoAdicional2}}</i>
                </div>
                <div [ngClass]="{'imagem-destaque-card':!historico.caminhoArquivo?.endsWith('.pdf'),'imagem-destaque-card-pdf':historico.caminhoArquivo?.endsWith('.pdf')}">
                  <div
                    *ngIf="historico.caminhoArquivo != ''"
                    [ngClass]="{'imagem-destaque-card':!historico.caminhoArquivo?.endsWith('.pdf'),'imagem-destaque-card-pdf':historico.caminhoArquivo?.endsWith('.pdf')}"
                  >
                    <img src="{{ historico.caminhoArquivo }}" *ngIf="!historico.caminhoArquivo?.endsWith('.pdf')" alt="" />
                    <div class="icon-pdf" *ngIf="historico.caminhoArquivo?.endsWith('.pdf')" (click)="downloadDocument(historico.caminhoArquivo)" >
                      <i href="javascript:void(0)"  class="pdf">
                      </i>
                      <p>{{ extraiNomeArquivoDaURLSemGuid(historico.caminhoArquivo)}}</p>
                    </div>
                  </div>
                </div>
                <div class="div-informacoes-historico">
                  <p class="time-left">
                    {{ historico.data }} - {{ historico.hora }} - {{ historico.nomeUsuario }}
                  </p>
                  <i *ngIf="
                  this.userLogado?.DadosUsuario![0].CodigoPerfil == 1477 ||
                  this.userLogado?.DadosUsuario![0].CodigoPerfil == 1634 "
                  class="icon-plus-add-hist" (click)="openModalAddInfo(historico.codigoHistorico)"></i>
                </div>
              </div>
            </div>

            <div *ngIf="this.userLogado?.funcionalidades?.includes(9)" class="input-upload">
              <form [formGroup]="formCadastrarHistorico">
                <textarea
                  rows="4"
                  cols="50"
                  id="dsHistoricoChamado"
                  [(ngModel)]="dsHistoricoChamado"
                  formControlName="dsHistoricoChamado"
                  name="dsHistoricoChamado"
                >
                </textarea>
                <input
                  type="file"
                  id="arquivos"
                  name="arquivos"
                  accept=".jpg, .jpeg, .png, .mp3, .mp4"
                  (change)="OnFileChange($event)"
                  #archivoInput
                />
                <input
                  type="button"
                  id="btnSalvarHistorico"
                  class="btn-upload"
                  (click)="onSalvarHistorico()"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModalArquivo">
  <div class="container-conviva form-registro modal-envolvidos">
    <swiper [navigation]="true" [autoHeight]="true"
    class="img-modal" >
      <ng-template
        swiperSlide
        *ngFor="let arquivo of chamado.caminhoArquivo"
        >
        <img  [src]="arquivo" alt="" *ngIf="!arquivo.includes('.pdf') && !arquivo.includes('mp3') && !arquivo.includes('mp4')" />
        <div class="container-pdf">
          <div class="icon-pdf" *ngIf="arquivo.endsWith('.pdf') || arquivo.endsWith('.mp3') || arquivo.endsWith('.mp4')" (click)="downloadDocument(arquivo)" >
            <i href="javascript:void(0)"  class="pdf">
            </i>
            <p>{{ extraiNomeArquivoDaURLSemGuid(arquivo)}}</p>
          </div>
        </div>

      </ng-template>
    </swiper>

    <div class="container-btns">
      <input type="button" (click)="FecharModalArquivo()" class="btn_outline" value="Fechar"/>
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalAddInfoHistorico">
  <div class="container-conviva form-registro modal-envolvidos">
    <form [formGroup]="formAddInfoHistorico">

      <h1 class="titulo-page">Adição</h1>
      <p>Ao comentario abaixo</p>
      <div class="descricao-historico-add-info">
        <p >{{this.historico?.descricaoHistorico}}</p>
        <i *ngIf="this.historico?.infoAdicional1" class="info-adiconal">- {{historico?.infoAdicional1}}</i>
        <i *ngIf="this.historico?.infoAdicional2" class="info-adiconal">- {{historico?.infoAdicional2}}</i>

      </div>
      <p>Escreva um comentário abaixo.</p>
      <textarea
        rows="5"
        cols="50"
        formControlName="newInfoHistoric"
        name="newInfoHistoric"
        class="text-area-new-info"
        maxlength="500"
        minlength="10"
        placeholder="O máximo de caracteres permitidos são 500 e o mínimo são 10."
        (keyup)="OnChangeCountLetters()"
      ></textarea>
      <div class="qtde-caracteres">
        <p>Quantidade de caracteres :{{qtdeCaracteresDescricao}}</p>
      </div>
    </form>


    <div class="container-btns">
      <input type="button" (click)="closeModalAddInfo()" class="btn_outline" value="Cancelar"/>
      <input type="submit" (click)="saveNewInfoOnHistoric()" class="btn_outline" value="Salvar"/>
    </div>
  </div>
</app-modal>
