import { Component, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { authConfig } from "src/app/shared/auth/models/auth.config";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { Turno } from "src/app/shared/models/escola/Turno";
import { FiltroPadrao } from "src/app/shared/models/filtro-padrao/filtroPadrao";
import { Polo } from "src/app/shared/models/polo/Polo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { Turma } from "src/app/shared/models/turma/Turma";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { callPromiseAsync } from "src/app/shared/services/convivaPromise.service";
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
// import { AppInsightsService } from "src/app/shared/services/appInsights.service";



@Component({
  templateUrl: './base.component.html'
})

export class BaseComponent {
  //Injeções
  public authService = inject(AuthService);
  public oAuthService = inject(OAuthService);
  public router = inject(Router);
  public toastr = inject(ToastrService);
  public spinnerService = inject(NgxSpinnerService);
  public psicologoService = inject(PsicologoService);
  public escolaService = inject(EscolaService);
  public convivaErrors = inject(ConvivaErrorHandlingService);
  // public appInsights = inject(AppInsightsService);
  //Variaveis
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  //Variaveis Filtro
  public polos: Polo[] = [];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public turmas: Turma[] = [];
  public filtros: FiltroPadrao;



  constructor(){
    //OAuthConfiguration
    this.configure();
    this.setUserLogadoByUserInLocalStorage();
    this.validateUserLogadoIsNullAndRedirectToLogout();
    this.setAtribuicoesLogin();
  }


  // async InitializeBaseComponent(): Promise<void> {
  //   await  this.UserBehavior();
  // }




  // Metodos de Autenticação
  public setUserLogadoInLocalStorage(userLogado: UserLoginSed) {
    this.authService.setUser(userLogado);
  }
  private setUserLogadoByUserInLocalStorage() {
    this.userLogado = this.authService.getUser();
  }
  private setAtribuicoesLogin() {
    this.atribuicoesLogin = this.userLogado!.DadosUsuario[0];
  }
  private validateUserLogadoIsNullAndRedirectToLogout() {
    if (this.userLogado == null)
      this.router.navigate(['/logout']);
  }
  //OAuth metodos
  private configure() {
    this.oAuthService.configure(authConfig);
  }

  //Metodos dos filtros


}
