import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { ListarAtendimentos } from 'src/app/shared/models/chamados/atendimentos-realizados';
import { AtendimentoPsicologos } from 'src/app/shared/models/chamados/listar-atendimentos-psicologos';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Polo } from 'src/app/shared/models/polo/Polo';
import { Atendimento } from 'src/app/shared/models/psicologo/Atendimento';
import { PrazoAtendimento } from 'src/app/shared/models/psicologo/PrazoAtendimento';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';

@Component({
    selector: 'atendimento.psicologo',
    templateUrl: './atendimento.psicologos.component.html',
    styleUrls: ['./atendimento.psicologo.component.scss']
  })

  export class AtendimentoPsicologosComponent implements OnInit {
    //Variaveis de Login
    public userLogado: UserLoginSed | null;
    public atribuicoesLogin: AtribuicaoLoginSed;

    constructor(private spinnerService: NgxSpinnerService,
                private authService: AuthService,
                private psicologoService:PsicologoService,
                private toastr: ToastrService,
                private ConvivaErrors: ConvivaErrorHandlingService) {

        this.userLogado = this.authService.getUser();

        if(this.userLogado != null && !this.userLogado.flPsicologo){
          this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
        }
    }

    //Variaveis do Grid DataTable
    public dtOptions: any = {};
    public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
    @ViewChild(DataTableDirective, {static: false})
    public dtElement: DataTableDirective;
    public isDtInitialized:boolean = false;

    //Form
    public formDashboard!: FormGroup;
    public get form() { return this.formDashboard.controls; }
    public submitted: boolean;
    public submittedModal: boolean;
    public showPesquisa: boolean;

    //Listas da tela
    public polos: Polo[] = [];
    public diretorias: Diretoria[] = [];
    public lstAtendimentos: Atendimento[] = [];
    public lstRealizados: PrazoAtendimento;

    //Modal
    public showModalAtendimentoRealizado:boolean;
    public showModalAtendimentoNaoRealizado:boolean;

    public async ngOnInit() {
      //Configurações do Grid
      this.dtOptions = {
        deferRender: true,
        destroy:true,
        processing: true,
        paging: true,
        dom: 'Bfrtip',
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
        },
        lengthMenu: [
          [ 10, 25, 50, -1 ],
          [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
        ],
        buttons: [
          'pageLength',
          {
            extend:'print',
            exportOptions:{
              columns:[0,1,2,3,4]
            }
          },
          {
            extend:'excel',
            exportOptions:{
              columns:[0,1,2,3,4]
            }
          },
          {
            extend:'pdf',
            exportOptions:{
              columns:[0,1,2,3,4]
            }
          }
        ],
      };

      //Formulario de Pesquisa
      this.formDashboard = new FormGroup({
        codigoPolo: new FormControl(null, [Validators.required]),
        codigoDiretoria: new FormControl(null, [Validators.required])
      });

      await this.getPolos();

      if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0)
          await this.getDiretoriasEscolasPorPolo();
      else
          this.form.codigoDiretoria.disable();
    }

    public async getPolos() {
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.getPolos())
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.polos = response.data;
      this.spinnerService.hide();
    }

    public async getDiretoriasEscolasPorPolo() {
      this.spinnerService.show();

      if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0) {

        var response = await lastValueFrom(this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
                            .catch((err) => {
                              return this.ConvivaErrors.handleResultError(err);
                            });

        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }

        this.diretorias = response.data.diretorias;
        this.form.codigoDiretoria.enable();
        this.ativaDiretoria();
      }

      this.spinnerService.hide();
    }

    public ativaDiretoria() {
      const diretoria = this.diretorias.filter(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria);

      if(this.userLogado?.codigoDiretoria && this.userLogado?.codigoDiretoria > 0 && diretoria.length > 0){
        this.formDashboard.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
      }

      const polo = this.polos.filter(s => s.codigoPolo == this.userLogado?.codigoPolo);
      if(this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0  && polo.length > 0){
        this.formDashboard.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
      }
    }

    public async changePolo()
    {
      this.spinnerService.show();

      const codigoPO = this.formDashboard.get('codigoPolo')?.value;

      if(this.userLogado !== null)
      {
        this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
        this.authService.setUser(this.userLogado);
      }

      this.form.codigoDiretoria.enable();
      await this.getDiretoriasEscolasPorPolo();

      this.spinnerService.hide();
    }

    public async changeDiretoria()
    {
      this.spinnerService.show();

      const codigoDiretoria = this.formDashboard.get('codigoDiretoria')?.value;

      if(this.userLogado !== null) {
        this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
        this.userLogado.codigoEscola = 0;
        this.authService.setUser(this.userLogado);
      }
      this.spinnerService.hide();
    }

    public async PesquisarAtendimentos() {
      this.spinnerService.show();
      this.submitted = true;

      if (this.formDashboard.invalid) {
        this.showPesquisa = false;
        this.spinnerService.hide();
        return;
      }

      this.showPesquisa = true;
      const parametros = this.SetParametrosAtendimentos();

      var response = await lastValueFrom(this.psicologoService.getAtendimentoPsicologos(parametros))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.lstAtendimentos = response.data;
      this.spinnerService.hide();

      if(this.lstAtendimentos.length == 0){
        this.toastr.warning("Não existem psicólogos para essa pesquisa !");

        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
          });
        }
      }
      else{
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
          });
        } else {
          this.isDtInitialized = true;
          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }
    }

    public SetParametrosAtendimentos(): AtendimentoPsicologos {
      const atendimento = new AtendimentoPsicologos();
      atendimento.polo = this.form?.codigoPolo.value;
      atendimento.diretoria = this.form?.codigoDiretoria.value;
      return atendimento;
    }

    public SetParametrosAtendimentosRealizados(cpf:string,diretoria:number): ListarAtendimentos {
      const listarRealizados = new ListarAtendimentos();
      listarRealizados.cpf = cpf;
      listarRealizados.diretoria = diretoria;
      listarRealizados.tipoBusca = true;
      return listarRealizados;
    }

    public SetParametrosAtendimentosNaoRealizados(cpf:string,diretoria:number): ListarAtendimentos {
      const listarRealizados = new ListarAtendimentos();
      listarRealizados.cpf = cpf;
      listarRealizados.diretoria = diretoria;
      listarRealizados.tipoBusca = false;
      return listarRealizados;
    }

    public async AbreModalAtendimentoRealizado(cpf:string,diretoria:number){
      this.spinnerService.show();

      const parametros = this.SetParametrosAtendimentosRealizados(cpf, diretoria);

      var response = await lastValueFrom(this.psicologoService.getPrazoAtendimentos(parametros))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.spinnerService.hide();
      this.lstRealizados = response.data;
      this.showModalAtendimentoRealizado = true;

      if(this.lstRealizados == undefined){
        this.toastr.warning("Não existem atendimentos para essa pesquisa");
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
          });
        }
      }
      else{
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
          });
        } else {
          this.isDtInitialized = true;
          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }
    }

    public async AbreModalAtendimentoNaoRealizado(cpf:string,diretoria:number){
      this.spinnerService.show();

      const parametros = this.SetParametrosAtendimentosNaoRealizados(cpf, diretoria);

      var response = await lastValueFrom(this.psicologoService.getPrazoAtendimentos(parametros))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.lstRealizados = response.data;
      this.showModalAtendimentoNaoRealizado = true;

      if(this.lstRealizados == undefined){
        this.toastr.warning("Não existem atendimentos para essa pesquisa");
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
          });
        }
      }
      else{
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
          });
        } else {
          this.isDtInitialized = true;
          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }

      this.spinnerService.hide();
    }

    public FecharModalAtendimentoRealizado(){
      this.showModalAtendimentoRealizado = false;
      this.submittedModal = false;
      // this.resetFormModal();
    }

    public FecharModalAtendimentoNaoRealizado(){
      this.showModalAtendimentoNaoRealizado = false;
      this.submittedModal = false;
      // this.resetFormModal();
    }
  }

