export class AtividadePsicologoDeslocamento {
    // Origem
    public codigoPoloOrigem: number;
    public codigoDiretoriaOrigem: number;
    public nomeDiretoriaOrigem: string;
    public codigoEscolaOrigem: number;
    public nomeEscolaOrigem: string;

    // Destino
    public codigoPoloDestino: number;
    public codigoDiretoriaDestino: number;
    public nomeDiretoriaDestino: string;
    public codigoEscolaDestino: number;
    public nomeEscolaDestino: string;

    // Deslocamento
    public codigoTipoTransporte: number;
    public horarioSaida: string;
    public horarioChegada: string;
    public tempoDeslocamento: string;
    public kmPercorrido: number
}
  