import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserLoginSed } from '../models/sed/UserLogin';
import { authConfig } from '../auth/models/auth.config';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService {
  public userLogado: UserLoginSed | null;

  constructor(private router: Router, private authService: AuthService,  private oauthService: OAuthService ){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
        {
          this.userLogado = this.authService.getUser();

          if(this.authService.hasTokenConviva()){

            if(this.userLogado != null)
              return true;
            else {
              this.router.navigate(['/logout']);
              return false;
            }
          }

          this.router.navigate(['/logout']);
          return false;
        }

      canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }
}
