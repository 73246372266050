import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, map } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";

@Injectable({
  providedIn: 'root'
})

export class AlunoService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient,
    private router:Router)
    { }


  public getAlunosPorNome(nomeAluno: string, codigoDiretoria: number ,codigoEscola: number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Aluno.getAlunosPorNome}?nome=${nomeAluno}&codigoDiretoria=${codigoDiretoria}&codigoEscola=${codigoEscola}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getAlunosPorRa(raAluno: string):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Aluno.getAlunosPorRa}?ra=${raAluno}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getDadosAlunoGerais(numeroRA: string,codigoEscola:number | null):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Aluno.getDadosAlunoGerais}?numeroRA=${numeroRA}&codigoEscola=${codigoEscola}`)
                    .pipe(map((response: DefaultResponse) => response));
  }
  public getAlunosPorEscola(codigoEscola:number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Aluno.getAlunosPorEscola}?codigoEscola=${codigoEscola}`)
                    .pipe(map((response: DefaultResponse) => response));
  }
  public getAlunosVisita(codigoVisita: number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Aluno.getAlunosVisita}?codigoVisita=${codigoVisita}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

}
