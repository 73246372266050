export class Psicologo {
    codigoPsicologo?:number;
    codigoPolo:number;
    nomePsicologo:string;
    codigoCPF:string;
    codigoRG:string;
    codigoUF:string;
    codigoCRP:string;
    telefone:string;
    email:string;
    codigoCoordinador?:number;
    nomeCoordinador?:string;
}