export class Consolidado {
    codigoPolo?: number;
    codigoDiretoria?: number;
    codigoEscola?: number;
    codigoSubCategoria?: number;
    codigoPerfil?: number;
    codigoUsuario?: number;
    codigoPsicologo?: number;
    dataInicio?: Date;
    dataFim?: Date;
    codigoAtivo?: boolean;
    ano?: number;
    mes?: number;
}