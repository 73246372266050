import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map, Observable } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";

@Injectable({
    providedIn: 'root'
})
  
export class UtilsService {
    private api = ConvivaApi;
  
    constructor(
      private http: HttpClient,
      private router:Router)
      { }
  
    public calcularHoras(hora: string, horaAdicionar: string):Observable<DefaultResponse> {
      return this.http.get<DefaultResponse>(`${this.api.Utils.calcularHoras}?hora=${hora}&horaAdicionar=${horaAdicionar}`)
                      .pipe(map((response: DefaultResponse) => response));
    }
}