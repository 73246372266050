export class ListarReincidentes{
  codigoEscola?:number;
  codigoDiretoria?:number;
  codigoTurno?:number;
  codigoTurma?:number;
  codigoAluno?:number;
  dataFim?:Date;
  dataInicio?:Date;
  codigoSubCategoria?:number;
  filtroStatusEnvolvido?:number;
}
