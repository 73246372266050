<form [formGroup]="formVisita">
  <breadcrumb
  [paginas]="[{route:'/home',name:'Home'},{route:'/filtro-visitas-psicologo',name:'Registro da Atividade do Psicólogo'},{route:'visita-psicologo-prevencao',name:'Visita de Prevenção Psicologo'}]"
/>
  <section class="section-filtro">
    <div class="container">
      <div class="grupo-filter">
        <label for="select-categoria">Polo</label>
        <ng-select
          [items]="polos"
          bindLabel="descricaoPolo"
          bindValue="codigoPolo"
          placeholder="Selecione um Polo"
          name="codigoPolo"
          formControlName="codigoPolo"
          (change)="cambioPolo()"
          [ngClass]="{
            'is-invalid': submitted && form.codigoPolo.errors
          }"
          (change)="cambioPolo()"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.descricaoPolo || "Unnamed group" }}
          </ng-template>
        </ng-select>
        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          (change)="trocaDiretoria()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Visita de Prevenção Psicologo</h2>
    </div>
  </section>

  <!-- Barra de Progresso -->
  <step-bar [passoAtual]="Passo" [temDeslocamento]="this.temDeslocamento" (outPasso)="setNovoPasso($event)"></step-bar>


    <!-- Form -->
    <section class="container-conviva form-registro">
      @if(this.temDeslocamento) {

        @if(ValidaPassoAtual(1)){
          <!-- Deslocamento -->
          <app-deslocamento [dadosDeslocamento]="this.modelDeslocamento" (proximoPasso)="proximoPassoDeslocamento($event)"></app-deslocamento>
        }

        @if(ValidaPassoAtual(2)){
          <!-- Psicologos -->
          <div class="container form-vista-psicologo info-psicologo">
            <div class="conteudo-select">
              <label for="select-gravidade">Psicólogo: </label>
              <ng-select
                [items]="lstPsicologos"
                bindLabel="nomePsicologo"
                bindValue="codigoPsicologo"
                placeholder="Selecione um Psicólogo"
                name="nomePsicologo"
                formControlName="codigoPsicologo"
                *ngIf="NomePsicologo == undefined"
                (change)="setPsicologo()"
                [ngClass]="{
                    'is-invalid': submittAba1 && form.codigoPsicologo.errors
                  }"
              >
              </ng-select>
              <div *ngIf="submittAba1 && form.codigoPsicologo.errors" class="alert">
                <div *ngIf="form.codigoPsicologo.errors.required">
                  * Psicólogo obrigatório
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label for="select-data-visita">Data da Visita: </label>
              <input type="date" name="dataVisita" class="input-date" formControlName="dataVisita" (change)="OnChangeData()"/>
              <div *ngIf="submittAba1 && form.dataVisita.errors" class="alert">
                <div *ngIf="form.dataVisita.errors.required">* Data obrigatória</div>
              </div>
            </div>

            <div class="conteudo-range-slider">
              <label>Quanto tempo durou a ação?</label>
              <input-range (change)="changeInputRange()"/>
            </div>

            <div class="conteudo-select">
              <label for="select-gravidade">Qual atividade foi realizada?</label>
              <ng-select
              [items]="lstAtividadeRealizada"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione o tipo de atividade"
              name="codigoAtividade"
              formControlName="codigoAtividade"
              (change)="mostrarEnvolvidos()"
              [ngClass]="{
                'is-invalid': submittAba1 && form.codigoAtividade.errors
              }"
              >
              </ng-select>
              <div *ngIf="submittAba1 && form.codigoAtividade.errors" class="alert">
                <div *ngIf="form.codigoAtividade.errors.required">
                  * Atividade obrigatória
                </div>
              </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarAlunos">
              <h3>Estudantes</h3>
              <div class="content-person">
                <a href="javascript:void(0)" class="btn-add-person btn-gg" (click)="AbreModalAluno()">
                  <img src="../../../../assets/img/icons/icon-add.svg" alt="">
                </a>
              </div>
              @for (aluno of alunosSelecionados; track $index;) {
                <div class="grupo-filter-full">
                  <label for="select-gravidade">
                    Aluno(a)
                    <a href="javascript:void(0)" class="btn-close-convidados"
                      (click)="removeAluno(aluno.codigoAluno)"></a>
                  </label>
                  <input type="text" id="func-{{$index}}" name="funcionario" readonly
                  [value]="aluno.nomeAluno" />
                </div>
                }@empty {
                Nenhum aluno adicionado.
                }
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarProffisionais">
              <h3>Envolvidos</h3>

              <label for="select-gravidade">
                Equipe Escolar
              </label>

              @for (func of lstFuncionariosSelecionados; track $index;) {
                <div class="grupo-filter-full">
                  <label for="select-gravidade">
                    Profissional
                    <a href="javascript:void(0)" class="btn-close-convidados"
                      (click)="removeFuncionario(func.codigoFuncionario)"
                      ></a>
                  </label>
                  <input type="text" id="func-{{$index}}" name="funcionario" readonly [value]="func.nome" />
                </div>
                }@empty {
                Nenhum profissional adicionado.
                }

                @for (outro of lstOutros; track $index;) {
                <div class="grupo-filter-full">
                  <label for="select-gravidade">
                    Outro
                    <a href="javascript:void(0)" class="btn-close-convidados" (click)="removeOutro(outro)"></a>
                  </label>
                  <input type="text" id="func-{{$index}}" name="outro" readonly [value]="outro" />
                </div>
                }

                <div class="content-person">
                  <a href="javascript:void(0)" class="btn-add-person btn-gg" (click)="AbreModalProfissionais()">
                    <img src="../../../../assets/img/icons/icon-add.svg" alt="">
                  </a>
                </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarGrupos">
              <label for="select-gravidade">Atividade em grupo</label>
              <ng-select
                [items]="lstGrupos"
                bindLabel="name"
                bindValue="id"
                placeholder="Selecione o tipo de grupos"
                name="tipoGrupo"
                formControlName="tipoGrupo"
                (change)="mostrarTipoGrupo()"
              >
              </ng-select>

              <div class="conteudo-envolvidos" *ngIf="mostrarDivTurmas">
                <section class="conteudo-principal">
                  <div class="container">
                    <div class="conteudo-perfil">
                    <br/>
                      <label for="select-gravidade">Turmas</label>
                      <div class="grid-datatable">
                        <table
                          datatable
                          [dtOptions]="dtOptions"
                          [dtTrigger]="dtTrigger"
                          class="row-border"
                          aria-describedby="dashboard"
                        >
                          <thead>
                            <tr>
                              <th>Codigo Turma</th>
                              <th>Numero Classe</th>
                              <th>Tipo de Ensino</th>
                              <th>Numero de Serie</th>
                              <th>Descrição Turma</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of lstTurmas">
                              <td>{{ item.codigoTurma }}</td>
                              <td>{{ item.numeroClasse }}</td>
                              <td>{{ item.nomeTipoEnsino }}</td>
                              <td>{{ item.numeroSerie }}</td>
                              <td>{{ item.descricaoTurma }}</td>
                              <td>
                                <label>
                                  <input
                                    type="checkbox"
                                    class="checkbox"
                                    (change)="onCheckTurma(item.codigoTurma, item.descricaoTurma)"
                                  /><i></i>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarDivEquipeEscolar">
              <h3>Envolvidos</h3>

              <label for="select-gravidade">
                Equipe Escolar
              </label>
              <div class="grupo-filter-full" *ngFor="let envolvido of envolvidos; let i = index">
                <label for="select-gravidade">
                  <a
                    href="javascript:void(0)"
                    class="btn-close-convidados"
                    (click)="OnExcluirEnvolvido(envolvido.idTemporario !== undefined ? envolvido.idTemporario : 0)">
                  </a>
                </label>
                <input [readOnly]="true"
                  type="text"
                  id="envolvido-{{i}}"
                  name="tituloVisita"
                  [value]="envolvido.nome"
                />
              </div>
              <div class="content-person">
                <a
                  href="javascript:void(0)"
                  class="btn-add-person btn-gg"
                  (click)="AbrirModalEquipeEscolar()"
                >
                  <img src="../../../../assets/img/icons/icon-add.svg" alt="">
                </a>
              </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarDivOutros">
              <h3>Envolvidos</h3>
              <label for="select-gravidade">
                Outros
              </label>
              <div class="grupo-filter-full" *ngFor="let envolvido of envolvidos; let i = index">
                <label for="select-gravidade">
                  <a
                    href="javascript:void(0)"
                    class="btn-close-convidados"
                    (click)="OnExcluirEnvolvido(envolvido.idTemporario !== undefined ? envolvido.idTemporario : 0)">
                  </a>
                </label>
                <input [readOnly]="true"
                  type="text"
                  id="envolvido-{{i}}"
                  name="text-titulo"
                  [value]="envolvido.nome"

                />
              </div>
              <div class="content-person">
                <a
                  href="javascript:void(0)"
                  class="btn-add-person btn-gg"
                  (click)="AbrirModalOutros()">
                  <img src="../../../../assets/img/icons/icon-add.svg" alt="">
                </a>
              </div>
            </div>

            <div class="container-btns-visitas">
              <input
                type="reset"
                class="btn_outline"
                id="btnLimpar"
                name="b2"
                value="Limpar"
                (click)="OnLimpar()"
              />
              <input
                type="button"
                (click)="setVoltarPasso(2)"
                class="btn_outline"
                value="Voltar"
              />
              <input
                type="button"
                (click)="setNovoPasso(3)"
                class="btn_primary"
                value="Próximo"
              />
            </div>
          </div>
        }

        @if(ValidaPassoAtual(3)){
          <!-- Ações -->
          <div class="container form-vista-psicologo acoes-psicologo">

            <div class="grupo-filter-full">
              <label for="select-gravidade">Qual o objetivo da ação?</label>
              <input
                type="text"
                id="codigoObjetivoAcao"
                name="descricaoObjetivoAcao"
                formControlName="objetivoAcao"
              />
              <div *ngIf="submittAba2 && form.objetivoAcao.errors" class="alert">
                <div *ngIf="form.objetivoAcao.errors.required">
                  * Objetivo da ação é obrigatório
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label for="text-descricao">Como você avalia o resultado da ação realizada?</label>
              <textarea
                id="codigoAvaliacaoResultado"
                name="descricaoAvaliacaoResultado"
                rows="6"
                cols="40"
                minlength="150"
                maxlength="500"
                (keyup)="OnChangeCountLetters(1)"
                formControlName="avaliacaoResultado"
              ></textarea>
              <p>Quantidade de caracteres atual: {{qtdeCaracteresAvalia}}</p>
              <div *ngIf="submittAba2 && form.avaliacaoResultado.errors" class="alert">
                <div *ngIf="form.avaliacaoResultado.errors.required">
                  * Avaliação obrigatória
                </div>
                <div *ngIf="form.avaliacaoResultado.errors?.minlength">
                  * É obrigatório no mínimo digitar 150 caracteres.
                </div>
                <div *ngIf="form.avaliacaoResultado.errors?.maxlength">
                  * Maximo de caracteres é 500.
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label for="text-descricao">Como você acha que o estudante recebeu e foi impactado pela ação realizada?</label>
              <textarea
                id="codigoImpactoAcao"
                name="descricaoImpactoAcao"
                rows="6"
                cols="40"
                minlength="150"
                maxlength="500"
                (keyup)="OnChangeCountLetters(2)"
                formControlName="impactoAcao"
              ></textarea>
              <p>Quantidade de caracteres atual: {{qtdeCaracteresImpacto}}</p>
              <div *ngIf="submittAba2 && form.impactoAcao.errors" class="alert">
                <div *ngIf="form.impactoAcao.errors.required">
                  * Impacto obrigatório
                </div>
                <div *ngIf="form.impactoAcao.errors?.minlength">
                  * É obrigatório no mínimo digitar 150 caracteres.
                </div>
                <div *ngIf="form.impactoAcao.errors?.maxlength">
                  * Maximo de caracteres é 500.
                </div>
              </div>
            </div>

            <div class="conteudo-upload grupo-filter-full">
              <label>Inserir documentos, fotos, áudios (MP3) ou vídeos (MP4).</label>
              <div class="input-upload">
                <input
                  type="file"
                  id="arquivos"
                  name="arquivos"
                  accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4, .ppt, .pptx, .potx, .ppsx, .odp"
                  formControlName="arquivos"
                  (change)="OnFileChange($event)"
                  multiple
                />
                <label for="arquivos" class="message-container" *ngIf="files.length === 0">
                  Nenhum arquivo escolhido
                </label>
                <label for="arquivos" class="message-container" *ngIf="files.length > 0">
                  Selecionar mais arquivos
                </label>
              </div>

              <div *ngIf="files.length > 0" class="imagem-escolhida">
                <div *ngFor="let file of files; let i = index">
                  <div class="imagem-item">
                    <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado" *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

                    <div class="info-container">
                      <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                      <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                      <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                      <span>{{ removeUniqueId(file.nome).length > 50 ? removeUniqueId(file.nome).slice(0, 50) + '...' : removeUniqueId(file.nome) }}</span>
                      <i class="fas fa-times" (click)="removeFile(i)"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="submittAba2 && form.arquivos.errors" class="alert">
                <div *ngIf="form.arquivos.errors.required">
                  * Arquivo(s) obrigatório(s)
                </div>
              </div>

            </div>

            <div class="container-btns-visitas">
              <input
                type="reset"
                class="btn_outline"
                id="btnLimpar"
                name="b2"
                value="Limpar"
                (click)="OnLimpar()"
              />
              <input
                type="button"
                (click)="setVoltarPasso(3)"
                class="btn_outline"
                value="Voltar"
              />
              <input
                type="button"
                (click)="setNovoPasso(4)"
                class="btn_primary"
                value="Próximo"
              />
            </div>
          </div>
        }

        @if(ValidaPassoAtual(4)){
          <!-- Visualização Geral -->
          <div class="container form-vista-psicologo visualizacao-geral">

            <div class="grupo-filter-full">
              <label>Psicólogo:</label>
              <p>{{this.nomePsicologoSelecionado}}</p>
            </div>

            <div class="conteudo-row">
              <div class="box-duas-colum">
                <div class="grupo-filter-full">
                  <label>Data da Visita: </label>
                  <p>{{this.dataVisita}}</p>
                </div>
              </div>
              <div class="box-duas-colum">
                <div class="grupo-filter-full">
                  <label>Quanto tempo durou a ação?</label>
                  <p>{{duracaoVisita}}MN</p>
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label>Qual atividade foi realizada?</label>
              <p>{{this.atividadeRealizadaSelecionado}}</p>
            </div>

            <div class="conteudo-envolvidos" *ngIf="form.codigoAtividade.value == 1">
              <div class="grid-datatable">
              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border"
              aria-describedby="dashboard">
              <thead>
                <tr>
                  <th>Estudantes</th>
                </tr>
              </thead>
              <tbody>
                @for (item of alunosSelecionados; track $index) {
                <tr>
                  <td>{{item.nomeAluno}}</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
          </div>

            <div class="conteudo-envolvidos" *ngIf="form.codigoAtividade.value == 2 && form.tipoGrupo.value != 1">
              <h3>Envolvido</h3>
              <div class="envolvidos-aluno" *ngFor="let envolvido of lstEnvolvidosSelecionados; let i = index">

                    <div class="perfil-aluno-chamado">
                      <div class="img-aluno">
                        <span>{{envolvido.iniciais}}</span>
                      </div>
                      <div class="infor-aluno-chamado">
                        <p class="nome-aluno">{{envolvido.nome}}</p>
                      </div>
                    </div>
                  </div>
                </div>

            <div class="conteudo-envolvidos" *ngIf="form.codigoAtividade.value == 2 && form.tipoGrupo.value == 1">
              <h3>Turmas</h3>
              <div class="grid-datatable">
                <table
                  datatable
                  [dtOptions]="dtOptions"
                  [dtTrigger]="dtTrigger"
                  class="row-border"
                  aria-describedby="dashboard"
                >
                  <thead>
                    <tr>
                      <th>Codigo Turma</th>
                      <th>Numero Classe</th>
                      <th>Tipo de Ensino</th>
                      <th>Numero de Serie</th>
                      <th>Descrição Turma</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of lstTurmasSelecionadas">
                      <td>{{ item.codigoTurma }}</td>
                      <td>{{ item.numeroClasse }}</td>
                      <td>{{ item.nomeTipoEnsino }}</td>
                      <td>{{ item.numeroSerie }}</td>
                      <td>{{ item.descricaoTurma }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="form.codigoAtividade.value == 3">
              <h3>Convidados</h3>
              <div class="grid-datatable outros">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border"
                  aria-describedby="dashboard">
                  <thead>
                    <tr>
                      <th>Equipe Escola e Outros</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (item of lstFuncionariosSelecionados; track $index) {
                    <tr>
                      <td>{{item.nome}}</td>
                    </tr>
                    }
                    @for (item of lstOutros; track $index) {
                    <tr>
                      <td>{{item}}</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <h3 class="sub-titulo-dash">Deslocamento:</h3>
            <div class="conteudo-row">
              <div class="box-duas-colum">
                <div class="grupo-filter-full">
                  <label>Origem:</label>
                  <p>Diretoria: </p>
                  <p>{{this.modelDeslocamento?.nomeDiretoriaOrigem}}</p>
                  <p>Escola: </p>
                  <p>{{this.modelDeslocamento?.nomeEscolaOrigem}}</p>
                  <p>Horário saída:</p>
                  <p>{{this.modelDeslocamento?.horarioSaida}}</p>
                </div>
              </div>
              <div class="box-duas-colum">
                <div class="grupo-filter-full">
                  <label>Destino:</label>
                  <p>Diretoria: </p>
                  <p>{{this.modelDeslocamento?.nomeDiretoriaDestino}}</p>
                  <p>Escola: </p>
                  <p>{{this.modelDeslocamento?.nomeEscolaDestino}}</p>
                  <p>Horário chegada:</p>
                  <p>{{this.modelDeslocamento?.horarioChegada}}</p>
                  <p><b>Tempo de Deslocamento:</b> {{this.modelDeslocamento?.tempoDeslocamento}}</p>

                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label>Qual o objetivo da ação?</label>
              <p>{{this.objetivoAcao}}</p>
            </div>

            <div class="grupo-filter-full">
              <label>Como você avalia o resultado da ação realizada?</label>
              <p>{{this.avaliacaoResultado}}</p>
            </div>

            <div class="grupo-filter-full">
              <label>Como você acha que o estudante recebeu e foi impactado pela ação realizada?</label>
              <p>{{this.impactoAcao}}</p>
            </div>

            <div class="grupo-filter-full">
              <label>Documentos, fotos e vídeos.</label>

              <div *ngIf="files.length > 0" class="imagem-escolhida">
                <div *ngFor="let file of files; let i = index">
                  <div class="imagem-item">
                    <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado" *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

                    <div class="info-container">
                      <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                      <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                      <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container-btns-visitas">
              <input
                type="reset"
                class="btn_outline"
                id="btnLimpar"
                name="b2"
                value="Cancelar"
                (click)="OnLimpar()"
              />
              <input
                type="button"
                (click)="setVoltarPasso(4)"
                class="btn_outline"
                value="Voltar"
              />
              <input
                type="button"
                (click)="OnSalvar()"
                class="btn_primary"
                value="Enviar para aprovação"
                [disabled]="enableEnviarAprovacao"
              />
            </div>
          </div>
        }
      }
      @else {
        @if(ValidaPassoAtual(1)){
          <!-- Psicologos -->
          <div class="container form-vista-psicologo info-psicologo">
            <div class="conteudo-select">
              <label for="select-gravidade">Psicólogo: </label>
              <ng-select
                [items]="lstPsicologos"
                bindLabel="nomePsicologo"
                bindValue="codigoPsicologo"
                placeholder="Selecione um Psicólogo"
                name="nomePsicologo"
                formControlName="codigoPsicologo"
                *ngIf="NomePsicologo == undefined"
                (change)="setPsicologo()"
                [ngClass]="{
                    'is-invalid': submittAba1 && form.codigoPsicologo.errors
                  }"
              >
              </ng-select>
              <div *ngIf="submittAba1 && form.codigoPsicologo.errors" class="alert">
                <div *ngIf="form.codigoPsicologo.errors.required">
                  * Psicólogo obrigatório
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label for="select-data-visita">Data da Visita: </label>
              <input type="date" name="dataVisita" class="input-date" formControlName="dataVisita" (change)="OnChangeData()"/>
              <div *ngIf="submittAba1 && form.dataVisita.errors" class="alert">
                <div *ngIf="form.dataVisita.errors.required">* Data obrigatória</div>
              </div>
            </div>

            <div class="conteudo-range-slider">
              <label>Quanto tempo durou a ação?</label>
              <input-range (change)="changeInputRange()"/>
            </div>

            <div class="conteudo-select">
              <label for="select-gravidade">Qual atividade foi realizada?</label>
              <ng-select
              [items]="lstAtividadeRealizada"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione o tipo de atividade"
              name="codigoAtividade"
              formControlName="codigoAtividade"
              (change)="mostrarEnvolvidos()"
              [ngClass]="{
                'is-invalid': submittAba1 && form.codigoAtividade.errors
              }"
              >
              </ng-select>
              <div *ngIf="submittAba1 && form.codigoAtividade.errors" class="alert">
                <div *ngIf="form.codigoAtividade.errors.required">
                  * Atividade obrigatória
                </div>
              </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarAlunos">
              <h3>Estudantes</h3>
              <div class="content-person">
                <a href="javascript:void(0)" class="btn-add-person btn-gg" (click)="AbreModalAluno()">
                  <img src="../../../../assets/img/icons/icon-add.svg" alt="">
                </a>
              </div>
              @for (aluno of alunosSelecionados; track $index;) {
                <div class="grupo-filter-full">
                  <label for="select-gravidade">
                    Aluno(a)
                    <a href="javascript:void(0)" class="btn-close-convidados"
                      (click)="removeAluno(aluno.codigoAluno)"></a>
                  </label>
                  <input type="text" id="func-{{$index}}" name="funcionario" readonly
                  [value]="aluno.nomeAluno" />
                </div>
                }@empty {
                Nenhum aluno adicionado.
                }
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarProffisionais">
              <h3>Envolvidos</h3>

              <label for="select-gravidade">
                Equipe Escolar
              </label>

              @for (func of lstFuncionariosSelecionados; track $index;) {
                <div class="grupo-filter-full">
                  <label for="select-gravidade">
                    Profissional
                    <a href="javascript:void(0)" class="btn-close-convidados"
                      (click)="removeFuncionario(func.codigoFuncionario)"
                      ></a>
                  </label>
                  <input type="text" id="func-{{$index}}" name="funcionario" readonly [value]="func.nome" />
                </div>
                }@empty {
                Nenhum profissional adicionado.
                }

                @for (outro of lstOutros; track $index;) {
                <div class="grupo-filter-full">
                  <label for="select-gravidade">
                    Outro
                    <a href="javascript:void(0)" class="btn-close-convidados" (click)="removeOutro(outro)"></a>
                  </label>
                  <input type="text" id="func-{{$index}}" name="outro" readonly [value]="outro" />
                </div>
                }

                <div class="content-person">
                  <a href="javascript:void(0)" class="btn-add-person btn-gg" (click)="AbreModalProfissionais()">
                    <img src="../../../../assets/img/icons/icon-add.svg" alt="">
                  </a>
                </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarGrupos">
              <label for="select-gravidade">Atividade em grupo</label>
              <ng-select
                [items]="lstGrupos"
                bindLabel="name"
                bindValue="id"
                placeholder="Selecione o tipo de grupos"
                name="tipoGrupo"
                formControlName="tipoGrupo"
                (change)="mostrarTipoGrupo()"
                [ngClass]="{
                  'is-invalid': submittAba1 && form.tipoGrupo.errors
                }"
              >
              </ng-select>

              <div class="conteudo-envolvidos" *ngIf="mostrarDivTurmas">
                <section class="conteudo-principal">
                  <div class="container">
                    <div class="conteudo-perfil">
                    <br/>
                      <label for="select-gravidade">Turmas</label>
                      <div class="grid-datatable">
                        <table
                          datatable
                          [dtOptions]="dtOptions"
                          [dtTrigger]="dtTrigger"
                          class="row-border"
                          aria-describedby="dashboard"
                        >
                          <thead>
                            <tr>
                              <th>Codigo Turma</th>
                              <th>Numero Classe</th>
                              <th>Tipo de Ensino</th>
                              <th>Numero de Serie</th>
                              <th>Descrição Turma</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of lstTurmas">
                              <td>{{ item.codigoTurma }}</td>
                              <td>{{ item.numeroClasse }}</td>
                              <td>{{ item.nomeTipoEnsino }}</td>
                              <td>{{ item.numeroSerie }}</td>
                              <td>{{ item.descricaoTurma }}</td>
                              <td>
                                <label>
                                  <input
                                    type="checkbox"
                                    class="checkbox"
                                    (change)="onCheckTurma(item.codigoTurma, item.descricaoTurma)"
                                  /><i></i>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarDivEquipeEscolar">
              <h3>Envolvidos</h3>

              <label for="select-gravidade">
                Equipe Escolar
              </label>
              <div class="grupo-filter-full" *ngFor="let envolvido of envolvidos; let i = index">
                <label for="select-gravidade">
                  <a
                    href="javascript:void(0)"
                    class="btn-close-convidados"
                    (click)="OnExcluirEnvolvido(envolvido.idTemporario !== undefined ? envolvido.idTemporario : 0)">
                  </a>
                </label>
                <input [readOnly]="true"
                  type="text"
                  id="envolvido-{{i}}"
                  name="tituloVisita"
                  [value]="envolvido.nome"
                />
              </div>

              <div class="content-person">
                <a
                  href="javascript:void(0)"
                  class="btn-add-person btn-gg"
                  (click)="AbrirModalEquipeEscolar()"
                >
                  <img src="../../../../assets/img/icons/icon-add.svg" alt="">
                </a>
              </div>
            </div>

            <div class="conteudo-envolvidos" *ngIf="mostrarDivOutros">
              <h3>Envolvidos</h3>
              <label for="select-gravidade">
                Outros
              </label>
              <div class="grupo-filter-full" *ngFor="let envolvido of envolvidos; let i = index">
                <label for="select-gravidade">
                  <a
                    href="javascript:void(0)"
                    class="btn-close-convidados"
                    (click)="OnExcluirEnvolvido(envolvido.idTemporario !== undefined ? envolvido.idTemporario : 0)">
                  </a>
                </label>
                <input [readOnly]="true"
                  type="text"
                  id="envolvido-{{i}}"
                  name="text-titulo"
                  [value]="envolvido.nome"

                />
              </div>
              <div class="content-person">
                <a
                  href="javascript:void(0)"
                  class="btn-add-person btn-gg"
                  (click)="AbrirModalOutros()">
                  <img src="../../../../assets/img/icons/icon-add.svg" alt="">
                </a>
              </div>
            </div>

            <div class="container-btns-visitas">
              <input
                type="reset"
                class="btn_outline"
                id="btnLimpar"
                name="b2"
                value="Limpar"
                (click)="OnLimpar()"
              />
              <input
                type="button"
                (click)="setNovoPasso(2)"
                class="btn_primary"
                value="Próximo"
              />
            </div>
          </div>
        }

        @if(ValidaPassoAtual(2)){
          <!-- Ações -->
          <div class="container form-vista-psicologo acoes-psicologo">

            <div class="grupo-filter-full">
              <label for="select-gravidade">Qual o objetivo da ação?</label>
              <input
                type="text"
                id="codigoObjetivoAcao"
                name="descricaoObjetivoAcao"
                formControlName="objetivoAcao"
              />
              <div *ngIf="submittAba2 && form.objetivoAcao.errors" class="alert">
                <div *ngIf="form.objetivoAcao.errors.required">
                  * O objetivo da ação é obrigatório
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label for="text-descricao">Como você avalia o resultado da ação realizada?</label>
              <textarea
                id="codigoAvaliacaoResultado"
                name="descricaoAvaliacaoResultado"
                rows="6"
                cols="40"
                minlength="150"
                maxlength="500"
                (keyup)="OnChangeCountLetters(1)"
                formControlName="avaliacaoResultado"
              ></textarea>
              <p>Quantidade de caracteres atual: {{qtdeCaracteresAvalia}}</p>
              <div *ngIf="submittAba2 && form.avaliacaoResultado.errors" class="alert">
                <div *ngIf="form.avaliacaoResultado.errors.required">
                  * Descrição obrigatória
                </div>
                <div *ngIf="form.avaliacaoResultado.errors?.minlength">
                  * É obrigatório no mínimo digitar 150 caracteres.
                </div>
                <div *ngIf="form.avaliacaoResultado.errors?.maxlength">
                  * Maximo de caracteres é 500.
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label for="text-descricao">Como você acha que o estudante recebeu e foi impactado pela ação realizada?</label>
              <textarea
                id="codigoImpactoAcao"
                name="descricaoImpactoAcao"
                rows="6"
                cols="40"
                minlength="150"
                maxlength="500"
                (keyup)="OnChangeCountLetters(2)"
                formControlName="impactoAcao"
              ></textarea>
              <p>Quantidade de caracteres atual: {{qtdeCaracteresImpacto}}</p>
              <div *ngIf="submittAba2 && form.impactoAcao.errors" class="alert">
                <div *ngIf="form.impactoAcao.errors.required">
                  * Descrição obrigatória
                </div>
                <div *ngIf="form.impactoAcao.errors?.minlength">
                  * É obrigatório no mínimo digitar 150 caracteres.
                </div>
                <div *ngIf="form.impactoAcao.errors?.maxlength">
                  * Maximo de caracteres é 500.
                </div>
              </div>
            </div>

            <div class="conteudo-upload grupo-filter-full">
              <label>Inserir documentos, fotos, áudios (MP3) ou vídeos (MP4).</label>
              <div class="input-upload">
                <input
                  type="file"
                  id="arquivos"
                  name="arquivos"
                  accept=".jpg, .jpeg, .png, .pdf, .mp3, .mp4, .ppt, .pptx, .potx, .ppsx, .odp"
                  formControlName="arquivos"
                  (change)="OnFileChange($event)"
                  multiple
                />
                <label for="arquivos" class="message-container" *ngIf="files.length === 0">
                  Nenhum arquivo escolhido
                </label>
                <label for="arquivos" class="message-container" *ngIf="files.length > 0">
                  Selecionar mais arquivos
                </label>
              </div>

              <div *ngIf="files.length > 0" class="imagem-escolhida">
                <div *ngFor="let file of files; let i = index">
                  <div class="imagem-item">
                    <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado" *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

                    <div class="info-container">
                      <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                      <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                      <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                      <span>{{ removeUniqueId(file.nome).length > 50 ? removeUniqueId(file.nome).slice(0, 50) + '...' : removeUniqueId(file.nome) }}</span>
                      <i class="fas fa-times" (click)="removeFile(i)"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="submittAba2 && form.arquivos.errors" class="alert">
                <div *ngIf="form.arquivos.errors.required">
                  * Arquivo(s) obrigatório(s)
                </div>
              </div>

            </div>

            <div class="container-btns-visitas">
              <input
                type="reset"
                class="btn_outline"
                id="btnLimpar"
                name="b2"
                value="Limpar"
                (click)="OnLimpar()"
              />
              <input
                type="button"
                (click)="setVoltarPasso(2)"
                class="btn_outline"
                value="Voltar"
              />
              <input
                type="button"
                (click)="setNovoPasso(3)"
                class="btn_primary"
                value="Próximo"
              />
            </div>
          </div>
        }

        @if(ValidaPassoAtual(3)){
          <!-- Visualização Geral -->
          <div class="container form-vista-psicologo visualizacao-geral">

            <div class="grupo-filter-full">
              <label>Psicólogo:</label>
              <p>{{this.nomePsicologoSelecionado}}</p>
            </div>

            <div class="conteudo-row">
              <div class="box-duas-colum">
                <div class="grupo-filter-full">
                  <label>Data da Visita: </label>
                  <p>{{this.dataVisita}}</p>
                </div>
              </div>
              <div class="box-duas-colum">
                <div class="grupo-filter-full">
                  <label>Quanto tempo durou a ação?</label>
                  <p>{{duracaoVisita}}MN</p>
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label>Qual atividade foi realizada?</label>
              <p>{{this.atividadeRealizadaSelecionado}}</p>
            </div>

        <div class="conteudo-envolvidos" *ngIf="form.codigoAtividade.value == 1">
          <div class="grid-datatable">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border"
            aria-describedby="dashboard">
            <thead>
              <tr>
                <th>Estudantes</th>
              </tr>
            </thead>
            <tbody>
              @for (item of alunosSelecionados; track $index) {
              <tr>
                <td>{{item.nomeAluno}}</td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        </div>

        <div class="conteudo-envolvidos" *ngIf="form.codigoAtividade.value == 2 && form.tipoGrupo.value != 1">
          <h3>Envolvido</h3>
          <div class="envolvidos-aluno" *ngFor="let envolvido of lstEnvolvidosSelecionados; let i = index">

            <div class="perfil-aluno-chamado">
              <div class="img-aluno">
                <span>{{envolvido.iniciais}}</span>
              </div>
              <div class="infor-aluno-chamado">
                <p class="nome-aluno">{{envolvido.nome}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="conteudo-envolvidos" *ngIf="form.codigoAtividade.value == 2 && form.tipoGrupo.value == 1">
          <h3>Turmas</h3>
          <div class="grid-datatable">
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border"
              aria-describedby="dashboard"
            >
              <thead>
                <tr>
                  <th>Codigo Turma</th>
                  <th>Numero Classe</th>
                  <th>Tipo de Ensino</th>
                  <th>Numero de Serie</th>
                  <th>Descrição Turma</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of lstTurmasSelecionadas">
                  <td>{{ item.codigoTurma }}</td>
                  <td>{{ item.numeroClasse }}</td>
                  <td>{{ item.nomeTipoEnsino }}</td>
                  <td>{{ item.numeroSerie }}</td>
                  <td>{{ item.descricaoTurma }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="conteudo-envolvidos" *ngIf="form.codigoAtividade.value == 3">
          <h3>Convidados</h3>

          <div class="grid-datatable outros">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border"
              aria-describedby="dashboard">
              <thead>
                <tr>
                  <th>Equipe Escola e Outros</th>
                </tr>
              </thead>
              <tbody>
                @for (item of lstFuncionariosSelecionados; track $index) {
                <tr>
                  <td>{{item.nome}}</td>
                </tr>
                }
                @for (item of lstOutros; track $index) {
                <tr>
                  <td>{{item}}</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

            <h3 class="sub-titulo-dash">Deslocamento:</h3>
            <div class="conteudo-row">
              <div class="box-duas-colum">
                <div class="grupo-filter-full">
                  <label>Origem:</label>
                  <p>Diretoria: </p>
                  <p>Escola: </p>
                  <p>Horário saída:</p>
                </div>
              </div>
              <div class="box-duas-colum">
                <div class="grupo-filter-full">
                  <label>Destino:</label>
                  <p>Diretoria: </p>
                  <p>Escola: </p>
                  <p>Horário chegada:</p>
                  <p><b>Tempo de Deslocamento:</b></p>
                </div>
              </div>
            </div>

            <div class="grupo-filter-full">
              <label>Qual o objetivo da ação?</label>
              <p>{{this.objetivoAcao}}</p>
            </div>

            <div class="grupo-filter-full">
              <label>Como você avalia o resultado da ação realizada?</label>
              <p>{{this.avaliacaoResultado}}</p>
            </div>

            <div class="grupo-filter-full">
              <label>Como você acha que o estudante recebeu e foi impactado pela ação realizada?</label>
              <p>{{this.impactoAcao}}</p>
            </div>

        <div class="grupo-filter-full">
          <label>Documentos, fotos e vídeos.</label>
          <div *ngIf="files.length > 0" class="imagem-escolhida">
            <div *ngFor="let file of files; let i = index">
              <div class="imagem-item">
                <img src="data:image/jpeg;base64,{{file.conteudo}}" alt="Anexo do chamado" *ngIf="!file.nome.includes('pdf') && !file.nome.includes('mp3') && !file.nome.includes('mp4')">

                <div class="info-container">
                  <img src="../../../../assets/img/icons/icon-pdf.svg" alt="Icone PDF" *ngIf="file.nome.includes('pdf')">
                  <img src="../../../../assets/img/icons/icon-mp3.svg" alt="Icone MP3" *ngIf="file.nome.includes('mp3')">
                  <img src="../../../../assets/img/icons/icon-mp4.svg" alt="Icone MP4" *ngIf="file.nome.includes('mp4')">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-btns-visitas">
          <input
            type="reset"
            class="btn_outline"
            id="btnLimpar"
            name="b2"
            value="Cancelar"
            (click)="OnLimpar()"
          />
          <input
            type="button"
            (click)="setVoltarPasso(3)"
            class="btn_outline"
            value="Voltar"
          />
          <input
            type="button"
            (click)="OnSalvar()"
            class="btn_primary"
            value="Enviar para aprovação"
            [disabled]="enableEnviarAprovacao"
          />
        </div>

      </div>
    }}
      <!--END VISUALIZACAO GERAL -->

    </section>
  </form>

  <app-modal *ngIf="showModalEquipeEscolar">
    <form [formGroup]="formModalEquipeEscolar" (submit)="OnAdicionarEquipeEscolar()">
      <div class="container-conviva form-registro modal-envolvidos">
        <div>
          <h1 class="titulo-page">Dados do Equipe Escolar</h1>
        </div>
        <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
          <div *ngIf="mostrarDivEquipeEscolar">
            <div class="modal-conteudo-busca-aluno">
              <div class="conteudo-search">
                <input
                  type="text"
                  placeholder="Digite o CPF"
                  id="js-input-search"
                  formControlName="cpfFuncionario"
                  name="cpfFuncionario"
                  mask="000.000.000-00"
                  [validation]="true"
                />
                <button type="button" id="btnBuscarFuncionario" (click)="GetFuncionariosPorCpf(1)">
                  <img src="../../../assets/img/icons/icon-search.svg" alt="" />
                </button>
              </div>
              <div
                *ngIf="submittedModal && formEquipeEscolar.cpfFuncionario.errors"
                class="alert"
              >
                <div *ngIf="formEquipeEscolar.cpfFuncionario.errors.required">
                  * CPF do funcionario é obrigatório.
                </div>
                <div
                  *ngIf="
                  formEquipeEscolar.cpfFuncionario.errors &&
                    this.formEquipeEscolar.cpfFuncionario.errors.message
                  ">
                  {{ this.formEquipeEscolar.cpfFuncionario.errors.message }}
                </div>
              </div>
            </div>
            <div *ngIf="showDropAuxiliar">
              <div class="grupo-filter">
                <p><b>Funcionario</b></p>
                <ng-select
                  [items]="lstFuncionarios"
                  bindLabel="nome"
                  bindValue="cpf"
                  placeholder="Selecione o funcionario"
                  formControlName="cpfFuncionarioModalDrop"
                  name="cpfFuncionarioModalDrop"
                >
                </ng-select>
                <div
              *ngIf="submittedModal && formEquipeEscolar.cpfFuncionarioModalDrop.errors"
              class="alert"
            >
              <div *ngIf="formEquipeEscolar.cpfFuncionarioModalDrop.errors.required">
                * Nenhum funcionario foi selecionado.
              </div>
              </div>
            </div>
            </div>
          </div>

          <div class="container-btns">
            <input
              type="button"
              (click)="FecharModalEquipeEscolar()"
              class="btn_outline"
              value="Fechar"
            />
            <input type="submit" class="btn_primary" value="Salvar" />
          </div>
        </div>
      </div>
    </form>
  </app-modal>

  <app-modal *ngIf="showModalOutros">
    <form [formGroup]="formModalOutros" (submit)="OnAdicionarOutros()">
      <div class="container-conviva form-registro modal-envolvidos">
        <div>
          <h1 class="titulo-page">Dados do Outros</h1>
        </div>
        <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
          <div *ngIf="mostrarDivOutros" class="conteudo-textarea">
            <label for="text-registro">Descreva quem são os outros</label>
            <textarea
              id="text-registro"
              name="text-registro"
              rows="6"
              cols="50"
              formControlName="dsOutros"
              name="dsOutros"
            ></textarea>
            <div
              *ngIf="submittedModal && formOutros.dsOutros.errors"
              class="alert"
            >
              <div *ngIf="formOutros.dsOutros.errors.required">
                * Descricao de outros é obrigatório.
              </div>
            </div>
          </div>

          <div class="container-btns">
            <input
              type="button"
              (click)="FecharModalOutros()"
              class="btn_outline"
              value="Fechar"
            />
            <input type="submit" class="btn_primary" value="Salvar" />
          </div>
        </div>
      </div>
    </form>
  </app-modal>

  <app-modal *ngIf="showModalExcluirEnvolvido">
    <div class="container-conviva form-registro modal-envolvidos">
      <div>
        <h1 class="titulo-page">Envolvidos</h1>
      </div>
      <div class="descricao-modal-excluir">
        <span>{{this.descricaoExclusao}}</span>
      </div>
      <div class="container-btns">
        <input type="button" (click)="FecharModalExclusao()" class="btn_outline" value="Não"/>
        <input type="button" (click)="ExluirEnvolvido(this.envolvidoExclusao?.idTemporario)" class="btn_primary" value="Sim" />
      </div>
    </div>
  </app-modal>

  <app-modal *ngIf="showModal">
    <form [formGroup]="formModal" (submit)="OnAdicionarProfissionaisEOutros()">
      <div class="container-conviva form-registro modal-envolvidos">
        <div>
          <h1 class="titulo-page">Adicionar Pessoas</h1>
        </div>

        <div class="time-line conteudo-registro modal-conteudo-busca-aluno">

          <div>
            <div class="modal-conteudo-busca-aluno">

              <div>
                <div class="grupo-filter">
                  <p><b>Tipo Pessoa</b></p>
                  <ng-select [items]="tipoPessoaAdd" bindLabel="descricao" bindValue="id" formControlName="tipoPessoa"
                    (change)="changeTipoPessoaModal()" name="tipoPessoa" placeholder="Selecione um tipo de pessoa">
                  </ng-select>

                </div>
              </div>
              @if(this.formModalControls.tipoPessoa.value == 2){
              <div class="conteudo-search">
                <input type="text" placeholder="Digite o CPF" id="js-input-search" formControlName="cpfBusca"
                  name="cpfBusca" mask="000.000.000-00" [validation]="true" />
                <button type="button" id="btnBuscarFuncionario" (click)="GetFuncionariosPorCpf(2)">
                  <img src="../../../assets/img/icons/icon-search.svg" alt="" />
                </button>
              </div>
              }
            </div>
            <div *ngIf="this.formModalControls.tipoPessoa.value == 2 &&
                        this.formModalControls.cpfBusca.value &&
                        this.lstFuncionarios.length > 0">
              <div class="grupo-filter">
                <p><b>Funcionario</b></p>
                <ng-select [items]="lstFuncionarios" bindLabel="nome" bindValue="cpf"
                  placeholder="Selecione o funcionario" formControlName="cpfProfissionalSelecionado"
                  name="cpfProfissionalSelecionado">
                </ng-select>
                <div *ngIf="submittedModal && formModalControls.cpfProfissionalSelecionado.errors" class="alert">
                  <div *ngIf="formModalControls.cpfProfissionalSelecionado.errors.required">
                    * Nenhum funcionario foi selecionado.
                  </div>
                </div>
              </div>
            </div>

            @if(this.formModalControls.tipoPessoa.value == 1){
            <div class="grupo-filter-full">
              <label for="dsAvaliacao">Descreva quem são os outros</label>
              <textarea id="dsOutros" name="dsOutros" rows="6" cols="40" formControlName="dsOutros"
                name="dsOutros"></textarea>

            </div>
            }
          </div>

          <div class="container-btns">
            <input type="button" (click)="FecharModal()" class="btn_outline" value="Fechar" />
            <input type="submit" class="btn_primary" value="Adicionar" />
          </div>
        </div>
      </div>
    </form>
  </app-modal>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Carregando ...</p>
  </ngx-spinner>


  <app-modal *ngIf="showModalAluno">
    <form [formGroup]="formModalAluno" (submit)="OnAdicionarEnvolvido()">
      <div class="container-conviva form-registro modal-envolvidos">
        <div>
          <h1 class="titulo-page">Busca Alunos</h1>
        </div>

        <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
          <div >
            <div class="modal-conteudo-busca-aluno">
              <div class="grupo-filter-modal">
                <p><b>Diretoria</b></p>
                <ng-select
                  [items]="diretorias"
                  bindLabel="nomeDiretoria"
                  bindValue="codigoDiretoria"
                  placeholder="Selecione uma Diretoria"
                  formControlName="codigoDiretoriaModal"
                  name="codigoDiretoriaModal"
                  (change)="getEscolasModalEnvolvidos()"
                >
                </ng-select>
              </div>

              <div class="grupo-filter-modal">
                <p><b>Escola</b></p>
                <ng-select
                  [items]="escolasModalAlunos"
                  bindLabel="nomeEscola"
                  bindValue="codigoEscola"
                  placeholder="Selecione uma Escola"
                  formControlName="codigoEscolaModal"
                  name="codigoEscolaModal"
                >
                </ng-select>
              </div>

              <div class="conteudo-search">
                <input
                  type="text"
                  placeholder="Buscar por nome ou RA"
                  id="js-input-search"
                  formControlName="raNomeAluno"
                  name="raNomeAluno"
                />
                <button
                  type="button"
                  id="btnPesquisaAluno"
                  (click)="BuscarAlunoPorNomeRA()"
                >
                  <img src="../../../assets/img/icons/icon-search.svg" alt="" />
                </button>
              </div>
              @if(showDropAuxiliar){
                <div >
                  <div class="grupo-filter-modal">
                    <p><b>Estudantes</b></p>
                    <ng-select
                      [items]="alunos"
                      bindLabel="nomeAluno"
                      bindValue="ra"
                      placeholder="Selecione o Aluno"
                      formControlName="raAlunoModalDrop"
                      name="raAlunoModalDrop"
                    >
                    </ng-select>
                  </div>
                </div>
              }
            </div>
          </div>
          <div class="container-btns">
            <input
              type="button"
              (click)="FecharModalAluno()"
              class="btn_outline"
              value="Fechar"
            />
            <input type="submit" class="btn_primary" value="Salvar" />
          </div>
        </div>
      </div>
    </form>

  </app-modal>
