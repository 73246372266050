
export  class AtividadePOC{
  public static TipoAtividadeRealizada:AtividadePOC[] = [{
    descricao:"Conversa/atividade individual com o estudante",
    id:1
  },{
    descricao:"Conversa/atividade em grupo",
    id:2
  },
  // {
  //   descricao:"Conversa/atividade com profissionais",
  //   id:3
  // }

];



}

export enum TipoAcaoAtividade{
  Acolhimento = 4,
  Prevenção = 3
}

export interface AtividadePOC{
  id:number;
  descricao:string;
}



