<form [formGroup]="formEditar" (submit)="OnSalvar()">

    <section class="section-saudacao  header-page-voltar">
        <div class="container">
          <h2 class="titulo-page">Alterar Psicólogo</h2>
          <a class="btn-voltar-page" (click)="OnVoltar()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </a>
        </div>
    </section>

    <section class="conteudo-principal-">
        <div class="container-conviva form-registro">
            <!-- INFO DO CHAMADO -->
            <div class="time-line conteudo-registro">

              <div class="inline-polo">

                <div class="conteudo-select">
                    <label for="select-polo">Polo</label>
                    <ng-select
                      [items]="polos"
                      bindLabel="descricaoPolo"
                      bindValue="codigoPolo"
                      class="select2-single"
                      placeholder="Selecione um Polo"
                      formControlName="codigoPolo"
                      name="codigoPolo"
                      [ngClass]="{'is-invalid': submitted && form.codigoPolo.errors}">
                    </ng-select>
                    <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
                      <div *ngIf="form.codigoPolo.errors.required">
                        * Polo é obrigatório
                      </div>
                    </div>
                </div>
              </div>
                <div class="cadastro-input">
                    <label for="input-nome">Nome Completo</label>
                    <div class="dx-field-value">
                        <input
                        id="nomePsicologo"
                        name="nomePsicologo"                        
                        placeholder="Nome Completo" 
                        formControlName="nomePsicologo"
                        >
                    </div>
                    <div *ngIf="submitted && form.nomePsicologo.errors" class="alert">
                      <div *ngIf="form.nomePsicologo.errors?.required">
                        * Nome do Psicólogo é obrigatório.
                      </div>
                    </div>
                </div>
                <div class="inline">
                  <div class="cadastro-input">
                      <label for="input-cpf">CPF </label>
                      <div>
                          <input
                          id="codigoCPF"
                          name="codigoCPF"                        
                          placeholder="000.000.000-00" 
                          bindValue="codigoCPF"
                          formControlName="codigoCPF"
                          mask="000.000.000-00"
                          >
                      </div>
                      <div *ngIf="submitted && form.codigoCPF.errors" class="alert">
                        <div *ngIf="form.codigoCPF.errors?.required">
                          * CPF é obrigatório.
                        </div>
                      </div>
                  </div>
                  <div class="cadastro-input">
                      <label for="input-rg">RG </label>
                      <div class="dx-field-value">
                          <input
                          id="codigoRG"
                          name="codigoRG"                        
                          placeholder="00.000.000-0" 
                          bindValue="codigoRG"
                          formControlName="codigoRG"
                          mask="00.000.000-0"                    
                          >
                      </div>
                      <div *ngIf="submitted && form.codigoRG.errors" class="alert">
                        <div *ngIf="form.codigoRG.errors?.required">
                          * RG é obrigatório.
                        </div>
                      </div>
                  </div>
                  <div class="conteudo-select-uf">
                      <label for="select-gravidade">UF</label>
                      <ng-select
                        [items]="uf"
                        bindLabel="name"
                        bindValue="id"
                        class="select2-single"
                        placeholder="--" 
                        formControlName="codigoUF"
                        name="codigoUF"
                        [ngClass]="{'is-invalid': submitted && form.codigoUF.errors}"
                        >
                      </ng-select>
                      <div *ngIf="submitted && form.codigoUF.errors" class="alert">
                        <div *ngIf="form.codigoUF.errors.required">
                          * UF é obrigatório
                        </div>
                      </div>
                  </div>
              </div>

              <div class="inline">
                <div class="cadastro-input">
                    <label for="input-crp">CRP </label>
                    <div class="dx-field-value">
                        <input
                        id="codigoCRP"
                        name="codigoCRP"                        
                        placeholder="00/00000" 
                        bindValue="codigoCRP"
                        formControlName="codigoCRP"
                        mask="00/00000"                    
                        >
                    </div>
                    <div *ngIf="submitted && form.codigoCRP.errors" class="alert">
                      <div *ngIf="form.codigoCRP.errors?.required">
                        * CRP é obrigatório.
                      </div>
                    </div>
                </div>

                <div class="cadastro-input">
                    <label for="input-telefone">Telefone </label>
                    <div class="dx-field-value">
                        <input
                        id="telefone"
                        name="telefone"                        
                        placeholder="(00) 00000-0000" 
                        bindValue="telefone"
                        formControlName="telefone"
                        mask="(00) 00000-0000"
                        >
                    </div>
                    <div *ngIf="submitted && form.telefone.errors" class="alert">
                      <div *ngIf="form.telefone.errors?.required">
                        * Telefone é obrigatório.
                      </div>
                    </div>
                </div>
              </div>
                <div class="cadastro-input">
                    <label for="input-email">E-Mail </label>
                    <div class="dx-field-value">
                        <input
                        id="email"
                        name="email"                        
                        placeholder="email@email.com" 
                        bindValue="email"
                        formControlName="email"
                        >
                    </div>
                    <div *ngIf="submitted && form.email.errors" class="alert">
                      <div *ngIf="form.email.errors?.required">
                        * E-mail é obrigatório.
                      </div>
                    </div>
                </div>
            </div>  
            <div  *ngIf="this.userLogado?.funcionalidades?.includes(13)" class="container-btns">
              <input type="reset" class="btn_outline" id="btnLimpar" name="b2" value="Limpar" (click)="OnLimpar()"/>
              <input type="submit" class="btn_primary" value="Salvar" />
            </div>
        </div>
    </section>

</form>


<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>