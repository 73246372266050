import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { Options, TabulatorFull as Tabulator } from 'tabulator-tables';


@Component({
  selector: 'app-tabulator-table',
  templateUrl: './tabulator-table.component.html',
  styleUrls: ['./tabulator-table.component.scss']
})
export class TabulatorTableComponent implements OnChanges, OnInit {

  public router = inject(Router);
  public spinner = inject(NgxSpinnerService);

  @Input() tableData: any[] = [];
  @Input() columnNames: any[] = [];
  @Input() height: string = '511px';
  @Input() options?: Options;
  @Input() tableTitle: string = "Tabela Conviva";
  @Input() orientacaoPortrait: boolean = true;
  @Input() tipoRelatorio: number = 0;
  /*
    0 = Painel Geral
    1 = Painel de Avaliação
    2 = Agendamento Psicologos
  */
  // list properties you want to set per implementation here...

  tab = document.createElement('div');

  private table: Tabulator;
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  constructor(private authService: AuthService) {
    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public formFilter!: FormGroup;
  public get form() { return this.formFilter.controls; }

  ngOnInit(): void {
    this.formFilter = new FormGroup({
      filterField: new FormControl(null),
      filterValue: new FormControl(null),
      filterType: new FormControl(null)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setOptions();
    this.drawTable();
  }

  public clearFilters() {
    this.formFilter.get('filterField')?.patchValue(null);
    this.formFilter.get('filterType')?.patchValue(null);
    this.formFilter.get('filterValue')?.patchValue(null);
  }

  public filtarValue() {
    let field = this.formFilter.get('filterField')?.value;
    let value = this.formFilter.get('filterValue')?.value;
    let type = this.formFilter.get('filterType')?.value;

    if (field && value && type) {
      this.table.setFilter(field, type, value);
    }
  }

  private drawTable(): void {

    this.table = new Tabulator(this.tab, { ...this.options });
    this.table.on("tableBuilt", () => {
      this.table.setData(this.tableData);
      const maximoDePaginas = Number(this.table.getPageMax()) ;
      if(this.userLogado?.currentPage && this.userLogado.currentPage <= maximoDePaginas)
        this.table.setPage(this.userLogado?.currentPage);


    },);

  this.table.on("pageLoaded", (pageNumber: number) => {
    if(this.userLogado !== null){
      if (this.userLogado) {
        this.userLogado.currentPage =  !this.userLogado?.currentPage ? 1 : pageNumber  ;
      }
      this.authService.setUser(this.userLogado);
    }
  });
    document.getElementById('table-conviva')?.appendChild(this.tab);
  }

  private setOptions(): void {
    this.options = {
      data: this.tableData,
      reactiveData: true, //enable data reactivity
      columns: this.columnNames,
      pagination: true, //enable.
      paginationSize: 10,
      paginationSizeSelector:[10, 25, 50],
      maxHeight:this.height,
      layout:"fitDataStretch",
      locale: true,
      langs: {
        "pt-br": {
          "data": {
            "loading": "Carregando",
            "error": "Erro",
          },
          "groups": {
            "item": "item",
            "items": "itens",
          },
          "pagination": {
            "page_size": "Tamanho da Página",
            "page_title": "Mostrar Pagina",
            "first": "Primeiro",
            "first_title": "Primeira Página",
            "last": "Último",
            "last_title": "Última Pagina",
            "prev": "Anterior",
            "prev_title": "Pagina Anterior",
            "next": "Próximo",
            "next_title": "Próxima Pagina",
            "all": "Tudo",
            "counter": {
              "showing": "Visivel",
              "of": "de",
              "rows": "linhas",
              "pages": "paginas",
            }
          },
        }
      },
    };
  }

  public ExportComoExcel() {
    this.spinner.show();
    this.table.deleteColumn("visualizar");
    if(this.tipoRelatorio == 1){
      this.table.deleteColumn("codigoAtividadePsicologo");
    }
    if(this.tipoRelatorio == 2){
      this.table.deleteColumn("codigoEnvolvido");
    }
    this.table.download("xlsx", this.tableTitle+".xlsx");
    this.table.addColumn(this.columnNames[this.columnNames.length - 1]);
    this.spinner.hide();

  }

  public ExportarComoPDF() {

    this.spinner.show();
    this.table.deleteColumn("visualizar");
    this.table.download("pdf", this.tableTitle+".pdf", {
      orientation: this.orientacaoPortrait ? "portrait" : "landscape",
      title: this.tableTitle,
      // jsPDF:{
      //     unit:"in", //set units to inches
      // },
      // autoTable:{ //advanced table styling
      //     styles: {
      //         fillColor: [100, 255, 255]
      //     },
      //     columnStyles: {
      //         id: {fillColor: 255}
      //     },
      //     margin: {top: 60},
      // },
    });
    this.table.addColumn(this.columnNames[this.columnNames.length - 1]);
    this.spinner.hide();
  }

  public ImprimirTabela() {
    this.table.deleteColumn("visualizar");
    this.table.print();
    this.table.addColumn(this.columnNames[this.columnNames.length - 1]);
  }

}
