import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";
import { Consolidado } from "../models/relatorio/Consolidado";

@Injectable({
    providedIn: 'root'
  })
  
  export class RelatorioService {
      private api = ConvivaApi;
  
      constructor(
        private http: HttpClient,
        private router:Router)
      { }
    
      public getRelatorioConsolidado(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getRelatorioConsolidado}`, parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getRelatorioPendencias(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getRelatorioPendencias}`, parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getCalendarioOcorrencias(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getCalendarioOcorrencias}`, parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getRelatorioVisitaPsicologo(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getVisitaPsicologo}`, parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getRelatorioChamadosUsuario(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getRelatorioChamadosUsuario}`, parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getUsuarioChamadosUnicos(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getUsuarioChamadosUnicos}`, parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getPerfisChamadosUnicos(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getPerfisChamadosUnicos}`, parametros)
                        .pipe(map((response: DefaultResponse) => response));
      }

      public getRelatorioOcorrenciasAtivas(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getRelatorioOcorrenciasAtivas}`, parametros)
                        .pipe(map((Response: DefaultResponse) => Response));
      }

      public getRelatorioCalculoUsabilidade(parametros: Consolidado):Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(`${this.api.Relatorios.getRelatorioCalculoUsabilidade}`, parametros)
                        .pipe(map((Response: DefaultResponse) => Response));
      }

  }