import { Component, Input } from '@angular/core';
import { ListarChamados } from 'src/app/shared/models/chamados/listar-chamados';

@Component({
  selector: 'app-modal-notificacoes-alert',
  templateUrl: './modal-notificacoes-alert.component.html',
  styleUrls: ['./modal-notificacoes-alert.component.scss']
})
export class ModalNotificacoesAlertComponent {
  @Input() chamadosAtrasados: ListarChamados[] =[];

  constructor() {

  }

    //MODAL ALERTA
    @Input() showModalNotificacao = false;
    public isShaking = false;

     countChamadoPorStatus(sla:string){
      return this.chamadosAtrasados.filter(x=>x.aluno?.dsStatusEnvolvido == sla).length;
    }

    countTotalSLA(){
      return this.chamadosAtrasados.filter(x=>
        x.aluno?.dsStatusEnvolvido == 'Pendente análise do chamado' ||
        x.aluno?.dsStatusEnvolvido == 'Atrasado Agendamento do Psicólogo' ||
        x.aluno?.dsStatusEnvolvido == 'Atrasado Atendimento da Rede Protetiva' ||
        x.aluno?.dsStatusEnvolvido == 'Atrasado Atendimento da Saúde' ||
        x.aluno?.dsStatusEnvolvido == 'Aguardando Atendimento do Psicólogo' ||
        x.aluno?.dsStatusEnvolvido == 'Aguardando Encerramento'
      ).length
    }


    public triggerShake() {
      this.isShaking = true;
      // Remove a classe após a duração da animação para reutilização
      setTimeout(() => {
        this.isShaking = false;
      }, 1500);
    }
    public openModalNotificacao() {
      this.showModalNotificacao = true;
      this.triggerShake();
    }

    public closeModalNotificacao() {
      this.showModalNotificacao = false;
    }
}
