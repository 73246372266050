<form [formGroup]="formVisitaEditar" (submit)="OnSalvar()">

    <section class="header-page-relatorio">
        <div class="container">
          <section class="section-saudacao header-page-voltar">
            <div class="periodo">
                <p><b>Pesquisar por período:</b></p>
                <div class="periodo-input">
                  <input
                  type="date"
                  name="dataInicial"
                  min="{{minDate}}"
                  max="{{maxDate}}"
                  (blur)="validaAno(this.form.dataInicio)"
                  formControlName="dataInicio"
                  />
                  &nbsp;-&nbsp;
                  <input
                  type="date"
                  name="dataFinal"
                  min="{{minDate}}"
                  max="{{maxDate}}"
                  (blur)="validaAno(this.form.dataFim)"
                  formControlName="dataFim"
                  />
                </div>
              </div>
          </section>
        </div>
      </section>

    <section class="section-filtro">
        <div class="container">
          <div class="grupo-filter">
            <label for="select-categoria">Polo</label>
            <ng-select
              [items]="polos"
              bindLabel="descricaoPolo"
              bindValue="codigoPolo"
              placeholder="Selecione um Polo"
              name="codigoPolo"
              formControlName="codigoPolo"
              (change)="cambioPolo()"
              [ngClass]="{
                'is-invalid': submitted && form.codigoPolo.errors
              }"
              (change)="cambioPolo()"
            >
              <ng-template ng-optgroup-tmp let-item="item">
                {{ item.descricaoPolo || "Unnamed group" }}
              </ng-template>
            </ng-select>
            <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
              <div *ngIf="form.codigoPolo.errors.required">
                * Polo é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Diretoria</b></p>
            <ng-select
              [items]="diretorias"
              bindLabel="nomeDiretoria"
              bindValue="codigoDiretoria"
              placeholder="Selecione uma Diretoria"
              formControlName="codigoDiretoria"
              name="codigoDiretoria"
              (change)="trocaDiretoria()"
              [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
              <div *ngIf="form.codigoDiretoria.errors.required">
                * Diretoria é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Escola</b></p>
            <ng-select
              [items]="escolas"
              bindLabel="nomeEscola"
              bindValue="codigoEscola"
              placeholder="Selecione uma Escola"
              formControlName="codigoEscola"
              name="codigoEscola"
              [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
              <div *ngIf="form.codigoEscola.errors.required">
                * Escola é obrigatório
              </div>
            </div>
          </div>

          <div class="grupo-filter">
            <p><b>Psicólogo</b></p>
            <ng-select
              [items]="lstPsicologos"
              bindLabel="nomePsicologo"
              bindValue="codigoPsicologo"
              placeholder="Selecione um Psicólogo"
              name="nomePsicologo"
              formControlName="codigoPsicologo"
              *ngIf="NomePsicologo == undefined"
              [ngClass]="{
                'is-invalid': submitted && form.codigoCPF.errors
              }"
            >
            </ng-select>
            <div *ngIf="submitted && form.codigoCPF.errors" class="alert">
              <div *ngIf="form.codigoCPF.errors.required">
                * Psicólogo obrigatório
              </div>
            </div>
          </div>

          <button type="button" class="btn-search-filtro" id="btn-search-chamado" (click)="pesqusarVisitas()">
            <img
              src="../../../../assets/img/icons/icon-search.svg"
              alt="Pesquisar Chamados"
              title="Pesquisar Chamados"
            />
          </button>

        </div>
    </section>

    <!-- TITULO -->
    <section class="section-saudacao">
        <div class="container">
            <h2 class="titulo-page">Editar Visita Psicólogos</h2>
        </div>
    </section>

    <section class="conteudo-principal">
      <div class="container">
        <div
          class="conteudo-perfil"
          *ngIf="this.showGridVisita"
          [hidden]="hideAfterLoadTable"
        >
          <h3 class="sub-titulo-dash">Visitas</h3>
          <div class="grid-datatable">
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border"
              aria-describedby="dashboard"
            >
              <thead>
                <tr>
                  <th>Codigo Visita</th>
                  <th>Polo</th>
                  <th>Diretoria</th>
                  <th>Escola</th>
                  <th>Titulo</th>
                  <th>Psicólogo</th>
                  <th>Data/Hora Inicio</th>
                  <th>Data/Hora Fim</th>
                  <th>Ação Preventiva</th>
                  <th>Atividade</th>
                  <th>Editar</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of lstVisitas">
                  <td>{{ item.codigoVisita }}</td>
                  <td>{{ item.codigoPolo }}</td>
                  <td>{{ item.nomeDiretoria }}</td>
                  <td>{{ item.nomeEscola }}</td>
                  <td>{{ item.tituloVisita.toUpperCase() }}</td>
                  <td>{{ item.nomePsicologo }}</td>
                  <td>{{ formatDate(item.dataVisitaInicio) }}</td>
                  <td>{{ formatDate(item.dataVisitaFim) }}</td>
                  <td>{{ item.descricaoAcaoPreventiva }}</td>
                  <td>{{ item.descricaoAtividade }}</td>
                  <td>
                    <label>
                      <input
                        type="radio"
                        (change)="onCheckVisita(item.codigoVisita)"
                        name="radioVisita"
                      /><i></i>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <form [formGroup]="formVisitaAlunos">
      <section class="conteudo-principal">
        <div class="container">
          <div
            class="conteudo-perfil"
            *ngIf="this.showGridAlunos"
            [hidden]="hideAfterLoadTableAlunos"
          >
          <div class="container conteudo-titulo-grid-alunos">
            <h3 class="sub-titulo-dash">Estudantes</h3>

            <div class="marcacao_falta_presenca">
              <p>Marcar todos como:</p>
              <button title="Falta" Class="btn_falta_presenca falta " (click)="onCheckTodosFalse()">
                <span> F </span>
              </button>
              <button title="Presença" Class="btn_falta_presenca presenca" (click)="onCheckTodosTrue()">
                <span> C </span>
              </button>
            </div>
          </div>
            <div class="grid-datatable">
              <table
                datatable
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger"
                class="row-border"
                aria-describedby="dashboard"
              >
                <thead>
                  <tr>
                    <th>Turma</th>
                    <th>N°</th>
                    <th>RA</th>
                    <th>Nome do Aluno</th>
                    <th>Presença / Falta</th>
                    <th>Atendimento Clinico</th>
                    <th>Encaminhamento Para Rede De Saude ou Assistencia Social</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let aluno of lstTurmaAluno; let i = index">
                    <td>{{ aluno.descricaoTurma }}</td>
                    <td>&nbsp; {{ aluno.numeroAluno }} &nbsp;</td>
                    <td>{{ aluno.raAluno }}</td>
                    <td>{{ aluno.nomeAluno }}</td>
                    <td>
                      <div class="falta_presenca_container">
                        <input
                          [checked]="aluno.presenca"
                          [value]="aluno.presenca"
                          type="checkbox"
                          title="checkbox"
                          (click)="oncheckPresenca(i)"
                        />
                      </div>
                    </td>
                    <td>
                      <label>
                        <input
                          [checked]="aluno.atendimentoClinico"
                          [value]="aluno.atendimentoClinico"
                          type="checkbox"
                          class="checkbox"
                          (click)="oncheckatendimentoClinico(i)"
                        /><i></i>
                      </label>
                    </td>
                    <td>
                      <label>
                        <ng-select
                        [items]="encaminhamentos"
                        [(ngModel)]="aluno.encaminhamentoRedeSaude"
                        bindLabel="name"
                        bindValue="id"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Selecione uma Encaminhamento"
                        (ngModelChange)="oncheckEncaminhamento(i, aluno.encaminhamentoRedeSaude)"
                        name="encaminhamentoRedeSaude"

                        >
                        </ng-select>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </form>

    <section class="conteudo-principal">
      <div class="container-conviva form-registro" style=" max-width: 1255px;">
        <div
          class="form-visita-psicologos conteudo-registro"
          style="background-color: transparent;"
          *ngIf="this.showGridAlunos"
          [hidden]="hideAfterLoadTableAlunos"
        >
          <div class="container-btns">
            <input type="submit" class="btn_primary" value="Salvar" />
          </div>
        </div>
      </div>
    </section>

</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
