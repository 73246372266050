//Principais
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { DataTableDirective } from "angular-datatables";
//Métodos
import { Polo } from "src/app/shared/models/polo/Polo";
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { TurmaAluno } from "src/app/shared/models/aluno/TurmaAluno";
import { VisitaPsicologo } from "src/app/shared/models/psicologo/VisitaPsicologo";
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
//Serviços
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Turma } from 'src/app/shared/models/turma/Turma';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { Perfil } from 'src/app/shared/enums/Perfis/Perfis';

@Component({
  selector: 'app-psicologo-visita',
  templateUrl: './psicologo-visita.component.html',
  styleUrls: ['./psicologo-visita.component.scss']
})
export class VisitaPsicologoComponent implements OnInit, OnDestroy {

  // Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;
  public hideAfterLoadTable: boolean;
  public hideAfterLoadTableAlunos: boolean;
  public isDtInitialized:boolean = false;

  constructor(
    private config: NgSelectConfig,
    private escolaService: EscolaService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private psicologoService:PsicologoService,
    private arquivoService: ArquivoService,
  ) {
    // ng-select mudança de texto
    this.config.notFoundText = 'Não encontrado';
    // Dados usuário logado
    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  public polos: Polo[] = [];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public psicologos: Psicologo[] = [];
  public lstPsicologos: Psicologo[] = [];
  public lstTurmas: Turma[] = [];
  public lstTurmasAtivas: number[] = [];
  public lstTurmaAluno: TurmaAluno[] = [];
  public files: ArquivoAzure[] = [];
  public NomePsicologo?:string;
  public showGridTurma:boolean;
  public showGridAlunos:boolean;
  public encaminhamentos = [ { id: 0, name: 'Sem Encaminhamento' }, { id: 1, name: 'Hospital Geral' }, { id: 2, name: 'Pronto Socorro' }, { id: 3, name: 'CRAS' }, { id: 4, name: 'UBS' }, { id: 5, name: 'UPA' }, { id: 6, name: 'CAISM' }, { id: 7, name: 'CAPS' }];
  public atividadesDesenvolvidas = [
    { id: 1, name:'Escuta/observação' },
    { id: 2, name:'Coleta de dados/informação' },
    { id: 3, name:'Reunião com equipe gestora' },
    { id: 4, name:'Reunião com Psicólogo' },
    { id: 5, name:'Reunião com o Supervisor' },
    { id: 6, name:'Reunião com professores/administrativo' },
    { id: 7, name:'Reunião com alunos' },
    { id: 8, name:'Outras' } ];
  public acoesPreventiva = [
    { id:1, name:'Alinhamento'},
    { id:2, name:'Devolutiva'},
    { id:3, name:'Orientação'},
    { id:4, name:'Planejamento'},
    { id:5, name:'Construção de propostas com gestores/PEC/POC/Vice/GOE/Comissão Gremista'},
    { id:6, name:'Produção do trabalho/construção de materiais'},
    { id:7, name:'Aplicação/realização do trabalho desenvolvido'},
    { id:8, name:'Outras'},
  ];

  public formVisita!: FormGroup;
  public formVisitaAlunos!: FormGroup;
  public get form() { return this.formVisita.controls; }
  public get formAluno() { return this.formVisitaAlunos.controls; }
  public submitted: boolean;


  public ngOnInit(): void {

    this.dtOptions = {
      deferRender: true,
      destroy:true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [ 10, 25, 50, -1 ],
        [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
      ],
      buttons: [
        'pageLength',
        {
          extend:'print',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        },
        {
          extend:'excel',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        },
        {
          extend:'pdf',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        }
      ],
    };

    this.formVisita = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null, [Validators.required]),
      nomeDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      nomeEscola: new FormControl(null, [Validators.required]),
      tituloVisita: new FormControl(null, [Validators.required]),
      codigoPsicologo: new FormControl(null, [Validators.required]),
      dataVisita: new FormControl(null, [Validators.required]),
      horaInicio: new FormControl("00:00",[Validators.required]),
      horaFim: new FormControl("00:00",[Validators.required]),
      dsVisita: new FormControl(null, [Validators.required]),
      codigoAtividade: new FormControl(null, [Validators.required]),
      codigoAcaoPreventiva: new FormControl(null, [Validators.required]),
    })

    this.formVisitaAlunos = new FormGroup({
      presenca: new FormControl(null),
      atendimentoClinico: new FormControl(null),
      encaminhamentoRedeSaude: new FormControl(null)
    })

    if(this.userLogado?.flPsicologo){
      this.getPoloDiretoriaPsicologo();
    }
    else{
        if( this.atribuicoesLogin.NumeroComportamento == 1){
          this.getPolosAdmin();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 2){
          this.getPolosPerfilDiretor();
        }
        if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
          this.getPolosPerfilProfessor();
        }
    }
    if(this.userLogado?.codigoPolo != 0 && this.userLogado?.codigoPolo != undefined){
        this.getDiretoriasPorPolo();
        this.getEscolasPorDiretoria();
    }else{
      this.form.codigoDiretoria.disable();
      this.form.codigoEscola.disable();
    }

      this.getPsicologosPolo();
  }

  public ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  private setPsicologoInDropDown(){
    this.spinnerService.show();
    const {cpf} = this.userLogado!;
    let psicologo = this.lstPsicologos.find(s=>s.codigoCPF == cpf);
    if(!psicologo)
      return;
    this.lstPsicologos = [psicologo];
    this.form.codigoPsicologo.setValue(psicologo.codigoPsicologo);
    this.form.codigoPsicologo.disable();
    this.spinnerService.hide();
  }

  public async getPoloDiretoriaPsicologo(){

    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
    await this.getPsicologosPolo();
  }

  public async getPolosAdmin() {
    this.spinnerService.show();
       var  response = await lastValueFrom(this.psicologoService.getPolos()).catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.polos = response.data;
      this.spinnerService.hide();
      if(this.userLogado?.codigoPolo != 0){
        this.ativaPolo();
      }
    this.spinnerService.hide();
    await this.getPsicologosPolo();
  }

  public async getPolosPerfilDiretor(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if(this.userLogado?.DadosUsuario[0].CodigoDiretoria != 0){
      var todasDiretoriasDoUsuario:Diretoria[] = [];
      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria) ;
      });
      this.diretorias = todasDiretoriasDoUsuario;

      if(this.diretorias.length == 1){
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      }else{
        this.form.codigoDiretoria.enable();
      }
      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    var dadosUsuario = this.userLogado?.DadosUsuario[0];
    if(dadosUsuario?.CodigoDiretoria != 0 && dadosUsuario?.CodigoEscola != 0){
      this.userLogado?.DadosUsuario.forEach(item=>{
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas = [escola];
      });

      this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }
    this.spinnerService.hide();
    await this.getPsicologosPolo();
  }

  public async getDiretoriasPorPolo(diretoriaLogada:boolean = false) {
    if(this.userLogado?.codigoPolo){
      this.zerarDiretoria();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!response.isSucess){
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.getEscolasPorDiretoria();
      this.getPsicologosPolo();
      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
      this.spinnerService.hide();
    }

    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria ;
      this.authService.setUser(this.userLogado);
    }
  }

  public async getEscolasPorDiretoria(){
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = this.userLogado.codigoEscola == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if(this.userLogado?.codigoDiretoria){
      this.spinnerService.show();
      this.zerarEscola();
      var response = await lastValueFrom( this.escolaService.getEscolasPorCodigoDiretoria(this.userLogado!.codigoDiretoria))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }
        this.escolas = response.data;
        this.ativaEscola();
        this.spinnerService.hide();
    }

  }

  public ativaPolo(){
    if(this.userLogado?.codigoPolo != undefined)
      this.formVisita.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);

    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public ativaDiretoria(){
    if(this.diretorias != undefined && this.userLogado?.codigoDiretoria != 0){
      const diretoria = this.diretorias.find(s=>s.codigoDiretoria == this.userLogado?.codigoDiretoria )
      if(this.userLogado?.codigoDiretoria != undefined  && diretoria != undefined){
        this.formVisita.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
      }
    }
    if(this.userLogado !== null)
        this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){
    if(this.escolas != undefined && this.userLogado?.codigoEscola != 0){
      const escola = this.escolas.find(s=>s.codigoEscola == this.userLogado?.codigoEscola)
      if(this.userLogado?.codigoEscola != undefined  && escola != undefined){
        this.formVisita.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }
    }
  }

  public zerarDiretoria() {
    this.diretorias = [];
    this.formVisita.get('codigoDiretoria')?.patchValue(null);
  }
  public zerarEscola() {
    this.escolas = [];
    this.formVisita.get('codigoEscola')?.patchValue(null);
  }

  public cambioPolo()
  {
    const codigoPO = this.formVisita.get('codigoPolo')?.value;
    if(this.userLogado !== null)
    {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }
    this.form.codigoDiretoria.enable();
    this.form.codigoEscola.enable();
    this.zerarEscola();
    this.getDiretoriasPorPolo();
  }

  public async trocaDiretoria(){
    const codigoDiretoria = this.formVisita.get('codigoDiretoria')?.value;
    if(this.userLogado !== null && codigoDiretoria != null)
    {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
    if(codigoDiretoria != null)
      await this.getEscolasPorDiretoria();
    else
      this.escolas = [];
      this.form.codigoEscola.setValue(null);
  }

  public async getPsicologosPolo(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(this.formVisita.get('codigoPolo')!.value))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.lstPsicologos = response.data;
    if(this.userLogado?.codigoPerfil == Perfil.Psicólogo)
      this.setPsicologoInDropDown();
    this.spinnerService.hide();
  }

  public async OnSalvar(){
    this.spinnerService.show();
    if(!this.ValidaCampos()){
      this.spinnerService.hide();
      return;
    }
    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.formVisita.get('codigoDiretoria')?.value)[0];
    const esc = this.escolas.filter(r => r.codigoEscola == this.formVisita.get('codigoEscola')?.value)[0];
      const objVisitaPsicologo: VisitaPsicologo = {
      codigoPolo: this.formVisita.get('codigoPolo')?.value,
      codigoDiretoria: this.formVisita.get('codigoDiretoria')?.value,
      nomeDiretoria: dir.nomeDiretoria,
      codigoEscola: this.formVisita.get('codigoEscola')?.value,
      nomeEscola: esc.nomeEscola,
      tituloVisita: this.formVisita.get('tituloVisita')?.value,
      codigoPsicologo: this.formVisita.get('codigoPsicologo')?.value,
      dataVisitaInicioString: this.concatenaDataHora(this.formVisita.get('dataVisita')?.value,this.formVisita.get('horaInicio')?.value),
      dataVisitaFimString: this.concatenaDataHora(this.formVisita.get('dataVisita')?.value,this.formVisita.get('horaFim')?.value),
      descricaoVisita: this.formVisita.get('dsVisita')?.value,
      codigoAtividade: this.formVisita.get('codigoAtividade')?.value,
      codigoAcaoPreventiva: this.formVisita.get('codigoAcaoPreventiva')?.value,
      codigoUsuario: this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      listaAlunos: this.lstTurmaAluno,
      arquivos: this.files,
      };
    var responseCadastro = await lastValueFrom(this.psicologoService.setCadastrarVisitaPsicologo(objVisitaPsicologo))
    .catch((err) => {
      this.spinnerService.hide();
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!responseCadastro.isSucess){
      this.toastr.error(responseCadastro.message);
      this.spinnerService.hide();
      return;
    }
    const visita: VisitaPsicologo = responseCadastro.data;
    this.toastr.success('Visita do Psicologo cadastrada com sucesso.');
    this.OnLimpar();
    this.spinnerService.hide();
  }

  public ValidaCampos(){
    var erro = false;
    var polo = this.formVisita.get('codigoPolo')?.value;
    var diretoria = this.formVisita.get('codigoDiretoria')?.value;
    var escola = this.formVisita.get('codigoEscola')?.value;
    var titulo = this.formVisita.get('tituloVisita')?.value;
    var psicologo = this.formVisita.get('codigoPsicologo')?.value;
    var dataVisita = this.formVisita.get('dataVisita')?.value;
    var horaInicio = this.formVisita.get('horaInicio')?.value;
    var horaFim = this.formVisita.get('horaFim')?.value;
    var descricaoVisita = this.formVisita.get('dsVisita')?.value;
    var codigoAtividade = this.formVisita.get('codigoAtividade')?.value;
    var codigoAcaoPreventiva = this.formVisita.get('codigoAcaoPreventiva')?.value;
    if(polo == 0 || polo == undefined){
      this.toastr.error("Favor de preencher o Polo");
      erro = true;
    }
    if(diretoria == 0 || diretoria == undefined){
      this.toastr.error("Favor de preencher a Diretoria");
      erro = true;
    }
    if(escola == 0 || escola == undefined){
      this.toastr.error("Favor de preencher a Escola");
      erro = true;
    }
    if(titulo == "" || titulo == null || titulo == undefined){
      this.toastr.error("Favor de preencher o Titulo");
      erro = true;
    }
    if(psicologo == "" || psicologo == null || psicologo == undefined){
      this.toastr.error("Favor de preencher o Psicologo");
      erro = true;
    }
    if(dataVisita == "" || dataVisita == null || dataVisita == undefined){
      this.toastr.error("Favor de preencher a Data da Visita");
      erro = true;
    }
    if(descricaoVisita == "" || descricaoVisita == null || descricaoVisita == undefined){
      this.toastr.error("Favor de preencher a Descrição da Visita");
      erro = true;
    }
    if(codigoAtividade == "" || codigoAtividade == null || codigoAtividade == undefined){
      this.toastr.error("Favor de preencher a Atividade");
      erro = true;
    }
    if(codigoAcaoPreventiva == "" || codigoAcaoPreventiva == null || codigoAcaoPreventiva == undefined){
      this.toastr.error("Favor de preencher a Ação Preventiva");
      erro = true;
    }
    if(horaInicio == "" || horaInicio == null || horaInicio == undefined){
      this.toastr.error("Favor de preencher a Hora Inicio");
      erro = true;
    }
    if(horaFim == "" || horaFim == null || horaFim == undefined){
      this.toastr.error("Favor de preencher a Hora Fim");
      erro = true;
    }else{
      if(horaInicio>horaFim){
        this.toastr.error("A hora Inicio não pode ser maior que a Hora Fim");
        erro = true;
      }
    }

    this.lstTurmaAluno.forEach(element => {
      if((element.atendimentoClinico || element.encaminhamentoRedeSaude > 0) && (element.presenca == false || element.presenca == undefined)){
        this.toastr.error("Não podem existir Encaminhamentos para Rede de Saude ou Atendimentos clinicos se o aluno não tive presença");
        erro = true;
      }
    });
    if(erro) return false;
    else return true;
  }

  public OnLimpar() {
    this.spinnerService.show();
    this.formVisita.get('tituloVisita')?.patchValue(null);
    this.formVisita.get('codigoPsicologo')?.patchValue(null);
    this.formVisita.get('dataVisita')?.patchValue(null);
    this.formVisita.get('horaInicio')?.patchValue(null);
    this.formVisita.get('horaFim')?.patchValue(null);
    this.formVisita.get('dsVisita')?.patchValue(null);
    this.formVisita.get('codigoAtividade')?.patchValue(null);
    this.formVisita.get('codigoAcaoPreventiva')?.patchValue(null);
    this.lstTurmas = [];
    this.lstTurmasAtivas = [];
    this.lstTurmaAluno = [];
    this.files = [];
    this.showGridTurma = false;
    this.showGridAlunos = false;
    this.getPsicologosPolo();
    this.spinnerService.hide();
  }

  public async caregarTurmas() {
    this.submitted = true;
    const codigoEs = this.formVisita.get('codigoEscola');

    if(codigoEs?.value != null) {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.escolaService.getTurmasPorCodigoCie(codigoEs?.value, 0))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.lstTurmas = result.data;
      this.spinnerService.hide();


      this.showGridTurma = true;
      if(this.lstTurmas.length == 0){
        this.toastr.warning("Não existem Turmas para essa pesquisa");
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
            this.hideAfterLoadTable = true;
          });
        }
      }
      else{
        this.hideAfterLoadTable = false;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);

          });
        } else {
          this.isDtInitialized = true;

          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }

          this.dtTrigger.next(this.dtOptions);
        }
      }
    }
  }

  public async carregarAlunos() {

    if(this.lstTurmasAtivas.length > 0) {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.escolaService.getTurmaAlunos(this.lstTurmasAtivas))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.lstTurmaAluno = result.data;
      this.spinnerService.hide();
      this.showGridAlunos = true;
      if(this.lstTurmaAluno.length == 0){
        this.toastr.warning("Não existem Alunos para essa pesquisa");
        if(this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);
            this.hideAfterLoadTableAlunos = true;
          });
        }
      }
      else{
        this.hideAfterLoadTableAlunos = false;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next(this.dtOptions);

          });
        } else {
          this.isDtInitialized = true;

          if(this.dtElement.dtInstance != null) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            });
          }
          this.dtTrigger.next(this.dtOptions);
        }
      }
    }

  }

  public async onCheckTurma(codigoTurma: number){
    var existeEmLista: boolean = false;
    this.lstTurmasAtivas.forEach(element => {
      if(element == codigoTurma){
        existeEmLista = true;
      }
    });
    if(existeEmLista){
      const index = this.lstTurmasAtivas.indexOf(codigoTurma)
      this.lstTurmasAtivas.splice(index, 1);
    }else{
        this.lstTurmasAtivas.push(codigoTurma);
    }
    this.carregarAlunos();
  }

  public onCheckTodosFalse(){
    this.lstTurmaAluno.forEach(element => {
      element.presenca = false;
    });
  }

  public onCheckTodosTrue(){
    this.lstTurmaAluno.forEach(element => {
      element.presenca = true;
    });
  }

  public concatenaDataHora(data:string,hora:string): string{
    return `${data +" " + hora}`
  }

  public async OnFileChange(event: any) {
    const maxFileSize =  31 * 1024 * 1024; // 30 MB
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      for (const file of files) {
        if (file.size > maxFileSize) {
          this.toastr.warning('O tamanho máximo do arquivo é 30 MB.');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
        if(file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png" ||
          file.type ==  "application/pdf" ||
          file.type ==  "application/ppt" ||
          file.type ==  "application/pptx" ||
          file.type ==  "application/potx" ||
          file.type ==  "application/ppsx" ||
          file.type ==  "application/odp" ||
          file.type == "audio/mpeg" ||
          file.type == "video/mp4"
           ){
          formData.append(file.name, file);
        }
        else{
          this.toastr.warning('Só é possivel subir arquivos .jpg, .jpeg , .png, .pdf, .mp3 , .mp4, .ppt, .pptx, .potx e ppsx');
          const fileInput = document.getElementById('arquivos') as HTMLInputElement;
          if (fileInput) {
              fileInput.value = '';
          }
          this.spinnerService.hide();
          return;
        }
      }
      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }else {
        this.files.push(...result.data);
        event.target.value = '';
      }
      this.spinnerService.hide();
    }
  }

  public removeFile(index: number) {
    this.files.splice(index, 1);
    const fileInput = document.getElementById('arquivos') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  removeUniqueId(fileName: string): string {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/;
    return fileName.replace(regex, '');
  }

}
