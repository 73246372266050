import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class FuncionarioService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient,
    private router:Router)
  { }

  public getFuncionariosPorCpf(funcionarioCpf: string):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Funcionario.getFuncionarioPorCpf}?CPF=${funcionarioCpf}`)
                    .pipe(map((response: DefaultResponse) => response));
  }
}
