import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, ControlContainer, FormControl, FormGroup, Validators } from "@angular/forms";


@Component({
  selector: 'input-range',
  templateUrl: './input-range.component.html',
  styleUrls: ['./input-range.component.scss']
})

export class InputRangeComponent implements OnInit {
  ngOnInit() {}

}
