import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, map } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";

@Injectable({
  providedIn: 'root'
})

export class EscolaService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient,
    private router:Router)
    { }


  public getDiretoriaPorTipoDiretoria(tpDiretoria: number = 0):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Escola.getDiretoriaPorTipoDiretoria}?codigoTipoDiretoria=${tpDiretoria}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getEscolasPorCodigoDiretoria(codigoDiretoria: number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Escola.getEscolaPorCodigoDiretoria}?codigoDiretoria=${codigoDiretoria}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getTurnosPorCodigoCie(codigoEscola: number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Escola.getTurnosPorCodigoCie}?codigoEscola=${codigoEscola}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getTurmasPorCodigoCie(codigoEscola: number, codigoTurno: number):Observable<DefaultResponse> {
    let params: URLSearchParams = new URLSearchParams();
    return this.http.get<DefaultResponse>(`${this.api.Aluno.getTurmasPorCodigoCie}?codigoEscola=${codigoEscola}&codigoTurno=${codigoTurno}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getTurmaAlunos(listaTurmas: number[]):Observable<DefaultResponse>{
    return this.http.post<DefaultResponse>(this.api.Aluno.getTurmaAlunos, listaTurmas)
                      .pipe(map((response: DefaultResponse) => response));
  }

  public getEscolaDetalhes(codigoEscola: number):Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(`${this.api.Escola.getEscolaDetalhes}?codigoEscola=${codigoEscola}`)
                    .pipe(map((response: DefaultResponse) => response));
  }

}
