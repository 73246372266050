//Principal
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
//Services
import { PerfilService } from "src/app/shared/services/perfil.service";
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
//Model
import { FuncionalidadePerfil } from 'src/app/shared/models/Funcionalidade/FuncionalidadePerfil';

@Component({
  selector: 'app-funcionalidade-manage',
  templateUrl: './funcionalidade-manage.component.html',
  styleUrls: ['./funcionalidade-manage.component.scss']
})
export class FuncionalidadeManageComponent implements OnInit {

  public submitted = false;
  public funcionalidades: FuncionalidadePerfil[] = [];
  public formFuncionalidadeManage!: FormGroup;
  public desFuncionalidade: string;

  public get form() {
    return this.formFuncionalidadeManage.controls;
  }

  constructor(
    private perfilService: PerfilService,
    private spinnerService: NgxSpinnerService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
  ){

  }

  public ngOnInit(){
    this.formFuncionalidadeManage = new FormGroup({
      desFuncionalidade: new FormControl(null),
    });
    this.mostrarFuncionalidades();
  }

  public async mostrarFuncionalidades()
  {
    this.spinnerService.show();
    var response = await lastValueFrom(this.perfilService.getFuncionalidades())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.funcionalidades = response.data;
    this.spinnerService.hide();
  }

  public OnSalvar(){
    this.validarFuncionalidade();
  }

  public async validarFuncionalidade(){
    const funcionalidad = this.formFuncionalidadeManage.get('desFuncionalidade');
    let existe: boolean = false;
    if(funcionalidad?.value != null)
    {
      this.funcionalidades.forEach(funcionalidadFor => {
        if(funcionalidadFor.descricaoFuncionalidade.toLowerCase().trim() == funcionalidad?.value.toLowerCase().trim())
          existe = true;
      });
      if(existe)
        this.toastr.warning('Esta funcionalidade já existe.');
      else
      {
        const objetoFuncionalidade: FuncionalidadePerfil = {
          descricaoFuncionalidade: funcionalidad?.value
        }
        this.spinnerService.show();
        var response = await lastValueFrom(this.perfilService.setCadastrarFuncionalidade(objetoFuncionalidade))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!response.isSucess){
          this.toastr.error(response.message);
          this.spinnerService.hide();
          return;
        }
        this.mostrarFuncionalidades();
        this.spinnerService.hide();
        this.toastr.success('Funcionalidade cadastrada com sucesso.');
      }
    }

  }

}
