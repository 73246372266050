<div class="range-slider" style='--min:0; --max:180; --step:15; --value:15; --text-value:"15";'>
  <input
    id="input-range"
    type="range"
    min="0"
    max="180"
    step="15"
    value="15"
    oninput="this.parentNode.style.setProperty('--value',this.value); this.parentNode.style.setProperty('--text-value', JSON.stringify(this.value))">
  <output></output>
  <div class='range-slider__progress'></div>
</div>
