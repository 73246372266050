export enum Perfil{
  Psicólogo = 1634,
  CoordenadorPsicologo = 1632,
  GestorConviva = 1477,
  GestorCGRH = 1656,
  POC = 1647,
  DiretorEscolar = 272,
  PEC = 112,
  FiscalPsicologo = 1633

}
